import React, { StrictMode, useEffect, useMemo, useState } from "react";
import Stack from "react-bootstrap/Stack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
// import topCompanies from "../../data/topCompanies.json";
import "./home.scss";
import { nFormatter } from "../../helpers";
import { localString } from "../../localization/localString";
import moment from "moment";
import axios from "axios";
import SPSidebar from "../header/Sidebar";
import { apiEndPoints } from "../../services/endpoints";
import { WebService } from "../../services/webServices";

const PortfolioLeader = ({ lang, mode, user }) => {
  const [rowData, setRowData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (rowData === null) {
      let fullUrl = `${apiEndPoints.topPortfolio}`;
      setLoading(true);

      let headers = {
        // "Content-Type": "application/json",
        // Authorization: `Bearer ${user?.token}`,
      };
      WebService.get(fullUrl, { headers: headers }).then((response) => {
        let data = response?.data;
        setRowData(data);
        console.log(data);

        setLoading(false);
      });
    }
  }, []);

  const RankColRender = (p) => {
    return `${p.node.rowIndex + 1}`;
  };
  const ProfitRender = (p) => {
    if (p.data?.profit === undefined) return null;
    if (isNaN(parseFloat(p.data?.profit))) return "-";
    return (
      <div>
        {parseFloat(p.data?.profit) > 0 ? (
          <span className="chart-numbers-change-green">
            <i className="bi bi-arrow-up-short"></i>
            {`${Math.abs(
              parseFloat(p.data?.profit).toFixed(3)
            )}%`}
          </span>
        ) : (
          <span className="chart-numbers-change-red">
            <i className="bi bi-arrow-down-short"></i>
            {`${Math.abs(
              parseFloat(p.data?.profit).toFixed(3)
            )}%`}
          </span>
        )}
      </div>
    );
  };
  const NameRender = (p) => {
    return (
      <a target="__blank" href={`/portfolio/${p.data?.id}`}>
        {`${p.data.name} Portfolio`}
      </a>
    );
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "rank",
      headerName: localString[lang].rank,
      cellRenderer: RankColRender,
      minWidth: 80,
      maxWidth: 80,
      sortable: false,
    },
    {
      field: "name",
      headerName: localString[lang].portfolio.name,
      // headerName: localString[lang].name,
      cellRenderer: NameRender,
      minWidth: 200,
      sortable: false,
    },
    {
      field: "profit",
      cellRenderer: ProfitRender,
      headerName: "Profit & Loss",
      headerName: localString[lang].portfolio.pctReturns,
      minWidth: 100,
      sortable: false,
    },
    {
      field: "number_of_companies",
      headerName: localString[lang].portfolio.noOfCompanies,
      // headerName: localString[lang].noOfCompanies,
      // cellRenderer: MarketCapRender,
      minWidth: 120,
      sortable: false,
    },
  ]);

  return (
    <>
      <Stack
        className={`content m-4 ${
          mode === "dark" ? "background-dark" : "background-light"
        }`}
        gap={3}
      >
        <div
          className={`ag-theme-${mode === "dark" ? "quartz-dark" : "quartz"}`}
          style={{ height: "calc(100vh - 6rem)" }}
        >
          <AgGridReact
            loading={loading}
            rowData={rowData}
            columnDefs={columnDefs}
            autoSizeStrategy={{ type: "fitGridWidth" }}
            pagination={true}
            paginationPageSize={25}
            paginationPageSizeSelector={[25, 50, 100]}
          />
        </div>
      </Stack>
    </>
  );
};

export default PortfolioLeader;
