import React, { useEffect, useState } from "react";
import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { nFormatter, stringFormatter } from "../../helpers";
import { localString } from "../../localization/localString";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { apiEndPoints } from "../../services/endpoints";
import { WebService } from "../../services/webServices";

const Data = ({
  ticker,
  tickerInfo,
  oneYearGrowth,
  fiveYearGrowth,
  lang,
  mode,
  _setMarketCap,
  _profitability,
  _growingRevenue,
  _growingNetProfit,
  _profitabilityAnnual,
  _growingRevenueAnnual,
  _growingNetProfitAnnual,
}) => {
  const [country, setCountry] = useState("US");
  const [loading, setLoading] = useState(true);
  const [marketCap, setMarketCap] = useState(null);
  const [fcfYield, setFcfYield] = useState(null);
  const [totalCash, setTotalCash] = useState(null);
  const [totalDebt, setTotalDebt] = useState(null);
  const [cashDebtRatio, setCashDebtRatio] = useState(null);
  const [pegRatio, setPegRatio] = useState(null);
  const [overallRisk, setOverallRisk] = useState(null);
  const [sector, setSector] = useState(null);
  const [payoutRatio, setPayoutRatio] = useState(null);
  const [dividendYield, setDividendYield] = useState(null);
  const [fiveYearAvgDividendYield, setFiveYearAvgDividendYield] =
    useState(null);
  const [fiftyTwoWeekHigh, setFiftyTwoWeekHigh] = useState(null);
  const [pricetosalestrailing12Months, setPricetosalestrailing12Months] =
    useState(null);
  const [priceToBook, setPriceToBook] = useState(null);
  const [currency, setCurrency] = useState("$");
  const [trailingPE, setTrailingPE] = useState(null);
  const [profitability, setProfitability] = useState(null);
  const [growingRevenue, setGrowingRevenue] = useState(null);
  const [growingNetProfit, setGrowingNetProfit] = useState(null);
  const [profitabilityAnnual, setProfitabilityAnnual] = useState(null);
  const [growingRevenueAnnual, setGrowingRevenueAnnual] = useState(null);
  const [growingNetProfitAnnual, setGrowingNetProfitAnnual] = useState(null);
  const [historicalPE, setHistoricalPE] = useState(null);
  const [underHistoricalPE, setUnderHistoricalPE] = useState(null);

  useEffect(() => {
    setProfitability(_profitability);
  }, [_profitability]);
  useEffect(() => {
    setGrowingRevenue(_growingRevenue);
  }, [_growingRevenue]);
  useEffect(() => {
    setGrowingNetProfit(_growingNetProfit);
  }, [_growingNetProfit]);
  useEffect(() => {
    setProfitabilityAnnual(_profitabilityAnnual);
  }, [_profitabilityAnnual]);
  useEffect(() => {
    setGrowingRevenueAnnual(_growingRevenueAnnual);
  }, [_growingRevenueAnnual]);
  useEffect(() => {
    setGrowingNetProfitAnnual(_growingNetProfitAnnual);
  }, [_growingNetProfitAnnual]);

  useEffect(() => {
    let profile = tickerInfo?.profile;
    let marketCap = parseFloat(profile?.mktCap);

    setMarketCap(nFormatter(marketCap, 2));
    _setMarketCap(marketCap);
    setSector(tickerInfo?.sector);

    let ratios = tickerInfo?.ratios[0];
    setTrailingPE(parseFloat(ratios?.peRatioTTM).toFixed(3));
    setPricetosalestrailing12Months(
      parseFloat(ratios?.priceSalesRatioTTM).toFixed(3)
    );
    setPriceToBook(parseFloat(ratios?.priceToBookRatioTTM).toFixed(3));
    setPegRatio(parseFloat(ratios?.pegRatioTTM).toFixed(2));
    let metrics = tickerInfo?.metrics;
    let _yearHigh = parseFloat(metrics?.yearHigh);
    setFiftyTwoWeekHigh(nFormatter(_yearHigh, 2));

    let financialsQuarter = tickerInfo?.financialsQuarter;
    let data = financialsQuarter?.balance;
    let curData = data[data.length - 1];
    let _cash = parseFloat(curData?.cashAndCashEquivalents);
    let _currency =
      curData?.reportedCurrency === "USD" ? "$" : curData?.reportedCurrency;
    let _debt = parseFloat(curData?.totalDebt);
    let _cbyd = _cash / _debt;
    setTotalCash(nFormatter(_cash, 2));
    setTotalDebt(nFormatter(_debt, 2));
    setCashDebtRatio(_cbyd.toFixed(2));
    setLoading(true);
    setCurrency(_currency);

    let fullUrl = `${apiEndPoints.getKeyMetrics}/${ticker}/annual`;
    WebService.get(fullUrl).then((response) => {
      let data = response?.data;
      let curData = data[data.length - 1];
      let _fcf = parseFloat(curData?.freeCashFlowYield) * 100;
      setFcfYield(_fcf.toFixed(2));
      let _dividendYield = parseFloat(curData?.dividendYield) * 100;
      setDividendYield(_dividendYield.toFixed(3));
      console.log("DAASAA -- ", curData)
      setPayoutRatio(parseFloat(curData?.payoutRatio).toFixed(6));
      let _totalDividendYield = 0;
      for (let i = 1; i <= 5; i++) {
        _totalDividendYield += parseFloat(data[data.length - i]?.dividendYield);
      }
      let _fiveYearAvgDividendYield = (_totalDividendYield / 5) * 100;
      setFiveYearAvgDividendYield(_fiveYearAvgDividendYield.toFixed(3));
    });
    fullUrl = `${apiEndPoints.getScore}/${ticker}`;
    WebService.get(fullUrl).then((response) => {
      let curData = response?.data;
      let _piotroskiScore = parseFloat(curData?.piotroskiScore);
      setOverallRisk(_piotroskiScore);
    });
    fullUrl = `${apiEndPoints.getStockInfo}/${ticker}`;
    WebService.get(fullUrl).then((response) => {
      let curData = response?.data;
      let __historicalPE = parseFloat(curData?.pe_ratio_ttm_historical).toFixed(
        3
      );
      setHistoricalPE(__historicalPE);
    });

    setLoading(false);
  }, [ticker, tickerInfo]);

  const getTitle = (type) => {
    return (
      <Col xs={8} className="data-value">
        {localString[lang][type]}
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="info">
              {localString[lang].dataDescriptions[type]}
            </Tooltip>
          }
        >
          <i className="bi bi-info-circle m-2 font-1"></i>
        </OverlayTrigger>
      </Col>
    );
  };

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <Stack
          className={`content  ${
            mode === "dark" ? "background-dark" : "background-light"
          }`}
          gap={3}
        >
          <div className="d-flex flex-row flex-wrap bd-highlight data-container">
            <div className="p-2 bd-highlight flex-column">
              <div className="data-title">
                {localString[lang].FinancialMetrics}
              </div>
              <Row>
                {getTitle("MarketCap")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("$", marketCap, "")}
                </Col>
              </Row>
              <Row>
                {getTitle("PE")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", trailingPE, "")}
                </Col>
              </Row>
              <Row>
                {getTitle("PS")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", pricetosalestrailing12Months, "")}
                </Col>
              </Row>
              <Row>
                {getTitle("PB")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", priceToBook, "")}
                </Col>
              </Row>
              <Row>
                {getTitle("FCFYield")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", fcfYield, "%")}
                </Col>
              </Row>
              <Row>
                {getTitle("PEGRatio")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", pegRatio, "")}
                </Col>
              </Row>
              <Row>
                {getTitle("peHistorical")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", historicalPE, "")}
                </Col>
              </Row>
              <Row>
                {getTitle("peHistoricalCompare")}
                <Col
                  xs={3}
                  className={`data-value text-end data-value-metric ${
                    historicalPE > trailingPE ? "color-green" : "color-red"
                  }`}
                >
                  {`${historicalPE >= trailingPE ? "Under" : "Over"}`}
                </Col>

                {/* <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", pegRatio, "")}
                </Col> */}
              </Row>
            </div>
            <div className="p-2 bd-highlight flex-column">
              <div className="data-title">
                {localString[lang].QualityOfCompany}
              </div>
              <Row>
                {getTitle("OverallRisk")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", overallRisk, "")}
                </Col>
              </Row>
              <Row>
                {getTitle("week52HighPrice")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("$", fiftyTwoWeekHigh, "")}
                </Col>
              </Row>
              <Row>
                {getTitle("ProfitabilityAnnual")}
                <Col
                  xs={3}
                  className={`data-value text-end data-value-metric ${
                    profitabilityAnnual === "Yes" ? "color-green" : "color-red"
                  }`}
                >
                  {profitabilityAnnual}
                </Col>
              </Row>
              <Row>
                {getTitle("GrowingRevenueAnnual")}
                <Col
                  xs={3}
                  className={`data-value text-end data-value-metric ${
                    growingRevenueAnnual === "Yes" ? "color-green" : "color-red"
                  }`}
                >
                  {growingRevenueAnnual}
                </Col>
              </Row>
              <Row>
                {getTitle("GrowingNetProfitAnnual")}
                <Col
                  xs={3}
                  className={`data-value text-end data-value-metric ${
                    growingNetProfitAnnual === "Yes"
                      ? "color-green"
                      : "color-red"
                  }`}
                >
                  {growingNetProfitAnnual}
                </Col>
              </Row>
              <Row>
                {getTitle("PriceGrowthoverthepast5Years")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", fiveYearGrowth, "%")}
                </Col>
              </Row>
              <Row>
                {getTitle("PriceGrowthoverthepast1Years")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", oneYearGrowth, "%")}
                </Col>
              </Row>
            </div>
            <div className="p-2 bd-highlight flex-column">
              <div className="data-title">{localString[lang].BalanceSheet}</div>
              <Row>
                {getTitle("Cash")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter(currency, totalCash, "")}
                </Col>
              </Row>
              <Row>
                {getTitle("Debt")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter(currency, totalDebt, "")}
                </Col>
              </Row>
              <Row>
                {getTitle("CashDebtRatio")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", cashDebtRatio, "")}
                </Col>
              </Row>
              <Row>
                {getTitle("Profitability")}
                <Col
                  xs={3}
                  className={`data-value text-end data-value-metric ${
                    profitability === "Yes" ? "color-green" : "color-red"
                  }`}
                >
                  {profitability}
                </Col>
              </Row>
              <Row>
                {getTitle("GrowingRevenue")}
                <Col
                  xs={3}
                  className={`data-value text-end data-value-metric ${
                    growingRevenue === "Yes" ? "color-green" : "color-red"
                  }`}
                >
                  {growingRevenue}
                </Col>
              </Row>
              <Row>
                {getTitle("GrowingNetProfit")}
                <Col
                  xs={3}
                  className={`data-value text-end data-value-metric ${
                    growingNetProfit === "Yes" ? "color-green" : "color-red"
                  }`}
                >
                  {growingNetProfit}
                </Col>
              </Row>
            </div>
            <div className="p-2 bd-highlight flex-column">
              <div className="data-title">{localString[lang].Dividend}</div>
              <Row>
                {getTitle("DividendYield")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", dividendYield, "%")}
                </Col>
              </Row>
              <Row>
                {getTitle("PayoutRatio")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", payoutRatio, "")}
                </Col>
              </Row>
              <Row>
                {getTitle("FiveYearAvgDividendYield")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", fiveYearAvgDividendYield, "%")}
                </Col>
              </Row>
              {/* <Row>
                <Col sm={6} className="data-value">
                  {localString[LANG].DividendGrowthRateoverthepast5Years}
                </Col>
                <Col sm={6} className="data-value text-end data-value-metric">
                  TTT
                </Col>
              </Row> */}
            </div>
          </div>
        </Stack>
      )}
    </>
  );
};

export default Data;
