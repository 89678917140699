import React, { useEffect, useState } from "react";
import Stack from "react-bootstrap/Stack";

import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import Row from "react-bootstrap/Row";
import MarketCap from "../graphs/MarketCap";
import Price from "../graphs/Price";
import Revenue from "../graphs/Revenue";
import GrossMargin from "../graphs/GrossMargin";
import NetIncome from "../graphs/NetIncome";
import Ebitda from "../graphs/Ebitda";
import FreeCashFlow from "../graphs/FreeCashFlow";
import OperatingCashFlow from "../graphs/OperatingCashFlow";
import Eps from "../graphs/Eps";
import CashVsDebt from "../graphs/CashVsDebt";
import Dividend from "../graphs/Dividend";
import SharesIssued from "../graphs/SharesIssued";
import Ratios from "../graphs/Ratios";
import TotalExpenses from "../graphs/TotalExpenses";
import RevenueNetIncomeRatio from "../graphs/RevenueNetIncomeRatio";
import { DURATION } from "../../constants";
import { apiEndPoints } from "../../services/endpoints";
import { WebService } from "../../services/webServices";
import { localString } from "../../localization/localString";
import { getRandomIDPrefix } from "../../helpers";
import Pe from "../graphs/Pe";
import Ps from "../graphs/Ps";
import Pb from "../graphs/Pb";
import RevenueGrowth from "../graphs/RevenueGrowth";
import NetIncomeGrowth from "../graphs/NetIncomeGrowth";

const Chart = ({
  ticker,
  lang,
  mode,
  threeMonthGrowth,
  oneYearGrowth,
  fiveYearGrowth,
  yearlyChange,
  _marketCap,
}) => {
  const [duration, setDuration] = useState("quarterly");
  const [selectedId, setSelectedId] = useState(-1);
  const [rawData, setRawData] = useState(null);
  const [incomeStatement, setIncomeStatement] = useState(null);
  const [cashflowStatement, setCashflowStatement] = useState(null);
  const [balanceSheetStatement, setBalanceSheetStatement] = useState(null);
  const [keyMetrics, setKeyMetrics] = useState(null);
  const [currency, setCurrency] = useState("$");

  const randomPostfix = getRandomIDPrefix(); // Math.floor(100000 + Math.random() * 900000);

  useEffect(() => {
    if (ticker !== null && ticker !== "" && ticker?.length !== 0) {
      let fullUrl = `${apiEndPoints.getIncomeStatement}/${ticker}/${duration}/`;
      WebService.get(fullUrl).then((response) => {
        let data = response?.data;
        setIncomeStatement(data);
        if (data.length > 0) {
          setCurrency(
            data[0]?.reportedCurrency === "USD"
              ? "$"
              : `${data[0]?.reportedCurrency} `
          );
        }
      });
      fullUrl = `${apiEndPoints.getKeyMetrics}/${ticker}/${duration}/`;
      WebService.get(fullUrl).then((response) => {
        let data = response?.data;
        setKeyMetrics(data);
      });
      fullUrl = `${apiEndPoints.getCashflowStatement}/${ticker}/${duration}/`;
      WebService.get(fullUrl).then((response) => {
        let data = response?.data;
        setCashflowStatement(data);
      });
      fullUrl = `${apiEndPoints.getBalanceSheetStatement}/${ticker}/${duration}/`;
      WebService.get(fullUrl).then((response) => {
        let data = response?.data;
        setBalanceSheetStatement(data);
      });
    }
  }, [ticker, duration]);

  return (
    <>
      <Stack className="chart-content" gap={3}>
        <ToggleButtonGroup
          className="chart-button-group"
          type="radio"
          name="options"
        >
          <ToggleButton
            id={`tbg-radio-1-${randomPostfix}`}
            variant={duration === DURATION.QUARTERLY ? "info" : "secondary"}
            value={DURATION.QUARTERLY}
            checked={duration === DURATION.QUARTERLY}
            onChange={(e) => {
              setDuration(e.currentTarget.value);
            }}
          >
            {localString[lang].Quarterly}
          </ToggleButton>
          <ToggleButton
            id={`tbg-radio-2-${randomPostfix}`}
            // id="tbg-radio-2"
            variant={duration === DURATION.YEARLY ? "info" : "secondary"}
            value={DURATION.YEARLY}
            checked={duration === DURATION.YEARLY}
            onChange={(e) => {
              setDuration(e.currentTarget.value);
            }}
          >
            {localString[lang].Annually}
          </ToggleButton>
        </ToggleButtonGroup>
        <div className="d-flex flex-row flex-wrap bd-highlight chart-container">
          <Row className="chart-row">
            <Price
              index={1}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              duration={duration}
              ticker={ticker}
              mode={mode}
              lang={lang}
              threeMonthGrowth={threeMonthGrowth}
              oneYearGrowth={oneYearGrowth}
              yearlyChange={yearlyChange}
            />
            {_marketCap && keyMetrics?.length && (
              <MarketCap
                index={2}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                mode={mode}
                lang={lang}
                _marketCap={_marketCap}
                rawData={keyMetrics}
                currency={currency}
              />
            )}
            {incomeStatement?.length > 0 && (
              <Revenue
                index={3}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={incomeStatement}
                mode={mode}
                lang={lang}
                currency={currency}
              />
            )}
            {incomeStatement?.length > 0 && (
              <RevenueNetIncomeRatio
                index={4}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={incomeStatement}
                mode={mode}
                lang={lang}
                currency={currency}
              />
            )}
            {incomeStatement?.length > 0 && (
              <TotalExpenses
                index={14}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={incomeStatement}
                mode={mode}
                lang={lang}
                currency={currency}
              />
            )}

            {incomeStatement?.length > 0 && (
              <GrossMargin
                index={5}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={incomeStatement}
                mode={mode}
                lang={lang}
              />
            )}
            {incomeStatement?.length > 0 && (
              <NetIncome
                index={6}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={incomeStatement}
                mode={mode}
                lang={lang}
                currency={currency}
              />
            )}
            {incomeStatement?.length > 0 && (
              <Ebitda
                index={7}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={incomeStatement}
                mode={mode}
                lang={lang}
                currency={currency}
              />
            )}
            {cashflowStatement?.length > 0 && (
              <FreeCashFlow
                index={8}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={cashflowStatement}
                mode={mode}
                lang={lang}
                currency={currency}
              />
            )}
            {cashflowStatement?.length > 0 && (
              <OperatingCashFlow
                index={9}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={cashflowStatement}
                mode={mode}
                lang={lang}
                currency={currency}
              />
            )}
            {incomeStatement?.length > 0 && (
              <Eps
                index={10}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={incomeStatement}
                mode={mode}
                lang={lang}
              />
            )}
            {balanceSheetStatement?.length > 0 && (
              <CashVsDebt
                index={11}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={balanceSheetStatement}
                mode={mode}
                lang={lang}
                currency={currency}
              />
            )}
            {cashflowStatement?.length > 0 && (
              <Dividend
                index={12}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={cashflowStatement}
                mode={mode}
                lang={lang}
                currency={currency}
              />
            )}
            {keyMetrics?.length > 0 && (
              <Pe
                index={13}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={keyMetrics}
                mode={mode}
                lang={lang}
              />
            )}
            {keyMetrics?.length > 0 && (
              <Ps
                index={14}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={keyMetrics}
                mode={mode}
                lang={lang}
              />
            )}
            {keyMetrics?.length > 0 && (
              <Pb
                index={15}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={keyMetrics}
                mode={mode}
                lang={lang}
              />
            )}
            {incomeStatement?.length > 0 && (
              <RevenueGrowth
                index={16}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={incomeStatement}
                mode={mode}
                lang={lang}
              />
            )}
            {incomeStatement?.length > 0 && (
              <NetIncomeGrowth
                index={17}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={incomeStatement}
                mode={mode}
                lang={lang}
              />
            )}

            {rawData?.balanceSheet?.hist?.sharesIssued?.length > 0 && (
              <SharesIssued
                index={13}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={rawData?.balanceSheet}
                mode={mode}
                lang={lang}
              />
            )}
            {/* <Ratios
              index={14}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              duration={duration}
            /> */}
          </Row>
        </div>
      </Stack>
    </>
  );
};

export default Chart;
