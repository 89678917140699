import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import { Typeahead } from "react-bootstrap-typeahead";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import Data from "./Data";
import Chart from "./Chart";
import "./home.scss";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { WebService } from "../../services/webServices";
import { apiEndPoints } from "../../services/endpoints";
import { localString } from "../../localization/localString";
import { NULL_TICKER } from "../../constants";
import { getRandomIDPrefix } from "../../helpers";
import { Helmet } from "react-helmet";
import SimilarCompanies from "./SimilarCompanies";

const Home = ({
  allTickers,
  lang,
  mode,
  starredTickers,
  updateStarred,
  compare,
  setTabTitle,
  tabKey,
  tabTicker,
}) => {
  const navigate = useNavigate();
  const searchRef = useRef();
  // const [country, setCountry] = useState("US");
  const [companyName, setCompanyName] = useState("");
  const [sharePrice, setSharePrice] = useState("");
  const [priceDiff, setPriceDiff] = useState("");
  const [industry, setIndustry] = useState("");
  const [sector, setSector] = useState("");
  const [longSummary, setLongSummary] = useState("");
  const [metaTitle, setMetaTitle] = useState(localString[lang].homeMetaTitle);
  const [metaDescription, setMetaDescription] = useState(
    localString[lang].homeMetaDescription
  );

  const [priceDiffPercentage, setPriceDiffPercentage] = useState("");
  const [ticker, setTicker] = useState(null);
  const [tickerObj, setTickerObj] = useState(null);
  const [tickerInfo, setTickerInfo] = useState(null);
  const [tickerInputText, setTickerInputText] = useState(null);
  const [fiveYearGrowth, setFiveYearGrowth] = useState(null);
  const [oneYearGrowth, setOneYearGrowth] = useState(null);
  const [threeMonthGrowth, setThreeMonthGrowth] = useState(null);
  const [_marketCap, _setMarketCap] = useState(null);
  const [loading, setLoading] = useState(false);
  const [starred, setStarred] = useState(false);
  const [_profitability, setProfitability] = useState(null);
  const [_growingRevenue, setGrowingRevenue] = useState(null);
  const [_growingNetProfit, setGrowingNetProfit] = useState(null);
  const [_profitabilityAnnual, setProfitabilityAnnual] = useState(null);
  const [_growingRevenueAnnual, setGrowingRevenueAnnual] = useState(null);
  const [_growingNetProfitAnnual, setGrowingNetProfitAnnual] = useState(null);
  const [yearlyChange, setYearlyChange] = useState(null);

  const randomPostfix = getRandomIDPrefix();

  useEffect(() => {
    if (tabTicker === NULL_TICKER) {
      return;
    } else if (tabTicker !== undefined) {
      let tickerObj = allTickers.find(
        (o) => o?.ticker?.toLowerCase() === tabTicker?.toLowerCase()
      );
      setTickerObj(tickerObj);
    }
  }, []);

  useEffect(() => {
    _setMarketCap(null);
    if (tickerObj) {
      setTicker(tickerObj?.ticker);
      setMetaTitle(`${tickerObj.label} ${localString[lang].pageMetaTitle}`);
      setMetaDescription(
        `${tickerObj.label} ${localString[lang].pageMetaDescription}`
      );
    } else {
      setTicker(null);
      setMetaTitle(localString[lang].homeMetaTitle);
      setMetaDescription(localString[lang].homeMetaDescription);
    }
    let itemIndex = starredTickers?.findIndex(
      (o) => o.ticker === tickerObj?.ticker
    );

    setStarred(itemIndex === -1 || itemIndex === undefined ? false : true);
  }, [tickerObj]);

  useEffect(() => {
    _setMarketCap(null);
    if (ticker !== null) {
      handleSearch();
    }
  }, [ticker]);

  const handleSearch = (bypassTicker = false) => {
    if (compare === true) {
      if (ticker === null || bypassTicker) {
        if (tickerInputText !== null || tickerInputText !== "") {
          let tickerObj = allTickers.find(
            (o) => o?.ticker?.toLowerCase() === tickerInputText?.toLowerCase()
          );
          if (tickerObj) {
            setTickerObj(tickerObj);
          } else if (bypassTicker) {
            setTickerObj(null);
          }
        }
      }
      handleSubmit();
      return;
    }
    if (ticker === null || bypassTicker) {
      if (tickerInputText !== null || tickerInputText !== "") {
        let tickerObj = allTickers.find(
          (o) => o?.ticker?.toLowerCase() === tickerInputText?.toLowerCase()
        );
        if (tickerObj) {
          setTickerObj(tickerObj);
        } else if (bypassTicker) {
          setTickerObj(null);
        } else {
          navigate(`/companies/stock-metrics/${tickerInputText}`);
        }
      }
    } else {
      navigate(`/companies/stock-metrics/${ticker}`);
    }
    handleSubmit();
    return;
  };

  const handleSubmit = () => {
    if (ticker !== null && ticker !== "" && ticker?.length !== 0) {
      let fullUrl = `${apiEndPoints.getCompanyOutlook}/${ticker}/`;
      setLoading(true);
      WebService.get(fullUrl).then((response) => {
        let data = response?.data;
        let profile = data?.profile;
        let currentPrice = parseFloat(profile?.price);
        let diff = parseFloat(profile?.changes);
        let diffPercentage = (diff / currentPrice) * 100;

        setCompanyName(profile?.companyName);
        setTabTitle && setTabTitle(tabKey, ticker, ticker);
        setSharePrice(currentPrice);
        setPriceDiff(diff.toFixed(2));
        setPriceDiffPercentage(diffPercentage.toFixed(2));
        setIndustry(profile?.industry);
        setSector(profile?.sector);
        setLongSummary(profile?.longBusinessSummary);
        setTickerInfo(data);

        let histAnnual = data?.financialsAnnual?.income;
        let histQuarter = data?.financialsQuarter?.income;
        let p_currentValue = histAnnual
          ? parseFloat(histAnnual[0]?.netIncome)
          : 0;
        let p_prevValue = histAnnual ? parseFloat(histAnnual[1]?.netIncome) : 0;
        let r_currentValue = histAnnual
          ? parseFloat(histAnnual[0]?.revenue)
          : 0;
        let r_prevValue = histAnnual ? parseFloat(histAnnual[0]?.revenue) : 0;
        setProfitabilityAnnual(p_currentValue > 0 ? "Yes" : "No");
        setGrowingNetProfitAnnual(p_currentValue > p_prevValue ? "Yes" : "No");
        setGrowingRevenueAnnual(r_currentValue > r_prevValue ? "Yes" : "No");
        p_currentValue = histQuarter
          ? parseFloat(histQuarter[0]?.netIncome)
          : 0;
        p_prevValue = histQuarter ? parseFloat(histQuarter[1]?.netIncome) : 0;
        r_currentValue = histQuarter ? parseFloat(histQuarter[0]?.revenue) : 0;
        r_prevValue = histQuarter ? parseFloat(histQuarter[1]?.revenue) : 0;
        setProfitability(p_currentValue > 0 ? "Yes" : "No");
        setGrowingNetProfit(p_currentValue > p_prevValue ? "Yes" : "No");
        setGrowingRevenue(r_currentValue > r_prevValue ? "Yes" : "No");
      });

      fullUrl = `${apiEndPoints.getPriceGrowth}/${ticker}/`;
      WebService.get(fullUrl).then((response) => {
        let data = response?.data;
        let _fiveYearGrowth = data && data["5Y"];
        let _oneYearGrowth = data && data["1Y"];
        let _threeMonthGrowth = data && data["3M"];

        _oneYearGrowth = isNaN(_oneYearGrowth) ? 0 : _oneYearGrowth;
        _fiveYearGrowth = isNaN(_fiveYearGrowth) ? 0 : _fiveYearGrowth;
        _threeMonthGrowth = isNaN(_threeMonthGrowth) ? 0 : _threeMonthGrowth;
        setYearlyChange(data);
        setOneYearGrowth(_oneYearGrowth.toFixed(2));
        setFiveYearGrowth(_fiveYearGrowth.toFixed(2));
        setThreeMonthGrowth(_threeMonthGrowth.toFixed(2));
        setLoading(false);
      });
    }
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      handleSearch(true);
      searchRef.current.blur();
    }
  };

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        {/* <link rel="canonical" href={"canonicalUrl.com"} /> */}
      </Helmet>

      <Stack
        className={`content ${
          mode === "dark" ? "background-dark" : "background-light"
        }`}
        gap={3}
      >
        <Stack className={`text-center`}>
          <div className="input-content">
            <InputGroup id={`typehead-main-ig-${randomPostfix}`}>
              <Typeahead
                ref={searchRef}
                id={`typehead-main-transform-${randomPostfix}`}
                input={`.typehead-main-ig-${randomPostfix}`}
                onChange={(data) => {
                  setTickerObj(data[0]);
                }}
                onInputChange={(data) => {
                  setTickerInputText(data);
                }}
                onKeyDown={(event) => {
                  handleEnter(event);
                }}
                inputProps={{
                  style: {
                    borderBottomLeftRadius: "20px",
                    borderTopLeftRadius: "20px",
                  },
                }}
                options={allTickers}
                placeholder={localString[lang].SearchBarPlaceholder}
                // selected={tickerObj}
              />
              <Button
                variant="outline-secondary"
                id={`search-button-${randomPostfix}`}
                onClick={() => {
                  handleSearch();
                }}
                className="search-button"
              >
                {localString[lang].Search}
              </Button>
            </InputGroup>
          </div>
          {ticker && tickerInfo && !loading && (
            <>
              <div className="pt-1 pb-2 d-flex justify-content-center align-items-center search-company-name">
                {companyName}
              </div>
              <i
                onClick={() => {
                  setStarred(!starred);
                  updateStarred(tickerObj);
                }}
                className={`bi ${
                  starred === true ? "bi-star-fill " : "bi-star "
                } ms-3 cursor-pointer star`}
              ></i>
              <div className="pt-2 pb-2 d-flex justify-content-center align-items-center search-data">
                {`$${sharePrice}`}
                <div
                  className={
                    priceDiff > 0
                      ? "search-sub-data-green"
                      : "search-sub-data-red"
                  }
                >
                  {`$${parseFloat(Math.abs(priceDiff)).toFixed(
                    2
                  )} | ${parseFloat(Math.abs(priceDiffPercentage)).toFixed(
                    2
                  )}%`}
                </div>
              </div>
              <div className="pb-3 justify-content-center align-items-center search-desc">
                <div>
                  <span>{`${localString[lang].Industry}:`}</span>&nbsp;
                  <span className="fw-bold">{industry}</span>
                </div>
                <span className="fw-bold margin-5-h search-divider">|</span>
                <div>
                  <span>{`${localString[lang].Sector}:`}</span>&nbsp;
                  <span className="fw-bold">{sector}</span>
                </div>
              </div>
              {/* <div className="pb-3 d-flex justify-content-center align-items-center">
                <span>{longSummary}</span>&nbsp;
              </div> */}
            </>
          )}
        </Stack>
      </Stack>
      {loading && (
        <div className="loader">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {ticker && tickerInfo && !loading && (
        <>
          <Data
            ticker={ticker}
            tickerInfo={tickerInfo}
            oneYearGrowth={oneYearGrowth}
            fiveYearGrowth={fiveYearGrowth}
            lang={lang}
            mode={mode}
            _setMarketCap={_setMarketCap}
            _profitability={_profitability}
            _growingRevenue={_growingRevenue}
            _growingNetProfit={_growingNetProfit}
            _profitabilityAnnual={_profitabilityAnnual}
            _growingRevenueAnnual={_growingRevenueAnnual}
            _growingNetProfitAnnual={_growingNetProfitAnnual}
          />
          <SimilarCompanies
            ticker={ticker}
            tickerInfo={tickerInfo}
            oneYearGrowth={oneYearGrowth}
            fiveYearGrowth={fiveYearGrowth}
            lang={lang}
            mode={mode}
            _setMarketCap={_setMarketCap}
            _profitability={_profitability}
            _growingRevenue={_growingRevenue}
            _growingNetProfit={_growingNetProfit}
            _profitabilityAnnual={_profitabilityAnnual}
            _growingRevenueAnnual={_growingRevenueAnnual}
            _growingNetProfitAnnual={_growingNetProfitAnnual}
          />
          <Chart
            ticker={ticker}
            lang={lang}
            mode={mode}
            threeMonthGrowth={threeMonthGrowth}
            oneYearGrowth={oneYearGrowth}
            fiveYearGrowth={fiveYearGrowth}
            _marketCap={_marketCap}
            yearlyChange={yearlyChange}
          />
        </>
      )}
    </>
  );
};

export default Home;
