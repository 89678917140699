/* eslint-disable max-len */
export const rootConfig = {
  // apiRoot: "http://localhost:8000",
  apiRoot: "https://api.stockpenguins.com",
};

export const primaryRootConfig = rootConfig.apiRoot; // primary root
export const apiEndPoints = {
  // V1
  getTickerProfile: `${primaryRootConfig}/v1/stocks/get-profile`,
  getTickerprofitability: `${primaryRootConfig}/v1/stocks/get-profitability`,
  getPriceGrowth: `${primaryRootConfig}/v1/stocks/get-price-change`,
  getKeyMetrics: `${primaryRootConfig}/v1/stocks/get-keymetrics`,
  getBalanceSheet: `${primaryRootConfig}/v1/stocks/get-balance-sheet`,
  getScore: `${primaryRootConfig}/v1/stocks/get-score`,
  getStockInfo: `${primaryRootConfig}/v1/stocks/get-stock-info`,
  getQuote: `${primaryRootConfig}/v1/stocks/get-quote`,
  getIncomeStatement: `${primaryRootConfig}/v1/stocks/get-income-statement`,
  getCashflowStatement: `${primaryRootConfig}/v1/stocks/get-cashflow-statement`,
  getBalanceSheetStatement: `${primaryRootConfig}/v1/stocks/get-balance-sheet`,
  getHistoricalPrice: `${primaryRootConfig}/v1/stocks/get-historical-price`,
  getCompanyList: `${primaryRootConfig}/v1/stocks/get-company-list`,
  getTopCompanies: `${primaryRootConfig}/v1/stocks/get-top-companies`,
  getCompanyOutlook: `${primaryRootConfig}/v1/stocks/get-company-outlook`,
  similarCompanies: `${primaryRootConfig}/v1/stocks/similar-companies`,
  savePresets: `${primaryRootConfig}/v1/stocks/save-presets`,
  getPresets: `${primaryRootConfig}/v1/stocks/get-presets`,
  getTopPresets: `${primaryRootConfig}/v1/stocks/get-top-presets`,
  upvote: `${primaryRootConfig}/v1/stocks/upvote`,
  getStockHoldings: `${primaryRootConfig}/v1/stocks/assets`,
  sell: `${primaryRootConfig}/v1/stocks/sell`,
  buy: `${primaryRootConfig}/v1/stocks/buy`,
  portfolio: `${primaryRootConfig}/v1/stocks/portfolio`,
  transactions: `${primaryRootConfig}/v1/stocks/transactions`,
  portfolioHistory: `${primaryRootConfig}/v1/stocks/portfolio-history`,
  portfolioName: `${primaryRootConfig}/v1/stocks/portfolio-name`,
  topPortfolio: `${primaryRootConfig}/v1/stocks/top-portfolio`,
  
  othersTransactions: `${primaryRootConfig}/v1/stocks/others-transactions`,
  othersGetStockHoldings: `${primaryRootConfig}/v1/stocks/others-assets`,
  othersPortfolio: `${primaryRootConfig}/v1/stocks/others-portfolio`,
  othersPortfolioHistory: `${primaryRootConfig}/v1/stocks/others-portfolio-history`,
  
  login: `${primaryRootConfig}/v1/profiles/login`,
  register: `${primaryRootConfig}/v1/profiles/register`,
  resetPasswordOtp: `${primaryRootConfig}/v1/profiles/reset-password-otp`,
  resetPassword: `${primaryRootConfig}/v1/profiles/reset-password`,
  socialLogin: `${primaryRootConfig}/v1/profiles/social/google`,
};
