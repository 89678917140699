import React, { StrictMode, useEffect, useMemo, useState, useRef } from "react";
import Stack from "react-bootstrap/Stack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
// import topCompanies from "../../data/topCompanies.json";
import "./home.scss";
import { nFormatter } from "../../helpers";
import { localString } from "../../localization/localString";
import moment from "moment";
import axios from "axios";
import SPSidebar from "../header/Sidebar";
import { apiEndPoints } from "../../services/endpoints";
import { WebService } from "../../services/webServices";
import Button from "react-bootstrap/Button";
import { Typeahead } from "react-bootstrap-typeahead";
import InputGroup from "react-bootstrap/InputGroup";
import compaines from "../../data/companies.json";

const TopCompanies = ({ lang, mode, user, showSearch = false }) => {
  const [rowData, setRowData] = useState(null);
  const [updateTime, setUpdateTime] = useState(null);
  const [order, setOrder] = useState("-market_cap");
  const [orderMetric, setOrderMetric] = useState("MarketCap");
  const [loading, setLoading] = useState(true);
  const searchRef = useRef();

  const [totalMarketCap, setTotalMarketCap] = useState(0);

  useEffect(() => {
    let fullUrl = `${apiEndPoints.getTopCompanies}/?o=${order}`;
    setLoading(true);

    let headers = {
      // "Content-Type": "application/json",
      // Authorization: `Bearer ${user?.token}`,
    };

    WebService.get(fullUrl, { headers: headers }).then((response) => {
      let data = response?.data;
      setRowData(data);

      let updateDate = new Date(
        Math.max.apply(
          null,
          data?.map(function (e) {
            return new Date(e.updated_at);
          })
        )
      );
      let totalMarCap = data?.reduce((n, { market_cap }) => n + market_cap, 0);
      setTotalMarketCap(totalMarCap);
      setUpdateTime(updateDate.toDateString());
      setLoading(false);
    });
  }, [order]);

  const RankColRender = (p) => {
    return `${p.node.rowIndex + 1}`;
  };

  const TodayColRender = (p) => {
    return (
      <div>
        {parseFloat(p.data.pctchange) > 0 ? (
          <span className="chart-numbers-change-green">
            <i className="bi bi-arrow-up-short"></i>
            {`${Math.abs(parseFloat(p.data.pctchange))}%`}
          </span>
        ) : (
          <span className="chart-numbers-change-red">
            <i className="bi bi-arrow-down-short"></i>
            {`${Math.abs(parseFloat(p.data.pctchange))}%`}
          </span>
        )}
      </div>
    );
  };

  const OneDayChangeRender = (p) => {
    if (p.data?.price_performance_1D === undefined) return null;
    if (isNaN(parseFloat(p.data?.price_performance_1D))) return "-";
    return (
      <div>
        {parseFloat(p.data?.price_performance_1D) > 0 ? (
          <span className="chart-numbers-change-green">
            <i className="bi bi-arrow-up-short"></i>
            {`${Math.abs(
              parseFloat(p.data?.price_performance_1D).toFixed(3)
            )}%`}
          </span>
        ) : (
          <span className="chart-numbers-change-red">
            <i className="bi bi-arrow-down-short"></i>
            {`${Math.abs(
              parseFloat(p.data?.price_performance_1D).toFixed(3)
            )}%`}
          </span>
        )}
      </div>
    );
  };
  const OneMonthChangeRender = (p) => {
    if (p.data?.price_performance_1M === undefined) return null;
    if (isNaN(parseFloat(p.data?.price_performance_1M))) return "-";
    return (
      <div>
        {parseFloat(p.data?.price_performance_1M) > 0 ? (
          <span className="chart-numbers-change-green">
            <i className="bi bi-arrow-up-short"></i>
            {`${Math.abs(
              parseFloat(p.data?.price_performance_1M).toFixed(3)
            )}%`}
          </span>
        ) : (
          <span className="chart-numbers-change-red">
            <i className="bi bi-arrow-down-short"></i>
            {`${Math.abs(
              parseFloat(p.data?.price_performance_1M).toFixed(3)
            )}%`}
          </span>
        )}
      </div>
    );
  };
  const OneYearChangeRender = (p) => {
    if (p.data?.price_performance_1Y === undefined) return null;
    if (isNaN(parseFloat(p.data?.price_performance_1Y))) return "-";
    return (
      <div>
        {parseFloat(p.data?.price_performance_1Y) > 0 ? (
          <span className="chart-numbers-change-green">
            <i className="bi bi-arrow-up-short"></i>
            {`${Math.abs(
              parseFloat(p.data?.price_performance_1Y).toFixed(3)
            )}%`}
          </span>
        ) : (
          <span className="chart-numbers-change-red">
            <i className="bi bi-arrow-down-short"></i>
            {`${Math.abs(
              parseFloat(p.data?.price_performance_1Y).toFixed(3)
            )}%`}
          </span>
        )}
      </div>
    );
  };
  const MarketCapRender = (p) => {
    if (p.data?.market_cap === undefined) return null;
    return `$${nFormatter(p.data?.market_cap, 4)}`;
  };
  const NetIncomeRender = (p) => {
    if (p.data?.net_income === undefined) return null;
    let cur = p.data?.currency === "USD" ? "$" : `${p.data?.currency} `;
    return `${cur}${nFormatter(p.data?.net_income, 4)}`;
  };
  const PriceRender = (p) => {
    if (p.data?.price === undefined) return null;
    return `$${nFormatter(p.data?.price, 4)}`;
  };
  const NameRender = (p) => {
    return (
      <a target="__blank" href={`/companies/stock-metrics/${p.data?.symbol}`}>
        {" "}
        {p.data.company_name}{" "}
      </a>
    );
  };

  const peRender = (p) => {
    if (p.data?.pe_ratio_ttm === undefined) return null;
    if (isNaN(parseFloat(p.data?.pe_ratio_ttm))) return "-";
    return <div>{parseFloat(p.data?.pe_ratio_ttm).toFixed(3)}</div>;
  };
  const pbRender = (p) => {
    if (p.data?.pb_ratio_ttm === undefined) return null;
    if (isNaN(parseFloat(p.data?.pb_ratio_ttm))) return "-";
    return <div>{parseFloat(p.data?.pb_ratio_ttm).toFixed(3)}</div>;
  };
  const psRender = (p) => {
    if (p.data?.ps_ratio_ttm === undefined) return null;
    if (isNaN(parseFloat(p.data?.ps_ratio_ttm))) return "-";
    return <div>{parseFloat(p.data?.ps_ratio_ttm).toFixed(3)}</div>;
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "rank",
      headerName: localString[lang].rank,
      cellRenderer: RankColRender,
      minWidth: 80,
      maxWidth: 80,
      sortable: false,
    },
    {
      field: "company_name",
      headerName: localString[lang].companyName,
      cellRenderer: NameRender,
      minWidth: 200,
      sortable: false,
    },
    {
      field: "symbol",
      headerName: localString[lang].symbol,
      minWidth: 100,
      maxWidth: 120,
      sortable: false,
    },
    {
      field: "market_cap",
      headerName: localString[lang].marketCap,
      cellRenderer: MarketCapRender,
      minWidth: 120,
      sortable: true,
    },
    {
      field: "price",
      headerName: localString[lang].price,
      cellRenderer: PriceRender,
      minWidth: 120,
      maxWidth: 150,
      sortable: true,
    },
    {
      field: "net_income",
      headerName: localString[lang]["titles"].NetIncome,
      cellRenderer: NetIncomeRender,
      minWidth: 120,
      maxWidth: 150,
      sortable: true,
    },
    {
      field: "pe_ratio_ttm",
      headerName: localString[lang].PE,
      cellRenderer: peRender,
      minWidth: 120,
      maxWidth: 120,
      sortable: true,
    },
    {
      field: "pb_ratio_ttm",
      headerName: localString[lang].PB,
      cellRenderer: pbRender,
      minWidth: 120,
      maxWidth: 120,
      sortable: true,
    },
    {
      field: "ps_ratio_ttm",
      headerName: localString[lang].PS,
      cellRenderer: psRender,
      minWidth: 120,
      maxWidth: 120,
      sortable: true,
    },
    {
      field: "price_performance_1D",
      headerName: localString[lang].today,
      cellRenderer: OneDayChangeRender,
      minWidth: 120,
      maxWidth: 120,
      sortable: true,
    },
    {
      field: "price_performance_1M",
      headerName: localString[lang].oneMonthChange,
      cellRenderer: OneMonthChangeRender,
      minWidth: 150,
      maxWidth: 150,
      sortable: true,
    },
    {
      field: "price_performance_1Y",
      headerName: localString[lang].oneYearChange,
      cellRenderer: OneYearChangeRender,
      minWidth: 150,
      maxWidth: 150,
      sortable: true,
    },
    {
      field: "country",
      headerName: localString[lang].country,
      minWidth: 120,
      sortable: false,
    },
  ]);

  return (
    <>
      {/* {rowData && ( */}
      <>
        {/* <span
          className="m-4"
          style={{
            position: "absolute",
            right: "0px",
            top: "-22px",
            fontSize: "12px",
          }}
        >
          Last Updated: {moment(updateTime).format("DD-MMM-YY HH:MM")}
        </span> */}
        <Stack
          className={`content  ${
            mode === "dark" ? "background-dark" : "background-light"
          }`}
          // gap={3}
        >
          {showSearch && (
            <div className="input-content">
              <InputGroup id={`typehead-main-ig`}>
                <Typeahead
                  className="typeahead-translate"
                  ref={searchRef}
                  id={`typehead-main`}
                  input={`.typehead-main-ig`}
                  onChange={(data) => {
                    window.location = `/companies/stock-metrics/${data[0].ticker}`;
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      if (event.target?.value) {
                        window.location = `/companies/stock-metrics/${event.target?.value}`;
                      }
                    }
                  }}
                  inputProps={{
                    style: {
                      borderBottomLeftRadius: "20px",
                      borderTopLeftRadius: "20px",
                    },
                  }}
                  options={compaines}
                  placeholder={localString[lang].SearchBarPlaceholder}
                />
                <Button
                  variant="outline-secondary"
                  id={`search-button`}
                  onClick={() => {
                    let value = searchRef.current?.inputNode?.value;
                    if (value) {
                      window.location = `/companies/stock-metrics/${value}`;
                    }
                  }}
                  className="search-button"
                >
                  {localString[lang].Search}
                </Button>
              </InputGroup>
            </div>
          )}

          <div className="d-flex flex-row flex-wrap bd-highlight data-container">
            <div className="p-2 bd-highlight flex-fill text-center">
              {/* <div className="p-2 bd-highlight flex-column"> */}
              <div className="data-title pb-1">
                {`${localString[lang].TopCompaniesTitle} ${localString[lang]["titles"][orderMetric]}`}
              </div>
              <div className="pb-3 justify-content-center align-items-center search-desc">
                <div>
                  <span>{`${localString[lang].Companies}:`}</span>&nbsp;
                  <span className="fw-bold">500</span>
                </div>
                <span className="fw-bold margin-5-h search-divider">|</span>
                <div>
                  <span>{`${localString[lang].TotalMarketCap}:`}</span>&nbsp;
                  <span className="fw-bold">{`$${nFormatter(
                    totalMarketCap,
                    3
                  )}`}</span>
                </div>
              </div>
              <div className="pb-3 justify-content-center align-items-center search-desc">
                <div>
                  <span className="fw-bold">{`${localString[lang]["titles"][orderMetric]}: `}</span>
                  <span>{localString[lang]["description"][orderMetric]}</span>
                  &nbsp;
                </div>
              </div>
              <div className="pb-3 justify-content-center align-items-center search-desc">
                <div className="search-sort-container">
                  <div className="fw-bold">{localString[lang].sortBy}: </div>
                  <div
                    onClick={() => {
                      setOrderMetric("MarketCap");
                      setOrder("-market_cap");
                    }}
                    className={`px-3 py-2 mx-2 border cursor-pointer ${
                      orderMetric === "MarketCap"
                        ? "border-info fw-semibold"
                        : ""
                    }`}
                  >
                    {localString[lang]["titles"]["MarketCap"]}
                  </div>
                  <div
                    onClick={() => {
                      setOrderMetric("NetIncome");
                      setOrder("-net_income");
                    }}
                    className={`px-3 py-2 mx-2 border cursor-pointer ${
                      orderMetric === "NetIncome"
                        ? "border-info fw-semibold"
                        : ""
                    }`}
                  >
                    {localString[lang]["titles"]["NetIncome"]}
                  </div>
                  <div
                    onClick={() => {
                      setOrderMetric("PE");
                      setOrder("-pe_ratio_ttm");
                    }}
                    className={`px-3 py-2 mx-2 border cursor-pointer ${
                      orderMetric === "PE" ? "border-info fw-semibold" : ""
                    }`}
                  >
                    {localString[lang]["titles"]["PE"]}
                  </div>
                  <div
                    onClick={() => {
                      setOrderMetric("PB");
                      setOrder("-pb_ratio_ttm");
                    }}
                    className={`px-3 py-2 mx-2 border cursor-pointer ${
                      orderMetric === "PB" ? "border-info fw-semibold" : ""
                    }`}
                  >
                    {localString[lang]["titles"]["PB"]}
                  </div>
                  <div
                    onClick={() => {
                      setOrderMetric("PS");
                      setOrder("-ps_ratio_ttm");
                    }}
                    className={`px-3 py-2 mx-2 border cursor-pointer ${
                      orderMetric === "PS" ? "border-info fw-semibold" : ""
                    }`}
                  >
                    {localString[lang]["titles"]["PS"]}
                  </div>
                </div>
              </div>

              {/* <div
                className={`ag-theme-${
                  mode === "dark" ? "quartz-dark" : "quartz"
                }`}
                style={{ height: "300px" }}
              >
                <AgGridReact
                  rowData={rowData}
                  columnDefs={columnDefs}
                  autoSizeStrategy={{ type: "fitGridWidth" }}
                  pagination={true}
                  paginationPageSize={25}
                  paginationPageSizeSelector={[25, 50, 100]}
                />
              </div> */}
            </div>
          </div>
        </Stack>

        <Stack
          className={`content m-4 ${
            mode === "dark" ? "background-dark" : "background-light"
          }`}
          gap={3}
        >
          <div
            className={`ag-theme-${mode === "dark" ? "quartz-dark" : "quartz"}`}
            style={{ height: "calc(100vh - 6rem)" }}
          >
            <AgGridReact
              loading={loading}
              rowData={rowData}
              columnDefs={columnDefs}
              autoSizeStrategy={{ type: "fitGridWidth" }}
              // defaultColDef={defaultColDef}
              // rowSelection="multiple"
              // suppressRowClickSelection={true}
              pagination={true}
              paginationPageSize={25}
              paginationPageSizeSelector={[25, 50, 100]}
            />
          </div>
        </Stack>
      </>
      {/* )} */}
    </>
  );
};

export default TopCompanies;
