import { LANGUAGES } from "../constants";

export const localString = {
  [LANGUAGES[0].value]: {
    // English
    peHistorical: "5-Year Historical Average P/E Ratio",
    peHistoricalCompare: "Under or over 5-year historical average P/E?",
    search: "Search Stock",
    login: {
      login: "Login",
      signup: "Register",
      forgotpassword: "Forgot Password",
      resetpassword: "Reset Password",
      email: "Email",
      password: "Password",
      forgotPasswordQ: "Forgot password?",
      createAccount: "Create a new Stock Penguins Account",
      firstName: "First Name",
      lastName: "Last Name",
      requestOTP: "Request OTP",
      otp: "OTP",
    },
    portfolio: {
      symbol: "Symbol",
      portfolioName: "Portfolio name",
      date: "Date",
      price: "Price",
      noOfShares: "No. Of Shares",
      buy: "Buy",
      sellTransactions: "Sell Transactions",
      purchasePrice: "Purchase Price",
      purchaseDate: "Purchase Date",
      priceSold: "Price Sold",
      dateSold: "Date Sold",
      profitAndLoss: "Profit & Loss",
      company: "Company",
      sector: "Sector",
      currentPrice: "Current Price",
      avgPurchasePrice: "Avg. Purchase Price",
      totalShares: "Total Shares",
      portfolioPct: "% of Portfolio",
      capital: "Capital",
      sell: "Sell",
      history: "History",
      pctReturns: "Returns %",
      name: "Name",
      noOfCompanies: "No. Of Companies",
      total: "Total",
    },
    presetName: "Preset Name",
    createdBy: "Created By",
    upvotes: "Upvotes",
    savePreset: "Save Preset",
    share: "Share",
    save: "Save",
    selectPreset: "Select a Preset",
    loginToSP: "Login to Stock Penguins",
    clearFilters: "Clear Filters",

    stockScreener: "Stock Screener",
    topPresets: "Top Presets",
    myPortfolio: "My Portfolio",
    portfolioLeaderboard: "Portfolio Leaderboard",
    peHistoric: "P/E Historical",
    SimilarCompanies: "Similar Companies",
    Stock: "Stock Financials",

    TopCompaniesTitle: "Largest Companies in the US by",
    TotalMarketCap: "Total Market Capitilisation",
    Companies: "Companies",
    sortBy: "Sort By",
    Terms: "Terms & Conditions",
    Privacy: "Privacy Policy",
    logout: "Logout",
    portfolioLeader: "Leaderboards",

    scanner: {
      exchange: "Exchange",
      any: "Any",
      nasdaq: "NASDAQ",
      nyse: "NYSE",
      amex: "AMEX",
      marketCap: "Market Cap",
      mcap_mega: "Mega ($200bln and more)",
      mcap_large: "Large ($10bln to $200bln)",
      mcap_mid: "Mid ($2bln to $10bln)",
      mcap_small: "Small ($300mln to $2bln)",
      mcap_micro: "Micro ($50mln to $300mln)",
      mcap_nano: "Nano (under $50mln)",
      mcap_largeover: "+Large (over $10bln)",
      mcap_midover: "+Mid (over $2bln)",
      mcap_smallover: "+Small (over $300mln)",
      mcap_microover: "+Micro (over $50mln)",
      mcap_largeunder: "-Large (under $200bln)",
      mcap_midunder: "-Mid (under $10bln)",
      mcap_smallunder: "-Small (under $2bln)",
      mcap_microunder: "-Micro (under $300mln)",

      performance: "Performance",
      perf_dup: "One Day Up",
      perf_ddown: "One Day Down",
      perf_d15u: "One Day -15%",
      perf_d10u: "One Day -10%",
      perf_d10o: "One Day +10%",
      perf_d15o: "One Day +15%",
      perf_1w30u: "Week -30%",
      perf_1w10u: "Week -10%",
      perf_1wdown: "Week Down",
      perf_1wup: "Week Up",
      perf_1w10o: "Week +10%",
      perf_1w30o: "Week +30%",
      perf_4w50u: "Month -50%",
      perf_4w30u: "Month -30%",
      perf_4w10u: "Month -10%",
      perf_4wdown: "Month Down",
      perf_4wup: "Month Up",
      perf_4w20o: "Month +20%",
      perf_4w30o: "Month +30%",
      perf_4w50o: "Month +50%",
      perf_13w50u: "Quarter -50%",
      perf_13w30u: "Quarter -30%",
      perf_13w10u: "Quarter -10%",
      perf_13wdown: "Quarter Down",
      perf_13wup: "Quarter Up",
      perf_13w10o: "Quarter +10%",
      perf_13w30o: "Quarter +30%",
      perf_13w50o: "Quarter +50%",
      perf_26w75u: "Half -75%",
      perf_26w50u: "Half -50%",
      perf_26w20u: "Half -20%",
      perf_26w10u: "Half -10%",
      perf_26wdown: "Half Down",
      perf_26wup: "Half Up",
      perf_26w10o: "Half +10%",
      perf_26w20o: "Half +20%",
      perf_26w50o: "Half +50%",
      perf_26w100o: "Half +100%",
      perf_52w75u: "Year -75%",
      perf_52w50u: "Year -50%",
      perf_52w30u: "Year -30%",
      perf_52w10u: "Year -10%",
      perf_52wdown: "Year Down",
      perf_52wup: "Year Up",
      perf_52w10o: "Year +10%",
      perf_52w50o: "Year +50%",
      perf_52w100o: "Year +100%",
      perf_52w300o: "Year +300%",
      perf_52w500o: "Year +500%",
      perf_ytd75u: "YTD -75%",
      perf_ytd50u: "YTD -50%",
      perf_ytd30u: "YTD -30%",
      perf_ytd10u: "YTD -10%",
      perf_ytddown: "YTD Down",
      perf_ytdup: "YTD Up",
      perf_ytd10o: "YTD +10%",
      perf_ytd30o: "YTD +30%",
      perf_ytd50o: "YTD +50%",
      perf_ytd100o: "YTD +100%",

      pe: "P / E",
      pe_low: "Low (<15)",
      pe_profitable: "Profitable (>0)",
      pe_high: "High (>50)",
      pe_u5: "Under 5",
      pe_u10: "Under 10",
      pe_u15: "Under 15",
      pe_u20: "Under 20",
      pe_u30: "Under 30",
      pe_u40: "Under 40",
      pe_u50: "Under 50",
      pe_o5: "Over 5",
      pe_o10: "Over 10",
      pe_o15: "Over 15",
      pe_o20: "Over 20",
      pe_o30: "Over 30",
      pe_o40: "Over 40",
      pe_o50: "Over 50",

      peg: "PEG",
      peg_low: "Low (<1)",
      peg_high: "High (>2)",
      peg_u1: "Under 1",
      peg_u2: "Under 2",
      peg_u3: "Under 3",
      peg_o1: "Over 1",
      peg_o2: "Over 2",
      peg_o3: "Over 3",

      pb: "P / B",
      pb_low: "Low (<1)",
      pb_high: "High (>5)",
      pb_u1: "Under 1",
      pb_u2: "Under 2",
      pb_u3: "Under 3",
      pb_u4: "Under 4",
      pb_u5: "Under 5",
      pb_u6: "Under 6",
      pb_u7: "Under 7",
      pb_u8: "Under 8",
      pb_u9: "Under 9",
      pb_u10: "Under 10",
      pb_o1: "Over 1",
      pb_o2: "Over 2",
      pb_o3: "Over 3",
      pb_o4: "Over 4",
      pb_o5: "Over 5",
      pb_o6: "Over 6",
      pb_o7: "Over 7",
      pb_o8: "Over 8",
      pb_o9: "Over 9",
      pb_o10: "Over 10",

      ps: "P / S",
      ps_low: "Low (<1)",
      ps_high: "High (>5)",
      ps_u1: "Under 1",
      ps_u2: "Under 2",
      ps_u3: "Under 3",
      ps_u4: "Under 4",
      ps_u5: "Under 5",
      ps_u6: "Under 6",
      ps_u7: "Under 7",
      ps_u8: "Under 8",
      ps_u9: "Under 9",
      ps_u10: "Under 10",
      ps_o1: "Over 1",
      ps_o2: "Over 2",
      ps_o3: "Over 3",
      ps_o4: "Over 4",
      ps_o5: "Over 5",
      ps_o6: "Over 6",
      ps_o7: "Over 7",
      ps_o8: "Over 8",
      ps_o9: "Over 9",
      ps_o10: "Over 10",

      quick: "Quick Ratio",
      quick_high: "High (>3)",
      quick_low: "Low (<0.5)",
      quick_u1: "Under 1",
      quick_u0_5: "Under 0.5",
      quick_o0_5: "Over 0.5",
      quick_o1: "Over 1",
      quick_o1_5: "Over 1.5",
      quick_o2: "Over 2",
      quick_o3: "Over 3",
      quick_o4: "Over 4",
      quick_o5: "Over 5",
      quick_o10: "Over 10",

      current: "Current Ratio",
      current_high: "High (>3)",
      current_low: "Low (<1)",
      current_u1: "Under 1",
      current_u0_5: "Under 0.5",
      current_o0_5: "Over 0.5",
      current_o1: "Over 1",
      current_o1_5: "Over 1.5",
      current_o2: "Over 2",
      current_o3: "Over 3",
      current_o4: "Over 4",
      current_o5: "Over 5",
      current_o10: "Over 10",

      sector: "Sector",
      sector_realestate: "Real Estate",
      sector_health: "Healthcare",
      sector_energy: "Energy",
      sector_utilities: "Utilities",
      sector_cd: "Consumer Defensive",
      sector_fs: "Financial Services",
      sector_coms: "Communication Services",
      sector_cs: "Consumer Services",
      sector_bm: "Basic Materials",
      sector_i: "Industrials",
      sector_cc: "Consumer Cyclical",
      sector_t: "Technology",

      pricecash: "Price/Cash",
      pricecash_low: "Low (<3)",
      pricecash_high: "High (>50)",
      pricecash_u1: "Under 1",
      pricecash_u2: "Under 2",
      pricecash_u3: "Under 3",
      pricecash_u4: "Under 4",
      pricecash_u5: "Under 5",
      pricecash_u6: "Under 6",
      pricecash_u7: "Under 7",
      pricecash_u8: "Under 8",
      pricecash_u9: "Under 9",
      pricecash_u10: "Under 10",
      pricecash_o1: "Over 1",
      pricecash_o2: "Over 2",
      pricecash_o3: "Over 3",
      pricecash_o4: "Over 4",
      pricecash_o5: "Over 5",
      pricecash_o6: "Over 6",
      pricecash_o7: "Over 7",
      pricecash_o8: "Over 8",
      pricecash_o9: "Over 9",
      pricecash_o10: "Over 10",
      pricecash_o20: "Over 20",
      pricecash_o30: "Over 30",
      pricecash_o40: "Over 40",
      pricecash_o50: "Over 50",

      operatingMargin: "Operating Margin",
      opmr_pos: "Positive (>0%)",
      opmr_neg: "Negative (<0%)",
      opmr_veryneg: "Very Negative (<-20%)",
      opmr_high: "High (>25%)",
      opmr_u90: "Under 90%",
      opmr_u80: "Under 80%",
      opmr_u70: "Under 70%",
      opmr_u60: "Under 60%",
      opmr_u50: "Under 50%",
      opmr_u40: "Under 40%",
      opmr_u30: "Under 30%",
      opmr_u20: "Under 20%",
      opmr_u10: "Under 10%",
      opmr_u_m10: "Under -10%",
      opmr_u_m30: "Under -30%",
      opmr_u_m50: "Under -50%",
      opmr_u_m100: "Under -100%",
      opmr_o10: "Over 10%",
      opmr_o20: "Over 20%",
      opmr_o30: "Over 30%",
      opmr_o40: "Over 40%",
      opmr_o50: "Over 50%",
      opmr_o60: "Over 60%",
      opmr_o70: "Over 70%",
      opmr_o80: "Over 80%",
      opmr_o90: "Over 90%",

      peHistoric: "P/E Historical",
      pehistoric_below: "Below Historical Average",
      pehistoric_above: "Above Historical Average",
      pbHistoric: "P/B Historical",
      pbhistoric_below: "Below Historical Average",
      pbhistoric_above: "Above Historical Average",
      psHistoric: "P/S Historical",
      pshistoric_below: "Below Historical Average",
      pshistoric_above: "Above Historical Average",

      pfcf: "Price/FCF",
      pfcf_low: "Low (<15)",
      pfcf_high: "High (>50)",
      pfcf_u10: "Under 10",
      pfcf_u20: "Under 20",
      pfcf_u50: "Under 50",
      pfcf_u80: "Under 80",
      pfcf_u100: "Under 100",
      pfcf_o10: "Over 10",
      pfcf_o20: "Over 20",
      pfcf_o50: "Over 50",
      pfcf_o80: "Over 80",
      pfcf_o100: "Over 100",

      dividendYield: "Dividend Yield",
      divy_none: "None (0%)",
      divy_pos: "Positive (>0%)",
      divy_high: "High (>5%)",
      divy_veryhigh: "Very High (>10%)",
      divy_o1: "Over 1%",
      divy_o2: "Over 2%",
      divy_o3: "Over 3%",
      divy_o4: "Over 4%",
      divy_o5: "Over 5%",
      divy_o6: "Over 6%",
      divy_o7: "Over 7%",
      divy_o8: "Over 8%",
      divy_o9: "Over 9%",
      divy_o10: "Over 10%",

      payoutRatio: "Payout Ratio",
      payoutr_none: "None (0%)",
      payoutr_pos: "Positive (>0%)",
      payoutr_low: "Low (<20%)",
      payoutr_high: "High (>50%)",
      payoutr_o10: "Over 10%",
      payoutr_o20: "Over 20%",
      payoutr_o50: "Over 50%",
      payoutr_o80: "Over 80%",
      payoutr_o100: "Over 100%",
      payoutr_u10: "Under 10%",
      payoutr_u20: "Under 20%",
      payoutr_u50: "Under 50%",
      payoutr_u80: "Under 80%",
      payoutr_u100: "Under 100%",

      netProfitMargin: "Net Profit Margin",
      netprf_pos: "Positive (>0%)",
      netprf_neg: "Negative (<0%)",
      netprf_veryneg: "Very Negative (<-20%)",
      netprf_high: "High (>20%)",
      netprf_u90: "Under 90%",
      netprf_u80: "Under 80%",
      netprf_u50: "Under 50%",
      netprf_u30: "Under 30%",
      netprf_u10: "Under 10%",
      netprf_u5: "Under 5%",
      netprf_u_10: "Under -10%",
      netprf_u_30: "Under -30%",
      netprf_u_50: "Under -50%",
      netprf_u_100: "Under -100%",
      netprf_o10: "Over 10%",
      netprf_o30: "Over 30%",
      netprf_o40: "Over 40%",
      netprf_o50: "Over 50%",
      netprf_o80: "Over 80%",
      netprf_o90: "Over 90%",

      deRatio: "Debt/Equity",
      deratio_high: "High (>0.5)",
      deratio_low: "Low (<0.1)",
      deratio_u1: "Under 1",
      deratio_u0_9: "Under 0.9",
      deratio_u0_8: "Under 0.8",
      deratio_u0_7: "Under 0.7",
      deratio_u0_6: "Under 0.6",
      deratio_u0_5: "Under 0.5",
      deratio_u0_4: "Under 0.4",
      deratio_u0_3: "Under 0.3",
      deratio_u0_2: "Under 0.2",
      deratio_u0_1: "Under 0.1",
      deratio_o0_1: "Over 0.1",
      deratio_o0_2: "Over 0.2",
      deratio_o0_3: "Over 0.3",
      deratio_o0_4: "Over 0.4",
      deratio_o0_5: "Over 0.5",
      deratio_o0_6: "Over 0.6",
      deratio_o0_7: "Over 0.7",
      deratio_o0_8: "Over 0.8",
      deratio_o0_9: "Over 0.9",
      deratio_o1: "Over 1",

      grossMargin: "Gross Margin",
      grossm_pos: "Positive (>0%)",
      grossm_neg: "Negative (<0%)",
      grossm_u90: "Under 90%",
      grossm_u70: "Under 70%",
      grossm_u50: "Under 50%",
      grossm_u30: "Under 30%",
      grossm_u10: "Under 10%",
      grossm_u5: "Under 5%",
      grossm_u_10: "Under -10%",
      grossm_u_30: "Under -30%",
      grossm_u_50: "Under -50%",
      grossm_u_70: "Under -70%",
      grossm_u_100: "Under -100%",
      grossm_o0: "Over 0%",
      grossm_o10: "Over 10%",
      grossm_o30: "Over 30%",
      grossm_o50: "Over 50%",
      grossm_o70: "Over 70%",
      grossm_o90: "Over 90%",

      ltdc: "LT Debt / Capitalization",
      ltdc_high: "High (>0.5)",
      ltdc_low: "Low (<0.1)",
      ltdc_u1: "Under 1",
      ltdc_u0_9: "Under 0.9",
      ltdc_u0_8: "Under 0.8",
      ltdc_u0_7: "Under 0.7",
      ltdc_u0_6: "Under 0.6",
      ltdc_u0_5: "Under 0.5",
      ltdc_u0_4: "Under 0.4",
      ltdc_u0_3: "Under 0.3",
      ltdc_u0_2: "Under 0.2",
      ltdc_u0_1: "Under 0.1",
      ltdc_o0_1: "Over 0.1",
      ltdc_o0_2: "Over 0.2",
      ltdc_o0_3: "Over 0.3",
      ltdc_o0_4: "Over 0.4",
      ltdc_o0_5: "Over 0.5",
      ltdc_o0_6: "Over 0.6",
      ltdc_o0_7: "Over 0.7",
      ltdc_o0_8: "Over 0.8",
      ltdc_o0_9: "Over 0.9",
      ltdc_o1: "Over 1",

      revgrow: "Revenue Growth q/q",
      revgrow_neg: "Negative (<0%)",
      revgrow_pos: "Positive (>0%)",
      revgrow_u10: "Under -10%",
      revgrow_u20: "Under -20%",
      revgrow_u50: "Under -50%",
      revgrow_u100: "Under -100%",
      revgrow_o10: "Over 10%",
      revgrow_o20: "Over 20%",
      revgrow_o50: "Over 50%",
      revgrow_o100: "Over 100%",

      netincgrow: "Net Income Growth q/q",
      netincgrow_neg: "Negative (<0%)",
      netincgrow_pos: "Positive (>0%)",
      netincgrow_u10: "Under -10%",
      netincgrow_u20: "Under -20%",
      netincgrow_u50: "Under -50%",
      netincgrow_u100: "Under -100%",
      netincgrow_o10: "Over 10%",
      netincgrow_o20: "Over 20%",
      netincgrow_o50: "Over 50%",
      netincgrow_o100: "Over 100%",

      revgrowavg: "Revenue Growth Past 5 years",
      revgrowavg_neg: "Negative (<0%)",
      revgrowavg_pos: "Positive (>0%)",
      revgrowavg_u10: "Under -10%",
      revgrowavg_u20: "Under -20%",
      revgrowavg_u50: "Under -50%",
      revgrowavg_u100: "Under -100%",
      revgrowavg_o10: "Over 10%",
      revgrowavg_o20: "Over 20%",
      revgrowavg_o50: "Over 50%",
      revgrowavg_o100: "Over 100%",

      industry: "Industry",
      ind_aginputs: "Agricultural Inputs",
      ind_indpollctrl: "Industrial - Pollution & Treatment Controls",
      ind_indmaterials: "Industrial Materials",
      ind_autorv: "Auto - Recreational Vehicles",
      ind_discstores: "Discount Stores",
      ind_insdiv: "Insurance - Diversified",
      ind_entertainment: "Entertainment",
      ind_reitdiv: "REIT - Diversified",
      ind_medicaldist: "Medical - Distribution",
      ind_softinfra: "Software - Infrastructure",
      ind_softapp: "Software - Application",
      ind_electricaleqp: "Electrical Equipment & Parts",
      ind_astmgmtglobal: "Asset Management - Global",
      ind_indpower: "Independent Power Producers",
      ind_oilgasdrill: "Oil & Gas Drilling",
      ind_leisure: "Leisure",
      ind_medicaldev: "Medical - Devices",
      ind_coal: "Coal",
      ind_edu: "Education & Training Services",
      ind_reitind: "REIT - Industrial",
      ind_autoparts: "Auto - Parts",
      ind_metalfab: "Manufacturing - Metal Fabrication",
      ind_fincredit: "Financial - Credit Services",
      ind_broadcasting: "Broadcasting",
      ind_medcarefac: "Medical - Care Facilities",
      ind_chemspecialty: "Chemicals - Specialty",
      ind_astmgmt: "Asset Management",
      ind_insreins: "Insurance - Reinsurance",
      ind_travellodging: "Travel Lodging",
      ind_findiv: "Financial - Diversified",
      ind_banks: "Banks",
      ind_bevalcoholic: "Beverages - Alcoholic",
      ind_hardwareeqp: "Hardware, Equipment & Parts",
      ind_inddist: "Industrial - Distribution",
      ind_constrmat: "Construction Materials",
      ind_fooddist: "Food Distribution",
      ind_bevwines: "Beverages - Wineries & Distilleries",
      ind_furnishings: "Furnishings, Fixtures & Appliances",
      ind_silver: "Silver",
      ind_specialtybiz: "Specialty Business Services",
      ind_apparelmanu: "Apparel - Manufacturers",
      ind_publishing: "Publishing",
      ind_finmortgages: "Financial - Mortgages",
      ind_railroads: "Railroads",
      ind_insprop: "Insurance - Property & Casualty",
      ind_telco: "Telecommunications Services",
      ind_reitretail: "REIT - Retail",
      ind_internetcontent: "Internet Content & Information",
      ind_indinfrastructure: "Industrial - Infrastructure Operations",
      ind_regwater: "Regulated Water",
      ind_packaging: "Packaging & Containers",
      ind_realeservices: "Real Estate - Services",
      ind_reddiversified: "Real Estate - Diversified",
      ind_renewutilities: "Renewable Utilities",
      ind_preciousmetals: "Other Precious Metals",
      ind_autodealerships: "Auto - Dealerships",
      ind_foodconf: "Food Confectioners",
      ind_toolsmfg: "Manufacturing - Tools & Accessories",
      ind_findata: "Financial - Data & Stock Exchanges",
      ind_adagencies: "Advertising Agencies",
      ind_luxurygoods: "Luxury Goods",
      ind_householdprod: "Household & Personal Products",
      ind_reitresidential: "REIT - Residential",
      ind_deptstores: "Department Stores",
      ind_personalprod: "Personal Products & Services",
      ind_paperlumber: "Paper, Lumber & Forest Products",
      ind_staffing: "Staffing & Employment Services",
      ind_resconstr: "Residential Construction",
      ind_healthcare: "Healthcare",
      ind_construction: "Construction",
      ind_specialtyretail: "Specialty Retail",
      ind_mediaentertain: "Media & Entertainment",
      ind_realdev: "Real Estate - Development",
      ind_astmgmtincome: "Asset Management - Income",
      ind_engconst: "Engineering & Construction",
      ind_gambling: "Gambling, Resorts & Casinos",
      ind_reitoffice: "REIT - Office",
      ind_finservices: "Financial Services",
      ind_drugmanugen: "Drug Manufacturers - General",
      ind_homeimprovement: "Home Improvement",
      ind_steel: "Steel",
      ind_regionalbanks: "Banks - Regional",
      ind_agfarmproducts: "Agricultural Farm Products",
      ind_conglomerates: "Conglomerates",
      ind_medhealthcareplans: "Medical - Healthcare Plans",
      ind_chemicals: "Chemicals",
      ind_oilmidstream: "Oil & Gas Midstream",
      ind_oilexp: "Oil & Gas Exploration & Production",
      ind_computerhardware: "Computer Hardware",
      ind_banksdiversified: "Banks - Diversified",
      ind_restaurants: "Restaurants",
      ind_oilintegrated: "Oil & Gas Integrated",
      ind_divutilities: "Diversified Utilities",
      ind_reithotel: "REIT - Hotel & Motel",
      ind_gaming: "Electronic Gaming & Multimedia",
      ind_medpharma: "Medical - Pharmaceuticals",
      ind_grocerystores: "Grocery Stores",
      ind_wastemgmt: "Waste Management",
      ind_reggas: "Regulated Gas",
      ind_trucking: "Trucking",
      ind_investbanking: "Investment - Banking & Investment Services",
      ind_automanufact: "Auto - Manufacturers",
      ind_reitmortgage: "REIT - Mortgage",
      ind_biotech: "Biotechnology",
      ind_techdistrib: "Technology Distributors",
      ind_bizequip: "Business Equipment & Supplies",
      ind_apparelfoot: "Apparel - Footwear & Accessories",
      ind_medicalspec: "Medical - Specialties",
      ind_apparelretail: "Apparel - Retail",
      ind_regelectric: "Regulated Electric",
      ind_finconglomerates: "Financial - Conglomerates",
      ind_oilrefining: "Oil & Gas Refining & Marketing",
      ind_reitspecialty: "REIT - Specialty",
      ind_agmachinery: "Agricultural - Machinery",
      ind_packagedfoods: "Packaged Foods",
      ind_reithealthcare: "REIT - Healthcare Facilities",
      ind_solar: "Solar",
      ind_copper: "Copper",
      ind_gold: "Gold",
      ind_medicalsupp: "Medical - Instruments & Supplies",
      ind_insurancebrokers: "Insurance - Brokers",
      ind_security: "Security & Protection Services",
      ind_tobacco: "Tobacco",
      ind_fincapmarkets: "Financial - Capital Markets",
      ind_mfgmisc: "Manufacturing - Miscellaneous",
      ind_uranium: "Uranium",
      ind_insurancelife: "Insurance - Life",
      ind_indmachinery: "Industrial - Machinery",
      ind_shellcompanies: "Shell Companies",
      ind_consumelectronics: "Consumer Electronics",
      ind_medicaldiag: "Medical - Diagnostics & Research",
      ind_oilequip: "Oil & Gas Equipment & Services",
      ind_commequip: "Communication Equipment",
      ind_aerospace: "Aerospace & Defense",
      ind_travelservices: "Travel Services",
      ind_drugmanuspecialty: "Drug Manufacturers - Specialty & Generic",
      ind_aluminum: "Aluminum",
      ind_itservices: "Information Technology Services",
      ind_medhealthinfo: "Medical - Healthcare Information Services",
      ind_bevnonalcoholic: "Beverages - Non-Alcoholic",
      ind_consulting: "Consulting Services",
      ind_rentleasing: "Rental & Leasing Services",
      ind_marineshipping: "Marine Shipping",
      ind_software: "Software - Services",
      ind_astmgmtbonds: "Asset Management - Bonds",
      ind_freightlogistics: "Integrated Freight & Logistics",
      ind_insspec: "Insurance - Specialty",
      ind_semiconductors: "Semiconductors",
      ind_airlines: "Airlines, Airports & Air Services",
    },
    OneYear: "1 Year",
    ThreeYear: "3 Years",
    FiveYear: "5 Years",
    TenYear: "10 Years",
    CAGRDesc: "Historical change over time",
    welcome: "Welcome",
    SearchBarPlaceholder: "What's your next 10 bagger going to be?",
    Search: "Search",
    Industry: "Industry",
    Sector: "Sector",
    MarketCap: "Market Cap",
    PE: "P/E",
    PS: "P/S",
    PB: "P/B",
    FCFYield: "FCF Yield",
    PEGRatio: "PEG Ratio",
    OverallRisk: "Piotroski Score",
    week52HighPrice: "52 Week High Price",
    PriceGrowthoverthepast5Years: "Price % Growth over the past 5 Years",
    PriceGrowthoverthepast1Years: "Price % Growth over the past 1 Years",
    Profitability: "Profitable? (Quarterly)",
    GrowingRevenue: "Growing Revenue? (Quarterly)",
    GrowingNetProfit: "Growing Net Profit? (Quarterly)",
    ProfitabilityAnnual: "Profitable? (Annually)",
    GrowingRevenueAnnual: "Growing Revenue? (Annually)",
    GrowingNetProfitAnnual: "Growing Net Profit? (Annually)",
    Cash: "Cash",
    Debt: "Debt",
    CashDebtRatio: "Cash Debt Ratio",
    DividendYield: "Dividend Yield",
    PayoutRatio: "Payout Ratio",
    FiveYearAvgDividendYield: "5 Year Avg Dividend Yield",
    DividendGrowthRateoverthepast5Years:
      "Dividend Growth Rate over the past 5 Years",
    FinancialMetrics: "Valuation Summary",
    BalanceSheet: "Financial Metrics",
    QualityOfCompany: "Quality Of Company",
    Dividend: "Dividend",
    comparedToLastQuarter: "Compared to Last Quarter",
    comparedToLastYear: "Compared to Last Year",
    newTab: "New Tab",
    compare: "Compare",
    topCompanies: "Top Companies",
    screener: "Screener",
    watchlist: "Watchlist",
    rank: "Rank",
    companyName: "Company Name",
    symbol: "Symbol",
    marketCap: "Market Cap",
    price: "Price",
    today: "1 Day Change",
    oneMonthChange: "1 Month Change",
    oneYearChange: "1 Year Change",
    volume: "Volume",
    country: "Country",
    joinDiscord: "Join Discord",
    joinTelegram: "Join Telegram",
    contactUs: "Contact Us",
    InTheLast365Days: "In the Last 365 Days",
    LastUpdated: "Last Updated",
    pages: "Pages",
    favoritePresets: "My Saved Presets",
    createNewPreset: "Create New Preset",
    copyToPreset: "Copy Link To Preset",
    resetFilters: "Reset Filters",
    titles: {
      Price: "Price",
      MarketCap: "Market Cap",
      Revenue: "Revenue",
      PE: "P / E",
      PS: "P / S",
      PB: "P / B",
      RevenueGrowth: "Revenue Growth %",
      NetIncomeGrowth: "Net Income Growth %",
      RevenueVsNetIncome: "Revenue Vs Net Income",
      NetIncome: "Net Income",
      Ebitda: "EBITDA",
      Eps: "EPS",
      TotalExpenses: "Total Expenses",
      SharesIssued: "Shares Outstanding",
      FreeCashFlow: "Free Cash Flow",
      OperatingCashFlow: "Operating Cash Flow",
      Dividend: "Dividend",
      GrossMargin: "Gross Margin",
      CashVsDebt: "Cash Vs Debt",
    },
    description: {
      PE: "Price-to-Earnings (P/E) ratio measure of how much investors are willing to pay for each dollar of a company's earnings; calculated by dividing the stock price by earnings per share. A high P/E ratio may indicate that investors expect high future growth rate or the stock may be overvalued",
      PS: "Price-to-Sales (P/S) ratio measures how much investors are paying for each dollar of the company's sales; calculated by dividing the stock price by sales per share. A high P/S ratio may indicate that investors expect high future sales growth or the stock may be overvalued",
      PB: "Price-to-Book (P/S) Ratio is a ratio that compares a company's market value to its book value (assets minus liabilities); calculated by dividing the stock price by the book value per share. A high P/B may indicate strong investor confidence in the company's future performance or that the stock might be overvalued if the company doesn't have strong asset growth",
      RevenueGrowth: "Percentage growth in revenue",
      NetIncomeGrowth: "Percentage growth in net income",
      Price: "Historical stock price of the company over time",
      MarketCap:
        "The total value of a company’s outstanding share of stocks over time",
      Revenue:
        "The total amount of money generated from business activities, sales of goods and services over time",
      RevenueVsNetIncome:
        "A comparison between the company’s total revenue and net profit over time, indicating how sustainable the company’s growth is. A lower ratio may indicate that the company is developing a competitive advantage and taking up market share",
      NetIncome:
        "The profit a company makes after all expenses, taxes, and costs have been deducted from total revenue over time",
      Ebitda:
        "A company’s Earnings Before Interest, Taxes, Depreciation, and Amortization over time. It measures a company's overall financial performance and profitability from core operations",
      Eps: "The portion of a company's profit allocated to each outstanding share of common stock. It indicates how much money shareholders would receive for each share they own",
      TotalExpenses:
        "The sum of all costs incurred by a company in conducting its business, including operating expenses, interest, taxes, etc.. The graph can show the total expenses over time, indicating cost trends and efficiency",
      SharesIssued: "The total number of a company’s shares issued",
      FreeCashFlow:
        "The cash a company generates after accounting for cash outflows to support operations and maintain capital assets. It represents the money available for dividends, debt repayment, or reinvestment",
      OperatingCashFlow:
        "The cash generated from a company’s business operations over time. It shows whether a company can generate enough positive cash flow to maintain and grow its operations",
      Dividend:
        "A portion of a company's earnings is distributed to shareholders, usually in the form of cash payments.",
      GrossMargin:
        "The percentage of revenue remaining after subtracting the cost of goods sold (COGS). It shows how efficiently a company produces and sells its products",
      CashVsDebt:
        "A comparison graph showing the company's cash reserves versus its total debt. It helps assess the company's financial health and liquidity",
    },
    dataDescriptions: {
      peHistorical:
        "The 5-year historical average P/E ratio provide adequate durational coverage to capture changes in market trends and the performance of the company. It also reflects the latest economic conditions, interest rates, and industry developments.",
      peHistoricalCompare:
        "If the P/E ratio is above the historical average, it often indicates overvaluation and high growth expectations, with potential risks if growth falls short. Conversely, a below-average P/E suggests undervaluation due to market pessimism or temporary issues with growth. It's crucial to consider economic, industry, and company-specific factors, as a growth company in a developing industry may justify higher valuations.",
      MarketCap:
        "The total value of a company's shares of stock; it's calculated by multiplying the stock price by the total number of shares",
      PE: "Price-to-Earnings (P/E) ratio measure of how much investors are willing to pay for each dollar of a company's earnings; calculated by dividing the stock price by earnings per share. A high P/E ratio may indicate that investors expect high future growth rate or the stock may be overvalued",
      PS: "Price-to-Sales (P/S) ratio measures how much investors are paying for each dollar of the company's sales; calculated by dividing the stock price by sales per share. A high P/S ratio may indicate that investors expect high future sales growth or the stock may be overvalued",
      PB: "Price-to-Book (P/S) Ratio is a ratio that compares a company's market value to its book value (assets minus liabilities); calculated by dividing the stock price by the book value per share. A high P/B may indicate strong investor confidence in the company's future performance or that the stock might be overvalued if the company doesn't have strong asset growth",
      FCFYield:
        "Free Cash Flow Yield is a measure of how much free cash flow a company generates relative to its market cap; calculated by dividing free cash flow by the market cap",
      PEGRatio:
        "The Price/earnings to Growth ratio (PEG) valuation metric considers a company's P/E ratio in relation to its earnings growth rate; it helps to see if a stock is over or under-valued. A PEG ratio of 1 suggests fair value, under 1 suggests undervaluation relative to growth rate, and over 1 suggests overvaluation relative to growth rate",
      OverallRisk:
        "An assessment of how risky an investment is, considering factors like volatility, financial stability, and market conditions; lower risk is usually better, but it depends on the investor's risk tolerance",
      week52HighPrice:
        "The highest price a stock has reached in the past year; a stock trading near its 52-week high may indicate strong recent performance, but it could also suggest it’s overvalued",
      ProfitabilityAnnual:
        "Whether the company has made more money than it spent over the past year; profitability is generally a good sign of a company’s financial health",
      GrowingRevenueAnnual:
        "Whether the company’s revenue (sales) has increased compared to the previous year; consistent revenue growth is usually a positive sign of business expansion",
      GrowingNetProfitAnnual:
        "Whether the company’s net profit (income after expenses) has increased compared to the previous year; growing net profit is a strong indicator of improving financial performance",
      PriceGrowthoverthepast5Years:
        "The percentage increase in a stock’s price over the last five years; a higher percentage indicates better long-term performance",
      PriceGrowthoverthepast1Years:
        "The percentage increase in a stock’s price over the last year; strong short-term growth is positive but should be considered in context with other factors",
      Cash: "The total amount of liquid money the company has available to use immediately; more cash is generally better for financial flexibility",
      Debt: "The total amount of money the company owes to lenders; higher debt can be risky if the company can't generate enough income to pay it off",
      CashDebtRatio:
        "A measure of a company's ability to pay off its debt with its cash on hand; a higher ratio indicates better financial health",
      Profitability:
        "Whether the company made more money than it spent in the most recent quarter; being profitable quarterly shows the company is consistently generating income",
      GrowingRevenue:
        "Whether the company’s revenue has increased compared to the previous quarter; continuous revenue growth is a sign of ongoing business success",
      GrowingNetProfit:
        "Whether the company’s net profit has increased compared to the previous quarter; growing net profit quarterly indicates improving financial performance in the short term",
      DividendYield:
        "The annual dividend payment a company gives to its shareholders, expressed as a percentage of its stock price; a higher yield can indicate more income from dividends relative to the stock price",
      PayoutRatio:
        "The percentage of a company’s earnings paid out as dividends to shareholders; a lower ratio suggests the company is retaining more earnings for growth, while a very high ratio might indicate the dividend could be unsustainable",
      FiveYearAvgDividendYield:
        "The average dividend yield a company has offered over the past five years; it helps investors see how consistent or stable the dividend payments have been over time",
    },
    labels: {
      TotalCash: "Total Cash",
      TotalDebt: "Total Debt",
      Revenue: "Revenue",
      NetIncome: "Net Income",
    },
    Quarterly: "Quarterly",
    Annually: "Annually",
    FullScreen: "Full Screen",
    ExitFullScreen: "Exit Full Screen",
    homeMetaTitle: "StockPenguins | Free Global Equities Stock Scanner",
    homeMetaDescription:
      "Discover the ultimate free stock scanner app for US equities like Apple, Tesla, Amazon, and Microsoft. Access detailed metrics such as Price, Market Cap, Revenue, Net Income, EBITDA, Free Cash Flow, EPS, Dividend, P/E Ratio, and more. Analyze and compare stocks effortlessly to make informed investment decisions. Search now!",
    pageMetaTitle: "Stock Metrics | P/E, Revenue & Market Capitalization",
    pageMetaDescription:
      "Discover free metrics, including Price, Market Cap, Revenue, Net Income, EBITDA, Free Cash Flow, EPS, Dividend, P/E Ratio, and more. Stay informed about Apple's financial performance. Explore now!",
  },
  [LANGUAGES[1].value]: {
    // Chinese
    peHistorical: "5年历史平均市盈率",
    peHistoricalCompare: "低于或高于5年历史平均市盈率？",
    search: "搜索股票",

    scanner: {
      industry: "行业",
      ind_aginputs: "农业投入",
      ind_indpollctrl: "工业 - 污染与处理控制",
      ind_indmaterials: "工业材料",
      ind_autorv: "汽车 - 休闲车辆",
      ind_discstores: "折扣商店",
      ind_insdiv: "保险 - 多元化",
      ind_entertainment: "娱乐",
      ind_reitdiv: "房地产投资信托 - 多元化",
      ind_medicaldist: "医疗 - 分销",
      ind_softinfra: "软件 - 基础设施",
      ind_softapp: "软件 - 应用",
      ind_electricaleqp: "电气设备与零件",
      ind_astmgmtglobal: "资产管理 - 全球",
      ind_indpower: "独立发电商",
      ind_oilgasdrill: "石油与天然气钻探",
      ind_leisure: "休闲",
      ind_medicaldev: "医疗 - 设备",
      ind_coal: "煤炭",
      ind_edu: "教育与培训服务",
      ind_reitind: "房地产投资信托 - 工业",
      ind_autoparts: "汽车 - 零件",
      ind_metalfab: "制造 - 金属加工",
      ind_fincredit: "金融 - 信贷服务",
      ind_broadcasting: "广播",
      ind_medcarefac: "医疗 - 护理设施",
      ind_chemspecialty: "化学 - 特种",
      ind_astmgmt: "资产管理",
      ind_insreins: "保险 - 再保险",
      ind_travellodging: "旅行住宿",
      ind_findiv: "金融 - 多元化",
      ind_banks: "银行",
      ind_bevalcoholic: "饮料 - 酒精",
      ind_hardwareeqp: "硬件、设备与零件",
      ind_inddist: "工业 - 分销",
      ind_constrmat: "建筑材料",
      ind_fooddist: "食品分销",
      ind_bevwines: "饮料 - 酒庄与酿酒厂",
      ind_furnishings: "家具、固定装置与电器",
      ind_silver: "白银",
      ind_specialtybiz: "特种商业服务",
      ind_apparelmanu: "服装 - 制造商",
      ind_publishing: "出版",
      ind_finmortgages: "金融 - 抵押贷款",
      ind_railroads: "铁路",
      ind_insprop: "保险 - 财产与意外险",
      ind_telco: "电信服务",
      ind_reitretail: "房地产投资信托 - 零售",
      ind_internetcontent: "互联网内容与信息",
      ind_indinfrastructure: "工业 - 基础设施运营",
      ind_regwater: "受监管水",
      ind_packaging: "包装与容器",
      ind_realeservices: "房地产 - 服务",
      ind_reddiversified: "房地产 - 多元化",
      ind_renewutilities: "可再生公用事业",
      ind_preciousmetals: "其他贵金属",
      ind_autodealerships: "汽车 - 经销商",
      ind_foodconf: "食品糖果商",
      ind_toolsmfg: "制造 - 工具与配件",
      ind_findata: "金融 - 数据与股票交易所",
      ind_adagencies: "广告代理",
      ind_luxurygoods: "奢侈品",
      ind_householdprod: "家庭与个人产品",
      ind_reitresidential: "房地产投资信托 - 住宅",
      ind_deptstores: "百货商店",
      ind_personalprod: "个人产品与服务",
      ind_paperlumber: "纸、木材与森林产品",
      ind_staffing: "人力资源与就业服务",
      ind_resconstr: "住宅建筑",
      ind_healthcare: "医疗保健",
      ind_construction: "建筑",
      ind_specialtyretail: "特种零售",
      ind_mediaentertain: "媒体与娱乐",
      ind_realdev: "房地产 - 开发",
      ind_astmgmtincome: "资产管理 - 收入",
      ind_engconst: "工程与建筑",
      ind_gambling: "赌博、度假村与赌场",
      ind_reitoffice: "房地产投资信托 - 办公室",
      ind_finservices: "金融服务",
      ind_drugmanugen: "药品制造商 - 一般",
      ind_homeimprovement: "家居改善",
      ind_steel: "钢铁",
      ind_regionalbanks: "银行 - 区域性",
      ind_agfarmproducts: "农业农场产品",
      ind_conglomerates: "综合企业",
      ind_medhealthcareplans: "医疗 - 健康计划",
      ind_chemicals: "化学品",
      ind_oilmidstream: "石油与天然气中游",
      ind_oilexp: "石油与天然气勘探与生产",
      ind_computerhardware: "计算机硬件",
      ind_banksdiversified: "银行 - 多元化",
      ind_restaurants: "餐厅",
      ind_oilintegrated: "石油与天然气综合",
      ind_divutilities: "多元化公用事业",
      ind_reithotel: "房地产投资信托 - 酒店与汽车旅馆",
      ind_gaming: "电子游戏与多媒体",
      ind_medpharma: "医疗 - 制药",
      ind_grocerystores: "杂货店",
      ind_wastemgmt: "废物管理",
      ind_reggas: "受监管气体",
      ind_trucking: "卡车运输",
      ind_investbanking: "投资 - 银行与投资服务",
      ind_automanufact: "汽车 - 制造商",
      ind_reitmortgage: "房地产投资信托 - 抵押贷款",
      ind_biotech: "生物技术",
      ind_techdistrib: "技术分销商",
      ind_bizequip: "商业设备与用品",
      ind_apparelfoot: "服装 - 鞋类与配件",
      ind_medicalspec: "医疗 - 专科",
      ind_apparelretail: "服装 - 零售",
      ind_regelectric: "受监管电力",
      ind_finconglomerates: "金融 - 综合企业",
      ind_oilrefining: "石油与天然气精炼与营销",
      ind_reitspecialty: "房地产投资信托 - 特种",
      ind_agmachinery: "农业 - 机械",
      ind_packagedfoods: "包装食品",
      ind_reithealthcare: "房地产投资信托 - 医疗设施",
      ind_solar: "太阳能",
      ind_copper: "铜",
      ind_gold: "黄金",
      ind_medicalsupp: "医疗 - 仪器与用品",
      ind_insurancebrokers: "保险 - 经纪人",
      ind_security: "安全与保护服务",
      ind_tobacco: "烟草",
      ind_fincapmarkets: "金融 - 资本市场",
      ind_mfgmisc: "制造 - 杂项",
      ind_uranium: "铀",
      ind_insurancelife: "保险 - 人寿",
      ind_indmachinery: "工业 - 机械",
      ind_shellcompanies: "空壳公司",
      ind_consumelectronics: "消费电子",
      ind_medicaldiag: "医疗 - 诊断与研究",
      ind_oilequip: "石油与天然气设备与服务",
      ind_commequip: "通信设备",
      ind_aerospace: "航空航天与国防",
      ind_travelservices: "旅行服务",
      ind_drugmanuspecialty: "药品制造商 - 特种与通用",
      ind_aluminum: "铝",
      ind_itservices: "信息技术服务",
      ind_medhealthinfo: "医疗 - 健康信息服务",
      ind_bevnonalcoholic: "饮料 - 非酒精",
      ind_consulting: "咨询服务",
      ind_rentleasing: "租赁与租约服务",
      ind_marineshipping: "海洋运输",
      ind_software: "软件 - 服务",
      ind_astmgmtbonds: "资产管理 - 债券",
      ind_freightlogistics: "综合货运与物流",
      ind_insspec: "保险 - 特种",
      ind_semiconductors: "半导体",
      ind_airlines: "航空公司",
      ind_railtransit: "铁路 - 公共交通",
      ind_silicon: "硅",
      ind_aerospacecomm: "航空航天 - 通信",
      ind_carrier: "承运人",
      ind_chemicalsag: "化学 - 农业",
      ind_equipment: "设备与配件",
      ind_indcap: "工业 - 资本货物",
      ind_constructionengine: "建筑 - 工程服务",
      ind_tins: "锡",
      ind_biotechgen: "生物技术 - 一般",
      ind_fuelcell: "燃料电池",
      ind_fundmanagers: "基金经理",
      ind_waterutilities: "水务公用事业",
      ind_multilineins: "多行保险",
      ind_hospitals: "医院",
      ind_brokers: "经纪商",
      ind_dispensaries: "药房",
      ind_savinginvest: "储蓄与投资服务",
      ind_mortgage: "抵押贷款",
      ind_manhuman: "人力资源与管理",
      ind_gasutilities: "天然气公用事业",
      ind_coastal: "海岸与滨海",
      ind_retailservices: "零售服务",
      ind_ecommerce: "电子商务",
      ind_indsecurity: "工业 - 安全",
      ind_adservicing: "广告服务",
      ind_automotive: "汽车与交通",
      ind_homedecor: "家居装饰",
      ind_manglobal: "管理 - 全球",
      ind_manfixed: "制造 - 固定装置",
      ind_jewelry: "珠宝",
      ind_iron: "铁",
      ind_supplychain: "供应链与物流",
      ind_multiline: "多行业务",
      ind_energydistr: "能源分销",
      ind_solarenerg: "太阳能发电",
      ind_landsurvey: "土地勘测与开发",
      ind_advisors: "顾问",
      ind_tollroad: "收费公路",
      ind_mfgdiv: "制造 - 多元化",
      ind_plumbing: "水管与电气",
      ind_electric: "电力与能源",
      ind_exploration: "勘探与开发",
      ind_gasmidstream: "天然气中游",
      ind_bioag: "生物农业",
      ind_exchanges: "交易所与平台",
      ind_retailcons: "零售与消费者产品",
      ind_thrift: "节俭与效率",
      ind_kitchens: "厨房与家居",
      ind_military: "军事与国防",
      ind_airport: "机场与航空",
      ind_electroniccomponents: "电子组件",
      ind_trees: "树木与林业",
      ind_localbusiness: "本地商业",
      ind_industrial: "工业服务",
      ind_direct: "直接业务",
      ind_semiutilities: "半公用事业",
      ind_retailandwholesale: "零售与批发",
      ind_sugar: "糖",
      ind_emerging: "新兴市场",
      ind_mobiletech: "移动技术",
      ind_healthins: "健康保险",
      ind_propservices: "物业服务",
      ind_telecomm: "电信",
      ind_commerce: "商业",
      ind_pharmacy: "药房与医疗",
      ind_data: "数据与分析",
      ind_constructionsteel: "建筑 - 钢铁",
      ind_investment: "投资",
      ind_enviroservices: "环境服务",
      ind_cashflows: "现金流与资本管理",
      ind_tools: "工具与设备",
      ind_socialmedia: "社交媒体与技术",
      ind_brokerspecialty: "经纪商 - 特种",
      ind_autoinsurance: "汽车保险",
      ind_midstream: "中游",
      ind_wholesalers: "批发商",
      ind_privateequity: "私募股权",
      ind_investmenttrusts: "投资信托",
      ind_employment: "就业与服务",
      ind_investments: "投资产品与服务",
      ind_mining: "采矿与资源",
      ind_stocks: "股票与证券",
      ind_lifesciences: "生命科学",
      ind_automated: "自动化与技术",
      ind_industrials: "工业与制造",
      ind_goodsservices: "商品与服务",
      ind_apparelretailgeneral: "服装零售 - 一般",
      ind_advertising: "广告与市场营销",
      ind_video: "视频与媒体",
      ind_biomedical: "生物医学",
      ind_miningresources: "采矿与资源",
      ind_transport: "运输与物流",
      ind_educationtraining: "教育与培训",
      ind_research: "研究与开发",
      ind_biometrics: "生物识别技术",
      ind_journalism: "新闻与媒体",
      ind_investmentbanking: "投资银行",
      ind_electricutilities: "电力公用事业",
      ind_events: "事件与会议",
      ind_manufacturingservices: "制造服务",
      ind_freshfood: "新鲜食品",
      ind_buildings: "建筑与基础设施",
      ind_energy: "能源与环境",
      ind_audit: "审计与合规",
      ind_furniture: "家具与家居用品",
      ind_automotiveparts: "汽车零部件",
      ind_vehicles: "车辆与交通工具",
      ind_equipmentmanufacturing: "设备制造",
      ind_serviceindustries: "服务行业",
      ind_foodprocessing: "食品加工",
      ind_furnituremanufacturing: "家具制造",
      ind_eventsmanagement: "活动管理",
      ind_generalmanufacturing: "一般制造",
      ind_development: "开发与建设",
      ind_careers: "职业与就业",
      ind_parksrecreation: "公园与休闲",
      ind_healthwellness: "健康与福祉",
      ind_agriculture: "农业与林业",
      ind_retailconsumables: "零售消费品",
      ind_donations: "捐赠与慈善",
      ind_startups: "初创企业与创新",
      ind_retailtechnology: "零售技术",
      ind_foodservices: "食品服务",
      ind_veterinary: "兽医服务",
      ind_nutrition: "营养与健康",
      ind_healthcaretechnology: "医疗技术与设备",
      ind_farming: "农业与种植",
      ind_specialtychemicals: "特种化学品",
      ind_schools: "学校与教育",
      ind_educationtechnology: "教育技术",
      ind_pharmaceutical: "制药与生物技术",
      ind_healthservices: "健康服务与保险",
      ind_economicdevelopment: "经济发展",
      ind_production: "生产与加工",
      ind_agroforestry: "农业与林业",
      ind_landscaping: "园艺与美化",
      ind_forestry: "林业与资源",
      ind_smartcities: "智慧城市与技术",
      ind_carbon: "碳管理与减排",
      ind_fertility: "生育与生殖",
      ind_interiordesign: "室内设计与装修",
      ind_urbanplanning: "城市规划与发展",
      ind_sustainableliving: "可持续生活与环境",
      ind_landuse: "土地利用与管理",
      ind_naturalresources: "自然资源与管理",
      ind_globaltrade: "全球贸易与物流",
      ind_communitydevelopment: "社区发展与建设",

      revgrowavg: "过去5年的收入增长",
      revgrowavg_neg: "负数 (<0%)",
      revgrowavg_pos: "正数 (>0%)",
      revgrowavg_u10: "低于 -10%",
      revgrowavg_u20: "低于 -20%",
      revgrowavg_u50: "低于 -50%",
      revgrowavg_u100: "低于 -100%",
      revgrowavg_o10: "超过 10%",
      revgrowavg_o20: "超过 20%",
      revgrowavg_o50: "超过 50%",
      revgrowavg_o100: "超过 100%",

      revgrow: "季度收入增长",
      revgrow_neg: "负增长 (<0%)",
      revgrow_pos: "正增长 (>0%)",
      revgrow_u10: "低于 -10%",
      revgrow_u20: "低于 -20%",
      revgrow_u50: "低于 -50%",
      revgrow_u100: "低于 -100%",
      revgrow_o10: "超过 10%",
      revgrow_o20: "超过 20%",
      revgrow_o50: "超过 50%",
      revgrow_o100: "超过 100%",

      netincgrow: "季度净收入增长",
      netincgrow_neg: "负增长 (<0%)",
      netincgrow_pos: "正增长 (>0%)",
      netincgrow_u10: "低于 -10%",
      netincgrow_u20: "低于 -20%",
      netincgrow_u50: "低于 -50%",
      netincgrow_u100: "低于 -100%",
      netincgrow_o10: "超过 10%",
      netincgrow_o20: "超过 20%",
      netincgrow_o50: "超过 50%",
      netincgrow_o100: "超过 100%",

      ltdc: "长期债务 / 资本化",
      ltdc_high: "高 (>0.5)",
      ltdc_low: "低 (<0.1)",
      ltdc_u1: "低于1",
      ltdc_u0_9: "低于0.9",
      ltdc_u0_8: "低于0.8",
      ltdc_u0_7: "低于0.7",
      ltdc_u0_6: "低于0.6",
      ltdc_u0_5: "低于0.5",
      ltdc_u0_4: "低于0.4",
      ltdc_u0_3: "低于0.3",
      ltdc_u0_2: "低于0.2",
      ltdc_u0_1: "低于0.1",
      ltdc_o0_1: "高于0.1",
      ltdc_o0_2: "高于0.2",
      ltdc_o0_3: "高于0.3",
      ltdc_o0_4: "高于0.4",
      ltdc_o0_5: "高于0.5",
      ltdc_o0_6: "高于0.6",
      ltdc_o0_7: "高于0.7",
      ltdc_o0_8: "高于0.8",
      ltdc_o0_9: "高于0.9",
      ltdc_o1: "高于1",

      grossMargin: "毛利率",
      grossm_pos: "正值 (>0%)",
      grossm_neg: "负值 (<0%)",
      grossm_u90: "低于90%",
      grossm_u70: "低于70%",
      grossm_u50: "低于50%",
      grossm_u30: "低于30%",
      grossm_u10: "低于10%",
      grossm_u5: "低于5%",
      grossm_u_10: "低于-10%",
      grossm_u_30: "低于-30%",
      grossm_u_50: "低于-50%",
      grossm_u_70: "低于-70%",
      grossm_u_100: "低于-100%",
      grossm_o0: "高于0%",
      grossm_o10: "高于10%",
      grossm_o30: "高于30%",
      grossm_o50: "高于50%",
      grossm_o70: "高于70%",
      grossm_o90: "高于90%",

      deRatio: "债务/股本",
      deratio_high: "高 (>0.5)",
      deratio_low: "低 (<0.1)",
      deratio_u1: "低于 1",
      deratio_u0_9: "低于 0.9",
      deratio_u0_8: "低于 0.8",
      deratio_u0_7: "低于 0.7",
      deratio_u0_6: "低于 0.6",
      deratio_u0_5: "低于 0.5",
      deratio_u0_4: "低于 0.4",
      deratio_u0_3: "低于 0.3",
      deratio_u0_2: "低于 0.2",
      deratio_u0_1: "低于 0.1",
      deratio_o0_1: "高于 0.1",
      deratio_o0_2: "高于 0.2",
      deratio_o0_3: "高于 0.3",
      deratio_o0_4: "高于 0.4",
      deratio_o0_5: "高于 0.5",
      deratio_o0_6: "高于 0.6",
      deratio_o0_7: "高于 0.7",
      deratio_o0_8: "高于 0.8",
      deratio_o0_9: "高于 0.9",
      deratio_o1: "高于 1",

      netProfitMargin: "净利润率",
      netprf_pos: "正数 (>0%)",
      netprf_neg: "负数 (<0%)",
      netprf_veryneg: "非常负数 (<-20%)",
      netprf_high: "高 (>20%)",
      netprf_u90: "低于 90%",
      netprf_u80: "低于 80%",
      netprf_u50: "低于 50%",
      netprf_u30: "低于 30%",
      netprf_u10: "低于 10%",
      netprf_u5: "低于 5%",
      netprf_u_10: "低于 -10%",
      netprf_u_30: "低于 -30%",
      netprf_u_50: "低于 -50%",
      netprf_u_100: "低于 -100%",
      netprf_o10: "高于 10%",
      netprf_o30: "高于 30%",
      netprf_o40: "高于 40%",
      netprf_o50: "高于 50%",
      netprf_o80: "高于 80%",
      netprf_o90: "高于 90%",

      dividendYield: "股息收益率",
      divy_none: "无 (0%)",
      divy_pos: "正 (>0%)",
      divy_high: "高 (>5%)",
      divy_veryhigh: "非常高 (>10%)",
      divy_o1: "超过1%",
      divy_o2: "超过2%",
      divy_o3: "超过3%",
      divy_o4: "超过4%",
      divy_o5: "超过5%",
      divy_o6: "超过6%",
      divy_o7: "超过7%",
      divy_o8: "超过8%",
      divy_o9: "超过9%",
      divy_o10: "超过10%",

      payoutRatio: "派息率",
      payoutr_none: "无 (0%)",
      payoutr_pos: "正 (>0%)",
      payoutr_low: "低 (<20%)",
      payoutr_high: "高 (>50%)",
      payoutr_o10: "超过10%",
      payoutr_o20: "超过20%",
      payoutr_o50: "超过50%",
      payoutr_o80: "超过80%",
      payoutr_o100: "超过100%",
      payoutr_u10: "低于10%",
      payoutr_u20: "低于20%",
      payoutr_u50: "低于50%",
      payoutr_u80: "低于80%",
      payoutr_u100: "低于100%",

      peHistoric: "历史市盈率",
      pehistoric_below: "低于历史平均",
      pehistoric_above: "高于历史平均",
      pbHistoric: "历史市净率",
      pbhistoric_below: "低于历史平均",
      pbhistoric_above: "高于历史平均",
      psHistoric: "历史市销率",
      pshistoric_below: "低于历史平均",
      pshistoric_above: "高于历史平均",
      pfcf: "价格/自由现金流",
      pfcf_low: "低 (<15)",
      pfcf_high: "高 (>50)",
      pfcf_u10: "低于 10",
      pfcf_u20: "低于 20",
      pfcf_u50: "低于 50",
      pfcf_u80: "低于 80",
      pfcf_u100: "低于 100",
      pfcf_o10: "高于 10",
      pfcf_o20: "高于 20",
      pfcf_o50: "高于 50",
      pfcf_o80: "高于 80",
      pfcf_o100: "高于 100",

      operatingMargin: "营业利润率",
      opmr_pos: "正值 (>0%)",
      opmr_neg: "负值 (<0%)",
      opmr_veryneg: "非常负 (<-20%)",
      opmr_high: "高 (>25%)",
      opmr_u90: "低于 90%",
      opmr_u80: "低于 80%",
      opmr_u70: "低于 70%",
      opmr_u60: "低于 60%",
      opmr_u50: "低于 50%",
      opmr_u40: "低于 40%",
      opmr_u30: "低于 30%",
      opmr_u20: "低于 20%",
      opmr_u10: "低于 10%",
      opmr_u_m10: "低于 -10%",
      opmr_u_m30: "低于 -30%",
      opmr_u_m50: "低于 -50%",
      opmr_u_m100: "低于 -100%",
      opmr_o10: "高于 10%",
      opmr_o20: "高于 20%",
      opmr_o30: "高于 30%",
      opmr_o40: "高于 40%",
      opmr_o50: "高于 50%",
      opmr_o60: "高于 60%",
      opmr_o70: "高于 70%",
      opmr_o80: "高于 80%",
      opmr_o90: "高于 90%",

      pricecash: "价格/现金",
      pricecash_low: "低 (<3)",
      pricecash_high: "高 (>50)",
      pricecash_u1: "低于 1",
      pricecash_u2: "低于 2",
      pricecash_u3: "低于 3",
      pricecash_u4: "低于 4",
      pricecash_u5: "低于 5",
      pricecash_u6: "低于 6",
      pricecash_u7: "低于 7",
      pricecash_u8: "低于 8",
      pricecash_u9: "低于 9",
      pricecash_u10: "低于 10",
      pricecash_o1: "高于 1",
      pricecash_o2: "高于 2",
      pricecash_o3: "高于 3",
      pricecash_o4: "高于 4",
      pricecash_o5: "高于 5",
      pricecash_o6: "高于 6",
      pricecash_o7: "高于 7",
      pricecash_o8: "高于 8",
      pricecash_o9: "高于 9",
      pricecash_o10: "高于 10",
      pricecash_o20: "高于 20",
      pricecash_o30: "高于 30",
      pricecash_o40: "高于 40",
      pricecash_o50: "高于 50",

      exchange: "交易所",
      any: "任何",
      nasdaq: "纳斯达克",
      nyse: "纽约证券交易所",
      amex: "美国证券交易所",
      marketCap: "市值",
      mcap_mega: "超大型（2000亿美元及以上）",
      mcap_large: "大型（100亿到2000亿美元）",
      mcap_mid: "中型（20亿到100亿美元）",
      mcap_small: "小型（3亿到20亿美元）",
      mcap_micro: "微型（5000万到3亿美元）",
      mcap_nano: "纳米型（5000万美元以下）",
      mcap_largeover: "大型以上（超过100亿美元）",
      mcap_midover: "中型以上（超过20亿美元）",
      mcap_smallover: "小型以上（超过3亿美元）",
      mcap_microover: "微型以上（超过5000万美元）",
      mcap_largeunder: "大型以下（2000亿美元以下）",
      mcap_midunder: "中型以下（100亿美元以下）",
      mcap_smallunder: "小型以下（20亿美元以下）",
      mcap_microunder: "微型以下（3亿美元以下）",

      performance: "表现",
      perf_dup: "一天上涨",
      perf_ddown: "一天下跌",
      perf_d15u: "一天 -15%",
      perf_d10u: "一天 -10%",
      perf_d10o: "一天 +10%",
      perf_d15o: "一天 +15%",
      perf_1w30u: "一周 -30%",
      perf_1w10u: "一周 -10%",
      perf_1wdown: "一周下跌",
      perf_1wup: "一周上涨",
      perf_1w10o: "一周 +10%",
      perf_1w30o: "一周 +30%",
      perf_4w50u: "一个月 -50%",
      perf_4w30u: "一个月 -30%",
      perf_4w10u: "一个月 -10%",
      perf_4wdown: "一个月下跌",
      perf_4wup: "一个月上涨",
      perf_4w20o: "一个月 +20%",
      perf_4w30o: "一个月 +30%",
      perf_4w50o: "一个月 +50%",
      perf_13w50u: "一季度 -50%",
      perf_13w30u: "一季度 -30%",
      perf_13w10u: "一季度 -10%",
      perf_13wdown: "一季度下跌",
      perf_13wup: "一季度上涨",
      perf_13w10o: "一季度 +10%",
      perf_13w30o: "一季度 +30%",
      perf_13w50o: "一季度 +50%",
      perf_26w75u: "半年 -75%",
      perf_26w50u: "半年 -50%",
      perf_26w20u: "半年 -20%",
      perf_26w10u: "半年 -10%",
      perf_26wdown: "半年下跌",
      perf_26wup: "半年上涨",
      perf_26w10o: "半年 +10%",
      perf_26w20o: "半年 +20%",
      perf_26w50o: "半年 +50%",
      perf_26w100o: "半年 +100%",
      perf_52w75u: "一年 -75%",
      perf_52w50u: "一年 -50%",
      perf_52w30u: "一年 -30%",
      perf_52w10u: "一年 -10%",
      perf_52wdown: "一年下跌",
      perf_52wup: "一年上涨",
      perf_52w10o: "一年 +10%",
      perf_52w50o: "一年 +50%",
      perf_52w100o: "一年 +100%",
      perf_52w300o: "一年 +300%",
      perf_52w500o: "一年 +500%",
      perf_ytd75u: "年初至今 -75%",
      perf_ytd50u: "年初至今 -50%",
      perf_ytd30u: "年初至今 -30%",
      perf_ytd10u: "年初至今 -10%",
      perf_ytddown: "年初至今下跌",
      perf_ytdup: "年初至今上涨",
      perf_ytd10o: "年初至今 +10%",
      perf_ytd30o: "年初至今 +30%",
      perf_ytd50o: "年初至今 +50%",
      perf_ytd100o: "年初至今 +100%",

      pe: "市盈率 (P / E)",
      pe_low: "低 (<15)",
      pe_profitable: "盈利 (>0)",
      pe_high: "高 (>50)",
      pe_u5: "低于 5",
      pe_u10: "低于 10",
      pe_u15: "低于 15",
      pe_u20: "低于 20",
      pe_u30: "低于 30",
      pe_u40: "低于 40",
      pe_u50: "低于 50",
      pe_o5: "高于 5",
      pe_o10: "高于 10",
      pe_o15: "高于 15",
      pe_o20: "高于 20",
      pe_o30: "高于 30",
      pe_o40: "高于 40",
      pe_o50: "高于 50",

      peg: "市盈增长比 (PEG)",
      peg_low: "低 (<1)",
      peg_high: "高 (>2)",
      peg_u1: "低于 1",
      peg_u2: "低于 2",
      peg_u3: "低于 3",
      peg_o1: "高于 1",
      peg_o2: "高于 2",
      peg_o3: "高于 3",

      pb: "市净率 (P / B)",
      pb_low: "低 (<1)",
      pb_high: "高 (>5)",
      pb_u1: "低于 1",
      pb_u2: "低于 2",
      pb_u3: "低于 3",
      pb_u4: "低于 4",
      pb_u5: "低于 5",
      pb_u6: "低于 6",
      pb_u7: "低于 7",
      pb_u8: "低于 8",
      pb_u9: "低于 9",
      pb_u10: "低于 10",
      pb_o1: "高于 1",
      pb_o2: "高于 2",
      pb_o3: "高于 3",
      pb_o4: "高于 4",
      pb_o5: "高于 5",
      pb_o6: "高于 6",
      pb_o7: "高于 7",
      pb_o8: "高于 8",
      pb_o9: "高于 9",
      pb_o10: "高于 10",

      ps: "市销率 (P / S)",
      ps_low: "低 (<1)",
      ps_high: "高 (>5)",
      ps_u1: "低于 1",
      ps_u2: "低于 2",
      ps_u3: "低于 3",
      ps_u4: "低于 4",
      ps_u5: "低于 5",
      ps_u6: "低于 6",
      ps_u7: "低于 7",
      ps_u8: "低于 8",
      ps_u9: "低于 9",
      ps_u10: "低于 10",
      ps_o1: "高于 1",
      ps_o2: "高于 2",
      ps_o3: "高于 3",
      ps_o4: "高于 4",
      ps_o5: "高于 5",
      ps_o6: "高于 6",
      ps_o7: "高于 7",
      ps_o8: "高于 8",
      ps_o9: "高于 9",
      ps_o10: "高于 10",

      quick: "速动比率",
      quick_high: "高 (>3)",
      quick_low: "低 (<0.5)",
      quick_u1: "低于 1",
      quick_u0_5: "低于 0.5",
      quick_o0_5: "高于 0.5",
      quick_o1: "高于 1",
      quick_o1_5: "高于 1.5",
      quick_o2: "高于 2",
      quick_o3: "高于 3",
      quick_o4: "高于 4",
      quick_o5: "高于 5",
      quick_o10: "高于 10",

      current: "流动比率",
      current_high: "高 (>3)",
      current_low: "低 (<1)",
      current_u1: "低于 1",
      current_u0_5: "低于 0.5",
      current_o0_5: "高于 0.5",
      current_o1: "高于 1",
      current_o1_5: "高于 1.5",
      current_o2: "高于 2",
      current_o3: "高于 3",
      current_o4: "高于 4",
      current_o5: "高于 5",
      current_o10: "高于 10",

      sector: "行业",
      sector_realestate: "房地产",
      sector_health: "医疗保健",
      sector_energy: "能源",
      sector_utilities: "公用事业",
      sector_cd: "消费者防御",
      sector_fs: "金融服务",
      sector_coms: "通信服务",
      sector_cs: "消费者服务",
      sector_bm: "基础材料",
      sector_i: "工业",
      sector_cc: "消费者周期性",
      sector_t: "科技",
    },
    TopCompaniesTitle: "美国最大公司依据",
    TotalMarketCap: "总市值",
    Companies: "公司",
    sortBy: "排序依据",
    Terms: "条款和条件",
    Privacy: "隐私政策",
    logout: "退出",
    portfolioLeader: "投资组合领先者",
    portfolio: {
      symbol: "股票代码",
      portfolioName: "投资组合名称",
      date: "日期",
      price: "价格",
      noOfShares: "股票数量",
      buy: "购买",
      sellTransactions: "卖出交易",
      purchasePrice: "购买价格",
      purchaseDate: "购买日期",
      priceSold: "售出价格",
      dateSold: "售出日期",
      profitAndLoss: "盈亏",
      company: "公司",
      sector: "行业",
      currentPrice: "当前价格",
      avgPurchasePrice: "平均购买价格",
      totalShares: "总股票数量",
      portfolioPct: "投资组合百分比",
      capital: "资金",
      sell: "卖出",
      history: "历史",
      pctReturns: "回报率 %",
      name: "名称",
      noOfCompanies: "公司数量",
      total: "总计",
    },

    login: {
      login: "登录",
      signup: "注册",
      forgotpassword: "忘记密码",
      resetpassword: "重置密码",
      email: "电子邮件",
      password: "密码",
      forgotPasswordQ: "忘记密码？",
      createAccount: "创建一个新的 Stock Penguins 账户",
      firstName: "名字",
      lastName: "姓氏",
      requestOTP: "请求 OTP",
      otp: "OTP",
    },
    presetName: "预设名称",
    createdBy: "创建者",
    upvotes: "点赞",
    savePreset: "保存预设",
    share: "分享",
    save: "保存",
    selectPreset: "选择预设",
    loginToSP: "登录到Stock Penguins",
    clearFilters: "清除筛选条件",

    stockScreener: "股票筛选器",
    topPresets: "热门预设",
    myPortfolio: "我的投资组合",
    portfolioLeaderboard: "投资组合排行榜",
    peHistoric: "P/E 历史数据",
    SimilarCompanies: "类似公司",
    Stock: "股票",
    today: "1日变化",
    oneMonthChange: "1月变化",
    oneYearChange: "1年变化",
    OneYear: "1 年",
    ThreeYear: "3 年",
    FiveYear: "5 年",
    TenYear: "10 年",
    CAGRDesc: "历史变化",
    welcome: "欢迎",
    SearchBarPlaceholder: "你的下一个十倍股是什么？",
    Search: "搜索",
    Industry: "行业",
    Sector: "部门",
    MarketCap: "市值",
    PE: "市盈率",
    PS: "市销率",
    PB: "市净率",
    FCFYield: "自由现金流收益率",
    PEGRatio: "市盈增长比率",
    OverallRisk: "皮奥特罗夫斯基评分",
    week52HighPrice: "52周最高价",
    PriceGrowthoverthepast5Years: "过去5年价格增长百分比",
    PriceGrowthoverthepast1Years: "过去1年价格增长百分比",
    Profitability: "盈利能力？（季度）",
    GrowingRevenue: "增长收入？（季度）",
    GrowingNetProfit: "增长净利润？（季度）",
    ProfitabilityAnnual: "盈利能力？（年度）",
    GrowingRevenueAnnual: "增长收入？（年度）",
    GrowingNetProfitAnnual: "增长净利润？（年度）",
    FinancialMetrics: "估值摘要",
    BalanceSheet: "财务指标",
    Cash: "现金",
    Debt: "债务",
    CashDebtRatio: "现金债务比",
    DividendYield: "股息率",
    PayoutRatio: "派息率",
    FiveYearAvgDividendYield: "过去5年平均股息率",
    DividendGrowthRateoverthepast5Years: "过去5年股息增长率",
    QualityOfCompany: "公司质量",
    Dividend: "股息",
    comparedToLastQuarter: "与上季度相比",
    comparedToLastYear: "与去年相比",
    newTab: "新标签页",
    compare: "比较",
    topCompanies: "顶级公司",
    watchlist: "关注列表",
    rank: "排名",
    companyName: "公司名称",
    symbol: "股票代码",
    marketCap: "市值",
    price: "价格",
    volume: "成交量",
    country: "国家",
    joinDiscord: "加入不和谐",
    joinTelegram: "加入电报群",
    contactUs: "联系我们",
    InTheLast365Days: "在过去的365天里",
    LastUpdated: "最后更新",
    pages: "页数",

    titles: {
      PE: "市盈率 (P / E)",
      PS: "市销率 (P / S)",
      PB: "市净率 (P / B)",
      RevenueGrowth: "收入增长 %",
      NetIncomeGrowth: "净收入增长 %",
      Price: "价格",
      MarketCap: "市值",
      Revenue: "收入",
      RevenueVsNetIncome: "收入与净收入对比",
      NetIncome: "净收入",
      Ebitda: "EBITDA",
      Eps: "每股收益",
      TotalExpenses: "总费用",
      SharesIssued: "流通股数",
      FreeCashFlow: "自由现金流",
      OperatingCashFlow: "运营现金流",
      Dividend: "股息",
      GrossMargin: "毛利率",
      CashVsDebt: "现金与债务对比",
    },
    description: {
      Price: "公司历年股票价格",
      PE: "市盈率 (P/E) 反映投资者愿意为公司每一美元的收益支付多少；通过将股价除以每股收益计算。较高的市盈率可能表明投资者预计未来增长率较高，或者股票可能被高估",
      PS: "市销率 (P/S) 衡量投资者为公司每一美元的销售额支付的价格；通过将股价除以每股销售额计算。较高的市销率可能表明投资者预计未来销售额将增长，或者股票可能被高估",
      PB: "市净率 (P/B) 是比较公司市场价值与账面价值 (资产减去负债) 的比率；通过将股价除以每股账面价值计算。较高的市净率可能表明投资者对公司未来表现有信心，或者如果公司没有强大的资产增长，则可能表明股票被高估",
      RevenueGrowth: "收入增长百分比",
      NetIncomeGrowth: "净收入增长百分比",
      MarketCap: "公司已发行股票的总价值历年变化",
      Revenue: "通过业务活动、商品和服务销售产生的总收入历年变化",
      RevenueVsNetIncome:
        "公司总收入与净利润的对比，表明公司增长的可持续性。较低的比率可能表明公司正在建立竞争优势并占据市场份额",
      NetIncome: "公司在扣除所有费用、税款和成本后的利润历年变化",
      Ebitda:
        "公司历年息税折旧及摊销前的收益。它衡量公司核心运营的整体财务表现和盈利能力",
      Eps: "分配给每股普通股的公司利润部分。它表明股东每持有一股将获得多少收益",
      TotalExpenses:
        "公司开展业务所产生的所有成本的总和，包括运营费用、利息、税款等。该图可以显示历年总费用，指示成本趋势和效率",
      SharesIssued: "公司已发行的总股数",
      FreeCashFlow:
        "在支持运营和维护资本资产的现金流出后公司产生的现金。它代表可用于股息、偿还债务或再投资的资金",
      OperatingCashFlow:
        "公司历年业务运营产生的现金。它表明公司是否能够产生足够的正现金流以维持和增长其运营",
      Dividend: "公司盈利的一部分以现金支付的形式分配给股东。",
      GrossMargin:
        "在扣除销售成本（COGS）后的收入百分比。它表明公司生产和销售产品的效率",
      CashVsDebt:
        "公司现金储备与总债务的对比图。它有助于评估公司的财务健康状况和流动性",
    },
    dataDescriptions: {
      peHistorical:
        "5年历史平均市盈率提供了足够的时间跨度，以捕捉市场趋势变化和公司的业绩表现。它还反映了最新的经济状况、利率和行业发展。",
      peHistoricalCompare:
        "如果市盈率高于历史平均水平，通常表明估值过高且增长预期很高，如果增长低于预期，可能存在潜在风险。相反，低于平均水平的市盈率表明市场悲观情绪或暂时的增长问题导致估值被低估。考虑经济、行业和公司特定的因素非常重要，因为发展中行业中的成长型公司可能会有较高的估值。",

      MarketCap: "公司股票的总价值；通过将股票价格乘以总股份数量计算得出",
      PE: "市盈率 (P/E 比率) 衡量投资者愿意为公司每美元的收益支付多少；通过将股票价格除以每股收益计算得出。高市盈率可能表明投资者预期未来增长率较高，或者股票可能被高估",
      PS: "市销率 (P/S 比率) 衡量投资者为公司每美元的销售额支付多少；通过将股票价格除以每股销售额计算得出。高市销率可能表明投资者预期未来销售增长较高，或者股票可能被高估",
      PB: "市净率 (P/B 比率) 是将公司的市场价值与其账面价值（资产减去负债）进行比较的比率；通过将股票价格除以每股账面价值计算得出。高市净率可能表明投资者对公司未来表现有强烈信心，或者如果公司没有强劲的资产增长，股票可能被高估",
      FCFYield:
        "自由现金流收益率是衡量公司相对于其市值产生的自由现金流量的指标；通过将自由现金流除以市值计算得出",
      PEGRatio:
        "市盈率与增长比率 (PEG 比率) 是一种估值指标，它将公司的市盈率与其收益增长率进行比较；有助于判断股票是被高估还是低估。PEG 比率为 1 表示公允价值，小于 1 表示相对于增长率被低估，大于 1 表示相对于增长率被高估",
      OverallRisk:
        "评估投资风险水平，考虑因素如波动性、财务稳定性和市场状况；通常风险越低越好，但这取决于投资者的风险承受能力",
      week52HighPrice:
        "过去一年中股票达到的最高价格；股票交易接近52周高点可能表明近期表现强劲，但也可能表明被高估",
      ProfitabilityAnnual:
        "公司在过去一年中是否赚的钱比花的钱多；盈利能力通常是公司财务健康的良好标志",
      GrowingRevenueAnnual:
        "公司收入（销售额）与前一年相比是否增加；持续的收入增长通常是业务扩展的积极信号",
      GrowingNetProfitAnnual:
        "公司净利润（扣除费用后的收入）与前一年相比是否增加；净利润增长是财务表现改善的强劲指标",
      PriceGrowthoverthepast5Years:
        "过去五年股票价格的涨幅；较高的百分比表明长期表现更好",
      PriceGrowthoverthepast1Years:
        "过去一年股票价格的涨幅；短期内的强劲增长是积极的，但应结合其他因素考虑",
      Cash: "公司可立即使用的流动资金总额；更多的现金通常对财务灵活性更有利",
      Debt: "公司欠贷方的总金额；如果公司不能产生足够的收入来偿还债务，较高的负债可能存在风险",
      CashDebtRatio:
        "衡量公司使用手头现金偿还债务的能力；较高的比率表明财务健康状况较好",
      Profitability:
        "公司在最近一个季度是否赚的钱比花的钱多；季度盈利能力表明公司持续产生收入",
      GrowingRevenue:
        "公司收入与上季度相比是否增加；持续的收入增长是业务成功的标志",
      GrowingNetProfit:
        "公司净利润与上季度相比是否增加；季度净利润的增长表明短期财务表现正在改善",
      DividendYield:
        "公司向股东支付的年度股息金额，占其股价的百分比；较高的收益率表明相对于股票价格，股息收入更多",
      PayoutRatio:
        "公司收益中支付给股东的股息比例；较低的比率表明公司保留更多的收益用于增长，而非常高的比率可能表明股息不可持续",
      FiveYearAvgDividendYield:
        "公司在过去五年中提供的平均股息收益率；有助于投资者了解股息支付的持续性或稳定性",
    },
    labels: {
      TotalCash: "总现金",
      TotalDebt: "总债务",
      Revenue: "收入",
      NetIncome: "净收入",
    },
    Quarterly: "季度",
    Annually: "每年",
    FullScreen: "全屏",
    ExitFullScreen: "退出全屏",
    homeMetaTitle: "StockPenguins | 免费全球股票扫描器",
    homeMetaDescription:
      "发现终极免费股票扫描应用程序，适用于Apple、Tesla、Amazon和Microsoft等美国股票。访问详细指标，如价格、市值、收入、净收入、EBITDA、自由现金流、每股收益、股息、市盈率等。轻松分析和比较股票，做出明智的投资决策。立即搜索！",
    pageMetaTitle: "股票指标 | 市盈率、收入和市值",
    pageMetaDescription:
      "发现免费指标，包括价格、市值、收入、净收入、EBITDA、自由现金流、每股收益、股息、市盈率等。了解Apple的财务表现。立即探索！",
  },
  [LANGUAGES[2].value]: {
    // Spanish
    peHistorical: "Promedio histórico de P/E a 5 años",
    peHistoricalCompare:
      "¿Por debajo o por encima del promedio histórico de P/E a 5 años?",
    search: "Buscar Acciones",

    scanner: {
      industry: "Industria",
      ind_aginputs: "Insumos Agrícolas",
      ind_indpollctrl: "Industrial - Controles de Contaminación y Tratamiento",
      ind_indmaterials: "Materiales Industriales",
      ind_autorv: "Automóvil - Vehículos Recreativos",
      ind_discstores: "Tiendas de Descuento",
      ind_insdiv: "Seguros - Diversificados",
      ind_entertainment: "Entretenimiento",
      ind_reitdiv: "REIT - Diversificados",
      ind_medicaldist: "Medicina - Distribución",
      ind_softinfra: "Software - Infraestructura",
      ind_softapp: "Software - Aplicación",
      ind_electricaleqp: "Equipos y Partes Eléctricas",
      ind_astmgmtglobal: "Gestión de Activos - Global",
      ind_indpower: "Productores Independientes de Energía",
      ind_oilgasdrill: "Petróleo y Gas - Perforación",
      ind_leisure: "Ocio",
      ind_medicaldev: "Medicina - Dispositivos",
      ind_coal: "Carbón",
      ind_edu: "Educación y Servicios de Capacitación",
      ind_reitind: "REIT - Industrial",
      ind_autoparts: "Automóvil - Partes",
      ind_metalfab: "Manufactura - Fabricación de Metales",
      ind_fincredit: "Finanzas - Servicios de Crédito",
      ind_broadcasting: "Radiodifusión",
      ind_medcarefac: "Medicina - Instalaciones de Atención",
      ind_chemspecialty: "Químicos - Especialidad",
      ind_astmgmt: "Gestión de Activos",
      ind_insreins: "Seguros - Reaseguro",
      ind_travellodging: "Alojamiento para Viajes",
      ind_findiv: "Finanzas - Diversificado",
      ind_banks: "Bancos",
      ind_bevalcoholic: "Bebidas - Alcohólicas",
      ind_hardwareeqp: "Hardware, Equipos y Partes",
      ind_inddist: "Industrial - Distribución",
      ind_constrmat: "Materiales de Construcción",
      ind_fooddist: "Distribución de Alimentos",
      ind_bevwines: "Bebidas - Viñedos y Destilerías",
      ind_furnishings: "Muebles, Accesorios y Electrodomésticos",
      ind_silver: "Plata",
      ind_specialtybiz: "Servicios Empresariales Especializados",
      ind_apparelmanu: "Ropa - Fabricantes",
      ind_publishing: "Publicación",
      ind_finmortgages: "Finanzas - Hipotecas",
      ind_railroads: "Ferrocarriles",
      ind_insprop: "Seguros - Propiedad y Casualidad",
      ind_telco: "Servicios de Telecomunicaciones",
      ind_reitretail: "REIT - Retail",
      ind_internetcontent: "Contenido e Información de Internet",
      ind_indinfrastructure: "Industrial - Operaciones de Infraestructura",
      ind_regwater: "Agua Regulada",
      ind_packaging: "Embalaje y Contenedores",
      ind_realeservices: "Bienes Raíces - Servicios",
      ind_reddiversified: "Bienes Raíces - Diversificado",
      ind_renewutilities: "Servicios Públicos Renovables",
      ind_preciousmetals: "Otros Metales Preciosos",
      ind_autodealerships: "Automóvil - Concesionarios",
      ind_foodconf: "Confitería de Alimentos",
      ind_toolsmfg: "Manufactura - Herramientas y Accesorios",
      ind_findata: "Finanzas - Datos y Bolsas de Valores",
      ind_adagencies: "Agencias de Publicidad",
      ind_luxurygoods: "Bienes de Lujo",
      ind_householdprod: "Productos y Servicios del Hogar",
      ind_reitresidential: "REIT - Residencial",
      ind_deptstores: "Grandes Almacenes",
      ind_personalprod: "Productos y Servicios Personales",
      ind_paperlumber: "Papel, Madera y Productos Forestales",
      ind_staffing: "Servicios de Empleo y Personal",
      ind_resconstr: "Construcción Residencial",
      ind_healthcare: "Atención Médica",
      ind_construction: "Construcción",
      ind_specialtyretail: "Retail Especializado",
      ind_mediaentertain: "Medios y Entretenimiento",
      ind_realdev: "Bienes Raíces - Desarrollo",
      ind_astmgmtincome: "Gestión de Activos - Ingresos",
      ind_engconst: "Ingeniería y Construcción",
      ind_gambling: "Juegos, Resorts y Casinos",
      ind_reitoffice: "REIT - Oficina",
      ind_finservices: "Servicios Financieros",
      ind_drugmanugen: "Fabricantes de Medicamentos - General",
      ind_homeimprovement: "Mejoras para el Hogar",
      ind_steel: "Acero",
      ind_regionalbanks: "Bancos - Regionales",
      ind_agfarmproducts: "Productos Agrícolas de Granja",
      ind_conglomerates: "Conglomerados",
      ind_medhealthcareplans: "Medicina - Planes de Atención Médica",
      ind_chemicals: "Químicos",
      ind_oilmidstream: "Petróleo y Gas - Midstream",
      ind_oilexp: "Petróleo y Gas - Exploración y Producción",
      ind_computerhardware: "Hardware de Computadora",
      ind_banksdiversified: "Bancos - Diversificados",
      ind_restaurants: "Restaurantes",
      ind_oilintegrated: "Petróleo y Gas - Integrado",
      ind_divutilities: "Servicios Públicos Diversificados",
      ind_reithotel: "REIT - Hotel y Motel",
      ind_gaming: "Juegos Electrónicos y Multimedia",
      ind_medpharma: "Medicina - Productos Farmacéuticos",
      ind_grocerystores: "Tiendas de Abarrotes",
      ind_wastemgmt: "Gestión de Residuos",
      ind_reggas: "Gas Regulada",
      ind_trucking: "Transporte de Carga",
      ind_investbanking: "Inversión - Banca y Servicios de Inversión",
      ind_automanufact: "Automóvil - Fabricantes",
      ind_reitmortgage: "REIT - Hipoteca",
      ind_biotech: "Biotecnología",
      ind_techdistrib: "Distribuidores de Tecnología",
      ind_bizequip: "Equipos y Suministros Empresariales",
      ind_apparelfoot: "Ropa - Calzado y Accesorios",
      ind_medicalspec: "Medicina - Especialidades",
      ind_apparelretail: "Ropa - Retail",
      ind_regelectric: "Electricidad Regulada",
      ind_finconglomerates: "Finanzas - Conglomerados",
      ind_oilrefining: "Petróleo y Gas - Refinación y Marketing",
      ind_reitspecialty: "REIT - Especialidad",
      ind_agmachinery: "Agrícola - Maquinaria",
      ind_packagedfoods: "Alimentos Envasados",
      ind_reithealthcare: "REIT - Instalaciones de Atención Médica",
      ind_solar: "Solar",
      ind_copper: "Cobre",
      ind_gold: "Oro",
      ind_medicalsupp: "Medicina - Instrumentos y Suministros",
      ind_insurancebrokers: "Corredores de Seguros",
      ind_security: "Seguridad y Servicios de Protección",
      ind_tobacco: "Tabaco",
      ind_fincapmarkets: "Finanzas - Mercados de Capital",
      ind_mfgmisc: "Manufactura - Misceláneos",
      ind_uranium: "Uranio",
      ind_insurancelife: "Seguros - Vida",
      ind_indmachinery: "Industrial - Maquinaria",
      ind_shellcompanies: "Empresas Fantasma",
      ind_consumelectronics: "Electrónica de Consumo",
      ind_medicaldiag: "Medicina - Diagnósticos e Investigación",
      ind_oilequip: "Petróleo y Gas - Equipos y Servicios",
      ind_commequip: "Equipos de Comunicación",
      ind_aerospace: "Aeroespacial y Defensa",
      ind_travelservices: "Servicios de Viajes",
      ind_drugmanuspecialty:
        "Fabricantes de Medicamentos - Especialidad y Genérico",
      ind_aluminum: "Aluminio",
      ind_apparelandretail: "Ropa - Distribuidores y Retail",
      ind_automanufactcars: "Automóvil - Fabricantes de Automóviles",
      ind_law: "Servicios Legales",
      ind_pipes: "Tuberías",
      ind_solarpanel: "Paneles Solares",
      ind_autoresales: "Automóvil - Ventas de Vehículos Usados",
      ind_realestate: "Bienes Raíces",
      ind_fuelcells: "Celdas de Combustible",
      ind_oilgasalt: "Petróleo y Gas - Alternativos",
      ind_multimedia: "Multimedia",
      ind_mining: "Minería",
      ind_electricprod: "Electricidad - Productores",
      ind_intservices: "Servicios de Internet",
      ind_metals: "Metales",
      ind_durables: "Bienes Duraderos",
      ind_constructionequipment: "Construcción - Equipos",
      ind_coating: "Recubrimiento",
      ind_indhome: "Industrial - Productos para el Hogar",
      ind_gasutility: "Servicios de Gas",
      ind_personalcare: "Cuidado Personal",
      ind_hardware: "Hardware",
      ind_lithium: "Litio",
      ind_explorations: "Exploraciones",
      ind_foodprod: "Productos Alimenticios",
      ind_seasonal: "Estacional",
      ind_finscience: "Finanzas - Ciencia y Tecnología",
      ind_densifying: "Densificación",
      ind_customgifts: "Regalos Personalizados",
      ind_subcontract: "Subcontratación",
      ind_supplies: "Suministros",
      ind_manufacturing: "Manufactura",
      ind_sustainability: "Sostenibilidad",
      ind_homeimprovement: "Mejoras para el Hogar",
      ind_aquaculture: "Acuicultura",
      ind_biofuels: "Biocombustibles",
      ind_utility: "Utilidad",
      ind_electronics: "Electrónica",
      ind_drones: "Drones",
      ind_multilevel: "Multinivel",
      ind_tourism: "Turismo",
      ind_logistics: "Logística",
      ind_business: "Negocios",
      ind_aluminum: "Aluminio",
      ind_itservices: "Servicios de Tecnología de la Información",
      ind_medhealthinfo:
        "Medicina - Servicios de Información de Atención Médica",
      ind_bevnonalcoholic: "Bebidas - No Alcohólicas",
      ind_consulting: "Servicios de Consultoría",
      ind_rentleasing: "Servicios de Alquiler y Arrendamiento",
      ind_marineshipping: "Transporte Marítimo",
      ind_software: "Software - Servicios",
      ind_astmgmtbonds: "Gestión de Activos - Bonos",
      ind_freightlogistics: "Carga y Logística Integrada",
      ind_insspec: "Seguros - Especialidad",
      ind_semiconductors: "Semiconductores",
      ind_airlines: "Aerolíneas, Aeropuertos y Servicios Aéreos",

      revgrowavg: "Crecimiento de Ingresos en los últimos 5 años",
      revgrowavg_neg: "Negativo (<0%)",
      revgrowavg_pos: "Positivo (>0%)",
      revgrowavg_u10: "Menos de -10%",
      revgrowavg_u20: "Menos de -20%",
      revgrowavg_u50: "Menos de -50%",
      revgrowavg_u100: "Menos de -100%",
      revgrowavg_o10: "Más de 10%",
      revgrowavg_o20: "Más de 20%",
      revgrowavg_o50: "Más de 50%",
      revgrowavg_o100: "Más de 100%",

      revgrow: "Crecimiento de Ingresos t/t",
      revgrow_neg: "Negativo (<0%)",
      revgrow_pos: "Positivo (>0%)",
      revgrow_u10: "Menos de -10%",
      revgrow_u20: "Menos de -20%",
      revgrow_u50: "Menos de -50%",
      revgrow_u100: "Menos de -100%",
      revgrow_o10: "Más de 10%",
      revgrow_o20: "Más de 20%",
      revgrow_o50: "Más de 50%",
      revgrow_o100: "Más de 100%",

      netincgrow: "Crecimiento de Ingresos Netos t/t",
      netincgrow_neg: "Negativo (<0%)",
      netincgrow_pos: "Positivo (>0%)",
      netincgrow_u10: "Menos de -10%",
      netincgrow_u20: "Menos de -20%",
      netincgrow_u50: "Menos de -50%",
      netincgrow_u100: "Menos de -100%",
      netincgrow_o10: "Más de 10%",
      netincgrow_o20: "Más de 20%",
      netincgrow_o50: "Más de 50%",
      netincgrow_o100: "Más de 100%",

      ltdc: "Deuda a Largo Plazo / Capitalización",
      ltdc_high: "Alto (>0.5)",
      ltdc_low: "Bajo (<0.1)",
      ltdc_u1: "Menos de 1",
      ltdc_u0_9: "Menos de 0.9",
      ltdc_u0_8: "Menos de 0.8",
      ltdc_u0_7: "Menos de 0.7",
      ltdc_u0_6: "Menos de 0.6",
      ltdc_u0_5: "Menos de 0.5",
      ltdc_u0_4: "Menos de 0.4",
      ltdc_u0_3: "Menos de 0.3",
      ltdc_u0_2: "Menos de 0.2",
      ltdc_u0_1: "Menos de 0.1",
      ltdc_o0_1: "Más de 0.1",
      ltdc_o0_2: "Más de 0.2",
      ltdc_o0_3: "Más de 0.3",
      ltdc_o0_4: "Más de 0.4",
      ltdc_o0_5: "Más de 0.5",
      ltdc_o0_6: "Más de 0.6",
      ltdc_o0_7: "Más de 0.7",
      ltdc_o0_8: "Más de 0.8",
      ltdc_o0_9: "Más de 0.9",
      ltdc_o1: "Más de 1",

      grossMargin: "Margen Bruto",
      grossm_pos: "Positivo (>0%)",
      grossm_neg: "Negativo (<0%)",
      grossm_u90: "Menos del 90%",
      grossm_u70: "Menos del 70%",
      grossm_u50: "Menos del 50%",
      grossm_u30: "Menos del 30%",
      grossm_u10: "Menos del 10%",
      grossm_u5: "Menos del 5%",
      grossm_u_10: "Menos del -10%",
      grossm_u_30: "Menos del -30%",
      grossm_u_50: "Menos del -50%",
      grossm_u_70: "Menos del -70%",
      grossm_u_100: "Menos del -100%",
      grossm_o0: "Más del 0%",
      grossm_o10: "Más del 10%",
      grossm_o30: "Más del 30%",
      grossm_o50: "Más del 50%",
      grossm_o70: "Más del 70%",
      grossm_o90: "Más del 90%",

      deRatio: "Deuda/Capital",
      deratio_high: "Alto (>0.5)",
      deratio_low: "Bajo (<0.1)",
      deratio_u1: "Menos de 1",
      deratio_u0_9: "Menos de 0.9",
      deratio_u0_8: "Menos de 0.8",
      deratio_u0_7: "Menos de 0.7",
      deratio_u0_6: "Menos de 0.6",
      deratio_u0_5: "Menos de 0.5",
      deratio_u0_4: "Menos de 0.4",
      deratio_u0_3: "Menos de 0.3",
      deratio_u0_2: "Menos de 0.2",
      deratio_u0_1: "Menos de 0.1",
      deratio_o0_1: "Más de 0.1",
      deratio_o0_2: "Más de 0.2",
      deratio_o0_3: "Más de 0.3",
      deratio_o0_4: "Más de 0.4",
      deratio_o0_5: "Más de 0.5",
      deratio_o0_6: "Más de 0.6",
      deratio_o0_7: "Más de 0.7",
      deratio_o0_8: "Más de 0.8",
      deratio_o0_9: "Más de 0.9",
      deratio_o1: "Más de 1",

      netProfitMargin: "Margen de Beneficio Neto",
      netprf_pos: "Positivo (>0%)",
      netprf_neg: "Negativo (<0%)",
      netprf_veryneg: "Muy Negativo (<-20%)",
      netprf_high: "Alto (>20%)",
      netprf_u90: "Menos de 90%",
      netprf_u80: "Menos de 80%",
      netprf_u50: "Menos de 50%",
      netprf_u30: "Menos de 30%",
      netprf_u10: "Menos de 10%",
      netprf_u5: "Menos de 5%",
      netprf_u_10: "Menos de -10%",
      netprf_u_30: "Menos de -30%",
      netprf_u_50: "Menos de -50%",
      netprf_u_100: "Menos de -100%",
      netprf_o10: "Más de 10%",
      netprf_o30: "Más de 30%",
      netprf_o40: "Más de 40%",
      netprf_o50: "Más de 50%",
      netprf_o80: "Más de 80%",
      netprf_o90: "Más de 90%",

      dividendYield: "Rendimiento por Dividendos",
      divy_none: "Ninguno (0%)",
      divy_pos: "Positivo (>0%)",
      divy_high: "Alto (>5%)",
      divy_veryhigh: "Muy Alto (>10%)",
      divy_o1: "Más de 1%",
      divy_o2: "Más de 2%",
      divy_o3: "Más de 3%",
      divy_o4: "Más de 4%",
      divy_o5: "Más de 5%",
      divy_o6: "Más de 6%",
      divy_o7: "Más de 7%",
      divy_o8: "Más de 8%",
      divy_o9: "Más de 9%",
      divy_o10: "Más de 10%",

      payoutRatio: "Tasa de Distribución",
      payoutr_none: "Ninguno (0%)",
      payoutr_pos: "Positivo (>0%)",
      payoutr_low: "Bajo (<20%)",
      payoutr_high: "Alto (>50%)",
      payoutr_o10: "Más del 10%",
      payoutr_o20: "Más del 20%",
      payoutr_o50: "Más del 50%",
      payoutr_o80: "Más del 80%",
      payoutr_o100: "Más del 100%",
      payoutr_u10: "Menos del 10%",
      payoutr_u20: "Menos del 20%",
      payoutr_u50: "Menos del 50%",
      payoutr_u80: "Menos del 80%",
      payoutr_u100: "Menos del 100%",

      peHistoric: "P/E Histórico",
      pehistoric_below: "Por debajo del promedio histórico",
      pehistoric_above: "Por encima del promedio histórico",
      pbHistoric: "P/B Histórico",
      pbhistoric_below: "Por debajo del promedio histórico",
      pbhistoric_above: "Por encima del promedio histórico",
      psHistoric: "P/S Histórico",
      pshistoric_below: "Por debajo del promedio histórico",
      pshistoric_above: "Por encima del promedio histórico",
      pfcf: "Precio/FCF",
      pfcf_low: "Bajo (<15)",
      pfcf_high: "Alto (>50)",
      pfcf_u10: "Menos de 10",
      pfcf_u20: "Menos de 20",
      pfcf_u50: "Menos de 50",
      pfcf_u80: "Menos de 80",
      pfcf_u100: "Menos de 100",
      pfcf_o10: "Más de 10",
      pfcf_o20: "Más de 20",
      pfcf_o50: "Más de 50",
      pfcf_o80: "Más de 80",
      pfcf_o100: "Más de 100",

      operatingMargin: "Margen operativo",
      opmr_pos: "Positivo (>0%)",
      opmr_neg: "Negativo (<0%)",
      opmr_veryneg: "Muy negativo (<-20%)",
      opmr_high: "Alto (>25%)",
      opmr_u90: "Menor al 90%",
      opmr_u80: "Menor al 80%",
      opmr_u70: "Menor al 70%",
      opmr_u60: "Menor al 60%",
      opmr_u50: "Menor al 50%",
      opmr_u40: "Menor al 40%",
      opmr_u30: "Menor al 30%",
      opmr_u20: "Menor al 20%",
      opmr_u10: "Menor al 10%",
      opmr_u_m10: "Menor a -10%",
      opmr_u_m30: "Menor a -30%",
      opmr_u_m50: "Menor a -50%",
      opmr_u_m100: "Menor a -100%",
      opmr_o10: "Mayor al 10%",
      opmr_o20: "Mayor al 20%",
      opmr_o30: "Mayor al 30%",
      opmr_o40: "Mayor al 40%",
      opmr_o50: "Mayor al 50%",
      opmr_o60: "Mayor al 60%",
      opmr_o70: "Mayor al 70%",
      opmr_o80: "Mayor al 80%",
      opmr_o90: "Mayor al 90%",

      pricecash: "Precio/Efectivo",
      pricecash_low: "Bajo (<3)",
      pricecash_high: "Alto (>50)",
      pricecash_u1: "Menor que 1",
      pricecash_u2: "Menor que 2",
      pricecash_u3: "Menor que 3",
      pricecash_u4: "Menor que 4",
      pricecash_u5: "Menor que 5",
      pricecash_u6: "Menor que 6",
      pricecash_u7: "Menor que 7",
      pricecash_u8: "Menor que 8",
      pricecash_u9: "Menor que 9",
      pricecash_u10: "Menor que 10",
      pricecash_o1: "Mayor que 1",
      pricecash_o2: "Mayor que 2",
      pricecash_o3: "Mayor que 3",
      pricecash_o4: "Mayor que 4",
      pricecash_o5: "Mayor que 5",
      pricecash_o6: "Mayor que 6",
      pricecash_o7: "Mayor que 7",
      pricecash_o8: "Mayor que 8",
      pricecash_o9: "Mayor que 9",
      pricecash_o10: "Mayor que 10",
      pricecash_o20: "Mayor que 20",
      pricecash_o30: "Mayor que 30",
      pricecash_o40: "Mayor que 40",
      pricecash_o50: "Mayor que 50",

      exchange: "Intercambio",
      any: "Cualquiera",
      nasdaq: "NASDAQ",
      nyse: "NYSE",
      amex: "AMEX",
      marketCap: "Capitalización de mercado",
      mcap_mega: "Mega (200 mil millones y más)",
      mcap_large: "Grande (10 mil millones a 200 mil millones)",
      mcap_mid: "Mediana (2 mil millones a 10 mil millones)",
      mcap_small: "Pequeña (300 millones a 2 mil millones)",
      mcap_micro: "Micro (50 millones a 300 millones)",
      mcap_nano: "Nano (menos de 50 millones)",
      mcap_largeover: "+Grande (más de 10 mil millones)",
      mcap_midover: "+Mediana (más de 2 mil millones)",
      mcap_smallover: "+Pequeña (más de 300 millones)",
      mcap_microover: "+Micro (más de 50 millones)",
      mcap_largeunder: "-Grande (menos de 200 mil millones)",
      mcap_midunder: "-Mediana (menos de 10 mil millones)",
      mcap_smallunder: "-Pequeña (menos de 2 mil millones)",
      mcap_microunder: "-Micro (menos de 300 millones)",

      performance: "Rendimiento",
      perf_dup: "Un día al alza",
      perf_ddown: "Un día a la baja",
      perf_d15u: "Un día -15%",
      perf_d10u: "Un día -10%",
      perf_d10o: "Un día +10%",
      perf_d15o: "Un día +15%",
      perf_1w30u: "Semana -30%",
      perf_1w10u: "Semana -10%",
      perf_1wdown: "Semana a la baja",
      perf_1wup: "Semana al alza",
      perf_1w10o: "Semana +10%",
      perf_1w30o: "Semana +30%",
      perf_4w50u: "Mes -50%",
      perf_4w30u: "Mes -30%",
      perf_4w10u: "Mes -10%",
      perf_4wdown: "Mes a la baja",
      perf_4wup: "Mes al alza",
      perf_4w20o: "Mes +20%",
      perf_4w30o: "Mes +30%",
      perf_4w50o: "Mes +50%",
      perf_13w50u: "Trimestre -50%",
      perf_13w30u: "Trimestre -30%",
      perf_13w10u: "Trimestre -10%",
      perf_13wdown: "Trimestre a la baja",
      perf_13wup: "Trimestre al alza",
      perf_13w10o: "Trimestre +10%",
      perf_13w30o: "Trimestre +30%",
      perf_13w50o: "Trimestre +50%",
      perf_26w75u: "Semestre -75%",
      perf_26w50u: "Semestre -50%",
      perf_26w20u: "Semestre -20%",
      perf_26w10u: "Semestre -10%",
      perf_26wdown: "Semestre a la baja",
      perf_26wup: "Semestre al alza",
      perf_26w10o: "Semestre +10%",
      perf_26w20o: "Semestre +20%",
      perf_26w50o: "Semestre +50%",
      perf_26w100o: "Semestre +100%",
      perf_52w75u: "Año -75%",
      perf_52w50u: "Año -50%",
      perf_52w30u: "Año -30%",
      perf_52w10u: "Año -10%",
      perf_52wdown: "Año a la baja",
      perf_52wup: "Año al alza",
      perf_52w10o: "Año +10%",
      perf_52w50o: "Año +50%",
      perf_52w100o: "Año +100%",
      perf_52w300o: "Año +300%",
      perf_52w500o: "Año +500%",
      perf_ytd75u: "Año hasta la fecha -75%",
      perf_ytd50u: "Año hasta la fecha -50%",
      perf_ytd30u: "Año hasta la fecha -30%",
      perf_ytd10u: "Año hasta la fecha -10%",
      perf_ytddown: "Año hasta la fecha a la baja",
      perf_ytdup: "Año hasta la fecha al alza",
      perf_ytd10o: "Año hasta la fecha +10%",
      perf_ytd30o: "Año hasta la fecha +30%",
      perf_ytd50o: "Año hasta la fecha +50%",
      perf_ytd100o: "Año hasta la fecha +100%",

      pe: "P / E",
      pe_low: "Bajo (<15)",
      pe_profitable: "Rentable (>0)",
      pe_high: "Alto (>50)",
      pe_u5: "Menos de 5",
      pe_u10: "Menos de 10",
      pe_u15: "Menos de 15",
      pe_u20: "Menos de 20",
      pe_u30: "Menos de 30",
      pe_u40: "Menos de 40",
      pe_u50: "Menos de 50",
      pe_o5: "Más de 5",
      pe_o10: "Más de 10",
      pe_o15: "Más de 15",
      pe_o20: "Más de 20",
      pe_o30: "Más de 30",
      pe_o40: "Más de 40",
      pe_o50: "Más de 50",
      peg: "PEG",
      peg_low: "Bajo (<1)",
      peg_high: "Alto (>2)",
      peg_u1: "Menos de 1",
      peg_u2: "Menos de 2",
      peg_u3: "Menos de 3",
      peg_o1: "Más de 1",
      peg_o2: "Más de 2",
      peg_o3: "Más de 3",

      pb: "P / B",
      pb_low: "Bajo (<1)",
      pb_high: "Alto (>5)",
      pb_u1: "Menos de 1",
      pb_u2: "Menos de 2",
      pb_u3: "Menos de 3",
      pb_u4: "Menos de 4",
      pb_u5: "Menos de 5",
      pb_u6: "Menos de 6",
      pb_u7: "Menos de 7",
      pb_u8: "Menos de 8",
      pb_u9: "Menos de 9",
      pb_u10: "Menos de 10",
      pb_o1: "Más de 1",
      pb_o2: "Más de 2",
      pb_o3: "Más de 3",
      pb_o4: "Más de 4",
      pb_o5: "Más de 5",
      pb_o6: "Más de 6",
      pb_o7: "Más de 7",
      pb_o8: "Más de 8",
      pb_o9: "Más de 9",
      pb_o10: "Más de 10",

      ps: "P / S",
      ps_low: "Bajo (<1)",
      ps_high: "Alto (>5)",
      ps_u1: "Menos de 1",
      ps_u2: "Menos de 2",
      ps_u3: "Menos de 3",
      ps_u4: "Menos de 4",
      ps_u5: "Menos de 5",
      ps_u6: "Menos de 6",
      ps_u7: "Menos de 7",
      ps_u8: "Menos de 8",
      ps_u9: "Menos de 9",
      ps_u10: "Menos de 10",
      ps_o1: "Más de 1",
      ps_o2: "Más de 2",
      ps_o3: "Más de 3",
      ps_o4: "Más de 4",
      ps_o5: "Más de 5",
      ps_o6: "Más de 6",
      ps_o7: "Más de 7",
      ps_o8: "Más de 8",
      ps_o9: "Más de 9",
      ps_o10: "Más de 10",

      quick: "Ratio Rápido",
      quick_high: "Alto (>3)",
      quick_low: "Bajo (<0.5)",
      quick_u1: "Menos de 1",
      quick_u0_5: "Menos de 0.5",
      quick_o0_5: "Más de 0.5",
      quick_o1: "Más de 1",
      quick_o1_5: "Más de 1.5",
      quick_o2: "Más de 2",
      quick_o3: "Más de 3",
      quick_o4: "Más de 4",
      quick_o5: "Más de 5",
      quick_o10: "Más de 10",

      current: "Ratio Actual",
      current_high: "Alto (>3)",
      current_low: "Bajo (<1)",
      current_u1: "Menos de 1",
      current_u0_5: "Menos de 0.5",
      current_o0_5: "Más de 0.5",
      current_o1: "Más de 1",
      current_o1_5: "Más de 1.5",
      current_o2: "Más de 2",
      current_o3: "Más de 3",
      current_o4: "Más de 4",
      current_o5: "Más de 5",
      current_o10: "Más de 10",

      sector: "Sector",
      sector_realestate: "Bienes Raíces",
      sector_health: "Salud",
      sector_energy: "Energía",
      sector_utilities: "Servicios Públicos",
      sector_cd: "Defensivo del Consumidor",
      sector_fs: "Servicios Financieros",
      sector_coms: "Servicios de Comunicación",
      sector_cs: "Servicios al Consumidor",
      sector_bm: "Materiales Básicos",
      sector_i: "Industriales",
      sector_cc: "Cíclico del Consumidor",
      sector_t: "Tecnología",
    },
    TopCompaniesTitle: "Las Empresas Más Grandes de EE.UU. por",
    TotalMarketCap: "Capitalización de Mercado Total",
    Companies: "Empresas",
    sortBy: "Ordenar por",
    Terms: "Términos y Condiciones",
    Privacy: "Política de Privacidad",
    logout: "Cerrar sesión",
    portfolioLeader: "Líder del Portafolio",
    portfolio: {
      symbol: "Símbolo",
      portfolioName: "Nombre de la cartera",
      date: "Fecha",
      price: "Precio",
      noOfShares: "Número de acciones",
      buy: "Comprar",
      sellTransactions: "Transacciones de venta",
      purchasePrice: "Precio de compra",
      purchaseDate: "Fecha de compra",
      priceSold: "Precio vendido",
      dateSold: "Fecha de venta",
      profitAndLoss: "Ganancias y Pérdidas",
      company: "Empresa",
      sector: "Sector",
      currentPrice: "Precio actual",
      avgPurchasePrice: "Precio de compra promedio",
      totalShares: "Total de acciones",
      portfolioPct: "% de la Cartera",
      capital: "Capital",
      sell: "Vender",
      history: "Historia",
      pctReturns: "Retornos %",
      name: "Nombre",
      noOfCompanies: "Nº de Empresas",
      total: "Total",
    },
    login: {
      login: "Iniciar Sesión",
      signup: "Registrarse",
      forgotpassword: "¿Olvidó su Contraseña?",
      resetpassword: "Restablecer Contraseña",
      email: "Correo Electrónico",
      password: "Contraseña",
      forgotPasswordQ: "¿Olvidaste tu contraseña?",
      createAccount: "Crea una nueva cuenta de Stock Penguins",
      firstName: "Nombre",
      lastName: "Apellido",
      requestOTP: "Solicitar OTP",
      otp: "OTP",
    },
    presetName: "Nombre del Preset",
    createdBy: "Creado Por",
    upvotes: "Votos Positivos",
    savePreset: "Guardar Preset",
    share: "Compartir",
    save: "Guardar",
    selectPreset: "Seleccionar un Preset",
    loginToSP: "Iniciar sesión en Stock Penguins",
    clearFilters: "Limpiar Filtros",

    stockScreener: "Filtrador de Acciones",
    topPresets: "Principales Preajustes",
    myPortfolio: "Mi Portafolio",
    portfolioLeaderboard: "Tabla de Clasificación del Portafolio",
    peHistoric: "P/E Histórico",
    SimilarCompanies: "Empresas Similares",
    Stock: "Acción",

    today: "Cambio en 1 Día",
    oneMonthChange: "Cambio en 1 Mes",
    oneYearChange: "Cambio en 1 Año",
    OneYear: "1 Año",
    ThreeYear: "3 Años",
    FiveYear: "5 Años",
    TenYear: "10 Años",
    CAGRDesc: "Cambio histórico a lo largo del tiempo",
    welcome: "Bienvenido",
    SearchBarPlaceholder: "¿Cuál será tu próximo 10 veces ganador?",
    Search: "Buscar",
    Industry: "Industria",
    Sector: "Sector",
    MarketCap: "Capitalización de Mercado",
    PE: "P / E",
    PS: "P / S",
    PB: "P / B",
    FCFYield: "Rendimiento de FCF",
    PEGRatio: "Ratio PEG",
    OverallRisk: "Puntuación de Piotroski",
    week52HighPrice: "Precio Máximo de 52 Semanas",
    PriceGrowthoverthepast5Years:
      "Crecimiento del Precio en los Últimos 5 Años",
    PriceGrowthoverthepast1Years: "Crecimiento del Precio en el Último Año",
    Profitability: "¿Rentable? (Trimestral)",
    GrowingRevenue: "¿Crecimiento de ingresos? (Trimestral)",
    GrowingNetProfit: "¿Crecimiento del beneficio neto? (Trimestral)",
    ProfitabilityAnnual: "¿Rentable? (Anual)",
    GrowingRevenueAnnual: "¿Crecimiento de ingresos? (Anual)",
    GrowingNetProfitAnnual: "¿Crecimiento del beneficio neto? (Anual)",
    FinancialMetrics: "Resumen de valoración",
    BalanceSheet: "Métricas financieras",
    Cash: "Efectivo",
    Debt: "Deuda",
    CashDebtRatio: "Ratio Efectivo / Deuda",
    DividendYield: "Rendimiento por Dividendo",
    PayoutRatio: "Ratio de Distribución",
    FiveYearAvgDividendYield: "Promedio de Rendimiento por Dividendo en 5 Años",
    DividendGrowthRateoverthepast5Years:
      "Tasa de Crecimiento del Dividendo en los Últimos 5 Años",
    QualityOfCompany: "Calidad de la Empresa",
    Dividend: "Dividendo",
    comparedToLastQuarter: "Comparado con el último trimestre",
    comparedToLastYear: "Comparado con el año pasado",
    newTab: "Nueva pestaña",
    compare: "Comparar",
    topCompanies: "Empresas principales",
    watchlist: "Lista de seguimiento",
    rank: "Rango",
    companyName: "Nombre de la empresa",
    symbol: "Símbolo",
    marketCap: "Capitalización de mercado",
    price: "Precio",
    volume: "Volumen",
    country: "País",
    joinDiscord: "Unirse a Discord",
    joinTelegram: "Unirse a Telegram",
    contactUs: "Contáctenos",
    InTheLast365Days: "En los últimos 365 días",
    LastUpdated: "Última actualización",
    pages: "Páginas",

    titles: {
      PE: "P / E",
      PS: "P / S",
      PB: "P / B",
      RevenueGrowth: "Crecimiento de ingresos %",
      NetIncomeGrowth: "Crecimiento del ingreso neto %",
      Price: "Precio",
      MarketCap: "Capitalización de Mercado",
      Revenue: "Ingresos",
      RevenueVsNetIncome: "Ingresos vs. Ingresos Netos",
      NetIncome: "Ingresos Netos",
      Ebitda: "EBITDA",
      Eps: "EPS",
      TotalExpenses: "Gastos Totales",
      SharesIssued: "Acciones en circulación",
      FreeCashFlow: "Flujo de Caja Libre",
      OperatingCashFlow: "Flujo de Caja Operativo",
      Dividend: "Dividendo",
      GrossMargin: "Margen Bruto",
      CashVsDebt: "Efectivo vs. Deuda",
    },
    description: {
      PE: "El índice Precio-Beneficio (P/E) mide cuánto están dispuestos a pagar los inversores por cada dólar de las ganancias de una empresa; se calcula dividiendo el precio de las acciones entre las ganancias por acción. Un índice P/E alto puede indicar que los inversores esperan un alto crecimiento futuro o que la acción está sobrevalorada",
      PS: "El índice Precio-Ventas (P/S) mide cuánto están pagando los inversores por cada dólar de ventas de la empresa; se calcula dividiendo el precio de las acciones entre las ventas por acción. Un índice P/S alto puede indicar que los inversores esperan un alto crecimiento futuro en las ventas o que la acción está sobrevalorada",
      PB: "El índice Precio-Valor en Libros (P/B) compara el valor de mercado de una empresa con su valor en libros (activos menos pasivos); se calcula dividiendo el precio de las acciones entre el valor en libros por acción. Un P/B alto puede indicar una fuerte confianza de los inversores en el rendimiento futuro de la empresa o que la acción está sobrevalorada si la empresa no tiene un crecimiento de activos sólido",
      RevenueGrowth: "Porcentaje de crecimiento de ingresos",
      NetIncomeGrowth: "Porcentaje de crecimiento de ingresos netos",
      Price:
        "Precio histórico de las acciones de la empresa a lo largo del tiempo",
      MarketCap:
        "El valor total de las acciones en circulación de una empresa a lo largo del tiempo",
      Revenue:
        "La cantidad total de dinero generada por actividades comerciales, ventas de bienes y servicios a lo largo del tiempo",
      RevenueVsNetIncome:
        "Una comparación entre los ingresos totales y el beneficio neto de la empresa a lo largo del tiempo, que indica la sostenibilidad del crecimiento de la empresa. Una relación más baja puede indicar que la empresa está desarrollando una ventaja competitiva y ganando cuota de mercado",
      NetIncome:
        "El beneficio que una empresa obtiene después de deducir todos los gastos, impuestos y costos de los ingresos totales a lo largo del tiempo",
      Ebitda:
        "Los beneficios de una empresa antes de intereses, impuestos, depreciación y amortización a lo largo del tiempo. Mide el rendimiento financiero general y la rentabilidad de una empresa de sus operaciones principales",
      Eps: "La parte del beneficio de una empresa asignada a cada acción en circulación. Indica cuánto dinero recibirían los accionistas por cada acción que poseen",
      TotalExpenses:
        "La suma de todos los costos incurridos por una empresa en la realización de su negocio, incluidos los gastos operativos, intereses, impuestos, etc. El gráfico puede mostrar los gastos totales a lo largo del tiempo, indicando tendencias de costos y eficiencia",
      SharesIssued: "El número total de acciones emitidas por una empresa",
      FreeCashFlow:
        "El efectivo que una empresa genera después de tener en cuenta las salidas de efectivo para apoyar las operaciones y mantener los activos de capital. Representa el dinero disponible para dividendos, pago de deudas o reinversión",
      OperatingCashFlow:
        "El efectivo generado por las operaciones comerciales de una empresa a lo largo del tiempo. Muestra si una empresa puede generar suficiente flujo de caja positivo para mantener y crecer sus operaciones",
      Dividend:
        "Una parte de las ganancias de una empresa distribuida a los accionistas, generalmente en forma de pagos en efectivo.",
      GrossMargin:
        "El porcentaje de los ingresos que queda después de restar el costo de los bienes vendidos (COGS). Muestra la eficiencia con la que una empresa produce y vende sus productos",
      CashVsDebt:
        "Un gráfico comparativo que muestra las reservas de efectivo de la empresa frente a su deuda total. Ayuda a evaluar la salud financiera y la liquidez de la empresa",
    },
    dataDescriptions: {
      peHistorical:
        "El promedio histórico de P/E de 5 años proporciona una cobertura de duración adecuada para capturar los cambios en las tendencias del mercado y el rendimiento de la empresa. También refleja las condiciones económicas más recientes, las tasas de interés y los desarrollos en la industria.",
      peHistoricalCompare:
        "Si el índice P/E está por encima del promedio histórico, a menudo indica una sobrevaloración y altas expectativas de crecimiento, con posibles riesgos si el crecimiento no se cumple. Por el contrario, un P/E por debajo del promedio sugiere una infravaloración debido al pesimismo del mercado o problemas temporales con el crecimiento. Es crucial considerar factores económicos, de la industria y específicos de la empresa, ya que una empresa en crecimiento en una industria en desarrollo puede justificar valoraciones más altas.",

      MarketCap:
        "El valor total de las acciones de una empresa; se calcula multiplicando el precio de la acción por el número total de acciones",
      PE: "La relación precio/beneficio (P/E) mide cuánto están dispuestos a pagar los inversores por cada dólar de ganancias de una empresa; se calcula dividiendo el precio de la acción por las ganancias por acción. Un alto P/E puede indicar que los inversores esperan una alta tasa de crecimiento futuro o que la acción puede estar sobrevalorada",
      PS: "La relación precio/ventas (P/S) mide cuánto están pagando los inversores por cada dólar de ventas de la empresa; se calcula dividiendo el precio de la acción por las ventas por acción. Un alto P/S puede indicar que los inversores esperan un alto crecimiento de ventas futuras o que la acción puede estar sobrevalorada",
      PB: "La relación precio/valor contable (P/B) es una relación que compara el valor de mercado de una empresa con su valor contable (activos menos pasivos); se calcula dividiendo el precio de la acción por el valor contable por acción. Un alto P/B puede indicar una fuerte confianza de los inversores en el rendimiento futuro de la empresa o que la acción podría estar sobrevalorada si la empresa no tiene un fuerte crecimiento de activos",
      FCFYield:
        "El rendimiento de flujo de caja libre es una medida de cuánto flujo de caja libre genera una empresa en relación con su capitalización de mercado; se calcula dividiendo el flujo de caja libre por la capitalización de mercado",
      PEGRatio:
        "La relación precio/beneficio respecto al crecimiento (PEG) es una métrica de valoración que considera la relación P/E de una empresa en relación con su tasa de crecimiento de ganancias; ayuda a ver si una acción está sobrevalorada o infravalorada. Una relación PEG de 1 sugiere un valor justo, por debajo de 1 sugiere infravaloración en relación con la tasa de crecimiento, y por encima de 1 sugiere sobrevaloración en relación con la tasa de crecimiento",
      OverallRisk:
        "Una evaluación de cuán arriesgada es una inversión, considerando factores como la volatilidad, la estabilidad financiera y las condiciones del mercado; un riesgo más bajo suele ser mejor, pero depende de la tolerancia al riesgo del inversor",
      week52HighPrice:
        "El precio más alto que ha alcanzado una acción en el último año; una acción que cotiza cerca de su máximo de 52 semanas puede indicar un rendimiento reciente fuerte, pero también podría sugerir que está sobrevalorada",
      ProfitabilityAnnual:
        "Si la empresa ha ganado más dinero del que ha gastado en el último año; la rentabilidad es generalmente una buena señal de la salud financiera de una empresa",
      GrowingRevenueAnnual:
        "Si los ingresos (ventas) de la empresa han aumentado en comparación con el año anterior; el crecimiento constante de los ingresos suele ser una señal positiva de expansión del negocio",
      GrowingNetProfitAnnual:
        "Si la ganancia neta de la empresa (ingresos después de gastos) ha aumentado en comparación con el año anterior; el crecimiento de la ganancia neta es un fuerte indicador de la mejora del rendimiento financiero",
      PriceGrowthoverthepast5Years:
        "El aumento porcentual en el precio de una acción en los últimos cinco años; un porcentaje más alto indica un mejor rendimiento a largo plazo",
      PriceGrowthoverthepast1Years:
        "El aumento porcentual en el precio de una acción en el último año; un fuerte crecimiento a corto plazo es positivo, pero debe considerarse en el contexto de otros factores",
      Cash: "La cantidad total de dinero líquido que la empresa tiene disponible para usar de inmediato; más efectivo generalmente es mejor para la flexibilidad financiera",
      Debt: "El monto total de dinero que la empresa debe a los prestamistas; una mayor deuda puede ser arriesgada si la empresa no puede generar suficientes ingresos para pagarla",
      CashDebtRatio:
        "Una medida de la capacidad de una empresa para pagar su deuda con el efectivo disponible; una relación más alta indica una mejor salud financiera",
      Profitability:
        "Si la empresa ha ganado más dinero del que ha gastado en el trimestre más reciente; ser rentable trimestralmente muestra que la empresa está generando ingresos de manera consistente",
      GrowingRevenue:
        "Si los ingresos de la empresa han aumentado en comparación con el trimestre anterior; el crecimiento continuo de los ingresos es una señal de éxito continuo del negocio",
      GrowingNetProfit:
        "Si la ganancia neta de la empresa ha aumentado en comparación con el trimestre anterior; el crecimiento de la ganancia neta trimestral indica una mejora del rendimiento financiero a corto plazo",
      DividendYield:
        "El pago anual de dividendos que una empresa otorga a sus accionistas, expresado como un porcentaje de su precio de acción; un rendimiento más alto puede indicar más ingresos por dividendos en relación con el precio de la acción",
      PayoutRatio:
        "El porcentaje de las ganancias de una empresa que se paga como dividendos a los accionistas; una relación más baja sugiere que la empresa está reteniendo más ganancias para el crecimiento, mientras que una relación muy alta podría indicar que el dividendo podría no ser sostenible",
      FiveYearAvgDividendYield:
        "El rendimiento promedio de dividendos que una empresa ha ofrecido en los últimos cinco años; ayuda a los inversores a ver cuán consistentes o estables han sido los pagos de dividendos a lo largo del tiempo",
    },
    labels: {
      TotalCash: "Efectivo Total",
      TotalDebt: "Deuda Total",
      Revenue: "Ingresos",
      NetIncome: "Ingresos Netos",
    },
    Quarterly: "Trimestral",
    Annually: "Anualmente",
    FullScreen: "Pantalla completa",
    ExitFullScreen: "Salir de pantalla completa",
    homeMetaTitle: "StockPenguins | Escáner de Acciones Globales Gratuito",
    homeMetaDescription:
      "Descubre la mejor aplicación gratuita de escáner de acciones para las acciones estadounidenses como Apple, Tesla, Amazon y Microsoft. Accede a métricas detalladas como Precio, Capitalización de Mercado, Ingresos, Ingreso Neto, EBITDA, Flujo de Caja Libre, EPS, Dividendo, Ratio P/E y más. Analiza y compara acciones sin esfuerzo para tomar decisiones de inversión informadas. ¡Busca ahora!",
    pageMetaTitle:
      "Métricas de Acciones | P/E, Ingresos y Capitalización de Mercado",
    pageMetaDescription:
      "Descubre métricas gratuitas, incluyendo Precio, Capitalización de Mercado, Ingresos, Ingreso Neto, EBITDA, Flujo de Caja Libre, EPS, Dividendo, Ratio P/E y más. Mantente informado sobre el rendimiento financiero de Apple. ¡Explora ahora!",
  },
  [LANGUAGES[3].value]: {
    // French
    search: "Rechercher des Actions",

    peHistorical: "Ratio P/E moyen historique sur 5 ans",
    peHistoricalCompare:
      "Sous ou au-dessus du ratio P/E moyen historique sur 5 ans ?",
    scanner: {
      industry: "Industrie",
      ind_aginputs: "Intrants Agricoles",
      ind_indpollctrl: "Industrie - Pollution et Contrôles de Traitement",
      ind_indmaterials: "Matériaux Industriels",
      ind_autorv: "Auto - Véhicules Récréatifs",
      ind_discstores: "Magasins de Distributeurs",
      ind_insdiv: "Assurances - Diversifiées",
      ind_entertainment: "Divertissement",
      ind_reitdiv: "REIT - Diversifié",
      ind_medicaldist: "Médecine - Distribution",
      ind_softinfra: "Logiciel - Infrastructure",
      ind_softapp: "Logiciel - Application",
      ind_electricaleqp: "Équipement et Pièces Électriques",
      ind_astmgmtglobal: "Gestion d'Actifs - Mondiale",
      ind_indpower: "Producteurs d'Énergie Indépendants",
      ind_oilgasdrill: "Pétrole et Gaz - Forage",
      ind_leisure: "Loisirs",
      ind_medicaldev: "Médecine - Dispositifs",
      ind_coal: "Charbon",
      ind_edu: "Éducation et Services de Formation",
      ind_reitind: "REIT - Industriel",
      ind_autoparts: "Auto - Pièces",
      ind_metalfab: "Fabrication - Fabrication de Métaux",
      ind_fincredit: "Financier - Services de Crédit",
      ind_broadcasting: "Diffusion",
      ind_medcarefac: "Médecine - Établissements de Soins",
      ind_chemspecialty: "Chimie - Spécialité",
      ind_astmgmt: "Gestion d'Actifs",
      ind_insreins: "Assurances - Réassurance",
      ind_travellodging: "Hébergement de Voyage",
      ind_findiv: "Financier - Diversifié",
      ind_banks: "Banques",
      ind_bevalcoholic: "Boissons - Alcoolisées",
      ind_hardwareeqp: "Matériel, Équipement et Pièces",
      ind_inddist: "Industrie - Distribution",
      ind_constrmat: "Matériaux de Construction",
      ind_fooddist: "Distribution Alimentaire",
      ind_bevwines: "Boissons - Vignobles et Distilleries",
      ind_furnishings: "Meubles, Équipements et Appareils",
      ind_silver: "Argent",
      ind_specialtybiz: "Services aux Entreprises Spécialisées",
      ind_apparelmanu: "Vêtements - Fabricants",
      ind_publishing: "Édition",
      ind_finmortgages: "Financier - Hypothèques",
      ind_railroads: "Chemins de Fer",
      ind_insprop: "Assurances - Biens et Responsabilité Civile",
      ind_telco: "Services de Télécommunications",
      ind_reitretail: "REIT - Vente au Détail",
      ind_internetcontent: "Contenu et Informations Internet",
      ind_indinfrastructure: "Industrie - Opérations d'Infrastructure",
      ind_regwater: "Eau Régulée",
      ind_packaging: "Emballages et Contenants",
      ind_realeservices: "Immobilier - Services",
      ind_reddiversified: "Immobilier - Diversifié",
      ind_renewutilities: "Services Publics Renouvelables",
      ind_preciousmetals: "Autres Métaux Précieux",
      ind_autodealerships: "Auto - Concessionnaires",
      ind_foodconf: "Confiseries Alimentaires",
      ind_toolsmfg: "Fabrication - Outils et Accessoires",
      ind_findata: "Financier - Données et Bourses",
      ind_adagencies: "Agences de Publicité",
      ind_luxurygoods: "Biens de Luxe",
      ind_householdprod: "Produits Ménagers et Personnels",
      ind_reitresidential: "REIT - Résidentiel",
      ind_deptstores: "Magasins de Département",
      ind_personalprod: "Produits et Services Personnels",
      ind_paperlumber: "Papier, Bois et Produits Forestiers",
      ind_staffing: "Services de Recrutement et d'Emploi",
      ind_resconstr: "Construction Résidentielle",
      ind_healthcare: "Soins de Santé",
      ind_construction: "Construction",
      ind_specialtyretail: "Vente au Détail Spécialisée",
      ind_mediaentertain: "Médias et Divertissement",
      ind_realdev: "Immobilier - Développement",
      ind_astmgmtincome: "Gestion d'Actifs - Revenu",
      ind_engconst: "Ingénierie et Construction",
      ind_gambling: "Jeux, Stations et Casinos",
      ind_reitoffice: "REIT - Bureau",
      ind_finservices: "Services Financiers",
      ind_drugmanugen: "Fabricants de Médicaments - Général",
      ind_homeimprovement: "Amélioration de l'Habitat",
      ind_steel: "Acier",
      ind_regionalbanks: "Banques - Régionales",
      ind_agfarmproducts: "Produits Agricoles de Ferme",
      ind_conglomerates: "Conglomérats",
      ind_medhealthcareplans: "Médecine - Plans de Soins de Santé",
      ind_chemicals: "Produits Chimiques",
      ind_oilmidstream: "Pétrole et Gaz - Intermédiaire",
      ind_oilexp: "Pétrole et Gaz - Exploration et Production",
      ind_computerhardware: "Matériel Informatique",
      ind_banksdiversified: "Banques - Diversifiées",
      ind_restaurants: "Restaurants",
      ind_oilintegrated: "Pétrole et Gaz - Intégré",
      ind_divutilities: "Services Publics Diversifiés",
      ind_reithotel: "REIT - Hôtels et Motels",
      ind_gaming: "Jeux Électroniques et Multimédia",
      ind_medpharma: "Médecine - Produits Pharmaceutiques",
      ind_grocerystores: "Magasins d'Alimentation",
      ind_wastemgmt: "Gestion des Déchets",
      ind_reggas: "Gaz Régulé",
      ind_trucking: "Transport Routier",
      ind_investbanking:
        "Investissement - Services Bancaires et d'Investissement",
      ind_automanufact: "Auto - Fabricants",
      ind_reitmortgage: "REIT - Hypothèque",
      ind_biotech: "Biotechnologie",
      ind_techdistrib: "Distributeurs de Technologie",
      ind_bizequip: "Équipement et Fournitures Professionnels",
      ind_apparelfoot: "Vêtements - Chaussures et Accessoires",
      ind_medicalspec: "Médecine - Spécialités",
      ind_apparelretail: "Vêtements - Vente au Détail",
      ind_regelectric: "Électricité Régulée",
      ind_finconglomerates: "Financier - Conglomérats",
      ind_oilrefining: "Pétrole et Gaz - Raffinage et Marketing",
      ind_reitspecialty: "REIT - Spécialité",
      ind_agmachinery: "Agriculture - Machinerie",
      ind_packagedfoods: "Aliments Emballés",
      ind_reithealthcare: "REIT - Établissements de Soins de Santé",
      ind_solar: "Solaire",
      ind_copper: "Cuivre",
      ind_gold: "Or",
      ind_medicalsupp: "Médecine - Instruments et Fournitures",
      ind_insurancebrokers: "Assurances - Courtiers",
      ind_security: "Sécurité et Services de Protection",
      ind_tobacco: "Tabac",
      ind_fincapmarkets: "Financier - Marchés de Capitaux",
      ind_mfgmisc: "Fabrication - Divers",
      ind_uranium: "Uranium",
      ind_insurancelife: "Assurances - Vie",
      ind_indmachinery: "Industrie - Machinerie",
      ind_shellcompanies: "Sociétés Écrans",
      ind_consumelectronics: "Électronique Grand Public",
      ind_medicaldiag: "Médecine - Diagnostics et Recherche",
      ind_oilequip: "Équipement et Services Pétroliers et Gaziers",
      ind_commequip: "Équipement de Communication",
      ind_aerospace: "Aérospatiale et Défense",
      ind_travelservices: "Services de Voyage",
      ind_corrections: "Services de Correction et de Réhabilitation",
      ind_tourism: "Tourisme",
      ind_electronics: "Électronique",
      ind_utilityservices: "Services Utilitaires",
      ind_motorsports: "Sports Motorisés",
      ind_medicaltherap: "Médecine - Thérapies et Services",
      ind_medicalequipment: "Équipement Médical",
      ind_electricvehicles: "Véhicules Électriques",
      ind_water: "Eau",
      ind_electric: "Électrique",
      ind_textiles: "Textiles",
      ind_strawberries: "Fraises",
      ind_drugmanuspecialty:
        "Fabricants de Médicaments - Spécialités et Génériques",
      ind_aluminum: "Aluminium",
      ind_itservices: "Services de Technologie de l'Information",
      ind_medhealthinfo:
        "Médecine - Services d'Information sur les Soins de Santé",
      ind_bevnonalcoholic: "Boissons - Non Alcoolisées",
      ind_consulting: "Services de Conseil",
      ind_rentleasing: "Services de Location et de Leasing",
      ind_marineshipping: "Expédition Maritime",
      ind_software: "Logiciel - Services",
      ind_astmgmtbonds: "Gestion d'Actifs - Obligations",
      ind_freightlogistics: "Fret et Logistique Intégrés",
      ind_insspec: "Assurances - Spécialité",
      ind_semiconductors: "Semi-conducteurs",
      ind_airlines: "Compagnies Aériennes, Aéroports et Services Aériens",

      revgrowavg: "Croissance des Revenus des 5 Dernières Années",
      revgrowavg_neg: "Négatif (<0%)",
      revgrowavg_pos: "Positif (>0%)",
      revgrowavg_u10: "Sous -10%",
      revgrowavg_u20: "Sous -20%",
      revgrowavg_u50: "Sous -50%",
      revgrowavg_u100: "Sous -100%",
      revgrowavg_o10: "Au-dessus de 10%",
      revgrowavg_o20: "Au-dessus de 20%",
      revgrowavg_o50: "Au-dessus de 50%",
      revgrowavg_o100: "Au-dessus de 100%",

      revgrow: "Croissance des Revenus t/t",
      revgrow_neg: "Négatif (<0%)",
      revgrow_pos: "Positif (>0%)",
      revgrow_u10: "Inférieur à -10%",
      revgrow_u20: "Inférieur à -20%",
      revgrow_u50: "Inférieur à -50%",
      revgrow_u100: "Inférieur à -100%",
      revgrow_o10: "Supérieur à 10%",
      revgrow_o20: "Supérieur à 20%",
      revgrow_o50: "Supérieur à 50%",
      revgrow_o100: "Supérieur à 100%",

      netincgrow: "Croissance du Revenu Net t/t",
      netincgrow_neg: "Négatif (<0%)",
      netincgrow_pos: "Positif (>0%)",
      netincgrow_u10: "Inférieur à -10%",
      netincgrow_u20: "Inférieur à -20%",
      netincgrow_u50: "Inférieur à -50%",
      netincgrow_u100: "Inférieur à -100%",
      netincgrow_o10: "Supérieur à 10%",
      netincgrow_o20: "Supérieur à 20%",
      netincgrow_o50: "Supérieur à 50%",
      netincgrow_o100: "Supérieur à 100%",

      ltdc: "Dette à Long Terme / Capitalisation",
      ltdc_high: "Élevé (>0.5)",
      ltdc_low: "Bas (<0.1)",
      ltdc_u1: "Inférieur à 1",
      ltdc_u0_9: "Inférieur à 0.9",
      ltdc_u0_8: "Inférieur à 0.8",
      ltdc_u0_7: "Inférieur à 0.7",
      ltdc_u0_6: "Inférieur à 0.6",
      ltdc_u0_5: "Inférieur à 0.5",
      ltdc_u0_4: "Inférieur à 0.4",
      ltdc_u0_3: "Inférieur à 0.3",
      ltdc_u0_2: "Inférieur à 0.2",
      ltdc_u0_1: "Inférieur à 0.1",
      ltdc_o0_1: "Supérieur à 0.1",
      ltdc_o0_2: "Supérieur à 0.2",
      ltdc_o0_3: "Supérieur à 0.3",
      ltdc_o0_4: "Supérieur à 0.4",
      ltdc_o0_5: "Supérieur à 0.5",
      ltdc_o0_6: "Supérieur à 0.6",
      ltdc_o0_7: "Supérieur à 0.7",
      ltdc_o0_8: "Supérieur à 0.8",
      ltdc_o0_9: "Supérieur à 0.9",
      ltdc_o1: "Supérieur à 1",

      grossMargin: "Marge Brute",
      grossm_pos: "Positif (>0%)",
      grossm_neg: "Négatif (<0%)",
      grossm_u90: "Inférieur à 90%",
      grossm_u70: "Inférieur à 70%",
      grossm_u50: "Inférieur à 50%",
      grossm_u30: "Inférieur à 30%",
      grossm_u10: "Inférieur à 10%",
      grossm_u5: "Inférieur à 5%",
      grossm_u_10: "Inférieur à -10%",
      grossm_u_30: "Inférieur à -30%",
      grossm_u_50: "Inférieur à -50%",
      grossm_u_70: "Inférieur à -70%",
      grossm_u_100: "Inférieur à -100%",
      grossm_o0: "Supérieur à 0%",
      grossm_o10: "Supérieur à 10%",
      grossm_o30: "Supérieur à 30%",
      grossm_o50: "Supérieur à 50%",
      grossm_o70: "Supérieur à 70%",
      grossm_o90: "Supérieur à 90%",

      deRatio: "Dette/Capitaux",
      deratio_high: "Élevé (>0.5)",
      deratio_low: "Bas (<0.1)",
      deratio_u1: "Inférieur à 1",
      deratio_u0_9: "Inférieur à 0.9",
      deratio_u0_8: "Inférieur à 0.8",
      deratio_u0_7: "Inférieur à 0.7",
      deratio_u0_6: "Inférieur à 0.6",
      deratio_u0_5: "Inférieur à 0.5",
      deratio_u0_4: "Inférieur à 0.4",
      deratio_u0_3: "Inférieur à 0.3",
      deratio_u0_2: "Inférieur à 0.2",
      deratio_u0_1: "Inférieur à 0.1",
      deratio_o0_1: "Supérieur à 0.1",
      deratio_o0_2: "Supérieur à 0.2",
      deratio_o0_3: "Supérieur à 0.3",
      deratio_o0_4: "Supérieur à 0.4",
      deratio_o0_5: "Supérieur à 0.5",
      deratio_o0_6: "Supérieur à 0.6",
      deratio_o0_7: "Supérieur à 0.7",
      deratio_o0_8: "Supérieur à 0.8",
      deratio_o0_9: "Supérieur à 0.9",
      deratio_o1: "Supérieur à 1",

      netProfitMargin: "Marge Bénéficiaire Nette",
      netprf_pos: "Positif (>0%)",
      netprf_neg: "Négatif (<0%)",
      netprf_veryneg: "Très Négatif (<-20%)",
      netprf_high: "Élevé (>20%)",
      netprf_u90: "Inférieur à 90%",
      netprf_u80: "Inférieur à 80%",
      netprf_u50: "Inférieur à 50%",
      netprf_u30: "Inférieur à 30%",
      netprf_u10: "Inférieur à 10%",
      netprf_u5: "Inférieur à 5%",
      netprf_u_10: "Inférieur à -10%",
      netprf_u_30: "Inférieur à -30%",
      netprf_u_50: "Inférieur à -50%",
      netprf_u_100: "Inférieur à -100%",
      netprf_o10: "Supérieur à 10%",
      netprf_o30: "Supérieur à 30%",
      netprf_o40: "Supérieur à 40%",
      netprf_o50: "Supérieur à 50%",
      netprf_o80: "Supérieur à 80%",
      netprf_o90: "Supérieur à 90%",

      dividendYield: "Rendement des Dividendes",
      divy_none: "Aucun (0%)",
      divy_pos: "Positif (>0%)",
      divy_high: "Élevé (>5%)",
      divy_veryhigh: "Très Élevé (>10%)",
      divy_o1: "Plus de 1%",
      divy_o2: "Plus de 2%",
      divy_o3: "Plus de 3%",
      divy_o4: "Plus de 4%",
      divy_o5: "Plus de 5%",
      divy_o6: "Plus de 6%",
      divy_o7: "Plus de 7%",
      divy_o8: "Plus de 8%",
      divy_o9: "Plus de 9%",
      divy_o10: "Plus de 10%",

      payoutRatio: "Taux de Distribution",
      payoutr_none: "Aucun (0%)",
      payoutr_pos: "Positif (>0%)",
      payoutr_low: "Faible (<20%)",
      payoutr_high: "Élevé (>50%)",
      payoutr_o10: "Plus de 10%",
      payoutr_o20: "Plus de 20%",
      payoutr_o50: "Plus de 50%",
      payoutr_o80: "Plus de 80%",
      payoutr_o100: "Plus de 100%",
      payoutr_u10: "Moins de 10%",
      payoutr_u20: "Moins de 20%",
      payoutr_u50: "Moins de 50%",
      payoutr_u80: "Moins de 80%",
      payoutr_u100: "Moins de 100%",

      peHistoric: "P/E Historique",
      pehistoric_below: "Sous la moyenne historique",
      pehistoric_above: "Au-dessus de la moyenne historique",
      pbHistoric: "P/B Historique",
      pbhistoric_below: "Sous la moyenne historique",
      pbhistoric_above: "Au-dessus de la moyenne historique",
      psHistoric: "P/S Historique",
      pshistoric_below: "Sous la moyenne historique",
      pshistoric_above: "Au-dessus de la moyenne historique",
      pfcf: "Prix/FCF",
      pfcf_low: "Faible (<15)",
      pfcf_high: "Élevé (>50)",
      pfcf_u10: "Moins de 10",
      pfcf_u20: "Moins de 20",
      pfcf_u50: "Moins de 50",
      pfcf_u80: "Moins de 80",
      pfcf_u100: "Moins de 100",
      pfcf_o10: "Plus de 10",
      pfcf_o20: "Plus de 20",
      pfcf_o50: "Plus de 50",
      pfcf_o80: "Plus de 80",
      pfcf_o100: "Plus de 100",

      operatingMargin: "Marge opérationnelle",
      opmr_pos: "Positif (>0%)",
      opmr_neg: "Négatif (<0%)",
      opmr_veryneg: "Très négatif (<-20%)",
      opmr_high: "Élevé (>25%)",
      opmr_u90: "Inférieur à 90%",
      opmr_u80: "Inférieur à 80%",
      opmr_u70: "Inférieur à 70%",
      opmr_u60: "Inférieur à 60%",
      opmr_u50: "Inférieur à 50%",
      opmr_u40: "Inférieur à 40%",
      opmr_u30: "Inférieur à 30%",
      opmr_u20: "Inférieur à 20%",
      opmr_u10: "Inférieur à 10%",
      opmr_u_m10: "Inférieur à -10%",
      opmr_u_m30: "Inférieur à -30%",
      opmr_u_m50: "Inférieur à -50%",
      opmr_u_m100: "Inférieur à -100%",
      opmr_o10: "Supérieur à 10%",
      opmr_o20: "Supérieur à 20%",
      opmr_o30: "Supérieur à 30%",
      opmr_o40: "Supérieur à 40%",
      opmr_o50: "Supérieur à 50%",
      opmr_o60: "Supérieur à 60%",
      opmr_o70: "Supérieur à 70%",
      opmr_o80: "Supérieur à 80%",
      opmr_o90: "Supérieur à 90%",

      pricecash: "Prix/Trésorerie",
      pricecash_low: "Bas (<3)",
      pricecash_high: "Élevé (>50)",
      pricecash_u1: "Inférieur à 1",
      pricecash_u2: "Inférieur à 2",
      pricecash_u3: "Inférieur à 3",
      pricecash_u4: "Inférieur à 4",
      pricecash_u5: "Inférieur à 5",
      pricecash_u6: "Inférieur à 6",
      pricecash_u7: "Inférieur à 7",
      pricecash_u8: "Inférieur à 8",
      pricecash_u9: "Inférieur à 9",
      pricecash_u10: "Inférieur à 10",
      pricecash_o1: "Supérieur à 1",
      pricecash_o2: "Supérieur à 2",
      pricecash_o3: "Supérieur à 3",
      pricecash_o4: "Supérieur à 4",
      pricecash_o5: "Supérieur à 5",
      pricecash_o6: "Supérieur à 6",
      pricecash_o7: "Supérieur à 7",
      pricecash_o8: "Supérieur à 8",
      pricecash_o9: "Supérieur à 9",
      pricecash_o10: "Supérieur à 10",
      pricecash_o20: "Supérieur à 20",
      pricecash_o30: "Supérieur à 30",
      pricecash_o40: "Supérieur à 40",
      pricecash_o50: "Supérieur à 50",

      exchange: "Échange",
      any: "N'importe lequel",
      nasdaq: "NASDAQ",
      nyse: "NYSE",
      amex: "AMEX",
      marketCap: "Capitalisation boursière",
      mcap_mega: "Méga (200 milliards $ et plus)",
      mcap_large: "Grande (10 milliards $ à 200 milliards $)",
      mcap_mid: "Moyenne (2 milliards $ à 10 milliards $)",
      mcap_small: "Petite (300 millions $ à 2 milliards $)",
      mcap_micro: "Micro (50 millions $ à 300 millions $)",
      mcap_nano: "Nano (moins de 50 millions $)",
      mcap_largeover: "+Grande (plus de 10 milliards $)",
      mcap_midover: "+Moyenne (plus de 2 milliards $)",
      mcap_smallover: "+Petite (plus de 300 millions $)",
      mcap_microover: "+Micro (plus de 50 millions $)",
      mcap_largeunder: "-Grande (moins de 200 milliards $)",
      mcap_midunder: "-Moyenne (moins de 10 milliards $)",
      mcap_smallunder: "-Petite (moins de 2 milliards $)",
      mcap_microunder: "-Micro (moins de 300 millions $)",

      performance: "Performance",
      perf_dup: "Un jour en hausse",
      perf_ddown: "Un jour en baisse",
      perf_d15u: "Un jour -15%",
      perf_d10u: "Un jour -10%",
      perf_d10o: "Un jour +10%",
      perf_d15o: "Un jour +15%",
      perf_1w30u: "Semaine -30%",
      perf_1w10u: "Semaine -10%",
      perf_1wdown: "Semaine en baisse",
      perf_1wup: "Semaine en hausse",
      perf_1w10o: "Semaine +10%",
      perf_1w30o: "Semaine +30%",
      perf_4w50u: "Mois -50%",
      perf_4w30u: "Mois -30%",
      perf_4w10u: "Mois -10%",
      perf_4wdown: "Mois en baisse",
      perf_4wup: "Mois en hausse",
      perf_4w20o: "Mois +20%",
      perf_4w30o: "Mois +30%",
      perf_4w50o: "Mois +50%",
      perf_13w50u: "Trimestre -50%",
      perf_13w30u: "Trimestre -30%",
      perf_13w10u: "Trimestre -10%",
      perf_13wdown: "Trimestre en baisse",
      perf_13wup: "Trimestre en hausse",
      perf_13w10o: "Trimestre +10%",
      perf_13w30o: "Trimestre +30%",
      perf_13w50o: "Trimestre +50%",
      perf_26w75u: "Semestre -75%",
      perf_26w50u: "Semestre -50%",
      perf_26w20u: "Semestre -20%",
      perf_26w10u: "Semestre -10%",
      perf_26wdown: "Semestre en baisse",
      perf_26wup: "Semestre en hausse",
      perf_26w10o: "Semestre +10%",
      perf_26w20o: "Semestre +20%",
      perf_26w50o: "Semestre +50%",
      perf_26w100o: "Semestre +100%",
      perf_52w75u: "Année -75%",
      perf_52w50u: "Année -50%",
      perf_52w30u: "Année -30%",
      perf_52w10u: "Année -10%",
      perf_52wdown: "Année en baisse",
      perf_52wup: "Année en hausse",
      perf_52w10o: "Année +10%",
      perf_52w50o: "Année +50%",
      perf_52w100o: "Année +100%",
      perf_52w300o: "Année +300%",
      perf_52w500o: "Année +500%",
      perf_ytd75u: "Depuis le début de l'année -75%",
      perf_ytd50u: "Depuis le début de l'année -50%",
      perf_ytd30u: "Depuis le début de l'année -30%",
      perf_ytd10u: "Depuis le début de l'année -10%",
      perf_ytddown: "Depuis le début de l'année en baisse",
      perf_ytdup: "Depuis le début de l'année en hausse",
      perf_ytd10o: "Depuis le début de l'année +10%",
      perf_ytd30o: "Depuis le début de l'année +30%",
      perf_ytd50o: "Depuis le début de l'année +50%",
      perf_ytd100o: "Depuis le début de l'année +100%",

      pe: "P / E",
      pe_low: "Bas (<15)",
      pe_profitable: "Rentable (>0)",
      pe_high: "Élevé (>50)",
      pe_u5: "Moins de 5",
      pe_u10: "Moins de 10",
      pe_u15: "Moins de 15",
      pe_u20: "Moins de 20",
      pe_u30: "Moins de 30",
      pe_u40: "Moins de 40",
      pe_u50: "Moins de 50",
      pe_o5: "Plus de 5",
      pe_o10: "Plus de 10",
      pe_o15: "Plus de 15",
      pe_o20: "Plus de 20",
      pe_o30: "Plus de 30",
      pe_o40: "Plus de 40",
      pe_o50: "Plus de 50",
      peg: "PEG",
      peg_low: "Bas (<1)",
      peg_high: "Élevé (>2)",
      peg_u1: "Moins de 1",
      peg_u2: "Moins de 2",
      peg_u3: "Moins de 3",
      peg_o1: "Plus de 1",
      peg_o2: "Plus de 2",
      peg_o3: "Plus de 3",

      pb: "P / B",
      pb_low: "Bas (<1)",
      pb_high: "Élevé (>5)",
      pb_u1: "Moins de 1",
      pb_u2: "Moins de 2",
      pb_u3: "Moins de 3",
      pb_u4: "Moins de 4",
      pb_u5: "Moins de 5",
      pb_u6: "Moins de 6",
      pb_u7: "Moins de 7",
      pb_u8: "Moins de 8",
      pb_u9: "Moins de 9",
      pb_u10: "Moins de 10",
      pb_o1: "Plus de 1",
      pb_o2: "Plus de 2",
      pb_o3: "Plus de 3",
      pb_o4: "Plus de 4",
      pb_o5: "Plus de 5",
      pb_o6: "Plus de 6",
      pb_o7: "Plus de 7",
      pb_o8: "Plus de 8",
      pb_o9: "Plus de 9",
      pb_o10: "Plus de 10",

      ps: "P / S",
      ps_low: "Bas (<1)",
      ps_high: "Élevé (>5)",
      ps_u1: "Moins de 1",
      ps_u2: "Moins de 2",
      ps_u3: "Moins de 3",
      ps_u4: "Moins de 4",
      ps_u5: "Moins de 5",
      ps_u6: "Moins de 6",
      ps_u7: "Moins de 7",
      ps_u8: "Moins de 8",
      ps_u9: "Moins de 9",
      ps_u10: "Moins de 10",
      ps_o1: "Plus de 1",
      ps_o2: "Plus de 2",
      ps_o3: "Plus de 3",
      ps_o4: "Plus de 4",
      ps_o5: "Plus de 5",
      ps_o6: "Plus de 6",
      ps_o7: "Plus de 7",
      ps_o8: "Plus de 8",
      ps_o9: "Plus de 9",
      ps_o10: "Plus de 10",

      quick: "Ratio de Liquidité",
      quick_high: "Élevé (>3)",
      quick_low: "Bas (<0.5)",
      quick_u1: "Moins de 1",
      quick_u0_5: "Moins de 0.5",
      quick_o0_5: "Plus de 0.5",
      quick_o1: "Plus de 1",
      quick_o1_5: "Plus de 1.5",
      quick_o2: "Plus de 2",
      quick_o3: "Plus de 3",
      quick_o4: "Plus de 4",
      quick_o5: "Plus de 5",
      quick_o10: "Plus de 10",

      current: "Ratio Courant",
      current_high: "Élevé (>3)",
      current_low: "Bas (<1)",
      current_u1: "Moins de 1",
      current_u0_5: "Moins de 0.5",
      current_o0_5: "Plus de 0.5",
      current_o1: "Plus de 1",
      current_o1_5: "Plus de 1.5",
      current_o2: "Plus de 2",
      current_o3: "Plus de 3",
      current_o4: "Plus de 4",
      current_o5: "Plus de 5",
      current_o10: "Plus de 10",

      sector: "Secteur",
      sector_realestate: "Immobilier",
      sector_health: "Soins de Santé",
      sector_energy: "Énergie",
      sector_utilities: "Services Publics",
      sector_cd: "Consommation Défensive",
      sector_fs: "Services Financiers",
      sector_coms: "Services de Communication",
      sector_cs: "Services aux Consommateurs",
      sector_bm: "Matériaux de Base",
      sector_i: "Industries",
      sector_cc: "Consommation Cyclique",
      sector_t: "Technologie",
    },
    TopCompaniesTitle: "Plus Grandes Entreprises aux États-Unis par",
    TotalMarketCap: "Capitalisation Boursière Totale",
    Companies: "Entreprises",
    sortBy: "Trier par",
    Terms: "Conditions Générales",
    Privacy: "Politique de Confidentialité",
    logout: "Déconnexion",
    portfolioLeader: "Leader du Portefeuille",
    portfolio: {
      symbol: "Symbole",
      portfolioName: "Nom du portefeuille",
      date: "Date",
      price: "Prix",
      noOfShares: "Nombre d'actions",
      buy: "Acheter",
      sellTransactions: "Transactions de vente",
      purchasePrice: "Prix d'achat",
      purchaseDate: "Date d'achat",
      priceSold: "Prix vendu",
      dateSold: "Date de vente",
      profitAndLoss: "Bénéfice et perte",
      company: "Entreprise",
      sector: "Secteur",
      currentPrice: "Prix actuel",
      avgPurchasePrice: "Prix d'achat moyen",
      totalShares: "Total des actions",
      portfolioPct: "% du portefeuille",
      capital: "Capital",
      sell: "Vendre",
      history: "Historique",
      pctReturns: "Rendements %",
      name: "Nom",
      noOfCompanies: "Nombre d'Entreprises",
      total: "Total",
    },
    login: {
      login: "Connexion",
      signup: "S'inscrire",
      forgotpassword: "Mot de passe oublié",
      resetpassword: "Réinitialiser le mot de passe",
      email: "E-mail",
      password: "Mot de passe",
      forgotPasswordQ: "Mot de passe oublié?",
      createAccount: "Créer un nouveau compte Stock Penguins",
      firstName: "Prénom",
      lastName: "Nom",
      requestOTP: "Demander un OTP",
      otp: "OTP",
    },
    presetName: "Nom du Préréglage",
    createdBy: "Créé Par",
    upvotes: "Votes Positifs",
    savePreset: "Enregistrer le Préréglage",
    share: "Partager",
    save: "Enregistrer",
    selectPreset: "Sélectionner un Préréglage",
    loginToSP: "Se Connecter à Stock Penguins",
    clearFilters: "Effacer les Filtres",

    stockScreener: "Sélecteur d'Actions",
    topPresets: "Meilleurs Préréglages",
    myPortfolio: "Mon Portefeuille",
    portfolioLeaderboard: "Classement des Portefeuilles",
    peHistoric: "P/E Historique",
    SimilarCompanies: "Sociétés Similaires",
    Stock: "Action",

    today: "Changement sur 1 Jour",
    oneMonthChange: "Changement sur 1 Mois",
    oneYearChange: "Changement sur 1 An",
    OneYear: "1 An",
    ThreeYear: "3 Ans",
    FiveYear: "5 Ans",
    TenYear: "10 Ans",
    CAGRDesc: "Changement historique au fil du temps",
    welcome: "Bienvenue",
    SearchBarPlaceholder: "Quel sera votre prochain décupleur?",
    Search: "Rechercher",
    Industry: "Industrie",
    Sector: "Secteur",
    MarketCap: "Capitalisation Boursière",
    PE: "P / E",
    PS: "P / S",
    PB: "P / B",
    FCFYield: "Rendement du FCF",
    PEGRatio: "Ratio PEG",
    OverallRisk: "Score Piotroski",
    week52HighPrice: "Prix Maximum sur 52 Semaines",
    PriceGrowthoverthepast5Years:
      "Croissance du Prix sur les 5 Dernières Années",
    PriceGrowthoverthepast1Years: "Croissance du Prix sur la Dernière Année",
    Profitability: "Rentable ? (Trimestriel)",
    GrowingRevenue: "Croissance des revenus ? (Trimestriel)",
    GrowingNetProfit: "Croissance du bénéfice net ? (Trimestriel)",
    ProfitabilityAnnual: "Rentable ? (Annuel)",
    GrowingRevenueAnnual: "Croissance des revenus ? (Annuel)",
    GrowingNetProfitAnnual: "Croissance du bénéfice net ? (Annuel)",
    FinancialMetrics: "Résumé de l'évaluation",
    BalanceSheet: "Métriques financières",
    Cash: "Trésorerie",
    Debt: "Dette",
    CashDebtRatio: "Ratio Trésorerie / Dette",
    DividendYield: "Rendement du Dividende",
    PayoutRatio: "Ratio de Distribution",
    FiveYearAvgDividendYield: "Rendement Moyen du Dividende sur 5 Ans",
    DividendGrowthRateoverthepast5Years:
      "Taux de Croissance du Dividende sur les 5 Dernières Années",
    QualityOfCompany: "Qualité de l'Entreprise",
    Dividend: "Dividende",
    comparedToLastQuarter: "Par rapport au dernier trimestre",
    comparedToLastYear: "Par rapport à l'année dernière",
    newTab: "Nouvel onglet",
    compare: "Comparer",
    topCompanies: "Meilleures entreprises",
    watchlist: "Liste de surveillance",
    rank: "Rang",
    companyName: "Nom de l'entreprise",
    symbol: "Symbole",
    marketCap: "Capitalisation boursière",
    price: "Prix",
    volume: "Volume",
    country: "Pays",
    joinDiscord: "Rejoindre Discord",
    joinTelegram: "Rejoindre Telegram",
    contactUs: "Contactez-nous",
    InTheLast365Days: "Au cours des 365 derniers jours",
    LastUpdated: "Dernière mise à jour",
    pages: "Pages",

    titles: {
      PE: "P / E",
      PS: "P / S",
      PB: "P / B",
      RevenueGrowth: "Croissance des revenus %",
      NetIncomeGrowth: "Croissance du revenu net %",
      Price: "Prix",
      MarketCap: "Capitalisation Boursière",
      Revenue: "Revenu",
      RevenueVsNetIncome: "Revenu vs. Revenu Net",
      NetIncome: "Revenu Net",
      Ebitda: "EBITDA",
      Eps: "BPA",
      TotalExpenses: "Dépenses Totales",
      SharesIssued: "Actions en circulation",
      FreeCashFlow: "Flux de Trésorerie Libre",
      OperatingCashFlow: "Flux de Trésorerie Opérationnel",
      Dividend: "Dividende",
      GrossMargin: "Marge Brute",
      CashVsDebt: "Trésorerie vs. Dette",
    },
    description: {
      PE: "Le ratio Cours/Bénéfices (P/E) mesure combien les investisseurs sont prêts à payer pour chaque dollar de bénéfices d'une entreprise; calculé en divisant le prix de l'action par les bénéfices par action. Un P/E élevé peut indiquer que les investisseurs anticipent une forte croissance future ou que l'action est surévaluée",
      PS: "Le ratio Cours/Chiffre d'affaires (P/S) mesure combien les investisseurs paient pour chaque dollar des ventes de l'entreprise; calculé en divisant le prix de l'action par le chiffre d'affaires par action. Un ratio P/S élevé peut indiquer que les investisseurs anticipent une forte croissance des ventes ou que l'action est surévaluée",
      PB: "Le ratio Cours/Valeur Comptable (P/B) compare la valeur marchande d'une entreprise à sa valeur comptable (actifs moins passifs); calculé en divisant le prix de l'action par la valeur comptable par action. Un P/B élevé peut indiquer une forte confiance des investisseurs dans les performances futures de l'entreprise ou que l'action est surévaluée si la société ne connaît pas une forte croissance des actifs",
      RevenueGrowth: "Pourcentage de croissance des revenus",
      NetIncomeGrowth: "Pourcentage de croissance du revenu net",
      Price: "Prix historique des actions de l'entreprise au fil du temps",
      MarketCap:
        "La valeur totale des actions en circulation d'une entreprise au fil du temps",
      Revenue:
        "Le montant total d'argent généré par les activités commerciales, la vente de biens et services au fil du temps",
      RevenueVsNetIncome:
        "Une comparaison entre le revenu total et le bénéfice net de l'entreprise au fil du temps, indiquant la durabilité de la croissance de l'entreprise. Un ratio plus faible peut indiquer que l'entreprise développe un avantage concurrentiel et gagne des parts de marché",
      NetIncome:
        "Le bénéfice qu'une entreprise réalise après déduction de toutes les dépenses, impôts et coûts du revenu total au fil du temps",
      Ebitda:
        "Les bénéfices avant intérêts, impôts, dépréciation et amortissement d'une entreprise au fil du temps. Il mesure la performance financière globale et la rentabilité d'une entreprise à partir de ses opérations principales",
      Eps: "La part du bénéfice d'une entreprise attribuée à chaque action en circulation. Il indique combien d'argent les actionnaires recevraient pour chaque action qu'ils possèdent",
      TotalExpenses:
        "La somme de tous les coûts encourus par une entreprise dans la conduite de ses affaires, y compris les dépenses opérationnelles, les intérêts, les impôts, etc. Le graphique peut montrer les dépenses totales au fil du temps, indiquant les tendances des coûts et l'efficacité",
      SharesIssued: "Le nombre total d'actions émises par une entreprise",
      FreeCashFlow:
        "La trésorerie générée par une entreprise après avoir pris en compte les sorties de trésorerie pour soutenir les opérations et maintenir les actifs de capital. Il représente l'argent disponible pour les dividendes, le remboursement de la dette ou la réinvestissement",
      OperatingCashFlow:
        "La trésorerie générée par les opérations commerciales d'une entreprise au fil du temps. Il montre si une entreprise peut générer suffisamment de flux de trésorerie positif pour maintenir et développer ses opérations",
      Dividend:
        "Une partie des bénéfices d'une entreprise distribuée aux actionnaires, généralement sous forme de paiements en espèces.",
      GrossMargin:
        "Le pourcentage du revenu restant après déduction du coût des biens vendus (COGS). Il montre l'efficacité avec laquelle une entreprise produit et vend ses produits",
      CashVsDebt:
        "Un graphique comparatif montrant les réserves de trésorerie de l'entreprise par rapport à sa dette totale. Il aide à évaluer la santé financière et la liquidité de l'entreprise",
    },
    dataDescriptions: {
      peHistorical:
        "La moyenne historique du ratio P/E sur 5 ans offre une couverture temporelle adéquate pour capturer les changements dans les tendances du marché et la performance de l'entreprise. Elle reflète également les conditions économiques récentes, les taux d'intérêt et les développements sectoriels.",
      peHistoricalCompare:
        "Si le ratio P/E est supérieur à la moyenne historique, cela indique souvent une surévaluation et de fortes attentes de croissance, avec des risques potentiels si la croissance est insuffisante. À l'inverse, un P/E inférieur à la moyenne suggère une sous-évaluation due à un pessimisme du marché ou à des problèmes de croissance temporaires. Il est crucial de prendre en compte les facteurs économiques, sectoriels et spécifiques à l'entreprise, car une entreprise en croissance dans une industrie émergente peut justifier des valorisations plus élevées.",

      MarketCap:
        "La valeur totale des actions d'une entreprise ; elle est calculée en multipliant le prix de l'action par le nombre total d'actions",
      PE: "Le ratio cours/bénéfice (P/E) mesure combien les investisseurs sont prêts à payer pour chaque dollar de bénéfice d'une entreprise ; il est calculé en divisant le prix de l'action par le bénéfice par action. Un ratio P/E élevé peut indiquer que les investisseurs s'attendent à une forte croissance future ou que l'action pourrait être surévaluée",
      PS: "Le ratio cours/ventes (P/S) mesure combien les investisseurs paient pour chaque dollar de ventes de l'entreprise ; il est calculé en divisant le prix de l'action par les ventes par action. Un ratio P/S élevé peut indiquer que les investisseurs s'attendent à une forte croissance des ventes futures ou que l'action pourrait être surévaluée",
      PB: "Le ratio cours/valeur comptable (P/B) compare la valeur marchande d'une entreprise à sa valeur comptable (actifs moins passifs) ; il est calculé en divisant le prix de l'action par la valeur comptable par action. Un ratio P/B élevé peut indiquer une forte confiance des investisseurs dans la performance future de l'entreprise ou que l'action pourrait être surévaluée si l'entreprise ne connaît pas une forte croissance de ses actifs",
      FCFYield:
        "Le rendement des flux de trésorerie disponibles est une mesure de la quantité de flux de trésorerie disponibles générée par une entreprise par rapport à sa capitalisation boursière ; il est calculé en divisant les flux de trésorerie disponibles par la capitalisation boursière",
      PEGRatio:
        "Le ratio cours/bénéfice/ratio de croissance (PEG) est une métrique d'évaluation qui prend en compte le ratio P/E d'une entreprise par rapport à son taux de croissance des bénéfices ; il aide à voir si une action est surévaluée ou sous-évaluée. Un ratio PEG de 1 suggère une juste valeur, en dessous de 1 suggère une sous-évaluation par rapport au taux de croissance, et au-dessus de 1 suggère une surévaluation par rapport au taux de croissance",
      OverallRisk:
        "Une évaluation du niveau de risque d'un investissement, en tenant compte de facteurs tels que la volatilité, la stabilité financière et les conditions du marché ; un risque plus faible est généralement préférable, mais cela dépend de la tolérance au risque de l'investisseur",
      week52HighPrice:
        "Le prix le plus élevé qu'une action a atteint au cours de l'année écoulée ; une action cotée près de son plus haut sur 52 semaines peut indiquer une performance récente solide, mais cela pourrait aussi suggérer qu'elle est surévaluée",
      ProfitabilityAnnual:
        "Si l'entreprise a gagné plus d'argent qu'elle n'en a dépensé au cours de l'année écoulée ; la rentabilité est généralement un bon signe de la santé financière d'une entreprise",
      GrowingRevenueAnnual:
        "Si les revenus (ventes) de l'entreprise ont augmenté par rapport à l'année précédente ; une croissance continue des revenus est généralement un signe positif de l'expansion de l'entreprise",
      GrowingNetProfitAnnual:
        "Si le bénéfice net de l'entreprise (revenus après dépenses) a augmenté par rapport à l'année précédente ; la croissance du bénéfice net est un indicateur fort de l'amélioration de la performance financière",
      PriceGrowthoverthepast5Years:
        "L'augmentation en pourcentage du prix d'une action au cours des cinq dernières années ; un pourcentage plus élevé indique une meilleure performance à long terme",
      PriceGrowthoverthepast1Years:
        "L'augmentation en pourcentage du prix d'une action au cours de la dernière année ; une forte croissance à court terme est positive mais doit être considérée dans le contexte d'autres facteurs",
      Cash: "Le montant total d'argent liquide que l'entreprise a immédiatement disponible ; plus de liquidités est généralement mieux pour la flexibilité financière",
      Debt: "Le montant total d'argent que l'entreprise doit aux prêteurs ; une dette plus élevée peut être risquée si l'entreprise ne peut pas générer suffisamment de revenus pour la rembourser",
      CashDebtRatio:
        "Une mesure de la capacité d'une entreprise à rembourser sa dette avec les liquidités disponibles ; un ratio plus élevé indique une meilleure santé financière",
      Profitability:
        "Si l'entreprise a gagné plus d'argent qu'elle n'en a dépensé au cours du trimestre le plus récent ; être rentable chaque trimestre montre que l'entreprise génère des revenus de manière constante",
      GrowingRevenue:
        "Si les revenus de l'entreprise ont augmenté par rapport au trimestre précédent ; une croissance continue des revenus est un signe de succès continu de l'entreprise",
      GrowingNetProfit:
        "Si le bénéfice net de l'entreprise a augmenté par rapport au trimestre précédent ; la croissance du bénéfice net trimestriel indique une amélioration de la performance financière à court terme",
      DividendYield:
        "Le paiement annuel de dividendes qu'une entreprise verse à ses actionnaires, exprimé en pourcentage de son prix d'action ; un rendement plus élevé peut indiquer plus de revenus de dividendes par rapport au prix de l'action",
      PayoutRatio:
        "Le pourcentage des bénéfices d'une entreprise versé en dividendes aux actionnaires ; un ratio plus faible suggère que l'entreprise conserve plus de bénéfices pour la croissance, tandis qu'un ratio très élevé pourrait indiquer que le dividende pourrait ne pas être durable",
      FiveYearAvgDividendYield:
        "Le rendement moyen des dividendes qu'une entreprise a offert au cours des cinq dernières années ; cela aide les investisseurs à voir à quel point les paiements de dividendes ont été constants ou stables au fil du temps",
    },
    labels: {
      TotalCash: "Trésorerie Totale",
      TotalDebt: "Dette Totale",
      Revenue: "Revenu",
      NetIncome: "Revenu Net",
    },
    Quarterly: "Trimestriel",
    Annually: "Annuellement",
    FullScreen: "Plein écran",
    ExitFullScreen: "Quitter le plein écran",
    homeMetaTitle: "StockPenguins | Scanner de Stock Global Gratuit",
    homeMetaDescription:
      "Découvrez l'application ultime de scanner de stock gratuit pour les actions américaines comme Apple, Tesla, Amazon et Microsoft. Accédez à des métriques détaillées telles que le Prix, la Capitalisation Boursière, les Revenus, le Revenu Net, l'EBITDA, le Flux de Trésorerie Libre, le BPA, le Dividende, le Ratio P/E et plus encore. Analysez et comparez les actions sans effort pour prendre des décisions d'investissement éclairées. Recherchez maintenant !",
    pageMetaTitle:
      "Métriques de Stock | P/E, Revenus et Capitalisation Boursière",
    pageMetaDescription:
      "Découvrez des métriques gratuites, y compris le Prix, la Capitalisation Boursière, les Revenus, le Revenu Net, l'EBITDA, le Flux de Trésorerie Libre, le BPA, le Dividende, le Ratio P/E et plus encore. Restez informé sur la performance financière d'Apple. Explorez maintenant !",
  },
  [LANGUAGES[4].value]: {
    // German
    search: "Aktien Suchen",
    peHistorical: "5-Jahres-Durchschnitts-KGV",
    peHistoricalCompare: "Unter oder über dem 5-Jahres-Durchschnitts-KGV?",

    scanner: {
      industry: "Industrie",
      ind_aginputs: "Agrarische Eingaben",
      ind_indpollctrl: "Industrie - Umwelt- und Behandlungskontrollen",
      ind_indmaterials: "Industriematerialien",
      ind_autorv: "Auto - Freizeitfahrzeuge",
      ind_discstores: "Discounter",
      ind_insdiv: "Versicherung - Diversifiziert",
      ind_entertainment: "Unterhaltung",
      ind_reitdiv: "REIT - Diversifiziert",
      ind_medicaldist: "Medizin - Distribution",
      ind_softinfra: "Software - Infrastruktur",
      ind_softapp: "Software - Anwendung",
      ind_electricaleqp: "Elektrogeräte und -teile",
      ind_astmgmtglobal: "Vermögensverwaltung - Global",
      ind_indpower: "Unabhängige Stromerzeuger",
      ind_oilgasdrill: "Öl- und Gasbohrung",
      ind_leisure: "Freizeit",
      ind_medicaldev: "Medizin - Geräte",
      ind_coal: "Kohle",
      ind_edu: "Bildungs- und Schulungsdienste",
      ind_reitind: "REIT - Industrie",
      ind_autoparts: "Auto - Teile",
      ind_metalfab: "Fertigung - Metallverarbeitung",
      ind_fincredit: "Finanzen - Kreditdienste",
      ind_broadcasting: "Rundfunk",
      ind_medcarefac: "Medizin - Pflegeeinrichtungen",
      ind_chemspecialty: "Chemikalien - Spezialität",
      ind_astmgmt: "Vermögensverwaltung",
      ind_insreins: "Versicherung - Rückversicherung",
      ind_travellodging: "Reiseunterkünfte",
      ind_findiv: "Finanzen - Diversifiziert",
      ind_banks: "Banken",
      ind_bevalcoholic: "Getränke - Alkoholische",
      ind_hardwareeqp: "Hardware, Geräte und Teile",
      ind_inddist: "Industrie - Distribution",
      ind_constrmat: "Baumaterialien",
      ind_fooddist: "Lebensmittelverteilung",
      ind_bevwines: "Getränke - Weingüter und Brennereien",
      ind_furnishings: "Einrichtungen, Ausstattungen und Geräte",
      ind_silver: "Silber",
      ind_specialtybiz: "Spezialgeschäftsdienste",
      ind_apparelmanu: "Bekleidung - Hersteller",
      ind_publishing: "Verlag",
      ind_finmortgages: "Finanzen - Hypotheken",
      ind_railroads: "Eisenbahnen",
      ind_insprop: "Versicherung - Sach- und Haftpflicht",
      ind_telco: "Telekommunikationsdienste",
      ind_reitretail: "REIT - Einzelhandel",
      ind_internetcontent: "Internetinhalt und -informationen",
      ind_indinfrastructure: "Industrie - Infrastrukturbetriebe",
      ind_regwater: "Reguliertes Wasser",
      ind_packaging: "Verpackung und Behälter",
      ind_realeservices: "Immobilien - Dienstleistungen",
      ind_reddiversified: "Immobilien - Diversifiziert",
      ind_renewutilities: "Erneuerbare Versorgungsunternehmen",
      ind_preciousmetals: "Andere Edelmetalle",
      ind_autodealerships: "Auto - Händler",
      ind_foodconf: "Lebensmittelkonfektionierer",
      ind_toolsmfg: "Fertigung - Werkzeuge und Zubehör",
      ind_findata: "Finanzen - Daten und Börsen",
      ind_adagencies: "Werbeagenturen",
      ind_luxurygoods: "Luxusgüter",
      ind_householdprod: "Haushalts- und persönliche Produkte",
      ind_reitresidential: "REIT - Wohnimmobilien",
      ind_deptstores: "Kaufhäuser",
      ind_personalprod: "Persönliche Produkte und Dienstleistungen",
      ind_paperlumber: "Papier, Holz und Forstprodukte",
      ind_staffing: "Personal- und Beschäftigungsdienste",
      ind_resconstr: "Wohnungsbau",
      ind_healthcare: "Gesundheitswesen",
      ind_construction: "Bau",
      ind_specialtyretail: "Spezialeinzelhandel",
      ind_mediaentertain: "Medien und Unterhaltung",
      ind_realdev: "Immobilien - Entwicklung",
      ind_astmgmtincome: "Vermögensverwaltung - Einkommen",
      ind_engconst: "Ingenieurwesen und Bau",
      ind_gambling: "Glücksspiel, Resorts und Casinos",
      ind_reitoffice: "REIT - Büro",
      ind_finservices: "Finanzdienstleistungen",
      ind_drugmanugen: "Arzneimittelhersteller - Allgemein",
      ind_homeimprovement: "Heimwerker",
      ind_steel: "Stahl",
      ind_regionalbanks: "Banken - Regional",
      ind_agfarmproducts: "Agrarische Farmprodukte",
      ind_conglomerates: "Konglomerate",
      ind_medhealthcareplans: "Medizin - Gesundheitspläne",
      ind_chemicals: "Chemikalien",
      ind_oilmidstream: "Öl und Gas - Midstream",
      ind_oilexp: "Öl- und Gasexploration und -produktion",
      ind_computerhardware: "Computerhardware",
      ind_banksdiversified: "Banken - Diversifiziert",
      ind_restaurants: "Restaurants",
      ind_oilintegrated: "Öl- und Gasintegriert",
      ind_divutilities: "Diversifizierte Versorgungsunternehmen",
      ind_reithotel: "REIT - Hotel und Motel",
      ind_gaming: "Elektronisches Gaming und Multimedia",
      ind_medpharma: "Medizin - Pharmazeutika",
      ind_grocerystores: "Lebensmittelgeschäfte",
      ind_wastemgmt: "Abfallwirtschaft",
      ind_reggas: "Reguliertes Gas",
      ind_trucking: "Lkw-Verkehr",
      ind_investbanking: "Investment - Bank- und Investmentdienste",
      ind_automanufact: "Auto - Hersteller",
      ind_reitmortgage: "REIT - Hypothek",
      ind_biotech: "Biotechnologie",
      ind_techdistrib: "Technologievertreiber",
      ind_bizequip: "Bürogeräte und -bedarf",
      ind_apparelfoot: "Bekleidung - Schuhe und Zubehör",
      ind_medicalspec: "Medizin - Spezialgebiete",
      ind_apparelretail: "Bekleidung - Einzelhandel",
      ind_regelectric: "Regulierte Elektrizität",
      ind_finconglomerates: "Finanzen - Konglomerate",
      ind_oilrefining: "Öl- und Gasraffinerie und -marketing",
      ind_reitspecialty: "REIT - Spezialität",
      ind_agmachinery: "Agrarische - Maschinen",
      ind_packagedfoods: "Verpackte Lebensmittel",
      ind_reithealthcare: "REIT - Gesundheitseinrichtungen",
      ind_solar: "Solar",
      ind_copper: "Kupfer",
      ind_gold: "Gold",
      ind_medicalsupp: "Medizin - Instrumente und Zubehör",
      ind_insurancebrokers: "Versicherung - Makler",
      ind_security: "Sicherheits- und Schutzdienste",
      ind_tobacco: "Tabak",
      ind_fincapmarkets: "Finanzen - Kapitalmärkte",
      ind_mfgmisc: "Fertigung - Verschiedenes",
      ind_uranium: "Uran",
      ind_insurancelife: "Versicherung - Leben",
      ind_indmachinery: "Industrie - Maschinen",
      ind_shellcompanies: "Briefkastenfirmen",
      ind_consumelectronics: "Unterhaltungs- und Haushaltselektronik",
      ind_medicaldiag: "Medizin - Diagnostik und Forschung",
      ind_oilequip: "Öl- und Gasgeräte und -dienste",
      ind_commequip: "Kommunikationsgeräte",
      ind_aerospace: "Luft- und Raumfahrt",
      ind_travelservices: "Reisedienste",
      ind_drugmanuspecialty:
        "Arzneimittelhersteller - Spezialität und Allgemein",
      ind_aluminum: "Aluminium",
      ind_itservices: "IT-Dienste",
      ind_medhealthinfo: "Medizin - Gesundheitsinformationsdienste",
      ind_bevnonalcoholic: "Getränke - Nichtalkoholisch",
      ind_consulting: "Beratungsdienste",
      ind_rentleasing: "Vermietung und Leasing",
      ind_educationservices: "Bildungsdienste",
      ind_enterpriseit: "Unternehmens-IT",
      ind_manufacturedhome: "Fertighaus",
      ind_fastfood: "Fast Food",
      ind_networking: "Netzwerkdienste",
      ind_inshealth: "Versicherung - Gesundheit",
      ind_autoestates: "Auto - Immobilien",
      ind_indchem: "Industrie - Chemikalien",
      ind_fabconstruction: "Fertigung - Bau",
      ind_farming: "Landwirtschaft",
      ind_otheragriculture: "Sonstige Landwirtschaft",
      ind_institutionalinv: "Institutionelle Investoren",
      ind_securities: "Wertpapiere",
      ind_rent: "Mieten und Leasing",
      ind_stocks: "Aktien",
      ind_housing: "Wohnen",
      ind_greendiv: "Grüne Unternehmen",
      ind_healthinsurance: "Gesundheitsversicherung",
      ind_marineshipping: "Marine Versand",
      ind_software: "Software - Dienstleistungen",
      ind_astmgmtbonds: "Vermögensverwaltung - Anleihen",
      ind_freightlogistics: "Integrierte Fracht- und Logistikdienste",
      ind_insspec: "Versicherung - Spezialität",
      ind_semiconductors: "Halbleiter",
      ind_airlines: "Fluggesellschaften, Flughäfen und Luftdienste",

      revgrowavg: "Umsatzwachstum der letzten 5 Jahre",
      revgrowavg_neg: "Negativ (<0%)",
      revgrowavg_pos: "Positiv (>0%)",
      revgrowavg_u10: "Unter -10%",
      revgrowavg_u20: "Unter -20%",
      revgrowavg_u50: "Unter -50%",
      revgrowavg_u100: "Unter -100%",
      revgrowavg_o10: "Über 10%",
      revgrowavg_o20: "Über 20%",
      revgrowavg_o50: "Über 50%",
      revgrowavg_o100: "Über 100%",

      revgrow: "Umsatzwachstum q/q",
      revgrow_neg: "Negativ (<0%)",
      revgrow_pos: "Positiv (>0%)",
      revgrow_u10: "Unter -10%",
      revgrow_u20: "Unter -20%",
      revgrow_u50: "Unter -50%",
      revgrow_u100: "Unter -100%",
      revgrow_o10: "Über 10%",
      revgrow_o20: "Über 20%",
      revgrow_o50: "Über 50%",
      revgrow_o100: "Über 100%",

      netincgrow: "Nettoeinkommenswachstum q/q",
      netincgrow_neg: "Negativ (<0%)",
      netincgrow_pos: "Positiv (>0%)",
      netincgrow_u10: "Unter -10%",
      netincgrow_u20: "Unter -20%",
      netincgrow_u50: "Unter -50%",
      netincgrow_u100: "Unter -100%",
      netincgrow_o10: "Über 10%",
      netincgrow_o20: "Über 20%",
      netincgrow_o50: "Über 50%",
      netincgrow_o100: "Über 100%",

      ltdc: "Langfristige Schulden / Kapitalisierung",
      ltdc_high: "Hoch (>0.5)",
      ltdc_low: "Niedrig (<0.1)",
      ltdc_u1: "Unter 1",
      ltdc_u0_9: "Unter 0.9",
      ltdc_u0_8: "Unter 0.8",
      ltdc_u0_7: "Unter 0.7",
      ltdc_u0_6: "Unter 0.6",
      ltdc_u0_5: "Unter 0.5",
      ltdc_u0_4: "Unter 0.4",
      ltdc_u0_3: "Unter 0.3",
      ltdc_u0_2: "Unter 0.2",
      ltdc_u0_1: "Unter 0.1",
      ltdc_o0_1: "Über 0.1",
      ltdc_o0_2: "Über 0.2",
      ltdc_o0_3: "Über 0.3",
      ltdc_o0_4: "Über 0.4",
      ltdc_o0_5: "Über 0.5",
      ltdc_o0_6: "Über 0.6",
      ltdc_o0_7: "Über 0.7",
      ltdc_o0_8: "Über 0.8",
      ltdc_o0_9: "Über 0.9",
      ltdc_o1: "Über 1",

      grossMargin: "Bruttomarge",
      grossm_pos: "Positiv (>0%)",
      grossm_neg: "Negativ (<0%)",
      grossm_u90: "Unter 90%",
      grossm_u70: "Unter 70%",
      grossm_u50: "Unter 50%",
      grossm_u30: "Unter 30%",
      grossm_u10: "Unter 10%",
      grossm_u5: "Unter 5%",
      grossm_u_10: "Unter -10%",
      grossm_u_30: "Unter -30%",
      grossm_u_50: "Unter -50%",
      grossm_u_70: "Unter -70%",
      grossm_u_100: "Unter -100%",
      grossm_o0: "Über 0%",
      grossm_o10: "Über 10%",
      grossm_o30: "Über 30%",
      grossm_o50: "Über 50%",
      grossm_o70: "Über 70%",
      grossm_o90: "Über 90%",

      deRatio: "Verschuldungsgrad",
      deratio_high: "Hoch (>0.5)",
      deratio_low: "Niedrig (<0.1)",
      deratio_u1: "Unter 1",
      deratio_u0_9: "Unter 0.9",
      deratio_u0_8: "Unter 0.8",
      deratio_u0_7: "Unter 0.7",
      deratio_u0_6: "Unter 0.6",
      deratio_u0_5: "Unter 0.5",
      deratio_u0_4: "Unter 0.4",
      deratio_u0_3: "Unter 0.3",
      deratio_u0_2: "Unter 0.2",
      deratio_u0_1: "Unter 0.1",
      deratio_o0_1: "Über 0.1",
      deratio_o0_2: "Über 0.2",
      deratio_o0_3: "Über 0.3",
      deratio_o0_4: "Über 0.4",
      deratio_o0_5: "Über 0.5",
      deratio_o0_6: "Über 0.6",
      deratio_o0_7: "Über 0.7",
      deratio_o0_8: "Über 0.8",
      deratio_o0_9: "Über 0.9",
      deratio_o1: "Über 1",

      netProfitMargin: "Nettogewinnmarge",
      netprf_pos: "Positiv (>0%)",
      netprf_neg: "Negativ (<0%)",
      netprf_veryneg: "Sehr Negativ (<-20%)",
      netprf_high: "Hoch (>20%)",
      netprf_u90: "Unter 90%",
      netprf_u80: "Unter 80%",
      netprf_u50: "Unter 50%",
      netprf_u30: "Unter 30%",
      netprf_u10: "Unter 10%",
      netprf_u5: "Unter 5%",
      netprf_u_10: "Unter -10%",
      netprf_u_30: "Unter -30%",
      netprf_u_50: "Unter -50%",
      netprf_u_100: "Unter -100%",
      netprf_o10: "Über 10%",
      netprf_o30: "Über 30%",
      netprf_o40: "Über 40%",
      netprf_o50: "Über 50%",
      netprf_o80: "Über 80%",
      netprf_o90: "Über 90%",

      dividendYield: "Dividendenrendite",
      divy_none: "Keine (0%)",
      divy_pos: "Positiv (>0%)",
      divy_high: "Hoch (>5%)",
      divy_veryhigh: "Sehr Hoch (>10%)",
      divy_o1: "Über 1%",
      divy_o2: "Über 2%",
      divy_o3: "Über 3%",
      divy_o4: "Über 4%",
      divy_o5: "Über 5%",
      divy_o6: "Über 6%",
      divy_o7: "Über 7%",
      divy_o8: "Über 8%",
      divy_o9: "Über 9%",
      divy_o10: "Über 10%",

      payoutRatio: "Ausschüttungsquote",
      payoutr_none: "Keine (0%)",
      payoutr_pos: "Positiv (>0%)",
      payoutr_low: "Niedrig (<20%)",
      payoutr_high: "Hoch (>50%)",
      payoutr_o10: "Über 10%",
      payoutr_o20: "Über 20%",
      payoutr_o50: "Über 50%",
      payoutr_o80: "Über 80%",
      payoutr_o100: "Über 100%",
      payoutr_u10: "Unter 10%",
      payoutr_u20: "Unter 20%",
      payoutr_u50: "Unter 50%",
      payoutr_u80: "Unter 80%",
      payoutr_u100: "Unter 100%",

      peHistoric: "Historisches KGV",
      pehistoric_below: "Unter dem historischen Durchschnitt",
      pehistoric_above: "Über dem historischen Durchschnitt",
      pbHistoric: "Historisches KBV",
      pbhistoric_below: "Unter dem historischen Durchschnitt",
      pbhistoric_above: "Über dem historischen Durchschnitt",
      psHistoric: "Historisches KUV",
      pshistoric_below: "Unter dem historischen Durchschnitt",
      pshistoric_above: "Über dem historischen Durchschnitt",
      pfcf: "Preis/FCF",
      pfcf_low: "Niedrig (<15)",
      pfcf_high: "Hoch (>50)",
      pfcf_u10: "Unter 10",
      pfcf_u20: "Unter 20",
      pfcf_u50: "Unter 50",
      pfcf_u80: "Unter 80",
      pfcf_u100: "Unter 100",
      pfcf_o10: "Über 10",
      pfcf_o20: "Über 20",
      pfcf_o50: "Über 50",
      pfcf_o80: "Über 80",
      pfcf_o100: "Über 100",

      operatingMargin: "Betriebsmarge",
      opmr_pos: "Positiv (>0%)",
      opmr_neg: "Negativ (<0%)",
      opmr_veryneg: "Sehr negativ (<-20%)",
      opmr_high: "Hoch (>25%)",
      opmr_u90: "Unter 90%",
      opmr_u80: "Unter 80%",
      opmr_u70: "Unter 70%",
      opmr_u60: "Unter 60%",
      opmr_u50: "Unter 50%",
      opmr_u40: "Unter 40%",
      opmr_u30: "Unter 30%",
      opmr_u20: "Unter 20%",
      opmr_u10: "Unter 10%",
      opmr_u_m10: "Unter -10%",
      opmr_u_m30: "Unter -30%",
      opmr_u_m50: "Unter -50%",
      opmr_u_m100: "Unter -100%",
      opmr_o10: "Über 10%",
      opmr_o20: "Über 20%",
      opmr_o30: "Über 30%",
      opmr_o40: "Über 40%",
      opmr_o50: "Über 50%",
      opmr_o60: "Über 60%",
      opmr_o70: "Über 70%",
      opmr_o80: "Über 80%",
      opmr_o90: "Über 90%",

      pricecash: "Preis/Kasse",
      pricecash_low: "Niedrig (<3)",
      pricecash_high: "Hoch (>50)",
      pricecash_u1: "Unter 1",
      pricecash_u2: "Unter 2",
      pricecash_u3: "Unter 3",
      pricecash_u4: "Unter 4",
      pricecash_u5: "Unter 5",
      pricecash_u6: "Unter 6",
      pricecash_u7: "Unter 7",
      pricecash_u8: "Unter 8",
      pricecash_u9: "Unter 9",
      pricecash_u10: "Unter 10",
      pricecash_o1: "Über 1",
      pricecash_o2: "Über 2",
      pricecash_o3: "Über 3",
      pricecash_o4: "Über 4",
      pricecash_o5: "Über 5",
      pricecash_o6: "Über 6",
      pricecash_o7: "Über 7",
      pricecash_o8: "Über 8",
      pricecash_o9: "Über 9",
      pricecash_o10: "Über 10",
      pricecash_o20: "Über 20",
      pricecash_o30: "Über 30",
      pricecash_o40: "Über 40",
      pricecash_o50: "Über 50",
      exchange: "Börse",
      any: "Irgendein",
      nasdaq: "NASDAQ",
      nyse: "NYSE",
      amex: "AMEX",
      marketCap: "Marktkapitalisierung",
      mcap_mega: "Mega (200 Mrd. $ und mehr)",
      mcap_large: "Groß (10 Mrd. $ bis 200 Mrd. $)",
      mcap_mid: "Mittel (2 Mrd. $ bis 10 Mrd. $)",
      mcap_small: "Klein (300 Mio. $ bis 2 Mrd. $)",
      mcap_micro: "Mikro (50 Mio. $ bis 300 Mio. $)",
      mcap_nano: "Nano (unter 50 Mio. $)",
      mcap_largeover: "+Groß (über 10 Mrd. $)",
      mcap_midover: "+Mittel (über 2 Mrd. $)",
      mcap_smallover: "+Klein (über 300 Mio. $)",
      mcap_microover: "+Mikro (über 50 Mio. $)",
      mcap_largeunder: "-Groß (unter 200 Mrd. $)",
      mcap_midunder: "-Mittel (unter 10 Mrd. $)",
      mcap_smallunder: "-Klein (unter 2 Mrd. $)",
      mcap_microunder: "-Mikro (unter 300 Mio. $)",

      performance: "Leistung",
      perf_dup: "Ein Tag hoch",
      perf_ddown: "Ein Tag runter",
      perf_d15u: "Ein Tag -15%",
      perf_d10u: "Ein Tag -10%",
      perf_d10o: "Ein Tag +10%",
      perf_d15o: "Ein Tag +15%",
      perf_1w30u: "Woche -30%",
      perf_1w10u: "Woche -10%",
      perf_1wdown: "Woche runter",
      perf_1wup: "Woche hoch",
      perf_1w10o: "Woche +10%",
      perf_1w30o: "Woche +30%",
      perf_4w50u: "Monat -50%",
      perf_4w30u: "Monat -30%",
      perf_4w10u: "Monat -10%",
      perf_4wdown: "Monat runter",
      perf_4wup: "Monat hoch",
      perf_4w20o: "Monat +20%",
      perf_4w30o: "Monat +30%",
      perf_4w50o: "Monat +50%",
      perf_13w50u: "Quartal -50%",
      perf_13w30u: "Quartal -30%",
      perf_13w10u: "Quartal -10%",
      perf_13wdown: "Quartal runter",
      perf_13wup: "Quartal hoch",
      perf_13w10o: "Quartal +10%",
      perf_13w30o: "Quartal +30%",
      perf_13w50o: "Quartal +50%",
      perf_26w75u: "Halbjahr -75%",
      perf_26w50u: "Halbjahr -50%",
      perf_26w20u: "Halbjahr -20%",
      perf_26w10u: "Halbjahr -10%",
      perf_26wdown: "Halbjahr runter",
      perf_26wup: "Halbjahr hoch",
      perf_26w10o: "Halbjahr +10%",
      perf_26w20o: "Halbjahr +20%",
      perf_26w50o: "Halbjahr +50%",
      perf_26w100o: "Halbjahr +100%",
      perf_52w75u: "Jahr -75%",
      perf_52w50u: "Jahr -50%",
      perf_52w30u: "Jahr -30%",
      perf_52w10u: "Jahr -10%",
      perf_52wdown: "Jahr runter",
      perf_52wup: "Jahr hoch",
      perf_52w10o: "Jahr +10%",
      perf_52w50o: "Jahr +50%",
      perf_52w100o: "Jahr +100%",
      perf_52w300o: "Jahr +300%",
      perf_52w500o: "Jahr +500%",
      perf_ytd75u: "Jahr bis heute -75%",
      perf_ytd50u: "Jahr bis heute -50%",
      perf_ytd30u: "Jahr bis heute -30%",
      perf_ytd10u: "Jahr bis heute -10%",
      perf_ytddown: "Jahr bis heute runter",
      perf_ytdup: "Jahr bis heute hoch",
      perf_ytd10o: "Jahr bis heute +10%",
      perf_ytd30o: "Jahr bis heute +30%",
      perf_ytd50o: "Jahr bis heute +50%",
      perf_ytd100o: "Jahr bis heute +100%",

      pe: "KGV (P / E)",
      pe_low: "Niedrig (<15)",
      pe_profitable: "Profitabel (>0)",
      pe_high: "Hoch (>50)",
      pe_u5: "Unter 5",
      pe_u10: "Unter 10",
      pe_u15: "Unter 15",
      pe_u20: "Unter 20",
      pe_u30: "Unter 30",
      pe_u40: "Unter 40",
      pe_u50: "Unter 50",
      pe_o5: "Über 5",
      pe_o10: "Über 10",
      pe_o15: "Über 15",
      pe_o20: "Über 20",
      pe_o30: "Über 30",
      pe_o40: "Über 40",
      pe_o50: "Über 50",
      peg: "PEG",
      peg_low: "Niedrig (<1)",
      peg_high: "Hoch (>2)",
      peg_u1: "Unter 1",
      peg_u2: "Unter 2",
      peg_u3: "Unter 3",
      peg_o1: "Über 1",
      peg_o2: "Über 2",
      peg_o3: "Über 3",

      pb: "P / B",
      pb_low: "Niedrig (<1)",
      pb_high: "Hoch (>5)",
      pb_u1: "Unter 1",
      pb_u2: "Unter 2",
      pb_u3: "Unter 3",
      pb_u4: "Unter 4",
      pb_u5: "Unter 5",
      pb_u6: "Unter 6",
      pb_u7: "Unter 7",
      pb_u8: "Unter 8",
      pb_u9: "Unter 9",
      pb_u10: "Unter 10",
      pb_o1: "Über 1",
      pb_o2: "Über 2",
      pb_o3: "Über 3",
      pb_o4: "Über 4",
      pb_o5: "Über 5",
      pb_o6: "Über 6",
      pb_o7: "Über 7",
      pb_o8: "Über 8",
      pb_o9: "Über 9",
      pb_o10: "Über 10",

      ps: "P / S",
      ps_low: "Niedrig (<1)",
      ps_high: "Hoch (>5)",
      ps_u1: "Unter 1",
      ps_u2: "Unter 2",
      ps_u3: "Unter 3",
      ps_u4: "Unter 4",
      ps_u5: "Unter 5",
      ps_u6: "Unter 6",
      ps_u7: "Unter 7",
      ps_u8: "Unter 8",
      ps_u9: "Unter 9",
      ps_u10: "Unter 10",
      ps_o1: "Über 1",
      ps_o2: "Über 2",
      ps_o3: "Über 3",
      ps_o4: "Über 4",
      ps_o5: "Über 5",
      ps_o6: "Über 6",
      ps_o7: "Über 7",
      ps_o8: "Über 8",
      ps_o9: "Über 9",
      ps_o10: "Über 10",

      quick: "Schnelles Verhältnis",
      quick_high: "Hoch (>3)",
      quick_low: "Niedrig (<0.5)",
      quick_u1: "Unter 1",
      quick_u0_5: "Unter 0.5",
      quick_o0_5: "Über 0.5",
      quick_o1: "Über 1",
      quick_o1_5: "Über 1.5",
      quick_o2: "Über 2",
      quick_o3: "Über 3",
      quick_o4: "Über 4",
      quick_o5: "Über 5",
      quick_o10: "Über 10",

      current: "Aktuelles Verhältnis",
      current_high: "Hoch (>3)",
      current_low: "Niedrig (<1)",
      current_u1: "Unter 1",
      current_u0_5: "Unter 0.5",
      current_o0_5: "Über 0.5",
      current_o1: "Über 1",
      current_o1_5: "Über 1.5",
      current_o2: "Über 2",
      current_o3: "Über 3",
      current_o4: "Über 4",
      current_o5: "Über 5",
      current_o10: "Über 10",

      sector: "Sektor",
      sector_realestate: "Immobilien",
      sector_health: "Gesundheitswesen",
      sector_energy: "Energie",
      sector_utilities: "Versorgungsunternehmen",
      sector_cd: "Defensiv Konsumgüter",
      sector_fs: "Finanzdienstleistungen",
      sector_coms: "Kommunikationsdienste",
      sector_cs: "Kundendienste",
      sector_bm: "Grundstoffe",
      sector_i: "Industrie",
      sector_cc: "Zyklischer Konsumgüter",
      sector_t: "Technologie",
    },

    TopCompaniesTitle: "Größte Unternehmen in den USA nach",
    TotalMarketCap: "Gesamtmarktkapitalisierung",
    Companies: "Unternehmen",
    sortBy: "Sortieren nach",
    Terms: "Geschäftsbedingungen",
    Privacy: "Datenschutzrichtlinie",
    logout: "Abmelden",
    portfolioLeader: "Portfoliomanager",
    portfolio: {
      symbol: "Symbol",
      portfolioName: "Portfolioname",
      date: "Datum",
      price: "Preis",
      noOfShares: "Anzahl der Aktien",
      buy: "Kaufen",
      sellTransactions: "Verkaufstransaktionen",
      purchasePrice: "Kaufpreis",
      purchaseDate: "Kaufdatum",
      priceSold: "Verkaufspreis",
      dateSold: "Verkaufsdatum",
      profitAndLoss: "Gewinn und Verlust",
      company: "Unternehmen",
      sector: "Sektor",
      currentPrice: "Aktueller Preis",
      avgPurchasePrice: "Durchschnittlicher Kaufpreis",
      totalShares: "Gesamtaktien",
      portfolioPct: "% des Portfolios",
      capital: "Kapital",
      sell: "Verkaufen",
      history: "Verlauf",
      pctReturns: "Renditen %",
      name: "Name",
      noOfCompanies: "Anzahl der Unternehmen",
      total: "Gesamt",
    },
    login: {
      login: "Anmelden",
      signup: "Registrieren",
      forgotpassword: "Passwort vergessen",
      resetpassword: "Passwort zurücksetzen",
      email: "E-Mail",
      password: "Passwort",
      forgotPasswordQ: "Passwort vergessen?",
      createAccount: "Neues Stock Penguins Konto erstellen",
      firstName: "Vorname",
      lastName: "Nachname",
      requestOTP: "OTP anfordern",
      otp: "OTP",
    },
    stockScreener: "Aktien-Screener",
    topPresets: "Top-Voreinstellungen",
    myPortfolio: "Mein Portfolio",
    portfolioLeaderboard: "Portfolio-Bestenliste",
    peHistoric: "P/E Historisch",
    SimilarCompanies: "Ähnliche Unternehmen",
    Stock: "Aktie",
    presetName: "Preset-Name",
    createdBy: "Erstellt Von",
    upvotes: "Abstimmungen",
    savePreset: "Preset Speichern",
    share: "Teilen",
    save: "Speichern",
    selectPreset: "Preset Auswählen",
    loginToSP: "Bei Stock Penguins Anmelden",
    clearFilters: "Filter Zurücksetzen",

    today: "1-Tages-Veränderung",
    oneMonthChange: "1-Monats-Veränderung",
    oneYearChange: "1-Jahres-Veränderung",
    OneYear: "1 Jahr",
    ThreeYear: "3 Jahre",
    FiveYear: "5 Jahre",
    TenYear: "10 Jahre",
    CAGRDesc: "Historische Veränderung im Laufe der Zeit",
    welcome: "Willkommen",
    SearchBarPlaceholder: "Was wird dein nächster 10-Bagger sein?",
    Search: "Suche",
    Industry: "Industrie",
    Sector: "Sektor",
    MarketCap: "Marktkapitalisierung",
    PE: "KGV",
    PS: "KUV",
    PB: "KBV",
    FCFYield: "FCF-Rendite",
    PEGRatio: "PEG-Verhältnis",
    OverallRisk: "Piotroski-Score",
    week52HighPrice: "52-Wochen-Höchstkurs",
    PriceGrowthoverthepast5Years: "Preiswachstum in den letzten 5 Jahren",
    PriceGrowthoverthepast1Years: "Preiswachstum im letzten Jahr",
    Profitability: "Rentabel? (Quartalsweise)",
    GrowingRevenue: "Wachstum des Umsatzes? (Quartalsweise)",
    GrowingNetProfit: "Wachstum des Nettogewinns? (Quartalsweise)",
    ProfitabilityAnnual: "Rentabel? (Jährlich)",
    GrowingRevenueAnnual: "Wachstum des Umsatzes? (Jährlich)",
    GrowingNetProfitAnnual: "Wachstum des Nettogewinns? (Jährlich)",
    FinancialMetrics: "Bewertungszusammenfassung",
    BalanceSheet: "Finanzkennzahlen",
    Cash: "Bargeld",
    Debt: "Schulden",
    CashDebtRatio: "Bargeld-Schulden-Verhältnis",
    DividendYield: "Dividendenrendite",
    PayoutRatio: "Ausschüttungsquote",
    FiveYearAvgDividendYield:
      "Durchschnittliche Dividendenrendite der letzten 5 Jahre",
    DividendGrowthRateoverthepast5Years:
      "Dividendenwachstumsrate der letzten 5 Jahre",
    QualityOfCompany: "Unternehmensqualität",
    Dividend: "Dividende",
    comparedToLastQuarter: "Verglichen mit dem letzten Quartal",
    comparedToLastYear: "Verglichen mit dem letzten Jahr",
    newTab: "Neuer Tab",
    compare: "Vergleichen",
    topCompanies: "Top-Unternehmen",
    watchlist: "Beobachtungsliste",
    rank: "Rang",
    companyName: "Firmenname",
    symbol: "Symbol",
    marketCap: "Marktkapitalisierung",
    price: "Preis",
    volume: "Volumen",
    country: "Land",
    joinDiscord: "Discord beitreten",
    joinTelegram: "Telegram beitreten",
    contactUs: "Kontaktieren Sie uns",
    InTheLast365Days: "In den letzten 365 Tagen",
    LastUpdated: "Zuletzt aktualisiert",
    pages: "Seiten",

    titles: {
      PE: "P / E",
      PS: "P / S",
      PB: "P / B",
      RevenueGrowth: "Umsatzwachstum %",
      NetIncomeGrowth: "Nettoeinkommenswachstum %",
      Price: "Preis",
      MarketCap: "Marktkapitalisierung",
      Revenue: "Umsatz",
      RevenueVsNetIncome: "Umsatz vs. Nettoergebnis",
      NetIncome: "Nettoergebnis",
      Ebitda: "EBITDA",
      Eps: "EPS",
      TotalExpenses: "Gesamtausgaben",
      SharesIssued: "Ausstehende Aktien",
      FreeCashFlow: "Freier Cashflow",
      OperatingCashFlow: "Operativer Cashflow",
      Dividend: "Dividende",
      GrossMargin: "Bruttomarge",
      CashVsDebt: "Bargeld vs. Schulden",
    },
    description: {
      PE: "Das Kurs-Gewinn-Verhältnis (KGV) misst, wie viel Investoren bereit sind, für jeden Dollar Gewinn eines Unternehmens zu zahlen; berechnet durch Division des Aktienkurses durch den Gewinn je Aktie. Ein hohes KGV kann darauf hindeuten, dass Investoren ein hohes zukünftiges Wachstum erwarten oder dass die Aktie überbewertet ist",
      PS: "Das Kurs-Umsatz-Verhältnis (KUV) misst, wie viel Investoren für jeden Dollar Umsatz des Unternehmens zahlen; berechnet durch Division des Aktienkurses durch den Umsatz je Aktie. Ein hohes KUV kann darauf hindeuten, dass Investoren ein hohes zukünftiges Umsatzwachstum erwarten oder dass die Aktie überbewertet ist",
      PB: "Das Kurs-Buchwert-Verhältnis (KBV) vergleicht den Marktwert eines Unternehmens mit seinem Buchwert (Vermögenswerte abzüglich Verbindlichkeiten); berechnet durch Division des Aktienkurses durch den Buchwert je Aktie. Ein hohes KBV kann auf großes Vertrauen der Investoren in die zukünftige Leistung des Unternehmens hinweisen oder darauf, dass die Aktie überbewertet ist, wenn das Unternehmen kein starkes Vermögenswachstum aufweist",
      RevenueGrowth: "Prozentuales Umsatzwachstum",
      NetIncomeGrowth: "Prozentuales Wachstum des Nettogewinns",
      Price: "Historischer Aktienkurs des Unternehmens im Laufe der Zeit",
      MarketCap:
        "Der Gesamtwert der im Umlauf befindlichen Aktien eines Unternehmens im Laufe der Zeit",
      Revenue:
        "Der Gesamtbetrag des durch Geschäftstätigkeiten, Waren- und Dienstleistungsverkäufe generierten Geldes im Laufe der Zeit",
      RevenueVsNetIncome:
        "Ein Vergleich zwischen den Gesamteinnahmen und dem Nettogewinn eines Unternehmens im Laufe der Zeit, der die Nachhaltigkeit des Unternehmenswachstums anzeigt. Ein niedrigeres Verhältnis kann darauf hinweisen, dass das Unternehmen einen Wettbewerbsvorteil entwickelt und Marktanteile gewinnt",
      NetIncome:
        "Der Gewinn, den ein Unternehmen nach Abzug aller Ausgaben, Steuern und Kosten vom Gesamteinkommen im Laufe der Zeit erzielt",
      Ebitda:
        "Die Erträge eines Unternehmens vor Zinsen, Steuern, Abschreibungen und Amortisation im Laufe der Zeit. Es misst die Gesamtfinanzleistung und Rentabilität eines Unternehmens aus seinen Kerngeschäften",
      Eps: "Der Teil des Gewinns eines Unternehmens, der auf jede ausstehende Stammaktie entfällt. Er zeigt, wie viel Geld die Aktionäre für jede Aktie, die sie besitzen, erhalten würden",
      TotalExpenses:
        "Die Summe aller Kosten, die ein Unternehmen bei der Durchführung seines Geschäfts verursacht, einschließlich Betriebskosten, Zinsen, Steuern usw. Das Diagramm kann die Gesamtausgaben im Laufe der Zeit zeigen und auf Kostenentwicklung und Effizienz hinweisen",
      SharesIssued: "Die Gesamtzahl der ausgegebenen Aktien eines Unternehmens",
      FreeCashFlow:
        "Der Cashflow, den ein Unternehmen nach Berücksichtigung der Barausflüsse zur Unterstützung des Betriebs und zur Aufrechterhaltung von Kapitalanlagen generiert. Er stellt das Geld dar, das für Dividenden, Schuldentilgung oder Reinvestitionen verfügbar ist",
      OperatingCashFlow:
        "Der Cashflow aus den Geschäftstätigkeiten eines Unternehmens im Laufe der Zeit. Er zeigt, ob ein Unternehmen genügend positiven Cashflow generieren kann, um seinen Betrieb aufrechtzuerhalten und auszubauen",
      Dividend:
        "Ein Teil des Gewinns eines Unternehmens, der an die Aktionäre verteilt wird, in der Regel in Form von Barauszahlungen.",
      GrossMargin:
        "Der Prozentsatz des verbleibenden Umsatzes nach Abzug der Kosten der verkauften Waren (COGS). Er zeigt, wie effizient ein Unternehmen seine Produkte produziert und verkauft",
      CashVsDebt:
        "Ein Vergleichsdiagramm, das die Bargeldreserven eines Unternehmens im Vergleich zu seinen Gesamtschulden zeigt. Es hilft, die Finanzlage und Liquidität des Unternehmens zu beurteilen",
    },
    dataDescriptions: {
      peHistorical:
        "Das 5-Jahres-Durchschnitts-KGV bietet eine ausreichende Zeitspanne, um Markttrends und die Unternehmensentwicklung abzubilden. Es spiegelt auch die neuesten wirtschaftlichen Bedingungen, Zinssätze und Branchenentwicklungen wider.",
      peHistoricalCompare:
        "Wenn das KGV über dem historischen Durchschnitt liegt, deutet dies oft auf eine Überbewertung und hohe Wachstumserwartungen hin, mit potenziellen Risiken, falls das Wachstum hinter den Erwartungen zurückbleibt. Ein unterdurchschnittliches KGV deutet hingegen auf eine Unterbewertung hin, die durch Marktpessimismus oder vorübergehende Wachstumsprobleme verursacht wird. Es ist wichtig, wirtschaftliche, branchenspezifische und unternehmensspezifische Faktoren zu berücksichtigen, da ein wachsendes Unternehmen in einer sich entwickelnden Branche höhere Bewertungen rechtfertigen kann.",
      MarketCap:
        "Der Gesamtwert der Aktien eines Unternehmens; er wird berechnet, indem der Aktienkurs mit der Gesamtzahl der Aktien multipliziert wird",
      PE: "Das Kurs-Gewinn-Verhältnis (KGV) misst, wie viel Anleger bereit sind, für jeden Dollar Gewinn eines Unternehmens zu zahlen; es wird berechnet, indem der Aktienkurs durch den Gewinn pro Aktie geteilt wird. Ein hohes KGV kann darauf hindeuten, dass Anleger ein hohes zukünftiges Wachstum erwarten oder die Aktie überbewertet sein könnte",
      PS: "Das Kurs-Umsatz-Verhältnis (KUV) misst, wie viel Anleger für jeden Dollar Umsatz des Unternehmens zahlen; es wird berechnet, indem der Aktienkurs durch den Umsatz pro Aktie geteilt wird. Ein hohes KUV kann darauf hindeuten, dass Anleger ein hohes zukünftiges Umsatzwachstum erwarten oder die Aktie überbewertet sein könnte",
      PB: "Das Kurs-Buchwert-Verhältnis (KBV) vergleicht den Marktwert eines Unternehmens mit seinem Buchwert (Vermögenswerte minus Verbindlichkeiten); es wird berechnet, indem der Aktienkurs durch den Buchwert pro Aktie geteilt wird. Ein hohes KBV kann auf ein starkes Vertrauen der Anleger in die zukünftige Leistung des Unternehmens hinweisen oder darauf, dass die Aktie überbewertet sein könnte, wenn das Unternehmen kein starkes Wachstum der Vermögenswerte aufweist",
      FCFYield:
        "Die Free-Cashflow-Rendite ist ein Maß dafür, wie viel Free-Cashflow ein Unternehmen im Verhältnis zu seiner Marktkapitalisierung generiert; sie wird berechnet, indem der Free-Cashflow durch die Marktkapitalisierung geteilt wird",
      PEGRatio:
        "Das Kurs-Gewinn-Wachstums-Verhältnis (PEG) ist eine Bewertungsmetrik, die das KGV eines Unternehmens in Bezug auf seine Gewinnwachstumsrate berücksichtigt; es hilft zu erkennen, ob eine Aktie über- oder unterbewertet ist. Ein PEG-Verhältnis von 1 deutet auf einen fairen Wert hin, unter 1 auf eine Unterbewertung im Verhältnis zur Wachstumsrate und über 1 auf eine Überbewertung im Verhältnis zur Wachstumsrate",
      OverallRisk:
        "Eine Einschätzung, wie riskant eine Investition ist, unter Berücksichtigung von Faktoren wie Volatilität, finanzieller Stabilität und Marktbedingungen; ein geringeres Risiko ist in der Regel besser, hängt jedoch von der Risikobereitschaft des Anlegers ab",
      week52HighPrice:
        "Der höchste Kurs, den eine Aktie im letzten Jahr erreicht hat; eine Aktie, die nahe ihrem 52-Wochen-Hoch notiert, kann auf eine starke jüngste Leistung hinweisen, könnte aber auch darauf hindeuten, dass sie überbewertet ist",
      ProfitabilityAnnual:
        "Ob das Unternehmen im letzten Jahr mehr Geld verdient hat, als es ausgegeben hat; Rentabilität ist im Allgemeinen ein gutes Zeichen für die finanzielle Gesundheit eines Unternehmens",
      GrowingRevenueAnnual:
        "Ob der Umsatz (Verkäufe) des Unternehmens im Vergleich zum Vorjahr gestiegen ist; ein stetiges Umsatzwachstum ist in der Regel ein positives Zeichen für die Expansion des Unternehmens",
      GrowingNetProfitAnnual:
        "Ob der Nettogewinn des Unternehmens (Einkommen nach Ausgaben) im Vergleich zum Vorjahr gestiegen ist; ein wachsender Nettogewinn ist ein starkes Indiz für eine Verbesserung der finanziellen Leistung",
      PriceGrowthoverthepast5Years:
        "Der prozentuale Anstieg des Aktienkurses in den letzten fünf Jahren; ein höherer Prozentsatz deutet auf eine bessere langfristige Leistung hin",
      PriceGrowthoverthepast1Years:
        "Der prozentuale Anstieg des Aktienkurses im letzten Jahr; starkes kurzfristiges Wachstum ist positiv, sollte jedoch im Kontext anderer Faktoren betrachtet werden",
      Cash: "Der Gesamtbetrag an liquiden Mitteln, die das Unternehmen sofort zur Verfügung hat; mehr Bargeld ist im Allgemeinen besser für die finanzielle Flexibilität",
      Debt: "Der Gesamtbetrag an Geld, den das Unternehmen Gläubigern schuldet; eine höhere Verschuldung kann riskant sein, wenn das Unternehmen nicht in der Lage ist, genügend Einkommen zu generieren, um sie zurückzuzahlen",
      CashDebtRatio:
        "Ein Maß für die Fähigkeit eines Unternehmens, seine Schulden mit den verfügbaren liquiden Mitteln zu begleichen; ein höheres Verhältnis deutet auf eine bessere finanzielle Gesundheit hin",
      Profitability:
        "Ob das Unternehmen im letzten Quartal mehr Geld verdient hat, als es ausgegeben hat; eine vierteljährliche Rentabilität zeigt, dass das Unternehmen kontinuierlich Einkommen generiert",
      GrowingRevenue:
        "Ob der Umsatz des Unternehmens im Vergleich zum Vorquartal gestiegen ist; kontinuierliches Umsatzwachstum ist ein Zeichen für anhaltenden Geschäftserfolg",
      GrowingNetProfit:
        "Ob der Nettogewinn des Unternehmens im Vergleich zum Vorquartal gestiegen ist; ein wachsender Nettogewinn im Quartalsvergleich deutet auf eine Verbesserung der finanziellen Leistung auf kurze Sicht hin",
      DividendYield:
        "Die jährliche Dividendenzahlung, die ein Unternehmen an seine Aktionäre ausschüttet, ausgedrückt als Prozentsatz des Aktienkurses; eine höhere Rendite kann auf mehr Einkommen aus Dividenden im Verhältnis zum Aktienkurs hinweisen",
      PayoutRatio:
        "Der Prozentsatz des Gewinns eines Unternehmens, der als Dividende an die Aktionäre ausgeschüttet wird; ein niedrigeres Verhältnis deutet darauf hin, dass das Unternehmen mehr Gewinne für Wachstum zurückhält, während ein sehr hohes Verhältnis darauf hindeuten könnte, dass die Dividende möglicherweise nicht nachhaltig ist",
      FiveYearAvgDividendYield:
        "Die durchschnittliche Dividendenrendite, die ein Unternehmen in den letzten fünf Jahren angeboten hat; sie hilft den Anlegern zu erkennen, wie konstant oder stabil die Dividendenzahlungen im Laufe der Zeit waren",
    },
    labels: {
      TotalCash: "Gesamtes Bargeld",
      TotalDebt: "Gesamtschulden",
      Revenue: "Umsatz",
      NetIncome: "Nettoergebnis",
    },
    Quarterly: "Quartalsweise",
    Annually: "Jährlich",
    FullScreen: "Vollbild",
    ExitFullScreen: "Vollbild beenden",
    homeMetaTitle: "StockPenguins | Kostenloser Globaler Aktien-Scanner",
    homeMetaDescription:
      "Entdecken Sie die ultimative kostenlose Aktien-Scanner-App für US-Aktien wie Apple, Tesla, Amazon und Microsoft. Greifen Sie auf detaillierte Kennzahlen wie Preis, Marktkapitalisierung, Umsatz, Nettogewinn, EBITDA, Freier Cashflow, EPS, Dividende, KGV und mehr zu. Analysieren und vergleichen Sie Aktien mühelos, um fundierte Anlageentscheidungen zu treffen. Jetzt suchen!",
    pageMetaTitle: "Aktienkennzahlen | KGV, Umsatz und Marktkapitalisierung",
    pageMetaDescription:
      "Entdecken Sie kostenlose Kennzahlen, einschließlich Preis, Marktkapitalisierung, Umsatz, Nettogewinn, EBITDA, Freier Cashflow, EPS, Dividende, KGV und mehr. Bleiben Sie über die finanzielle Leistung von Apple informiert. Jetzt erkunden!",
  },
  [LANGUAGES[5].value]: {
    // Dutch
    search: "Zoek Aandelen",
    peHistorical: "5-jarig historisch gemiddeld K/W-verhouding",
    peHistoricalCompare:
      "Onder of boven de 5-jarig historisch gemiddelde K/W-verhouding?",

    scanner: {
      industry: "Industrie",
      ind_aginputs: "Landbouwinput",
      ind_indpollctrl: "Industrieel - Verontreiniging & Behandelingen",
      ind_indmaterials: "Industriële Materialen",
      ind_autorv: "Auto - Vrijetijdsvoertuigen",
      ind_discstores: "Korting Winkels",
      ind_insdiv: "Verzekering - Gediversifieerd",
      ind_entertainment: "Vermaak",
      ind_reitdiv: "REIT - Gediversifieerd",
      ind_medicaldist: "Medisch - Distributie",
      ind_softinfra: "Software - Infrastructuur",
      ind_softapp: "Software - Toepassing",
      ind_electricaleqp: "Elektrische Apparatuur & Onderdelen",
      ind_astmgmtglobal: "Vermogensbeheer - Wereldwijd",
      ind_indpower: "Onafhankelijke Energieproducenten",
      ind_oilgasdrill: "Olie & Gas Boren",
      ind_leisure: "Vrije Tijd",
      ind_medicaldev: "Medisch - Apparaten",
      ind_coal: "Kolen",
      ind_edu: "Onderwijs & Opleidingsdiensten",
      ind_reitind: "REIT - Industrieel",
      ind_autoparts: "Auto - Onderdelen",
      ind_metalfab: "Fabricage - Metaalbewerking",
      ind_fincredit: "Financieel - Kredietdiensten",
      ind_broadcasting: "Uitzending",
      ind_medcarefac: "Medisch - Zorgfaciliteiten",
      ind_chemspecialty: "Chemicaliën - Specialiteit",
      ind_astmgmt: "Vermogensbeheer",
      ind_insreins: "Verzekering - Herverzekering",
      ind_travellodging: "Reisverblijven",
      ind_findiv: "Financieel - Gediversifieerd",
      ind_banks: "Banken",
      ind_bevalcoholic: "Dranken - Alcoholisch",
      ind_hardwareeqp: "Hardware, Apparatuur & Onderdelen",
      ind_inddist: "Industrieel - Distributie",
      ind_constrmat: "Bouwmaterialen",
      ind_fooddist: "Voedsel Distributie",
      ind_bevwines: "Dranken - Wijnhuizen & Stokerijen",
      ind_furnishings: "Meubels, Fixture & Apparaten",
      ind_silver: "Zilver",
      ind_specialtybiz: "Specialiteitsdiensten",
      ind_apparelmanu: "Kleding - Fabrikanten",
      ind_publishing: "Uitgeverij",
      ind_finmortgages: "Financieel - Hypotheken",
      ind_railroads: "Spoorwegen",
      ind_insprop: "Verzekering - Onroerend goed & Schade",
      ind_telco: "Telecommunicatiediensten",
      ind_reitretail: "REIT - Retail",
      ind_internetcontent: "Internetinhoud & Informatie",
      ind_indinfrastructure: "Industrieel - Infrastructuur",
      ind_regwater: "Gereguleerde Water",
      ind_packaging: "Verpakking & Containers",
      ind_realeservices: "Vastgoed - Diensten",
      ind_reddiversified: "Vastgoed - Gediversifieerd",
      ind_renewutilities: "Hernieuwbare Nutsvoorzieningen",
      ind_preciousmetals: "Andere Edele Metalen",
      ind_autodealerships: "Auto - Dealerships",
      ind_foodconf: "Voedselconfectie",
      ind_toolsmfg: "Fabricage - Gereedschappen & Accessoires",
      ind_findata: "Financieel - Gegevens & Beurzen",
      ind_adagencies: "Reclamebureaus",
      ind_luxurygoods: "Luxe Goederen",
      ind_householdprod: "Huishoudelijke & Persoonlijke Producten",
      ind_reitresidential: "REIT - Woonhuizen",
      ind_deptstores: "Warenhuizen",
      ind_personalprod: "Persoonlijke Producten & Diensten",
      ind_paperlumber: "Papier, Hout & Bosproducten",
      ind_staffing: "Personeels- & Werkgelegenheidsdiensten",
      ind_resconstr: "Woningbouw",
      ind_healthcare: "Gezondheidszorg",
      ind_construction: "Bouw",
      ind_specialtyretail: "Specialiteitswinkel",
      ind_mediaentertain: "Media & Vermaak",
      ind_realdev: "Vastgoed - Ontwikkeling",
      ind_astmgmtincome: "Vermogensbeheer - Inkomen",
      ind_engconst: "Ingenieurs- & Bouwdiensten",
      ind_gambling: "Gokken, Resorts & Casino's",
      ind_reitoffice: "REIT - Kantoor",
      ind_finservices: "Financiële Diensten",
      ind_drugmanugen: "Geneesmiddelenfabrikanten - Algemeen",
      ind_homeimprovement: "Huisverbetering",
      ind_steel: "Staal",
      ind_regionalbanks: "Banken - Regionaal",
      ind_agfarmproducts: "Agrarische Boerderijproducten",
      ind_conglomerates: "Conglomeraten",
      ind_medhealthcareplans: "Medisch - Gezondheidsplannen",
      ind_chemicals: "Chemicaliën",
      ind_oilmidstream: "Olie & Gas Middenstream",
      ind_oilexp: "Olie & Gas Exploratie & Productie",
      ind_computerhardware: "Computerhardware",
      ind_banksdiversified: "Banken - Gediversifieerd",
      ind_restaurants: "Restaurants",
      ind_oilintegrated: "Olie & Gas Geïntegreerd",
      ind_divutilities: "Gediversifieerde Nutsvoorzieningen",
      ind_reithotel: "REIT - Hotel & Motel",
      ind_gaming: "Elektronisch Gokken & Multimedia",
      ind_medpharma: "Medisch - Farmaceutisch",
      ind_grocerystores: "Supermarkten",
      ind_wastemgmt: "Afvalbeheer",
      ind_reggas: "Gereguleerde Gas",
      ind_trucking: "Vrachtvervoer",
      ind_investbanking: "Investeringen - Bank- & Investeringsdiensten",
      ind_automanufact: "Auto - Fabrikanten",
      ind_reitmortgage: "REIT - Hypotheek",
      ind_biotech: "Biotechnologie",
      ind_techdistrib: "Technologie Distributeurs",
      ind_bizequip: "Bedrijfsapparatuur & Benodigdheden",
      ind_apparelfoot: "Kleding - Schoenen & Accessoires",
      ind_medicalspec: "Medisch - Specialismen",
      ind_apparelretail: "Kleding - Detailhandel",
      ind_regelectric: "Gereguleerde Elektriciteit",
      ind_finconglomerates: "Financieel - Conglomeraten",
      ind_oilrefining: "Olie & Gas Raffinage & Marketing",
      ind_reitspecialty: "REIT - Specialiteit",
      ind_agmachinery: "Landbouw - Machines",
      ind_packagedfoods: "Verpakte Voeding",
      ind_reithealthcare: "REIT - Gezondheidsfaciliteiten",
      ind_solar: "Zon",
      ind_copper: "Koper",
      ind_gold: "Goud",
      ind_medicalsupp: "Medisch - Instrumenten & Benodigdheden",
      ind_insurancebrokers: "Verzekering - Makelaars",
      ind_security: "Beveiligings- & Beschermingsdiensten",
      ind_tobacco: "Tabak",
      ind_fincapmarkets: "Financieel - Kapitaalmarkten",
      ind_mfgmisc: "Fabricage - Diversen",
      ind_uranium: "Uranium",
      ind_insurancelife: "Verzekering - Leven",
      ind_indmachinery: "Industrieel - Machines",
      ind_shellcompanies: "Shellbedrijven",
      ind_consumelectronics: "Consumentenelektronica",
      ind_medicaldiag: "Medisch - Diagnostiek & Onderzoek",
      ind_oilequip: "Olie & Gas Apparatuur & Diensten",
      ind_commequip: "Communicatieapparatuur",
      ind_aerospace: "Luchtvaart & Defensie",
      ind_travelservices: "Reisdiensten",
      ind_drugmanuspecialty:
        "Geneesmiddelenfabrikanten - Specialiteit & Generiek",
      ind_aluminum: "Aluminium",
      ind_itservices: "Informatietechnologie Diensten",
      ind_scouting: "Scouting & Klantbeheer",
      ind_autoimport: "Auto - Import",
      ind_specializedservices: "Gespecialiseerde Diensten",
      ind_mixeduse: "Gemengd Gebruik",
      ind_manufacturedhomes: "Fabriekswoningen",
      ind_indpetro: "Industrieel - Petrochemie",
      ind_hedgefund: "Hedgefondsen",
      ind_medhealthinfo: "Medisch - Gezondheidsinformatie Diensten",
      ind_bevnonalcoholic: "Dranken - Niet-Alcoholisch",
      ind_consulting: "Consultancydiensten",
      ind_rentleasing: "Verhuur- & Leasingdiensten",
      ind_marineshipping: "Maritieme Vrachtvervoer",
      ind_software: "Software - Diensten",
      ind_astmgmtbonds: "Vermogensbeheer - Obligaties",
      ind_freightlogistics: "Geïntegreerd Vracht- & Logistiek",
      ind_insspec: "Verzekering - Specialiteit",
      ind_semiconductors: "Halfgeleiders",
      ind_airlines:
        "Luchtvaartmaatschappijen, Luchthavens & Luchtvaartdiensten",

      revgrowavg: "Omzetgroei van de afgelopen 5 jaar",
      revgrowavg_neg: "Negatief (<0%)",
      revgrowavg_pos: "Positief (>0%)",
      revgrowavg_u10: "Onder -10%",
      revgrowavg_u20: "Onder -20%",
      revgrowavg_u50: "Onder -50%",
      revgrowavg_u100: "Onder -100%",
      revgrowavg_o10: "Boven 10%",
      revgrowavg_o20: "Boven 20%",
      revgrowavg_o50: "Boven 50%",
      revgrowavg_o100: "Boven 100%",

      revgrow: "Omzetgroei q/q",
      revgrow_neg: "Negatief (<0%)",
      revgrow_pos: "Positief (>0%)",
      revgrow_u10: "Onder -10%",
      revgrow_u20: "Onder -20%",
      revgrow_u50: "Onder -50%",
      revgrow_u100: "Onder -100%",
      revgrow_o10: "Boven 10%",
      revgrow_o20: "Boven 20%",
      revgrow_o50: "Boven 50%",
      revgrow_o100: "Boven 100%",

      netincgrow: "Netto-inkomen groei q/q",
      netincgrow_neg: "Negatief (<0%)",
      netincgrow_pos: "Positief (>0%)",
      netincgrow_u10: "Onder -10%",
      netincgrow_u20: "Onder -20%",
      netincgrow_u50: "Onder -50%",
      netincgrow_u100: "Onder -100%",
      netincgrow_o10: "Boven 10%",
      netincgrow_o20: "Boven 20%",
      netincgrow_o50: "Boven 50%",
      netincgrow_o100: "Boven 100%",

      ltdc: "LT Schulden / Capitalisatie",
      ltdc_high: "Hoog (>0.5)",
      ltdc_low: "Laag (<0.1)",
      ltdc_u1: "Onder 1",
      ltdc_u0_9: "Onder 0.9",
      ltdc_u0_8: "Onder 0.8",
      ltdc_u0_7: "Onder 0.7",
      ltdc_u0_6: "Onder 0.6",
      ltdc_u0_5: "Onder 0.5",
      ltdc_u0_4: "Onder 0.4",
      ltdc_u0_3: "Onder 0.3",
      ltdc_u0_2: "Onder 0.2",
      ltdc_u0_1: "Onder 0.1",
      ltdc_o0_1: "Boven 0.1",
      ltdc_o0_2: "Boven 0.2",
      ltdc_o0_3: "Boven 0.3",
      ltdc_o0_4: "Boven 0.4",
      ltdc_o0_5: "Boven 0.5",
      ltdc_o0_6: "Boven 0.6",
      ltdc_o0_7: "Boven 0.7",
      ltdc_o0_8: "Boven 0.8",
      ltdc_o0_9: "Boven 0.9",
      ltdc_o1: "Boven 1",

      grossMargin: "Bruto Marge",
      grossm_pos: "Positief (>0%)",
      grossm_neg: "Negatief (<0%)",
      grossm_u90: "Onder 90%",
      grossm_u70: "Onder 70%",
      grossm_u50: "Onder 50%",
      grossm_u30: "Onder 30%",
      grossm_u10: "Onder 10%",
      grossm_u5: "Onder 5%",
      grossm_u_10: "Onder -10%",
      grossm_u_30: "Onder -30%",
      grossm_u_50: "Onder -50%",
      grossm_u_70: "Onder -70%",
      grossm_u_100: "Onder -100%",
      grossm_o0: "Boven 0%",
      grossm_o10: "Boven 10%",
      grossm_o30: "Boven 30%",
      grossm_o50: "Boven 50%",
      grossm_o70: "Boven 70%",
      grossm_o90: "Boven 90%",

      deRatio: "Schuld/Eigen vermogen",
      deratio_high: "Hoog (>0.5)",
      deratio_low: "Laag (<0.1)",
      deratio_u1: "Onder 1",
      deratio_u0_9: "Onder 0.9",
      deratio_u0_8: "Onder 0.8",
      deratio_u0_7: "Onder 0.7",
      deratio_u0_6: "Onder 0.6",
      deratio_u0_5: "Onder 0.5",
      deratio_u0_4: "Onder 0.4",
      deratio_u0_3: "Onder 0.3",
      deratio_u0_2: "Onder 0.2",
      deratio_u0_1: "Onder 0.1",
      deratio_o0_1: "Boven 0.1",
      deratio_o0_2: "Boven 0.2",
      deratio_o0_3: "Boven 0.3",
      deratio_o0_4: "Boven 0.4",
      deratio_o0_5: "Boven 0.5",
      deratio_o0_6: "Boven 0.6",
      deratio_o0_7: "Boven 0.7",
      deratio_o0_8: "Boven 0.8",
      deratio_o0_9: "Boven 0.9",
      deratio_o1: "Boven 1",

      netProfitMargin: "Nettowinstmarge",
      netprf_pos: "Positief (>0%)",
      netprf_neg: "Negatief (<0%)",
      netprf_veryneg: "Zeer Negatief (<-20%)",
      netprf_high: "Hoog (>20%)",
      netprf_u90: "Onder 90%",
      netprf_u80: "Onder 80%",
      netprf_u50: "Onder 50%",
      netprf_u30: "Onder 30%",
      netprf_u10: "Onder 10%",
      netprf_u5: "Onder 5%",
      netprf_u_10: "Onder -10%",
      netprf_u_30: "Onder -30%",
      netprf_u_50: "Onder -50%",
      netprf_u_100: "Onder -100%",
      netprf_o10: "Boven 10%",
      netprf_o30: "Boven 30%",
      netprf_o40: "Boven 40%",
      netprf_o50: "Boven 50%",
      netprf_o80: "Boven 80%",
      netprf_o90: "Boven 90%",

      dividendYield: "Dividendrendement",
      divy_none: "Geen (0%)",
      divy_pos: "Positief (>0%)",
      divy_high: "Hoog (>5%)",
      divy_veryhigh: "Zeer Hoog (>10%)",
      divy_o1: "Meer dan 1%",
      divy_o2: "Meer dan 2%",
      divy_o3: "Meer dan 3%",
      divy_o4: "Meer dan 4%",
      divy_o5: "Meer dan 5%",
      divy_o6: "Meer dan 6%",
      divy_o7: "Meer dan 7%",
      divy_o8: "Meer dan 8%",
      divy_o9: "Meer dan 9%",
      divy_o10: "Meer dan 10%",

      payoutRatio: "Uitkeringsratio",
      payoutr_none: "Geen (0%)",
      payoutr_pos: "Positief (>0%)",
      payoutr_low: "Laag (<20%)",
      payoutr_high: "Hoog (>50%)",
      payoutr_o10: "Meer dan 10%",
      payoutr_o20: "Meer dan 20%",
      payoutr_o50: "Meer dan 50%",
      payoutr_o80: "Meer dan 80%",
      payoutr_o100: "Meer dan 100%",
      payoutr_u10: "Minder dan 10%",
      payoutr_u20: "Minder dan 20%",
      payoutr_u50: "Minder dan 50%",
      payoutr_u80: "Minder dan 80%",
      payoutr_u100: "Minder dan 100%",

      peHistoric: "Historische K/W",
      pehistoric_below: "Onder historisch gemiddelde",
      pehistoric_above: "Boven historisch gemiddelde",
      pbHistoric: "Historische K/B",
      pbhistoric_below: "Onder historisch gemiddelde",
      pbhistoric_above: "Boven historisch gemiddelde",
      psHistoric: "Historische K/S",
      pshistoric_below: "Onder historisch gemiddelde",
      pshistoric_above: "Boven historisch gemiddelde",
      pfcf: "Prijs/FCF",
      pfcf_low: "Laag (<15)",
      pfcf_high: "Hoog (>50)",
      pfcf_u10: "Onder 10",
      pfcf_u20: "Onder 20",
      pfcf_u50: "Onder 50",
      pfcf_u80: "Onder 80",
      pfcf_u100: "Onder 100",
      pfcf_o10: "Boven 10",
      pfcf_o20: "Boven 20",
      pfcf_o50: "Boven 50",
      pfcf_o80: "Boven 80",
      pfcf_o100: "Boven 100",

      operatingMargin: "Bedrijfsresultaatmarge",
      opmr_pos: "Positief (>0%)",
      opmr_neg: "Negatief (<0%)",
      opmr_veryneg: "Zeer negatief (<-20%)",
      opmr_high: "Hoog (>25%)",
      opmr_u90: "Onder 90%",
      opmr_u80: "Onder 80%",
      opmr_u70: "Onder 70%",
      opmr_u60: "Onder 60%",
      opmr_u50: "Onder 50%",
      opmr_u40: "Onder 40%",
      opmr_u30: "Onder 30%",
      opmr_u20: "Onder 20%",
      opmr_u10: "Onder 10%",
      opmr_u_m10: "Onder -10%",
      opmr_u_m30: "Onder -30%",
      opmr_u_m50: "Onder -50%",
      opmr_u_m100: "Onder -100%",
      opmr_o10: "Boven 10%",
      opmr_o20: "Boven 20%",
      opmr_o30: "Boven 30%",
      opmr_o40: "Boven 40%",
      opmr_o50: "Boven 50%",
      opmr_o60: "Boven 60%",
      opmr_o70: "Boven 70%",
      opmr_o80: "Boven 80%",
      opmr_o90: "Boven 90%",

      pricecash: "Prijs/Contant",
      pricecash_low: "Laag (<3)",
      pricecash_high: "Hoog (>50)",
      pricecash_u1: "Onder 1",
      pricecash_u2: "Onder 2",
      pricecash_u3: "Onder 3",
      pricecash_u4: "Onder 4",
      pricecash_u5: "Onder 5",
      pricecash_u6: "Onder 6",
      pricecash_u7: "Onder 7",
      pricecash_u8: "Onder 8",
      pricecash_u9: "Onder 9",
      pricecash_u10: "Onder 10",
      pricecash_o1: "Boven 1",
      pricecash_o2: "Boven 2",
      pricecash_o3: "Boven 3",
      pricecash_o4: "Boven 4",
      pricecash_o5: "Boven 5",
      pricecash_o6: "Boven 6",
      pricecash_o7: "Boven 7",
      pricecash_o8: "Boven 8",
      pricecash_o9: "Boven 9",
      pricecash_o10: "Boven 10",
      pricecash_o20: "Boven 20",
      pricecash_o30: "Boven 30",
      pricecash_o40: "Boven 40",
      pricecash_o50: "Boven 50",

      exchange: "Beurs",
      any: "Elke",
      nasdaq: "NASDAQ",
      nyse: "NYSE",
      amex: "AMEX",
      marketCap: "Marktkapitalisatie",
      mcap_mega: "Mega (€200 miljard en meer)",
      mcap_large: "Groot (€10 miljard tot €200 miljard)",
      mcap_mid: "Middelgroot (€2 miljard tot €10 miljard)",
      mcap_small: "Klein (€300 miljoen tot €2 miljard)",
      mcap_micro: "Micro (€50 miljoen tot €300 miljoen)",
      mcap_nano: "Nano (onder €50 miljoen)",
      mcap_largeover: "+Groot (meer dan €10 miljard)",
      mcap_midover: "+Middelgroot (meer dan €2 miljard)",
      mcap_smallover: "+Klein (meer dan €300 miljoen)",
      mcap_microover: "+Micro (meer dan €50 miljoen)",
      mcap_largeunder: "-Groot (onder €200 miljard)",
      mcap_midunder: "-Middelgroot (onder €10 miljard)",
      mcap_smallunder: "-Klein (onder €2 miljard)",
      mcap_microunder: "-Micro (onder €300 miljoen)",

      performance: "Prestatie",
      perf_dup: "Eén dag omhoog",
      perf_ddown: "Eén dag omlaag",
      perf_d15u: "Eén dag -15%",
      perf_d10u: "Eén dag -10%",
      perf_d10o: "Eén dag +10%",
      perf_d15o: "Eén dag +15%",
      perf_1w30u: "Week -30%",
      perf_1w10u: "Week -10%",
      perf_1wdown: "Week omlaag",
      perf_1wup: "Week omhoog",
      perf_1w10o: "Week +10%",
      perf_1w30o: "Week +30%",
      perf_4w50u: "Maand -50%",
      perf_4w30u: "Maand -30%",
      perf_4w10u: "Maand -10%",
      perf_4wdown: "Maand omlaag",
      perf_4wup: "Maand omhoog",
      perf_4w20o: "Maand +20%",
      perf_4w30o: "Maand +30%",
      perf_4w50o: "Maand +50%",
      perf_13w50u: "Kwartaal -50%",
      perf_13w30u: "Kwartaal -30%",
      perf_13w10u: "Kwartaal -10%",
      perf_13wdown: "Kwartaal omlaag",
      perf_13wup: "Kwartaal omhoog",
      perf_13w10o: "Kwartaal +10%",
      perf_13w30o: "Kwartaal +30%",
      perf_13w50o: "Kwartaal +50%",
      perf_26w75u: "Halfjaar -75%",
      perf_26w50u: "Halfjaar -50%",
      perf_26w20u: "Halfjaar -20%",
      perf_26w10u: "Halfjaar -10%",
      perf_26wdown: "Halfjaar omlaag",
      perf_26wup: "Halfjaar omhoog",
      perf_26w10o: "Halfjaar +10%",
      perf_26w20o: "Halfjaar +20%",
      perf_26w50o: "Halfjaar +50%",
      perf_26w100o: "Halfjaar +100%",
      perf_52w75u: "Jaar -75%",
      perf_52w50u: "Jaar -50%",
      perf_52w30u: "Jaar -30%",
      perf_52w10u: "Jaar -10%",
      perf_52wdown: "Jaar omlaag",
      perf_52wup: "Jaar omhoog",
      perf_52w10o: "Jaar +10%",
      perf_52w50o: "Jaar +50%",
      perf_52w100o: "Jaar +100%",
      perf_52w300o: "Jaar +300%",
      perf_52w500o: "Jaar +500%",
      perf_ytd75u: "Jaar tot nu toe -75%",
      perf_ytd50u: "Jaar tot nu toe -50%",
      perf_ytd30u: "Jaar tot nu toe -30%",
      perf_ytd10u: "Jaar tot nu toe -10%",
      perf_ytddown: "Jaar tot nu toe omlaag",
      perf_ytdup: "Jaar tot nu toe omhoog",
      perf_ytd10o: "Jaar tot nu toe +10%",
      perf_ytd30o: "Jaar tot nu toe +30%",
      perf_ytd50o: "Jaar tot nu toe +50%",
      perf_ytd100o: "Jaar tot nu toe +100%",

      pe: "P / E",
      pe_low: "Laag (<15)",
      pe_profitable: "Winstgevend (>0)",
      pe_high: "Hoog (>50)",
      pe_u5: "Onder 5",
      pe_u10: "Onder 10",
      pe_u15: "Onder 15",
      pe_u20: "Onder 20",
      pe_u30: "Onder 30",
      pe_u40: "Onder 40",
      pe_u50: "Onder 50",
      pe_o5: "Boven 5",
      pe_o10: "Boven 10",
      pe_o15: "Boven 15",
      pe_o20: "Boven 20",
      pe_o30: "Boven 30",
      pe_o40: "Boven 40",
      pe_o50: "Boven 50",
      peg: "PEG",
      peg_low: "Laag (<1)",
      peg_high: "Hoog (>2)",
      peg_u1: "Onder 1",
      peg_u2: "Onder 2",
      peg_u3: "Onder 3",
      peg_o1: "Boven 1",
      peg_o2: "Boven 2",
      peg_o3: "Boven 3",

      pb: "P / B",
      pb_low: "Laag (<1)",
      pb_high: "Hoog (>5)",
      pb_u1: "Onder 1",
      pb_u2: "Onder 2",
      pb_u3: "Onder 3",
      pb_u4: "Onder 4",
      pb_u5: "Onder 5",
      pb_u6: "Onder 6",
      pb_u7: "Onder 7",
      pb_u8: "Onder 8",
      pb_u9: "Onder 9",
      pb_u10: "Onder 10",
      pb_o1: "Boven 1",
      pb_o2: "Boven 2",
      pb_o3: "Boven 3",
      pb_o4: "Boven 4",
      pb_o5: "Boven 5",
      pb_o6: "Boven 6",
      pb_o7: "Boven 7",
      pb_o8: "Boven 8",
      pb_o9: "Boven 9",
      pb_o10: "Boven 10",

      ps: "P / S",
      ps_low: "Laag (<1)",
      ps_high: "Hoog (>5)",
      ps_u1: "Onder 1",
      ps_u2: "Onder 2",
      ps_u3: "Onder 3",
      ps_u4: "Onder 4",
      ps_u5: "Onder 5",
      ps_u6: "Onder 6",
      ps_u7: "Onder 7",
      ps_u8: "Onder 8",
      ps_u9: "Onder 9",
      ps_u10: "Onder 10",
      ps_o1: "Boven 1",
      ps_o2: "Boven 2",
      ps_o3: "Boven 3",
      ps_o4: "Boven 4",
      ps_o5: "Boven 5",
      ps_o6: "Boven 6",
      ps_o7: "Boven 7",
      ps_o8: "Boven 8",
      ps_o9: "Boven 9",
      ps_o10: "Boven 10",

      quick: "Snelle Ratio",
      quick_high: "Hoog (>3)",
      quick_low: "Laag (<0.5)",
      quick_u1: "Onder 1",
      quick_u0_5: "Onder 0.5",
      quick_o0_5: "Boven 0.5",
      quick_o1: "Boven 1",
      quick_o1_5: "Boven 1.5",
      quick_o2: "Boven 2",
      quick_o3: "Boven 3",
      quick_o4: "Boven 4",
      quick_o5: "Boven 5",
      quick_o10: "Boven 10",

      current: "Huidige Ratio",
      current_high: "Hoog (>3)",
      current_low: "Laag (<1)",
      current_u1: "Onder 1",
      current_u0_5: "Onder 0.5",
      current_o0_5: "Boven 0.5",
      current_o1: "Boven 1",
      current_o1_5: "Boven 1.5",
      current_o2: "Boven 2",
      current_o3: "Boven 3",
      current_o4: "Boven 4",
      current_o5: "Boven 5",
      current_o10: "Boven 10",

      sector: "Sector",
      sector_realestate: "Vastgoed",
      sector_health: "Gezondheidszorg",
      sector_energy: "Energie",
      sector_utilities: "Nutvoorzieningen",
      sector_cd: "Defensieve Consumptie",
      sector_fs: "Financiële Diensten",
      sector_coms: "Communicatiediensten",
      sector_cs: "Consumentenservices",
      sector_bm: "Basismaterialen",
      sector_i: "Industrieën",
      sector_cc: "Cyclische Consumptie",
      sector_t: "Technologie",
    },
    TopCompaniesTitle: "Grootste Bedrijven in de VS volgens",
    TotalMarketCap: "Totale Marktkapitalisatie",
    Companies: "Bedrijven",
    sortBy: "Sorteren op",
    Terms: "Algemene Voorwaarden",
    Privacy: "Privacybeleid",
    logout: "Uitloggen",
    portfolioLeader: "Portefeuilleleider",
    portfolio: {
      symbol: "Symbool",
      portfolioName: "Portefeuille Naam",
      date: "Datum",
      price: "Prijs",
      noOfShares: "Aantal Aandelen",
      buy: "Kopen",
      sellTransactions: "Verkooptransacties",
      purchasePrice: "Aankoopprijs",
      purchaseDate: "Aankoopdatum",
      priceSold: "Verkoopprijs",
      dateSold: "Verkoopdatum",
      profitAndLoss: "Winst & Verlies",
      company: "Bedrijf",
      sector: "Sector",
      currentPrice: "Huidige Prijs",
      avgPurchasePrice: "Gem. Aankoopprijs",
      totalShares: "Totaal Aandelen",
      portfolioPct: "% van de Portefeuille",
      capital: "Kapitaal",
      sell: "Verkopen",
      history: "Geschiedenis",
      pctReturns: "Rendementen %",
      name: "Naam",
      noOfCompanies: "Aantal Bedrijven",
      total: "Totaal",
    },
    login: {
      login: "Inloggen",
      signup: "Registreren",
      forgotpassword: "Wachtwoord Vergeten",
      resetpassword: "Wachtwoord Resetten",
      email: "E-mail",
      password: "Wachtwoord",
      forgotPasswordQ: "Wachtwoord vergeten?",
      createAccount: "Maak een nieuw Stock Penguins-account aan",
      firstName: "Voornaam",
      lastName: "Achternaam",
      requestOTP: "OTP Aanvragen",
      otp: "OTP",
    },
    stockScreener: "Aandelenfilter",
    topPresets: "Topvoorinstellingen",
    myPortfolio: "Mijn Portefeuille",
    portfolioLeaderboard: "Portefeuille Ranglijst",
    peHistoric: "P/E Historisch",
    SimilarCompanies: "Vergelijkbare Bedrijven",
    Stock: "Aandeel",
    presetName: "Presetnaam",
    createdBy: "Gemaakt Door",
    upvotes: "Stemmen",
    savePreset: "Preset Opslaan",
    share: "Delen",
    save: "Opslaan",
    selectPreset: "Selecteer een Preset",
    loginToSP: "Inloggen bij Stock Penguins",
    clearFilters: "Filters Wissen",

    today: "1 Dags Verandering",
    oneMonthChange: "1 Maand Verandering",
    oneYearChange: "1 Jaar Verandering",
    OneYear: "1 Jaar",
    ThreeYear: "3 Jaar",
    FiveYear: "5 Jaar",
    TenYear: "10 Jaar",
    CAGRDesc: "Historische verandering in de loop van de tijd",
    welcome: "Welkom",
    SearchBarPlaceholder: "Wat wordt jouw volgende tienvoudige winnaar?",
    Search: "Zoeken",
    Industry: "Industrie",
    Sector: "Sector",
    MarketCap: "Marktkapitalisatie",
    PE: "K/W",
    PS: "P/S",
    PB: "P/B",
    FCFYield: "FCF-rendement",
    PEGRatio: "PEG-ratio",
    OverallRisk: "Piotroski-score",
    week52HighPrice: "52-Week Hoogste Prijs",
    PriceGrowthoverthepast5Years: "Prijsstijging over de afgelopen 5 jaar",
    PriceGrowthoverthepast1Years: "Prijsstijging over het afgelopen jaar",
    Profitability: "Winstgevend? (Kwartaal)",
    GrowingRevenue: "Groeiende omzet? (Kwartaal)",
    GrowingNetProfit: "Groeiende nettowinst? (Kwartaal)",
    ProfitabilityAnnual: "Winstgevend? (Jaarlijks)",
    GrowingRevenueAnnual: "Groeiende omzet? (Jaarlijks)",
    GrowingNetProfitAnnual: "Groeiende nettowinst? (Jaarlijks)",
    FinancialMetrics: "Waarderingsoverzicht",
    BalanceSheet: "Financiële kengetallen",
    Cash: "Kasgeld",
    Debt: "Schuld",
    CashDebtRatio: "Kasgeld/Schuld-verhouding",
    DividendYield: "Dividendrendement",
    PayoutRatio: "Uitbetalingspercentage",
    FiveYearAvgDividendYield: "Gemiddeld dividendrendement over 5 jaar",
    DividendGrowthRateoverthepast5Years:
      "Dividendgroeipercentage over de afgelopen 5 jaar",
    QualityOfCompany: "Kwaliteit van het Bedrijf",
    Dividend: "Dividend",
    comparedToLastQuarter: "Vergeleken met vorig kwartaal",
    comparedToLastYear: "Vergeleken met vorig jaar",
    newTab: "Nieuw tabblad",
    compare: "Vergelijken",
    topCompanies: "Topbedrijven",
    watchlist: "Volglijst",
    rank: "Rang",
    companyName: "Bedrijfsnaam",
    symbol: "Symbool",
    marketCap: "Marktkapitalisatie",
    price: "Prijs",
    volume: "Volume",
    country: "Land",
    joinDiscord: "Word lid van Discord",
    joinTelegram: "Word lid van Telegram",
    contactUs: "Neem contact op",
    InTheLast365Days: "In de laatste 365 dagen",
    LastUpdated: "Laatst bijgewerkt",
    pages: "Pagina's",

    titles: {
      PE: "P / E",
      PS: "P / S",
      PB: "P / B",
      RevenueGrowth: "Omzetgroei %",
      NetIncomeGrowth: "Netto-inkomsten groei %",
      Price: "Prijs",
      MarketCap: "Marktkapitalisatie",
      Revenue: "Omzet",
      RevenueVsNetIncome: "Omzet vs. Netto-inkomen",
      NetIncome: "Netto-inkomen",
      Ebitda: "EBITDA",
      Eps: "WPA",
      TotalExpenses: "Totale Uitgaven",
      SharesIssued: "Uitstaande Aandelen",
      FreeCashFlow: "Vrije Kasstroom",
      OperatingCashFlow: "Operationele Kasstroom",
      Dividend: "Dividend",
      GrossMargin: "Brutomarge",
      CashVsDebt: "Kasgeld vs. Schuld",
    },
    description: {
      PE: "De koers-winstverhouding (P/E) meet hoeveel beleggers bereid zijn te betalen voor elke dollar winst van een bedrijf; berekend door de aandelenkoers te delen door de winst per aandeel. Een hoge P/E-ratio kan aangeven dat beleggers hoge toekomstige groei verwachten of dat het aandeel overgewaardeerd is",
      PS: "De koers-omzetverhouding (P/S) meet hoeveel beleggers betalen voor elke dollar omzet van het bedrijf; berekend door de aandelenkoers te delen door de omzet per aandeel. Een hoge P/S-ratio kan aangeven dat beleggers een hoge toekomstige omzetgroei verwachten of dat het aandeel overgewaardeerd is",
      PB: "De koers-boekwaarde verhouding (P/B) vergelijkt de marktwaarde van een bedrijf met de boekwaarde (activa minus passiva); berekend door de aandelenkoers te delen door de boekwaarde per aandeel. Een hoge P/B kan wijzen op groot vertrouwen van beleggers in de toekomstige prestaties van het bedrijf of dat het aandeel overgewaardeerd is als het bedrijf geen sterke activagroei heeft",
      RevenueGrowth: "Percentage omzetgroei",
      NetIncomeGrowth: "Percentage groei van de nettowinst",
      Price: "Historische aandelenkoers van het bedrijf in de loop der tijd",
      MarketCap:
        "De totale waarde van de uitstaande aandelen van een bedrijf in de loop der tijd",
      Revenue:
        "Het totale bedrag aan geld gegenereerd door bedrijfsactiviteiten, verkoop van goederen en diensten in de loop der tijd",
      RevenueVsNetIncome:
        "Een vergelijking tussen de totale omzet en de nettowinst van het bedrijf in de loop der tijd, die aangeeft hoe duurzaam de groei van het bedrijf is. Een lagere verhouding kan erop wijzen dat het bedrijf een concurrentievoordeel ontwikkelt en marktaandeel wint",
      NetIncome:
        "De winst die een bedrijf maakt na aftrek van alle kosten, belastingen en kosten van de totale omzet in de loop der tijd",
      Ebitda:
        "De winst van een bedrijf voor rente, belastingen, afschrijvingen en amortisatie in de loop der tijd. Het meet de algemene financiële prestaties en winstgevendheid van een bedrijf uit de kernactiviteiten",
      Eps: "Het deel van de winst van een bedrijf dat wordt toegewezen aan elk uitstaand gewoon aandeel. Het geeft aan hoeveel geld aandeelhouders zouden ontvangen voor elk aandeel dat ze bezitten",
      TotalExpenses:
        "Het totaal van alle kosten die een bedrijf maakt bij het uitvoeren van zijn activiteiten, inclusief operationele kosten, rente, belastingen, enz. De grafiek kan de totale uitgaven in de loop der tijd weergeven, wat trends in kosten en efficiëntie aangeeft",
      SharesIssued: "Het totale aantal uitgegeven aandelen van een bedrijf",
      FreeCashFlow:
        "De kasstroom die een bedrijf genereert na aftrek van kasuitstromen ter ondersteuning van de activiteiten en het onderhouden van kapitaalactiva. Het vertegenwoordigt het geld dat beschikbaar is voor dividenden, schuldenaflossing of herinvestering",
      OperatingCashFlow:
        "De kasstroom gegenereerd uit de bedrijfsactiviteiten van een bedrijf in de loop der tijd. Het laat zien of een bedrijf voldoende positieve kasstroom kan genereren om zijn activiteiten te behouden en uit te breiden",
      Dividend:
        "Een deel van de winst van een bedrijf dat wordt uitgekeerd aan aandeelhouders, meestal in de vorm van contante betalingen.",
      GrossMargin:
        "Het percentage van de omzet dat overblijft na aftrek van de kosten van verkochte goederen (COGS). Het laat zien hoe efficiënt een bedrijf zijn producten produceert en verkoopt",
      CashVsDebt:
        "Een vergelijkende grafiek die de kasreserves van het bedrijf toont in vergelijking met de totale schuld. Het helpt bij het beoordelen van de financiële gezondheid en liquiditeit van het bedrijf",
    },
    dataDescriptions: {
      peHistorical:
        "Het historisch gemiddelde van de K/W-verhouding over 5 jaar biedt voldoende dekking om veranderingen in markttrends en de prestaties van het bedrijf vast te leggen. Het weerspiegelt ook de nieuwste economische omstandigheden, rentetarieven en ontwikkelingen in de industrie.",
      peHistoricalCompare:
        "Als de K/W-verhouding boven het historisch gemiddelde ligt, duidt dit vaak op overwaardering en hoge groeiverwachtingen, met potentiële risico's als de groei achterblijft. Een ondergemiddelde K/W-verhouding suggereert daarentegen onderwaardering door marktpessimisme of tijdelijke groeiproblemen. Het is van cruciaal belang om economische, sector- en bedrijfsspecifieke factoren te overwegen, aangezien een groeiend bedrijf in een ontwikkelende sector hogere waarderingen kan rechtvaardigen.",
      MarketCap:
        "De totale waarde van de aandelen van een bedrijf; het wordt berekend door de aandelenkoers te vermenigvuldigen met het totale aantal aandelen",
      PE: "De koers-winstverhouding (K/W) meet hoeveel beleggers bereid zijn te betalen voor elke dollar winst van een bedrijf; het wordt berekend door de aandelenkoers te delen door de winst per aandeel. Een hoge K/W-verhouding kan erop wijzen dat beleggers een hoog toekomstig groeipercentage verwachten of dat het aandeel overgewaardeerd is",
      PS: "De koers-omzetverhouding (K/O) meet hoeveel beleggers betalen voor elke dollar van de omzet van het bedrijf; het wordt berekend door de aandelenkoers te delen door de omzet per aandeel. Een hoge K/O-verhouding kan erop wijzen dat beleggers een hoge toekomstige omzetgroei verwachten of dat het aandeel overgewaardeerd is",
      PB: "De koers-boekwaarde verhouding (K/B) is een verhouding die de marktwaarde van een bedrijf vergelijkt met de boekwaarde (activa minus passiva); het wordt berekend door de aandelenkoers te delen door de boekwaarde per aandeel. Een hoge K/B-verhouding kan wijzen op sterk vertrouwen van beleggers in de toekomstige prestaties van het bedrijf of dat het aandeel overgewaardeerd kan zijn als het bedrijf geen sterke activa-groei heeft",
      FCFYield:
        "De vrije kasstroomrendement is een maatstaf voor hoeveel vrije kasstroom een bedrijf genereert ten opzichte van zijn marktkapitalisatie; het wordt berekend door de vrije kasstroom te delen door de marktkapitalisatie",
      PEGRatio:
        "De koers/winst tot groei-verhouding (PEG) is een waarderingsmaatstaf die de K/W-verhouding van een bedrijf in verhouding tot zijn winstgroeipercentage beschouwt; het helpt te bepalen of een aandeel over- of ondergewaardeerd is. Een PEG-verhouding van 1 duidt op een faire waarde, onder 1 duidt op onderwaardering ten opzichte van de groeisnelheid, en boven 1 duidt op overwaardering ten opzichte van de groeisnelheid",
      OverallRisk:
        "Een beoordeling van hoe risicovol een investering is, rekening houdend met factoren zoals volatiliteit, financiële stabiliteit en marktomstandigheden; een lager risico is meestal beter, maar hangt af van de risicotolerantie van de belegger",
      week52HighPrice:
        "De hoogste koers die een aandeel in het afgelopen jaar heeft bereikt; een aandeel dat in de buurt van zijn 52-weeks hoogtepunt handelt, kan wijzen op een sterke recente prestatie, maar het kan ook suggereren dat het overgewaardeerd is",
      ProfitabilityAnnual:
        "Of het bedrijf het afgelopen jaar meer geld heeft verdiend dan het heeft uitgegeven; winstgevendheid is over het algemeen een goed teken van de financiële gezondheid van een bedrijf",
      GrowingRevenueAnnual:
        "Of de omzet van het bedrijf is gestegen ten opzichte van het voorgaande jaar; consistente omzetgroei is meestal een positief teken van bedrijfsuitbreiding",
      GrowingNetProfitAnnual:
        "Of de nettowinst van het bedrijf is gestegen ten opzichte van het voorgaande jaar; een groeiende nettowinst is een sterke indicator van verbetering van de financiële prestaties",
      PriceGrowthoverthepast5Years:
        "Het percentage stijging van de aandelenkoers in de afgelopen vijf jaar; een hoger percentage wijst op betere prestaties op de lange termijn",
      PriceGrowthoverthepast1Years:
        "Het percentage stijging van de aandelenkoers in het afgelopen jaar; sterke kortetermijngroei is positief, maar moet in context met andere factoren worden beschouwd",
      Cash: "Het totale bedrag aan liquide middelen dat het bedrijf direct beschikbaar heeft; meer contant geld is over het algemeen beter voor financiële flexibiliteit",
      Debt: "Het totale bedrag aan geld dat het bedrijf verschuldigd is aan schuldeisers; hogere schulden kunnen risicovol zijn als het bedrijf niet genoeg inkomsten kan genereren om het af te betalen",
      CashDebtRatio:
        "Een maatstaf voor het vermogen van een bedrijf om zijn schulden af te betalen met het beschikbare contante geld; een hogere verhouding wijst op een betere financiële gezondheid",
      Profitability:
        "Of het bedrijf in het meest recente kwartaal meer geld heeft verdiend dan het heeft uitgegeven; een kwartaalwinstgevendheid toont aan dat het bedrijf consequent inkomsten genereert",
      GrowingRevenue:
        "Of de omzet van het bedrijf is gestegen ten opzichte van het voorgaande kwartaal; voortdurende omzetgroei is een teken van aanhoudend zakelijk succes",
      GrowingNetProfit:
        "Of de nettowinst van het bedrijf is gestegen ten opzichte van het voorgaande kwartaal; een groeiende nettowinst op kwartaalbasis wijst op een verbetering van de financiële prestaties op korte termijn",
      DividendYield:
        "De jaarlijkse dividenduitkering die een bedrijf aan zijn aandeelhouders geeft, uitgedrukt als een percentage van de aandelenkoers; een hoger rendement kan wijzen op meer inkomsten uit dividenden in verhouding tot de aandelenkoers",
      PayoutRatio:
        "Het percentage van de winst van een bedrijf dat als dividend aan de aandeelhouders wordt uitgekeerd; een lagere verhouding suggereert dat het bedrijf meer winst overhoudt voor groei, terwijl een zeer hoge verhouding erop kan wijzen dat het dividend mogelijk niet houdbaar is",
      FiveYearAvgDividendYield:
        "Het gemiddelde dividendrendement dat een bedrijf in de afgelopen vijf jaar heeft aangeboden; het helpt beleggers te zien hoe consistent of stabiel de dividenduitkeringen in de loop van de tijd zijn geweest",
    },
    labels: {
      TotalCash: "Totale Kasgeld",
      TotalDebt: "Totale Schuld",
      Revenue: "Omzet",
      NetIncome: "Netto-inkomen",
    },
    Quarterly: "Kwartaal",
    Annually: "Jaarlijks",
    FullScreen: "Volledig scherm",
    ExitFullScreen: "Volledig scherm verlaten",
    homeMetaTitle: "StockPenguins | Gratis Wereldwijde Aandelen Scanner",
    homeMetaDescription:
      "Ontdek de ultieme gratis aandelen scanner app voor Amerikaanse aandelen zoals Apple, Tesla, Amazon en Microsoft. Toegang tot gedetailleerde metrics zoals Prijs, Marktkapitalisatie, Inkomsten, Nettowinst, EBITDA, Vrije Kasstroom, Winst per Aandeel, Dividend, K/W Ratio en meer. Analyseer en vergelijk aandelen moeiteloos om weloverwogen investeringsbeslissingen te nemen. Zoek nu!",
    pageMetaTitle: "Aandelen Metrics | K/W, Inkomsten & Marktkapitalisatie",
    pageMetaDescription:
      "Ontdek gratis metrics, inclusief Prijs, Marktkapitalisatie, Inkomsten, Nettowinst, EBITDA, Vrije Kasstroom, Winst per Aandeel, Dividend, K/W Ratio en meer. Blijf op de hoogte van de financiële prestaties van Apple. Verken nu!",
  },
  [LANGUAGES[6].value]: {
    // Japanese
    search: "株を検索",
    peHistorical: "5年平均P/E比率",
    peHistoricalCompare: "5年平均P/E比率の下か上か？",

    scanner: {
      industry: "業界",
      ind_aginputs: "農業資材",
      ind_indpollctrl: "産業 - 汚染と処理制御",
      ind_indmaterials: "産業材料",
      ind_autorv: "自動車 - レクリエーション車両",
      ind_discstores: "ディスカウントストア",
      ind_insdiv: "保険 - 多様化",
      ind_entertainment: "エンターテイメント",
      ind_reitdiv: "REIT - 多様化",
      ind_medicaldist: "医療 - 流通",
      ind_softinfra: "ソフトウェア - インフラ",
      ind_softapp: "ソフトウェア - アプリケーション",
      ind_electricaleqp: "電気機器と部品",
      ind_astmgmtglobal: "資産管理 - グローバル",
      ind_indpower: "独立系発電所",
      ind_oilgasdrill: "石油・ガス掘削",
      ind_leisure: "レジャー",
      ind_medicaldev: "医療 - デバイス",
      ind_coal: "石炭",
      ind_edu: "教育・訓練サービス",
      ind_reitind: "REIT - 産業",
      ind_autoparts: "自動車 - 部品",
      ind_metalfab: "製造 - 金属加工",
      ind_fincredit: "金融 - クレジットサービス",
      ind_broadcasting: "放送",
      ind_medcarefac: "医療 - ケア施設",
      ind_chemspecialty: "化学 - 専門",
      ind_astmgmt: "資産管理",
      ind_insreins: "保険 - 再保険",
      ind_travellodging: "旅行宿泊",
      ind_findiv: "金融 - 多様化",
      ind_banks: "銀行",
      ind_bevalcoholic: "飲料 - アルコール飲料",
      ind_hardwareeqp: "ハードウェア、機器と部品",
      ind_inddist: "産業 - 流通",
      ind_constrmat: "建設材料",
      ind_fooddist: "食品流通",
      ind_bevwines: "飲料 - ワイナリーと蒸留所",
      ind_furnishings: "家具、備品と家電",
      ind_silver: "銀",
      ind_specialtybiz: "専門ビジネスサービス",
      ind_apparelmanu: "衣料品 - 製造業者",
      ind_publishing: "出版",
      ind_finmortgages: "金融 - モーゲージ",
      ind_railroads: "鉄道",
      ind_insprop: "保険 - 不動産・損害保険",
      ind_telco: "通信サービス",
      ind_reitretail: "REIT - 小売",
      ind_internetcontent: "インターネットコンテンツと情報",
      ind_indinfrastructure: "産業 - インフラ運営",
      ind_regwater: "規制水",
      ind_packaging: "パッケージングとコンテナ",
      ind_realeservices: "不動産 - サービス",
      ind_reddiversified: "不動産 - 多様化",
      ind_renewutilities: "再生可能エネルギー",
      ind_preciousmetals: "その他の貴金属",
      ind_autodealerships: "自動車 - ディーラー",
      ind_foodconf: "食品コンフェクショナリー",
      ind_toolsmfg: "製造 - ツールとアクセサリー",
      ind_findata: "金融 - データと株式市場",
      ind_adagencies: "広告代理店",
      ind_luxurygoods: "高級品",
      ind_householdprod: "家庭用および個人用製品",
      ind_reitresidential: "REIT - 住宅",
      ind_deptstores: "デパート",
      ind_personalprod: "個人製品とサービス",
      ind_paperlumber: "紙、木材と森林製品",
      ind_staffing: "スタッフと雇用サービス",
      ind_resconstr: "住宅建設",
      ind_healthcare: "医療",
      ind_construction: "建設",
      ind_specialtyretail: "専門小売",
      ind_mediaentertain: "メディアとエンターテイメント",
      ind_realdev: "不動産 - 開発",
      ind_astmgmtincome: "資産管理 - 収入",
      ind_engconst: "エンジニアリングと建設",
      ind_gambling: "ギャンブル、リゾートとカジノ",
      ind_reitoffice: "REIT - オフィス",
      ind_finservices: "金融サービス",
      ind_drugmanugen: "医薬品製造業者 - 一般",
      ind_homeimprovement: "ホームインプルーブメント",
      ind_steel: "鉄鋼",
      ind_regionalbanks: "銀行 - 地域",
      ind_agfarmproducts: "農業 - 農産物",
      ind_conglomerates: "コングロマリット",
      ind_medhealthcareplans: "医療 - 医療プラン",
      ind_chemicals: "化学",
      ind_oilmidstream: "石油・ガス ミッドストリーム",
      ind_oilexp: "石油・ガス 探査と生産",
      ind_computerhardware: "コンピュータハードウェア",
      ind_banksdiversified: "銀行 - 多様化",
      ind_restaurants: "レストラン",
      ind_oilintegrated: "石油・ガス 統合",
      ind_divutilities: "多様化ユーティリティ",
      ind_reithotel: "REIT - ホテルとモーテル",
      ind_gaming: "電子ゲームとマルチメディア",
      ind_medpharma: "医療 - 医薬品",
      ind_grocerystores: "食料品店",
      ind_wastemgmt: "廃棄物管理",
      ind_reggas: "規制ガス",
      ind_trucking: "トラッキング",
      ind_investbanking: "投資 - 銀行と投資サービス",
      ind_automanufact: "自動車 - 製造業者",
      ind_reitmortgage: "REIT - モーゲージ",
      ind_biotech: "バイオテクノロジー",
      ind_techdistrib: "テクノロジー流通業者",
      ind_bizequip: "ビジネス機器と用品",
      ind_apparelfoot: "衣料品 - フットウェアとアクセサリー",
      ind_medicalspec: "医療 - 専門分野",
      ind_apparelretail: "衣料品 - 小売",
      ind_regelectric: "規制電気",
      ind_finconglomerates: "金融 - コングロマリット",
      ind_oilrefining: "石油・ガス 精製とマーケティング",
      ind_reitspecialty: "REIT - 専門",
      ind_agmachinery: "農業 - 機械",
      ind_packagedfoods: "パッケージ食品",
      ind_reithealthcare: "REIT - 医療施設",
      ind_solar: "太陽光",
      ind_copper: "銅",
      ind_gold: "金",
      ind_medicalsupp: "医療 - 器具と用品",
      ind_insurancebrokers: "保険 - ブローカー",
      ind_security: "セキュリティと保護サービス",
      ind_tobacco: "タバコ",
      ind_fincapmarkets: "金融 - キャピタルマーケット",
      ind_mfgmisc: "製造 - その他",
      ind_uranium: "ウラン",
      ind_insurancelife: "保険 - 生命",
      ind_indmachinery: "産業 - 機械",
      ind_shellcompanies: "シェル会社",
      ind_consumelectronics: "コンシューマーエレクトロニクス",
      ind_medicaldiag: "医療 - 診断と研究",
      ind_oilequip: "石油・ガス 機器とサービス",
      ind_investmanaging: "投資管理",
      ind_broadband: "ブロードバンド",
      ind_gasprod: "ガス生産",
      ind_officeproducts: "オフィス用品",
      ind_coalpower: "石炭発電",
      ind_shoes: "靴",
      ind_coffeebeans: "コーヒー豆",
      ind_solarenergy: "太陽エネルギー",
      ind_banking: "銀行業",
      ind_malls: "モール",
      ind_realstate: "不動産",
      ind_vacationresorts: "バケーションリゾート",
      ind_brewery: "ブルワリー",
      ind_vegan: "ビーガン",
      ind_commequip: "通信機器",
      ind_aerospace: "航空宇宙・防衛",
      ind_travelservices: "旅行サービス",
      ind_drugmanuspecialty: "医薬品製造業者 - 専門および一般",
      ind_aluminum: "アルミニウム",
      ind_itservices: "情報技術サービス",
      ind_medhealthinfo: "医療 - ヘルスケア情報サービス",
      ind_bevnonalcoholic: "飲料 - 非アルコール",
      ind_consulting: "コンサルティングサービス",
      ind_rentleasing: "レンタルおよびリースサービス",
      ind_marineshipping: "海運",
      ind_software: "ソフトウェア - サービス",
      ind_astmgmtbonds: "資産管理 - 債券",
      ind_freightlogistics: "統合貨物および物流",
      ind_insspec: "保険 - 専門",
      ind_semiconductors: "半導体",
      ind_airlines: "航空会社、空港および航空サービス",

      revgrowavg: "過去5年間の収益成長",
      revgrowavg_neg: "負 (<0%)",
      revgrowavg_pos: "正 (>0%)",
      revgrowavg_u10: "-10%未満",
      revgrowavg_u20: "-20%未満",
      revgrowavg_u50: "-50%未満",
      revgrowavg_u100: "-100%未満",
      revgrowavg_o10: "10%以上",
      revgrowavg_o20: "20%以上",
      revgrowavg_o50: "50%以上",
      revgrowavg_o100: "100%以上",

      revgrow: "収益成長 q/q",
      revgrow_neg: "マイナス (<0%)",
      revgrow_pos: "プラス (>0%)",
      revgrow_u10: "10%未満",
      revgrow_u20: "20%未満",
      revgrow_u50: "50%未満",
      revgrow_u100: "100%未満",
      revgrow_o10: "10%以上",
      revgrow_o20: "20%以上",
      revgrow_o50: "50%以上",
      revgrow_o100: "100%以上",

      netincgrow: "純利益成長 q/q",
      netincgrow_neg: "マイナス (<0%)",
      netincgrow_pos: "プラス (>0%)",
      netincgrow_u10: "10%未満",
      netincgrow_u20: "20%未満",
      netincgrow_u50: "50%未満",
      netincgrow_u100: "100%未満",
      netincgrow_o10: "10%以上",
      netincgrow_o20: "20%以上",
      netincgrow_o50: "50%以上",
      netincgrow_o100: "100%以上",

      ltdc: "長期負債 / 資本化",
      ltdc_high: "高 (>0.5)",
      ltdc_low: "低 (<0.1)",
      ltdc_u1: "1未満",
      ltdc_u0_9: "0.9未満",
      ltdc_u0_8: "0.8未満",
      ltdc_u0_7: "0.7未満",
      ltdc_u0_6: "0.6未満",
      ltdc_u0_5: "0.5未満",
      ltdc_u0_4: "0.4未満",
      ltdc_u0_3: "0.3未満",
      ltdc_u0_2: "0.2未満",
      ltdc_u0_1: "0.1未満",
      ltdc_o0_1: "0.1超",
      ltdc_o0_2: "0.2超",
      ltdc_o0_3: "0.3超",
      ltdc_o0_4: "0.4超",
      ltdc_o0_5: "0.5超",
      ltdc_o0_6: "0.6超",
      ltdc_o0_7: "0.7超",
      ltdc_o0_8: "0.8超",
      ltdc_o0_9: "0.9超",
      ltdc_o1: "1超",

      grossMargin: "粗利益率",
      grossm_pos: "正 (>0%)",
      grossm_neg: "負 (<0%)",
      grossm_u90: "90%未満",
      grossm_u70: "70%未満",
      grossm_u50: "50%未満",
      grossm_u30: "30%未満",
      grossm_u10: "10%未満",
      grossm_u5: "5%未満",
      grossm_u_10: "-10%未満",
      grossm_u_30: "-30%未満",
      grossm_u_50: "-50%未満",
      grossm_u_70: "-70%未満",
      grossm_u_100: "-100%未満",
      grossm_o0: "0%以上",
      grossm_o10: "10%以上",
      grossm_o30: "30%以上",
      grossm_o50: "50%以上",
      grossm_o70: "70%以上",
      grossm_o90: "90%以上",

      deRatio: "負債/資本",
      deratio_high: "高い (>0.5)",
      deratio_low: "低い (<0.1)",
      deratio_u1: "1未満",
      deratio_u0_9: "0.9未満",
      deratio_u0_8: "0.8未満",
      deratio_u0_7: "0.7未満",
      deratio_u0_6: "0.6未満",
      deratio_u0_5: "0.5未満",
      deratio_u0_4: "0.4未満",
      deratio_u0_3: "0.3未満",
      deratio_u0_2: "0.2未満",
      deratio_u0_1: "0.1未満",
      deratio_o0_1: "0.1以上",
      deratio_o0_2: "0.2以上",
      deratio_o0_3: "0.3以上",
      deratio_o0_4: "0.4以上",
      deratio_o0_5: "0.5以上",
      deratio_o0_6: "0.6以上",
      deratio_o0_7: "0.7以上",
      deratio_o0_8: "0.8以上",
      deratio_o0_9: "0.9以上",
      deratio_o1: "1以上",

      netProfitMargin: "純利益率",
      netprf_pos: "プラス (>0%)",
      netprf_neg: "マイナス (<0%)",
      netprf_veryneg: "非常にマイナス (<-20%)",
      netprf_high: "高い (>20%)",
      netprf_u90: "90%未満",
      netprf_u80: "80%未満",
      netprf_u50: "50%未満",
      netprf_u30: "30%未満",
      netprf_u10: "10%未満",
      netprf_u5: "5%未満",
      netprf_u_10: "-10%未満",
      netprf_u_30: "-30%未満",
      netprf_u_50: "-50%未満",
      netprf_u_100: "-100%未満",
      netprf_o10: "10%以上",
      netprf_o30: "30%以上",
      netprf_o40: "40%以上",
      netprf_o50: "50%以上",
      netprf_o80: "80%以上",
      netprf_o90: "90%以上",

      dividendYield: "配当利回り",
      divy_none: "なし (0%)",
      divy_pos: "プラス (>0%)",
      divy_high: "高い (>5%)",
      divy_veryhigh: "非常に高い (>10%)",
      divy_o1: "1%以上",
      divy_o2: "2%以上",
      divy_o3: "3%以上",
      divy_o4: "4%以上",
      divy_o5: "5%以上",
      divy_o6: "6%以上",
      divy_o7: "7%以上",
      divy_o8: "8%以上",
      divy_o9: "9%以上",
      divy_o10: "10%以上",

      payoutRatio: "配当性向",
      payoutr_none: "なし (0%)",
      payoutr_pos: "プラス (>0%)",
      payoutr_low: "低い (<20%)",
      payoutr_high: "高い (>50%)",
      payoutr_o10: "10%以上",
      payoutr_o20: "20%以上",
      payoutr_o50: "50%以上",
      payoutr_o80: "80%以上",
      payoutr_o100: "100%以上",
      payoutr_u10: "10%未満",
      payoutr_u20: "20%未満",
      payoutr_u50: "50%未満",
      payoutr_u80: "80%未満",
      payoutr_u100: "100%未満",

      peHistoric: "P/E履歴",
      pehistoric_below: "歴史的平均以下",
      pehistoric_above: "歴史的平均以上",
      pbHistoric: "P/B履歴",
      pbhistoric_below: "歴史的平均以下",
      pbhistoric_above: "歴史的平均以上",
      psHistoric: "P/S履歴",
      pshistoric_below: "歴史的平均以下",
      pshistoric_above: "歴史的平均以上",
      pfcf: "価格/FCF",
      pfcf_low: "低い (<15)",
      pfcf_high: "高い (>50)",
      pfcf_u10: "10未満",
      pfcf_u20: "20未満",
      pfcf_u50: "50未満",
      pfcf_u80: "80未満",
      pfcf_u100: "100未満",
      pfcf_o10: "10以上",
      pfcf_o20: "20以上",
      pfcf_o50: "50以上",
      pfcf_o80: "80以上",
      pfcf_o100: "100以上",

      operatingMargin: "営業利益率",
      opmr_pos: "正 (>0%)",
      opmr_neg: "負 (<0%)",
      opmr_veryneg: "非常に悪い (<-20%)",
      opmr_high: "高い (>25%)",
      opmr_u90: "90%以下",
      opmr_u80: "80%以下",
      opmr_u70: "70%以下",
      opmr_u60: "60%以下",
      opmr_u50: "50%以下",
      opmr_u40: "40%以下",
      opmr_u30: "30%以下",
      opmr_u20: "20%以下",
      opmr_u10: "10%以下",
      opmr_u_m10: "-10%以下",
      opmr_u_m30: "-30%以下",
      opmr_u_m50: "-50%以下",
      opmr_u_m100: "-100%以下",
      opmr_o10: "10%以上",
      opmr_o20: "20%以上",
      opmr_o30: "30%以上",
      opmr_o40: "40%以上",
      opmr_o50: "50%以上",
      opmr_o60: "60%以上",
      opmr_o70: "70%以上",
      opmr_o80: "80%以上",
      opmr_o90: "90%以上",

      pricecash: "価格/現金",
      pricecash_low: "低 (<3)",
      pricecash_high: "高 (>50)",
      pricecash_u1: "1以下",
      pricecash_u2: "2以下",
      pricecash_u3: "3以下",
      pricecash_u4: "4以下",
      pricecash_u5: "5以下",
      pricecash_u6: "6以下",
      pricecash_u7: "7以下",
      pricecash_u8: "8以下",
      pricecash_u9: "9以下",
      pricecash_u10: "10以下",
      pricecash_o1: "1以上",
      pricecash_o2: "2以上",
      pricecash_o3: "3以上",
      pricecash_o4: "4以上",
      pricecash_o5: "5以上",
      pricecash_o6: "6以上",
      pricecash_o7: "7以上",
      pricecash_o8: "8以上",
      pricecash_o9: "9以上",
      pricecash_o10: "10以上",
      pricecash_o20: "20以上",
      pricecash_o30: "30以上",
      pricecash_o40: "40以上",
      pricecash_o50: "50以上",

      exchange: "取引所",
      any: "任意",
      nasdaq: "ナスダック",
      nyse: "ニューヨーク証券取引所",
      amex: "アメリカン証券取引所",
      marketCap: "時価総額",
      mcap_mega: "メガ (2000億ドル以上)",
      mcap_large: "大型 (100億ドルから2000億ドル)",
      mcap_mid: "中型 (20億ドルから100億ドル)",
      mcap_small: "小型 (3億ドルから20億ドル)",
      mcap_micro: "マイクロ (5000万ドルから3億ドル)",
      mcap_nano: "ナノ (5000万ドル未満)",
      mcap_largeover: "大型以上 (100億ドル超)",
      mcap_midover: "中型以上 (20億ドル超)",
      mcap_smallover: "小型以上 (3億ドル超)",
      mcap_microover: "マイクロ以上 (5000万ドル超)",
      mcap_largeunder: "大型未満 (2000億ドル未満)",
      mcap_midunder: "中型未満 (100億ドル未満)",
      mcap_smallunder: "小型未満 (20億ドル未満)",
      mcap_microunder: "マイクロ未満 (3億ドル未満)",

      performance: "パフォーマンス",
      perf_dup: "1日上昇",
      perf_ddown: "1日下落",
      perf_d15u: "1日 -15%",
      perf_d10u: "1日 -10%",
      perf_d10o: "1日 +10%",
      perf_d15o: "1日 +15%",
      perf_1w30u: "1週間 -30%",
      perf_1w10u: "1週間 -10%",
      perf_1wdown: "1週間下落",
      perf_1wup: "1週間上昇",
      perf_1w10o: "1週間 +10%",
      perf_1w30o: "1週間 +30%",
      perf_4w50u: "1か月 -50%",
      perf_4w30u: "1か月 -30%",
      perf_4w10u: "1か月 -10%",
      perf_4wdown: "1か月下落",
      perf_4wup: "1か月上昇",
      perf_4w20o: "1か月 +20%",
      perf_4w30o: "1か月 +30%",
      perf_4w50o: "1か月 +50%",
      perf_13w50u: "四半期 -50%",
      perf_13w30u: "四半期 -30%",
      perf_13w10u: "四半期 -10%",
      perf_13wdown: "四半期下落",
      perf_13wup: "四半期上昇",
      perf_13w10o: "四半期 +10%",
      perf_13w30o: "四半期 +30%",
      perf_13w50o: "四半期 +50%",
      perf_26w75u: "半年 -75%",
      perf_26w50u: "半年 -50%",
      perf_26w20u: "半年 -20%",
      perf_26w10u: "半年 -10%",
      perf_26wdown: "半年下落",
      perf_26wup: "半年上昇",
      perf_26w10o: "半年 +10%",
      perf_26w20o: "半年 +20%",
      perf_26w50o: "半年 +50%",
      perf_26w100o: "半年 +100%",
      perf_52w75u: "1年 -75%",
      perf_52w50u: "1年 -50%",
      perf_52w30u: "1年 -30%",
      perf_52w10u: "1年 -10%",
      perf_52wdown: "1年下落",
      perf_52wup: "1年上昇",
      perf_52w10o: "1年 +10%",
      perf_52w50o: "1年 +50%",
      perf_52w100o: "1年 +100%",
      perf_52w300o: "1年 +300%",
      perf_52w500o: "1年 +500%",
      perf_ytd75u: "年初来 -75%",
      perf_ytd50u: "年初来 -50%",
      perf_ytd30u: "年初来 -30%",
      perf_ytd10u: "年初来 -10%",
      perf_ytddown: "年初来下落",
      perf_ytdup: "年初来上昇",
      perf_ytd10o: "年初来 +10%",
      perf_ytd30o: "年初来 +30%",
      perf_ytd50o: "年初来 +50%",
      perf_ytd100o: "年初来 +100%",

      pe: "P / E (株価収益率)",
      pe_low: "低い (<15)",
      pe_profitable: "利益あり (>0)",
      pe_high: "高い (>50)",
      pe_u5: "5以下",
      pe_u10: "10以下",
      pe_u15: "15以下",
      pe_u20: "20以下",
      pe_u30: "30以下",
      pe_u40: "40以下",
      pe_u50: "50以下",
      pe_o5: "5以上",
      pe_o10: "10以上",
      pe_o15: "15以上",
      pe_o20: "20以上",
      pe_o30: "30以上",
      pe_o40: "40以上",
      pe_o50: "50以上",
      peg: "PEG (株価収益成長率)",
      peg_low: "低い (<1)",
      peg_high: "高い (>2)",
      peg_u1: "1以下",
      peg_u2: "2以下",
      peg_u3: "3以下",
      peg_o1: "1以上",
      peg_o2: "2以上",
      peg_o3: "3以上",

      pb: "P / B",
      pb_low: "低い (<1)",
      pb_high: "高い (>5)",
      pb_u1: "1未満",
      pb_u2: "2未満",
      pb_u3: "3未満",
      pb_u4: "4未満",
      pb_u5: "5未満",
      pb_u6: "6未満",
      pb_u7: "7未満",
      pb_u8: "8未満",
      pb_u9: "9未満",
      pb_u10: "10未満",
      pb_o1: "1以上",
      pb_o2: "2以上",
      pb_o3: "3以上",
      pb_o4: "4以上",
      pb_o5: "5以上",
      pb_o6: "6以上",
      pb_o7: "7以上",
      pb_o8: "8以上",
      pb_o9: "9以上",
      pb_o10: "10以上",

      ps: "P / S",
      ps_low: "低い (<1)",
      ps_high: "高い (>5)",
      ps_u1: "1未満",
      ps_u2: "2未満",
      ps_u3: "3未満",
      ps_u4: "4未満",
      ps_u5: "5未満",
      ps_u6: "6未満",
      ps_u7: "7未満",
      ps_u8: "8未満",
      ps_u9: "9未満",
      ps_u10: "10未満",
      ps_o1: "1以上",
      ps_o2: "2以上",
      ps_o3: "3以上",
      ps_o4: "4以上",
      ps_o5: "5以上",
      ps_o6: "6以上",
      ps_o7: "7以上",
      ps_o8: "8以上",
      ps_o9: "9以上",
      ps_o10: "10以上",

      quick: "クイック比率",
      quick_high: "高 (>3)",
      quick_low: "低 (<0.5)",
      quick_u1: "1未満",
      quick_u0_5: "0.5未満",
      quick_o0_5: "0.5超",
      quick_o1: "1超",
      quick_o1_5: "1.5超",
      quick_o2: "2超",
      quick_o3: "3超",
      quick_o4: "4超",
      quick_o5: "5超",
      quick_o10: "10超",

      current: "流動比率",
      current_high: "高 (>3)",
      current_low: "低 (<1)",
      current_u1: "1未満",
      current_u0_5: "0.5未満",
      current_o0_5: "0.5超",
      current_o1: "1超",
      current_o1_5: "1.5超",
      current_o2: "2超",
      current_o3: "3超",
      current_o4: "4超",
      current_o5: "5超",
      current_o10: "10超",

      sector: "セクター",
      sector_realestate: "不動産",
      sector_health: "ヘルスケア",
      sector_energy: "エネルギー",
      sector_utilities: "公益事業",
      sector_cd: "消費者防御",
      sector_fs: "金融サービス",
      sector_coms: "通信サービス",
      sector_cs: "消費者サービス",
      sector_bm: "基礎材料",
      sector_i: "工業",
      sector_cc: "消費者循環",
      sector_t: "テクノロジー",
    },

    TopCompaniesTitle: "米国最大の企業",
    TotalMarketCap: "総時価総額",
    Companies: "企業",
    sortBy: "並び替え",
    Terms: "利用規約",
    Privacy: "プライバシーポリシー",
    logout: "ログアウト",
    portfolioLeader: "ポートフォリオリーダー",
    portfolio: {
      symbol: "シンボル",
      portfolioName: "ポートフォリオ名",
      date: "日付",
      price: "価格",
      noOfShares: "株数",
      buy: "購入",
      sellTransactions: "売却取引",
      purchasePrice: "購入価格",
      purchaseDate: "購入日",
      priceSold: "売却価格",
      dateSold: "売却日",
      profitAndLoss: "損益",
      company: "会社",
      sector: "セクター",
      currentPrice: "現在の価格",
      avgPurchasePrice: "平均購入価格",
      totalShares: "株式数",
      portfolioPct: "ポートフォリオの割合",
      capital: "資本",
      sell: "売却",
      history: "履歴",
      pctReturns: "リターン %",
      name: "名前",
      noOfCompanies: "会社数",
      total: "合計",
    },
    login: {
      login: "ログイン",
      signup: "登録",
      forgotpassword: "パスワードを忘れましたか",
      resetpassword: "パスワードをリセット",
      email: "メール",
      password: "パスワード",
      forgotPasswordQ: "パスワードをお忘れですか？",
      createAccount: "新しい Stock Penguins アカウントを作成",
      firstName: "名前",
      lastName: "苗字",
      requestOTP: "OTPをリクエスト",
      otp: "OTP",
    },
    stockScreener: "株式スクリーナー",
    topPresets: "トッププリセット",
    myPortfolio: "私のポートフォリオ",
    portfolioLeaderboard: "ポートフォリオリーダーボード",
    peHistoric: "P/E 過去データ",
    SimilarCompanies: "類似会社",
    Stock: "株式",
    presetName: "プリセット名",
    createdBy: "作成者",
    upvotes: "投票数",
    savePreset: "プリセットを保存",
    share: "共有",
    save: "保存",
    selectPreset: "プリセットを選択",
    loginToSP: "Stock Penguinsにログイン",
    clearFilters: "フィルターをクリア",

    today: "1日変動",
    oneMonthChange: "1ヶ月変動",
    oneYearChange: "1年変動",
    OneYear: "1 年",
    ThreeYear: "3 年",
    FiveYear: "5 年",
    TenYear: "10 年",
    CAGRDesc: "過去の推移",
    welcome: "ようこそ",
    SearchBarPlaceholder: "次のテンバガーは何になる？",
    Search: "検索",
    Industry: "産業",
    Sector: "セクター",
    MarketCap: "時価総額",
    PE: "P / E",
    PS: "P / S",
    PB: "P / B",
    FCFYield: "FCF利回り",
    PEGRatio: "PEGレシオ",
    OverallRisk: "ピオトロスキスコア",
    week52HighPrice: "52週間高値",
    PriceGrowthoverthepast5Years: "過去5年間の株価成長率",
    PriceGrowthoverthepast1Years: "過去1年間の株価成長率",
    Profitability: "収益性（四半期）",
    GrowingRevenue: "売上高成長（四半期）",
    GrowingNetProfit: "純利益成長（四半期）",
    ProfitabilityAnnual: "収益性（年次）",
    GrowingRevenueAnnual: "売上高成長（年次）",
    GrowingNetProfitAnnual: "純利益成長（年次）",
    BalanceSheet: "財務メトリクス",
    Cash: "現金",
    Debt: "債務",
    CashDebtRatio: "現金・債務比率",
    DividendYield: "配当利回り",
    PayoutRatio: "配当性向",
    FiveYearAvgDividendYield: "過去5年間の平均配当利回り",
    DividendGrowthRateoverthepast5Years: "過去5年間の配当成長率",
    FinancialMetrics: "財務指標",
    QualityOfCompany: "企業の質",
    Dividend: "配当",
    comparedToLastQuarter: "前四半期と比較して",
    comparedToLastYear: "前年と比較して",
    newTab: "新しいタブ",
    compare: "比較",
    topCompanies: "トップ企業",
    watchlist: "ウォッチリスト",
    rank: "ランク",
    companyName: "会社名",
    symbol: "シンボル",
    marketCap: "時価総額",
    price: "価格",
    volume: "出来高",
    country: "国",
    joinDiscord: "Discordに参加",
    joinTelegram: "Telegramに参加",
    contactUs: "お問い合わせ",
    InTheLast365Days: "過去365日間で",
    LastUpdated: "最終更新",
    pages: "ページ",

    titles: {
      PE: "P / E",
      PS: "P / S",
      PB: "P / B",
      RevenueGrowth: "収益成長 %",
      NetIncomeGrowth: "純利益成長 %",
      Price: "価格",
      MarketCap: "時価総額",
      Revenue: "収益",
      RevenueVsNetIncome: "収益対純利益",
      NetIncome: "純利益",
      Ebitda: "EBITDA",
      Eps: "EPS",
      TotalExpenses: "総費用",
      SharesIssued: "発行済株式数",
      FreeCashFlow: "フリーキャッシュフロー",
      OperatingCashFlow: "営業キャッシュフロー",
      Dividend: "配当",
      GrossMargin: "粗利益率",
      CashVsDebt: "現金対債務",
    },
    description: {
      PE: "株価収益率 (P/E) は、投資家が企業の利益1ドルに対してどれだけ支払う意思があるかを測定する指標であり、株価を1株当たり利益で割ることで計算されます。P/E比率が高い場合、投資家が将来の高い成長率を期待しているか、株式が過大評価されている可能性があります",
      PS: "株価売上高比率 (P/S) は、投資家が企業の売上1ドルに対してどれだけ支払っているかを測定する指標であり、株価を1株当たり売上高で割ることで計算されます。P/S比率が高い場合、投資家が将来の売上高の成長を期待しているか、株式が過大評価されている可能性があります",
      PB: "株価純資産倍率 (P/B) は、企業の市場価値をその帳簿価値 (資産から負債を引いた値) と比較する比率です。株価を1株当たり帳簿価値で割ることで計算されます。P/B比率が高い場合、投資家が企業の将来のパフォーマンスに強い信頼を寄せているか、資産の成長が強くない場合には株式が過大評価されている可能性があります",
      RevenueGrowth: "収益成長率",
      NetIncomeGrowth: "純利益成長率",
      Price: "企業の株価の推移",
      MarketCap: "企業の発行済株式の総価値の推移",
      Revenue: "企業活動、商品・サービスの販売から得られる総収入の推移",
      RevenueVsNetIncome:
        "企業の総収入と純利益の比較で、企業の成長の持続可能性を示します。比率が低いほど、企業が競争優位を築き、市場シェアを拡大している可能性があります",
      NetIncome:
        "総収入からすべての費用、税金、コストを差し引いた後の企業の利益の推移",
      Ebitda:
        "利息、税金、減価償却費および償却費前の企業の収益の推移。企業の主要な業務からの全体的な財務業績と収益性を測定します",
      Eps: "発行済の普通株式1株あたりに配分される企業の利益の部分。株主が所有する各株式に対してどれだけのお金を受け取るかを示します",
      TotalExpenses:
        "企業が事業を行う上で発生するすべての費用の合計。営業費用、利息、税金などが含まれます。グラフは総費用の推移を示し、費用の傾向と効率を示します",
      SharesIssued: "企業の発行株式総数",
      FreeCashFlow:
        "運転資金と資本資産の維持に必要な現金流出を差し引いた後の企業が生成する現金。配当、債務返済、または再投資に利用可能なお金を表します",
      OperatingCashFlow:
        "企業の事業活動から生じるキャッシュフローの推移。企業が事業を維持・拡大するために十分な正のキャッシュフローを生成できるかどうかを示します",
      Dividend: "企業の利益の一部が株主に分配され、通常は現金で支払われます",
      GrossMargin:
        "売上総利益率。売上総利益（COGS）を差し引いた後の収益の割合。企業が製品を効率的に生産・販売しているかを示します",
      CashVsDebt:
        "企業の現金準備金と総債務の比較グラフ。企業の財務状況と流動性を評価するのに役立ちます",
    },
    dataDescriptions: {
      peHistorical:
        "過去5年間の平均P/E比率は、市場動向や企業業績の変化を捉えるのに十分な期間を提供します。また、最新の経済状況、金利、業界の発展も反映しています。",
      peHistoricalCompare:
        "P/E比率が過去の平均を上回っている場合、それはしばしば過大評価と高成長期待を示しており、成長が予想を下回った場合にはリスクが伴います。逆に、平均以下のP/E比率は、市場の悲観論や一時的な成長問題による過小評価を示唆します。経済、業界、および企業固有の要因を考慮することが重要であり、成長中の業界にある企業はより高い評価を正当化する可能性があります。",
      MarketCap:
        "会社の株式の総価値で、株価に発行済株式数を掛けることで計算されます",
      PE: "株価収益率 (P/E) は、投資家が会社の利益の1ドルに対して支払う意欲がある額を測定します。株価を1株あたりの利益で割って計算します。高いP/E比率は、投資家が高い将来の成長率を期待しているか、または株が過大評価されている可能性があることを示す場合があります",
      PS: "株価売上高比率 (P/S) は、投資家が会社の売上高の1ドルに対して支払う額を測定します。株価を1株あたりの売上高で割って計算します。高いP/S比率は、投資家が高い将来の売上成長を期待しているか、または株が過大評価されている可能性があることを示す場合があります",
      PB: "株価純資産比率 (P/B) は、会社の市場価値を帳簿価値 (資産から負債を引いた額) と比較する比率です。株価を1株あたりの帳簿価値で割って計算します。高いP/B比率は、会社の将来のパフォーマンスに対する強い投資家の信頼を示すか、会社が強い資産成長を持っていない場合は株が過大評価されている可能性があります",
      FCFYield:
        "フリーキャッシュフロー利回りは、会社が生成するフリーキャッシュフローを市場価値に対して測定する指標です。フリーキャッシュフローを市場価値で割って計算します",
      PEGRatio:
        "株価収益成長比率 (PEG) は、会社のP/E比率を利益成長率と比較する評価指標です。これにより、株が過大評価または過小評価されているかを確認できます。PEG比率が1の場合は公正価値を示し、1未満は成長率に対して過小評価、1を超える場合は成長率に対して過大評価を示します",
      OverallRisk:
        "投資がどれだけリスクがあるかの評価で、ボラティリティ、財務の安定性、市場の状況などの要素を考慮します。リスクが低いほど通常は良いですが、投資家のリスク許容度によります",
      week52HighPrice:
        "過去1年間における株の最高価格で、52週間の高値に近い株は強い最近のパフォーマンスを示す場合がありますが、過大評価されている可能性もあります",
      ProfitabilityAnnual:
        "会社が過去1年間に費やした額よりも多くの利益を上げたかどうか。利益率は一般的に会社の財務健全性の良い兆候です",
      GrowingRevenueAnnual:
        "会社の売上高が前年度と比べて増加したかどうか。継続的な売上高の成長は通常、ビジネスの拡張を示すポジティブなサインです",
      GrowingNetProfitAnnual:
        "会社の純利益 (費用後の収入) が前年度と比べて増加したかどうか。増加する純利益は、財務パフォーマンスの改善を示す強い指標です",
      PriceGrowthoverthepast5Years:
        "過去5年間の株価のパーセンテージ増加で、より高いパーセンテージは長期的なパフォーマンスの向上を示します",
      PriceGrowthoverthepast1Years:
        "過去1年間の株価のパーセンテージ増加で、強い短期成長はポジティブですが、他の要素とともに考慮する必要があります",
      Cash: "会社が即座に使用できる流動資金の総額で、より多くの現金は一般的に財務の柔軟性を向上させます",
      Debt: "会社が貸し手に対して負っている総額で、高い負債は会社が十分な収益を生み出せない場合にリスクが伴います",
      CashDebtRatio:
        "会社が手元の現金で負債を返済する能力を測定する指標で、高い比率はより良い財務健全性を示します",
      Profitability:
        "会社が最も最近の四半期に費やした額よりも多くの利益を上げたかどうか。四半期ごとの利益は、会社が一貫して収益を生み出していることを示します",
      GrowingRevenue:
        "会社の売上高が前の四半期と比べて増加したかどうか。継続的な売上高の増加は、ビジネスの成功を示すサインです",
      GrowingNetProfit:
        "会社の純利益が前の四半期と比べて増加したかどうか。四半期ごとの純利益の増加は、短期的な財務パフォーマンスの改善を示します",
      DividendYield:
        "会社が株主に支払う年間配当で、株価のパーセンテージとして表されます。高い利回りは、株価に対してより多くの配当収入を示す場合があります",
      PayoutRatio:
        "会社の利益の中で株主に支払われる配当の割合で、低い比率は会社がより多くの利益を成長のために留保していることを示し、非常に高い比率は配当が持続不可能である可能性を示します",
      FiveYearAvgDividendYield:
        "過去5年間にわたる会社の平均配当利回りで、投資家が配当支払いの一貫性や安定性を確認するのに役立ちます",
    },
    labels: {
      TotalCash: "総現金",
      TotalDebt: "総債務",
      Revenue: "収益",
      RevenueVsNetIncome: "収益対純利益",
      NetIncome: "純利益",
    },
    Quarterly: "四半期ごと",
    Annually: "年次",
    FullScreen: "フルスクリーン",
    ExitFullScreen: "フルスクリーンを終了",
    homeMetaTitle: "StockPenguins | 無料のグローバル株式スキャナー",
    homeMetaDescription:
      "Apple、Tesla、Amazon、Microsoftなどの米国株のための究極の無料株式スキャナーアプリを発見しましょう。価格、市場価値、収益、純利益、EBITDA、フリーキャッシュフロー、EPS、配当、P/Eレシオなどの詳細な指標にアクセスできます。株式を簡単に分析および比較して、情報に基づいた投資判断を下しましょう。今すぐ検索！",
    pageMetaTitle: "株式指標 | P/E、収益、市場価値",
    pageMetaDescription:
      "価格、市場価値、収益、純利益、EBITDA、フリーキャッシュフロー、EPS、配当、P/Eレシオなど、無料の指標を発見しましょう。Appleの財務状況についての情報を把握しましょう。今すぐ探索！",
  },
  [LANGUAGES[7].value]: {
    // Italian
    search: "Cerca Azioni",
    peHistorical: "Rapporto P/E medio storico a 5 anni",
    peHistoricalCompare:
      "Sotto o sopra il rapporto P/E medio storico a 5 anni?",
    scanner: {
      industry: "Settore",
      ind_aginputs: "Input Agricoli",
      ind_indpollctrl:
        "Industriale - Controllo dell'Inquinamento e Trattamento",
      ind_indmaterials: "Materiali Industriali",
      ind_autorv: "Auto - Veicoli Ricreativi",
      ind_discstores: "Negozi di Sconto",
      ind_insdiv: "Assicurazione - Diversificata",
      ind_entertainment: "Intrattenimento",
      ind_reitdiv: "REIT - Diversificato",
      ind_medicaldist: "Medico - Distribuzione",
      ind_softinfra: "Software - Infrastruttura",
      ind_softapp: "Software - Applicazione",
      ind_electricaleqp: "Apparecchiature Elettriche e Parti",
      ind_astmgmtglobal: "Gestione Patrimoniale - Globale",
      ind_indpower: "Produttori di Energia Indipendenti",
      ind_oilgasdrill: "Estrazione di Petrolio e Gas",
      ind_leisure: "Tempo Libero",
      ind_medicaldev: "Medico - Dispositivi",
      ind_coal: "Carbone",
      ind_edu: "Servizi di Istruzione e Formazione",
      ind_reitind: "REIT - Industriale",
      ind_autoparts: "Auto - Parti",
      ind_metalfab: "Manifattura - Lavorazione dei Metalli",
      ind_fincredit: "Finanza - Servizi di Credito",
      ind_broadcasting: "Broadcasting",
      ind_medcarefac: "Medico - Strutture di Cura",
      ind_chemspecialty: "Chimica - Specialità",
      ind_astmgmt: "Gestione Patrimoniale",
      ind_insreins: "Assicurazione - Riassicurazione",
      ind_travellodging: "Alloggio Turistico",
      ind_findiv: "Finanza - Diversificata",
      ind_banks: "Banche",
      ind_bevalcoholic: "Bevande - Alcoliche",
      ind_hardwareeqp: "Hardware, Attrezzature e Parti",
      ind_inddist: "Industriale - Distribuzione",
      ind_constrmat: "Materiali da Costruzione",
      ind_fooddist: "Distribuzione Alimentare",
      ind_bevwines: "Bevande - Cantine e Distillerie",
      ind_furnishings: "Arredamenti, Fissi e Apparecchi",
      ind_silver: "Argento",
      ind_specialtybiz: "Servizi per Attività Specialistiche",
      ind_apparelmanu: "Abbigliamento - Fabbricanti",
      ind_publishing: "Editoria",
      ind_finmortgages: "Finanza - Mutui",
      ind_railroads: "Ferrovie",
      ind_insprop: "Assicurazione - Proprietà e Responsabilità",
      ind_telco: "Servizi di Telecomunicazione",
      ind_reitretail: "REIT - Vendita al Dettaglio",
      ind_internetcontent: "Contenuti e Informazioni Internet",
      ind_indinfrastructure: "Industriale - Operazioni Infrastrutturali",
      ind_regwater: "Acqua Regolamentata",
      ind_packaging: "Imballaggio e Contenitori",
      ind_realeservices: "Immobiliare - Servizi",
      ind_reddiversified: "Immobiliare - Diversificato",
      ind_renewutilities: "Utilità Rinnovabili",
      ind_preciousmetals: "Altri Metalli Preziosi",
      ind_autodealerships: "Auto - Concessionarie",
      ind_foodconf: "Confezionatori di Alimenti",
      ind_toolsmfg: "Manifattura - Strumenti e Accessori",
      ind_findata: "Finanza - Dati e Borse Valori",
      ind_adagencies: "Agenzie Pubblicitarie",
      ind_luxurygoods: "Beni di Lusso",
      ind_householdprod: "Prodotti per la Casa e Personali",
      ind_reitresidential: "REIT - Residenziale",
      ind_deptstores: "Negozi di Dipartimento",
      ind_personalprod: "Prodotti e Servizi Personali",
      ind_paperlumber: "Carta, Legname e Prodotti Forestali",
      ind_staffing: "Servizi di Assunzione e Occupazione",
      ind_resconstr: "Costruzione Residenziale",
      ind_healthcare: "Assistenza Sanitaria",
      ind_construction: "Costruzione",
      ind_specialtyretail: "Vendita al Dettaglio Specializzata",
      ind_mediaentertain: "Media e Intrattenimento",
      ind_realdev: "Immobiliare - Sviluppo",
      ind_astmgmtincome: "Gestione Patrimoniale - Reddito",
      ind_engconst: "Ingegneria e Costruzione",
      ind_gambling: "Gioco, Resort e Casinò",
      ind_reitoffice: "REIT - Ufficio",
      ind_finservices: "Servizi Finanziari",
      ind_drugmanugen: "Fabbricanti di Farmaci - Generale",
      ind_homeimprovement: "Miglioramento della Casa",
      ind_steel: "Acciaio",
      ind_regionalbanks: "Banche - Regionali",
      ind_agfarmproducts: "Prodotti Agricoli da Fattoria",
      ind_conglomerates: "Conglomerati",
      ind_medhealthcareplans: "Medico - Piani di Assistenza Sanitaria",
      ind_chemicals: "Chimica",
      ind_oilmidstream: "Petrolio e Gas Midstream",
      ind_oilexp: "Esplorazione e Produzione di Petrolio e Gas",
      ind_computerhardware: "Hardware per Computer",
      ind_banksdiversified: "Banche - Diversificate",
      ind_restaurants: "Ristoranti",
      ind_oilintegrated: "Petrolio e Gas Integrati",
      ind_divutilities: "Utilità Diversificate",
      ind_reithotel: "REIT - Hotel e Motel",
      ind_gaming: "Giochi Elettronici e Multimedia",
      ind_medpharma: "Medico - Farmaceutici",
      ind_grocerystores: "Negozi di Generi Alimentari",
      ind_wastemgmt: "Gestione dei Rifiuti",
      ind_reggas: "Gas Regolamentato",
      ind_trucking: "Trasporti",
      ind_investbanking: "Investimenti - Servizi Bancari e di Investimento",
      ind_automanufact: "Auto - Fabbricanti",
      ind_reitmortgage: "REIT - Mutui",
      ind_biotech: "Biotecnologia",
      ind_techdistrib: "Distributori di Tecnologia",
      ind_bizequip: "Attrezzature e Forniture per Aziende",
      ind_apparelfoot: "Abbigliamento - Calzature e Accessori",
      ind_medicalspec: "Medico - Specialità",
      ind_apparelretail: "Abbigliamento - Vendita al Dettaglio",
      ind_regelectric: "Elettricità Regolamentata",
      ind_finconglomerates: "Finanza - Conglomerati",
      ind_oilrefining: "Raffinazione e Commercializzazione di Petrolio e Gas",
      ind_reitspecialty: "REIT - Specialità",
      ind_agmachinery: "Agricoltura - Macchinari",
      ind_packagedfoods: "Alimenti Confezionati",
      ind_reithealthcare: "REIT - Strutture Sanitarie",
      ind_solar: "Solare",
      ind_copper: "Rame",
      ind_gold: "Oro",
      ind_medicalsupp: "Medico - Strumenti e Forniture",
      ind_insurancebrokers: "Assicurazione - Broker",
      ind_security: "Sicurezza e Servizi di Protezione",
      ind_tobacco: "Tabacco",
      ind_fincapmarkets: "Finanza - Mercati dei Capitali",
      ind_mfgmisc: "Manifattura - Varie",
      ind_uranium: "Uranio",
      ind_insurancelife: "Assicurazione - Vita",
      ind_indmachinery: "Industriale - Macchinari",
      ind_shellcompanies: "Società di Facciata",
      ind_consumelectronics: "Elettronica di Consumo",
      ind_medicaldiag: "Medico - Diagnostica e Ricerca",
      ind_specretail: "Vendita al Dettaglio Specializzata",
      ind_reitmortgages: "REIT - Mutui",
      ind_insurancelifehealth: "Assicurazione - Vita e Sanitaria",
      ind_biomed: "Biomedicina",
      ind_utilities: "Utilità",
      ind_meatprocessing: "Lavorazione della Carne",
      ind_drugspecialty: "Farmaci - Specialità",
      ind_woodproducts: "Prodotti in Legno",
      ind_friservices: "Servizi Finanziari",
      ind_insurancehealth: "Assicurazione - Sanitaria",
      ind_mining: "Miniera",
      ind_fish: "Pesca",
      ind_foodprod: "Prodotti Alimentari",
      ind_investfund: "Fondi di Investimento",
      ind_tourism: "Turismo",
      ind_biotechhealth: "Biotecnologia - Sanitaria",
      ind_constrservice: "Servizi di Costruzione",
      ind_specialtyins: "Assicurazione - Specialità",
      ind_serviceprovid: "Fornitura di Servizi",
      ind_gas: "Gas",
      ind_gasdrilling: "Gas - Estrazione",
      ind_consprod: "Beni di Consumo",
      ind_insreinshealth: "Assicurazione - Riassicurazione Sanitaria",
      ind_telecom: "Telecomunicazioni",
      ind_gasutilities: "Gas - Utilità",
      ind_travel: "Viaggi",
      ind_law: "Legge",
      ind_electronics: "Elettronica",
      ind_materialsandmfg: "Materiali e Manifattura",
      ind_biomedical: "Biomedico",
      ind_insurancespecialty: "Assicurazione - Specialità",
      ind_newtech: "Nuove Tecnologie",
      ind_oilequip: "Attrezzature e Servizi per Petrolio e Gas",
      ind_commequip: "Attrezzature per la Comunicazione",
      ind_aerospace: "Aerospaziale e Difesa",
      ind_travelservices: "Servizi di Viaggio",
      ind_drugmanuspecialty: "Fabbricanti di Farmaci - Specialità e Generici",
      ind_aluminum: "Alluminio",
      ind_itservices: "Servizi di Tecnologia dell'Informazione",
      ind_medhealthinfo: "Medico - Servizi di Informazione Sanitaria",
      ind_bevnonalcoholic: "Bevande - Non Alcoliche",
      ind_consulting: "Servizi di Consulenza",
      ind_rentleasing: "Servizi di Noleggio e Leasing",
      ind_marineshipping: "Navigazione Marittima",
      ind_software: "Software - Servizi",
      ind_astmgmtbonds: "Gestione Patrimoniale - Obbligazioni",
      ind_freightlogistics: "Logistica e Trasporto Integrato",
      ind_insspec: "Assicurazione - Specialità",
      ind_semiconductors: "Semiconduttori",
      ind_airlines: "Compagnie Aeree, Aeroporti e Servizi Aerei",

      revgrowavg: "Crescita dei Ricavi negli Ultimi 5 Anni",
      revgrowavg_neg: "Negativo (<0%)",
      revgrowavg_pos: "Positivo (>0%)",
      revgrowavg_u10: "Sotto -10%",
      revgrowavg_u20: "Sotto -20%",
      revgrowavg_u50: "Sotto -50%",
      revgrowavg_u100: "Sotto -100%",
      revgrowavg_o10: "Sopra 10%",
      revgrowavg_o20: "Sopra 20%",
      revgrowavg_o50: "Sopra 50%",
      revgrowavg_o100: "Sopra 100%",

      revgrow: "Crescita dei Ricavi q/q",
      revgrow_neg: "Negativa (<0%)",
      revgrow_pos: "Positiva (>0%)",
      revgrow_u10: "Sotto -10%",
      revgrow_u20: "Sotto -20%",
      revgrow_u50: "Sotto -50%",
      revgrow_u100: "Sotto -100%",
      revgrow_o10: "Oltre 10%",
      revgrow_o20: "Oltre 20%",
      revgrow_o50: "Oltre 50%",
      revgrow_o100: "Oltre 100%",

      netincgrow: "Crescita del Reddito Netto q/q",
      netincgrow_neg: "Negativa (<0%)",
      netincgrow_pos: "Positiva (>0%)",
      netincgrow_u10: "Sotto -10%",
      netincgrow_u20: "Sotto -20%",
      netincgrow_u50: "Sotto -50%",
      netincgrow_u100: "Sotto -100%",
      netincgrow_o10: "Oltre 10%",
      netincgrow_o20: "Oltre 20%",
      netincgrow_o50: "Oltre 50%",
      netincgrow_o100: "Oltre 100%",

      ltdc: "Debito a Lungo Termine / Capitalizzazione",
      ltdc_high: "Alto (>0.5)",
      ltdc_low: "Basso (<0.1)",
      ltdc_u1: "Sotto 1",
      ltdc_u0_9: "Sotto 0.9",
      ltdc_u0_8: "Sotto 0.8",
      ltdc_u0_7: "Sotto 0.7",
      ltdc_u0_6: "Sotto 0.6",
      ltdc_u0_5: "Sotto 0.5",
      ltdc_u0_4: "Sotto 0.4",
      ltdc_u0_3: "Sotto 0.3",
      ltdc_u0_2: "Sotto 0.2",
      ltdc_u0_1: "Sotto 0.1",
      ltdc_o0_1: "Sopra 0.1",
      ltdc_o0_2: "Sopra 0.2",
      ltdc_o0_3: "Sopra 0.3",
      ltdc_o0_4: "Sopra 0.4",
      ltdc_o0_5: "Sopra 0.5",
      ltdc_o0_6: "Sopra 0.6",
      ltdc_o0_7: "Sopra 0.7",
      ltdc_o0_8: "Sopra 0.8",
      ltdc_o0_9: "Sopra 0.9",
      ltdc_o1: "Sopra 1",

      grossMargin: "Margine Lordo",
      grossm_pos: "Positivo (>0%)",
      grossm_neg: "Negativo (<0%)",
      grossm_u90: "Sotto 90%",
      grossm_u70: "Sotto 70%",
      grossm_u50: "Sotto 50%",
      grossm_u30: "Sotto 30%",
      grossm_u10: "Sotto 10%",
      grossm_u5: "Sotto 5%",
      grossm_u_10: "Sotto -10%",
      grossm_u_30: "Sotto -30%",
      grossm_u_50: "Sotto -50%",
      grossm_u_70: "Sotto -70%",
      grossm_u_100: "Sotto -100%",
      grossm_o0: "Sopra 0%",
      grossm_o10: "Sopra 10%",
      grossm_o30: "Sopra 30%",
      grossm_o50: "Sopra 50%",
      grossm_o70: "Sopra 70%",
      grossm_o90: "Sopra 90%",

      deRatio: "Debito/Capitale",
      deratio_high: "Alto (>0.5)",
      deratio_low: "Basso (<0.1)",
      deratio_u1: "Sotto 1",
      deratio_u0_9: "Sotto 0.9",
      deratio_u0_8: "Sotto 0.8",
      deratio_u0_7: "Sotto 0.7",
      deratio_u0_6: "Sotto 0.6",
      deratio_u0_5: "Sotto 0.5",
      deratio_u0_4: "Sotto 0.4",
      deratio_u0_3: "Sotto 0.3",
      deratio_u0_2: "Sotto 0.2",
      deratio_u0_1: "Sotto 0.1",
      deratio_o0_1: "Sopra 0.1",
      deratio_o0_2: "Sopra 0.2",
      deratio_o0_3: "Sopra 0.3",
      deratio_o0_4: "Sopra 0.4",
      deratio_o0_5: "Sopra 0.5",
      deratio_o0_6: "Sopra 0.6",
      deratio_o0_7: "Sopra 0.7",
      deratio_o0_8: "Sopra 0.8",
      deratio_o0_9: "Sopra 0.9",
      deratio_o1: "Sopra 1",

      netProfitMargin: "Margine di Profitto Netto",
      netprf_pos: "Positivo (>0%)",
      netprf_neg: "Negativo (<0%)",
      netprf_veryneg: "Molto Negativo (<-20%)",
      netprf_high: "Alto (>20%)",
      netprf_u90: "Sotto 90%",
      netprf_u80: "Sotto 80%",
      netprf_u50: "Sotto 50%",
      netprf_u30: "Sotto 30%",
      netprf_u10: "Sotto 10%",
      netprf_u5: "Sotto 5%",
      netprf_u_10: "Sotto -10%",
      netprf_u_30: "Sotto -30%",
      netprf_u_50: "Sotto -50%",
      netprf_u_100: "Sotto -100%",
      netprf_o10: "Sopra 10%",
      netprf_o30: "Sopra 30%",
      netprf_o40: "Sopra 40%",
      netprf_o50: "Sopra 50%",
      netprf_o80: "Sopra 80%",
      netprf_o90: "Sopra 90%",

      dividendYield: "Rendimento da Dividendi",
      divy_none: "Nessuno (0%)",
      divy_pos: "Positivo (>0%)",
      divy_high: "Alto (>5%)",
      divy_veryhigh: "Molto Alto (>10%)",
      divy_o1: "Superiore all'1%",
      divy_o2: "Superiore al 2%",
      divy_o3: "Superiore al 3%",
      divy_o4: "Superiore al 4%",
      divy_o5: "Superiore al 5%",
      divy_o6: "Superiore al 6%",
      divy_o7: "Superiore al 7%",
      divy_o8: "Superiore all'8%",
      divy_o9: "Superiore al 9%",
      divy_o10: "Superiore al 10%",

      payoutRatio: "Tasso di Distribuzione",
      payoutr_none: "Nessuno (0%)",
      payoutr_pos: "Positivo (>0%)",
      payoutr_low: "Basso (<20%)",
      payoutr_high: "Alto (>50%)",
      payoutr_o10: "Superiore al 10%",
      payoutr_o20: "Superiore al 20%",
      payoutr_o50: "Superiore al 50%",
      payoutr_o80: "Superiore all'80%",
      payoutr_o100: "Superiore al 100%",
      payoutr_u10: "Inferiore al 10%",
      payoutr_u20: "Inferiore al 20%",
      payoutr_u50: "Inferiore al 50%",
      payoutr_u80: "Inferiore all'80%",
      payoutr_u100: "Inferiore al 100%",

      peHistoric: "P/E Storico",
      pehistoric_below: "Sotto la media storica",
      pehistoric_above: "Sopra la media storica",
      pbHistoric: "P/B Storico",
      pbhistoric_below: "Sotto la media storica",
      pbhistoric_above: "Sopra la media storica",
      psHistoric: "P/S Storico",
      pshistoric_below: "Sotto la media storica",
      pshistoric_above: "Sopra la media storica",
      pfcf: "Prezzo/FCF",
      pfcf_low: "Basso (<15)",
      pfcf_high: "Alto (>50)",
      pfcf_u10: "Sotto 10",
      pfcf_u20: "Sotto 20",
      pfcf_u50: "Sotto 50",
      pfcf_u80: "Sotto 80",
      pfcf_u100: "Sotto 100",
      pfcf_o10: "Sopra 10",
      pfcf_o20: "Sopra 20",
      pfcf_o50: "Sopra 50",
      pfcf_o80: "Sopra 80",
      pfcf_o100: "Sopra 100",

      operatingMargin: "Margine operativo",
      opmr_pos: "Positivo (>0%)",
      opmr_neg: "Negativo (<0%)",
      opmr_veryneg: "Molto negativo (<-20%)",
      opmr_high: "Alto (>25%)",
      opmr_u90: "Sotto il 90%",
      opmr_u80: "Sotto l'80%",
      opmr_u70: "Sotto il 70%",
      opmr_u60: "Sotto il 60%",
      opmr_u50: "Sotto il 50%",
      opmr_u40: "Sotto il 40%",
      opmr_u30: "Sotto il 30%",
      opmr_u20: "Sotto il 20%",
      opmr_u10: "Sotto il 10%",
      opmr_u_m10: "Sotto -10%",
      opmr_u_m30: "Sotto -30%",
      opmr_u_m50: "Sotto -50%",
      opmr_u_m100: "Sotto -100%",
      opmr_o10: "Sopra il 10%",
      opmr_o20: "Sopra il 20%",
      opmr_o30: "Sopra il 30%",
      opmr_o40: "Sopra il 40%",
      opmr_o50: "Sopra il 50%",
      opmr_o60: "Sopra il 60%",
      opmr_o70: "Sopra il 70%",
      opmr_o80: "Sopra l'80%",
      opmr_o90: "Sopra il 90%",

      pricecash: "Prezzo/Contanti",
      pricecash_low: "Basso (<3)",
      pricecash_high: "Alto (>50)",
      pricecash_u1: "Inferiore a 1",
      pricecash_u2: "Inferiore a 2",
      pricecash_u3: "Inferiore a 3",
      pricecash_u4: "Inferiore a 4",
      pricecash_u5: "Inferiore a 5",
      pricecash_u6: "Inferiore a 6",
      pricecash_u7: "Inferiore a 7",
      pricecash_u8: "Inferiore a 8",
      pricecash_u9: "Inferiore a 9",
      pricecash_u10: "Inferiore a 10",
      pricecash_o1: "Superiore a 1",
      pricecash_o2: "Superiore a 2",
      pricecash_o3: "Superiore a 3",
      pricecash_o4: "Superiore a 4",
      pricecash_o5: "Superiore a 5",
      pricecash_o6: "Superiore a 6",
      pricecash_o7: "Superiore a 7",
      pricecash_o8: "Superiore a 8",
      pricecash_o9: "Superiore a 9",
      pricecash_o10: "Superiore a 10",
      pricecash_o20: "Superiore a 20",
      pricecash_o30: "Superiore a 30",
      pricecash_o40: "Superiore a 40",
      pricecash_o50: "Superiore a 50",

      exchange: "Borsa",
      any: "Qualsiasi",
      nasdaq: "NASDAQ",
      nyse: "NYSE",
      amex: "AMEX",
      marketCap: "Capitalizzazione di mercato",
      mcap_mega: "Mega (€200 miliardi e oltre)",
      mcap_large: "Grande (€10 miliardi a €200 miliardi)",
      mcap_mid: "Medio (€2 miliardi a €10 miliardi)",
      mcap_small: "Piccolo (€300 milioni a €2 miliardi)",
      mcap_micro: "Micro (€50 milioni a €300 milioni)",
      mcap_nano: "Nano (meno di €50 milioni)",
      mcap_largeover: "+Grande (oltre €10 miliardi)",
      mcap_midover: "+Medio (oltre €2 miliardi)",
      mcap_smallover: "+Piccolo (oltre €300 milioni)",
      mcap_microover: "+Micro (oltre €50 milioni)",
      mcap_largeunder: "-Grande (meno di €200 miliardi)",
      mcap_midunder: "-Medio (meno di €10 miliardi)",
      mcap_smallunder: "-Piccolo (meno di €2 miliardi)",
      mcap_microunder: "-Micro (meno di €300 milioni)",

      performance: "Performance",
      perf_dup: "Un Giorno Su",
      perf_ddown: "Un Giorno Giù",
      perf_d15u: "Un Giorno -15%",
      perf_d10u: "Un Giorno -10%",
      perf_d10o: "Un Giorno +10%",
      perf_d15o: "Un Giorno +15%",
      perf_1w30u: "Settimana -30%",
      perf_1w10u: "Settimana -10%",
      perf_1wdown: "Settimana Giù",
      perf_1wup: "Settimana Su",
      perf_1w10o: "Settimana +10%",
      perf_1w30o: "Settimana +30%",
      perf_4w50u: "Mese -50%",
      perf_4w30u: "Mese -30%",
      perf_4w10u: "Mese -10%",
      perf_4wdown: "Mese Giù",
      perf_4wup: "Mese Su",
      perf_4w20o: "Mese +20%",
      perf_4w30o: "Mese +30%",
      perf_4w50o: "Mese +50%",
      perf_13w50u: "Trimestre -50%",
      perf_13w30u: "Trimestre -30%",
      perf_13w10u: "Trimestre -10%",
      perf_13wdown: "Trimestre Giù",
      perf_13wup: "Trimestre Su",
      perf_13w10o: "Trimestre +10%",
      perf_13w30o: "Trimestre +30%",
      perf_13w50o: "Trimestre +50%",
      perf_26w75u: "Semestre -75%",
      perf_26w50u: "Semestre -50%",
      perf_26w20u: "Semestre -20%",
      perf_26w10u: "Semestre -10%",
      perf_26wdown: "Semestre Giù",
      perf_26wup: "Semestre Su",
      perf_26w10o: "Semestre +10%",
      perf_26w20o: "Semestre +20%",
      perf_26w50o: "Semestre +50%",
      perf_26w100o: "Semestre +100%",
      perf_52w75u: "Anno -75%",
      perf_52w50u: "Anno -50%",
      perf_52w30u: "Anno -30%",
      perf_52w10u: "Anno -10%",
      perf_52wdown: "Anno Giù",
      perf_52wup: "Anno Su",
      perf_52w10o: "Anno +10%",
      perf_52w50o: "Anno +50%",
      perf_52w100o: "Anno +100%",
      perf_52w300o: "Anno +300%",
      perf_52w500o: "Anno +500%",
      perf_ytd75u: "Da Inizio Anno -75%",
      perf_ytd50u: "Da Inizio Anno -50%",
      perf_ytd30u: "Da Inizio Anno -30%",
      perf_ytd10u: "Da Inizio Anno -10%",
      perf_ytddown: "Da Inizio Anno Giù",
      perf_ytdup: "Da Inizio Anno Su",
      perf_ytd10o: "Da Inizio Anno +10%",
      perf_ytd30o: "Da Inizio Anno +30%",
      perf_ytd50o: "Da Inizio Anno +50%",
      perf_ytd100o: "Da Inizio Anno +100%",

      pe: "P / E",
      pe_low: "Basso (<15)",
      pe_profitable: "Redditizio (>0)",
      pe_high: "Alto (>50)",
      pe_u5: "Inferiore a 5",
      pe_u10: "Inferiore a 10",
      pe_u15: "Inferiore a 15",
      pe_u20: "Inferiore a 20",
      pe_u30: "Inferiore a 30",
      pe_u40: "Inferiore a 40",
      pe_u50: "Inferiore a 50",
      pe_o5: "Superiore a 5",
      pe_o10: "Superiore a 10",
      pe_o15: "Superiore a 15",
      pe_o20: "Superiore a 20",
      pe_o30: "Superiore a 30",
      pe_o40: "Superiore a 40",
      pe_o50: "Superiore a 50",
      peg: "PEG",
      peg_low: "Basso (<1)",
      peg_high: "Alto (>2)",
      peg_u1: "Inferiore a 1",
      peg_u2: "Inferiore a 2",
      peg_u3: "Inferiore a 3",
      peg_o1: "Superiore a 1",
      peg_o2: "Superiore a 2",
      peg_o3: "Superiore a 3",

      pb: "P / B",
      pb_low: "Basso (<1)",
      pb_high: "Alto (>5)",
      pb_u1: "Inferiore a 1",
      pb_u2: "Inferiore a 2",
      pb_u3: "Inferiore a 3",
      pb_u4: "Inferiore a 4",
      pb_u5: "Inferiore a 5",
      pb_u6: "Inferiore a 6",
      pb_u7: "Inferiore a 7",
      pb_u8: "Inferiore a 8",
      pb_u9: "Inferiore a 9",
      pb_u10: "Inferiore a 10",
      pb_o1: "Superiore a 1",
      pb_o2: "Superiore a 2",
      pb_o3: "Superiore a 3",
      pb_o4: "Superiore a 4",
      pb_o5: "Superiore a 5",
      pb_o6: "Superiore a 6",
      pb_o7: "Superiore a 7",
      pb_o8: "Superiore a 8",
      pb_o9: "Superiore a 9",
      pb_o10: "Superiore a 10",

      ps: "P / S",
      ps_low: "Basso (<1)",
      ps_high: "Alto (>5)",
      ps_u1: "Inferiore a 1",
      ps_u2: "Inferiore a 2",
      ps_u3: "Inferiore a 3",
      ps_u4: "Inferiore a 4",
      ps_u5: "Inferiore a 5",
      ps_u6: "Inferiore a 6",
      ps_u7: "Inferiore a 7",
      ps_u8: "Inferiore a 8",
      ps_u9: "Inferiore a 9",
      ps_u10: "Inferiore a 10",
      ps_o1: "Superiore a 1",
      ps_o2: "Superiore a 2",
      ps_o3: "Superiore a 3",
      ps_o4: "Superiore a 4",
      ps_o5: "Superiore a 5",
      ps_o6: "Superiore a 6",
      ps_o7: "Superiore a 7",
      ps_o8: "Superiore a 8",
      ps_o9: "Superiore a 9",
      ps_o10: "Superiore a 10",

      quick: "Rapporto Rapido",
      quick_high: "Alto (>3)",
      quick_low: "Basso (<0.5)",
      quick_u1: "Sotto 1",
      quick_u0_5: "Sotto 0.5",
      quick_o0_5: "Sopra 0.5",
      quick_o1: "Sopra 1",
      quick_o1_5: "Sopra 1.5",
      quick_o2: "Sopra 2",
      quick_o3: "Sopra 3",
      quick_o4: "Sopra 4",
      quick_o5: "Sopra 5",
      quick_o10: "Sopra 10",

      current: "Rapporto Corrente",
      current_high: "Alto (>3)",
      current_low: "Basso (<1)",
      current_u1: "Sotto 1",
      current_u0_5: "Sotto 0.5",
      current_o0_5: "Sopra 0.5",
      current_o1: "Sopra 1",
      current_o1_5: "Sopra 1.5",
      current_o2: "Sopra 2",
      current_o3: "Sopra 3",
      current_o4: "Sopra 4",
      current_o5: "Sopra 5",
      current_o10: "Sopra 10",

      sector: "Settore",
      sector_realestate: "Immobiliare",
      sector_health: "Sanità",
      sector_energy: "Energia",
      sector_utilities: "Servizi Pubblici",
      sector_cd: "Difesa del Consumatore",
      sector_fs: "Servizi Finanziari",
      sector_coms: "Servizi di Comunicazione",
      sector_cs: "Servizi ai Consumatori",
      sector_bm: "Materiali di Base",
      sector_i: "Industria",
      sector_cc: "Ciclico del Consumatore",
      sector_t: "Tecnologia",
    },

    TopCompaniesTitle: "Le Più Grandi Aziende degli USA per",
    TotalMarketCap: "Capitalizzazione di Mercato Totale",
    Companies: "Aziende",
    sortBy: "Ordina per",
    Terms: "Termini e Condizioni",
    Privacy: "Politica sulla Privacy",
    logout: "Disconnettersi",
    portfolioLeader: "Leader del Portafoglio",
    portfolio: {
      symbol: "Simbolo",
      portfolioName: "Nome del Portafoglio",
      date: "Data",
      price: "Prezzo",
      noOfShares: "Numero di Azioni",
      buy: "Acquista",
      sellTransactions: "Transazioni di Vendita",
      purchasePrice: "Prezzo d'Acquisto",
      purchaseDate: "Data d'Acquisto",
      priceSold: "Prezzo Venduto",
      dateSold: "Data Vendita",
      profitAndLoss: "Profitto & Perdita",
      company: "Azienda",
      sector: "Settore",
      currentPrice: "Prezzo Attuale",
      avgPurchasePrice: "Prezzo d'Acquisto Medio",
      totalShares: "Azioni Totali",
      portfolioPct: "% del Portafoglio",
      capital: "Capitale",
      sell: "Vendi",
      history: "Storia",
      pctReturns: "Rendimenti %",
      name: "Nome",
      noOfCompanies: "N. di Aziende",
      total: "Totale",
    },

    login: {
      login: "Accedi",
      signup: "Registrati",
      forgotpassword: "Password Dimenticata",
      resetpassword: "Reimposta Password",
      email: "Email",
      password: "Password",
      forgotPasswordQ: "Hai dimenticato la password?",
      createAccount: "Crea un nuovo account Stock Penguins",
      firstName: "Nome",
      lastName: "Cognome",
      requestOTP: "Richiedi OTP",
      otp: "OTP",
    },
    stockScreener: "Selezionatore di Azioni",
    topPresets: "Migliori Preimpostazioni",
    myPortfolio: "Il Mio Portafoglio",
    portfolioLeaderboard: "Classifica del Portafoglio",
    peHistoric: "P/E Storico",
    SimilarCompanies: "Aziende Simili",
    Stock: "Azione",
    presetName: "Nome Preset",
    createdBy: "Creato Da",
    upvotes: "Voti Positivi",
    savePreset: "Salva Preset",
    share: "Condividi",
    save: "Salva",
    selectPreset: "Seleziona un Preset",
    loginToSP: "Accedi a Stock Penguins",
    clearFilters: "Cancella Filtri",

    today: "Variazione di 1 Giorno",
    oneMonthChange: "Variazione di 1 Mese",
    oneYearChange: "Variazione di 1 Anno",
    OneYear: "1 Anno",
    ThreeYear: "3 Anni",
    FiveYear: "5 Anni",
    TenYear: "10 Anni",
    CAGRDesc: "Cambiamento storico nel tempo",
    welcome: "Benvenuto",
    SearchBarPlaceholder: "Quale sarà il tuo prossimo 10 bagger?",
    Search: "Cerca",
    Industry: "Industria",
    Sector: "Settore",
    MarketCap: "Capitalizzazione di Mercato",
    PE: "P / E",
    PS: "P / S",
    PB: "P / B",
    FCFYield: "Rendimento del FCF",
    PEGRatio: "Rapporto PEG",
    OverallRisk: "Punteggio Piotroski",
    week52HighPrice: "Prezzo Massimo delle 52 Settimane",
    PriceGrowthoverthepast5Years: "Crescita del Prezzo negli Ultimi 5 Anni",
    PriceGrowthoverthepast1Years: "Crescita del Prezzo nell'Ultimo Anno",
    Profitability: "Redditività? (Trimestrale)",
    GrowingRevenue: "Crescita dei ricavi? (Trimestrale)",
    GrowingNetProfit: "Crescita del profitto netto? (Trimestrale)",
    ProfitabilityAnnual: "Redditività? (Annuale)",
    GrowingRevenueAnnual: "Crescita dei ricavi? (Annuale)",
    GrowingNetProfitAnnual: "Crescita del profitto netto? (Annuale)",
    FinancialMetrics: "Sintesi della valutazione",
    BalanceSheet: "Indicatori finanziari",
    Cash: "Cassa",
    Debt: "Debito",
    CashDebtRatio: "Rapporto Cassa/Debito",
    DividendYield: "Rendimento del Dividendo",
    PayoutRatio: "Rapporto di Pagamento",
    FiveYearAvgDividendYield:
      "Rendimento Medio del Dividendo negli Ultimi 5 Anni",
    DividendGrowthRateoverthepast5Years:
      "Tasso di Crescita del Dividendo negli Ultimi 5 Anni",
    QualityOfCompany: "Qualità dell'Azienda",
    Dividend: "Dividendo",
    comparedToLastQuarter: "Rispetto all'ultimo trimestre",
    comparedToLastYear: "Rispetto all'anno scorso",
    newTab: "Nuova scheda",
    compare: "Confronta",
    topCompanies: "Aziende migliori",
    watchlist: "Lista di controllo",
    rank: "Classifica",
    companyName: "Nome dell'azienda",
    symbol: "Simbolo",
    marketCap: "Capitalizzazione di mercato",
    price: "Prezzo",
    volume: "Volume",
    country: "Paese",
    joinDiscord: "Unisciti a Discord",
    joinTelegram: "Unisciti a Telegram",
    contactUs: "Contattaci",
    InTheLast365Days: "Negli ultimi 365 giorni",
    LastUpdated: "Ultimo aggiornamento",
    pages: "Pagine",

    titles: {
      PE: "P / E",
      PS: "P / S",
      PB: "P / B",
      RevenueGrowth: "Crescita dei ricavi %",
      NetIncomeGrowth: "Crescita del reddito netto %",
      Price: "Prezzo",
      MarketCap: "Capitalizzazione di Mercato",
      Revenue: "Entrate",
      RevenueVsNetIncome: "Entrate vs. Reddito Netto",
      NetIncome: "Reddito Netto",
      Ebitda: "EBITDA",
      Eps: "EPS",
      TotalExpenses: "Spese Totali",
      SharesIssued: "Azioni in circolazione",
      FreeCashFlow: "Flusso di Cassa Libero",
      OperatingCashFlow: "Flusso di Cassa Operativo",
      Dividend: "Dividendo",
      GrossMargin: "Margine Lordo",
      CashVsDebt: "Cassa vs. Debito",
    },
    description: {
      PE: "Il rapporto Prezzo/Utili (P/E) misura quanto gli investitori sono disposti a pagare per ogni dollaro di utili di un'azienda; si calcola dividendo il prezzo delle azioni per l'utile per azione. Un rapporto P/E elevato può indicare che gli investitori si aspettano una forte crescita futura o che il titolo potrebbe essere sopravvalutato",
      PS: "Il rapporto Prezzo/Vendite (P/S) misura quanto gli investitori stanno pagando per ogni dollaro di vendite dell'azienda; si calcola dividendo il prezzo delle azioni per le vendite per azione. Un rapporto P/S elevato può indicare che gli investitori si aspettano una forte crescita futura delle vendite o che il titolo potrebbe essere sopravvalutato",
      PB: "Il rapporto Prezzo/Valore contabile (P/B) confronta il valore di mercato di un'azienda con il suo valore contabile (attività meno passività); si calcola dividendo il prezzo delle azioni per il valore contabile per azione. Un rapporto P/B elevato può indicare una forte fiducia degli investitori nelle performance future dell'azienda o che il titolo potrebbe essere sopravvalutato se l'azienda non ha una forte crescita degli attivi",
      RevenueGrowth: "Percentuale di crescita dei ricavi",
      NetIncomeGrowth: "Percentuale di crescita dell'utile netto",
      Price: "Prezzo storico delle azioni dell'azienda nel tempo",
      MarketCap:
        "Il valore totale delle azioni in circolazione di un'azienda nel tempo",
      Revenue:
        "L'importo totale di denaro generato dalle attività aziendali, dalla vendita di beni e servizi nel tempo",
      RevenueVsNetIncome:
        "Un confronto tra il totale delle entrate e il reddito netto dell'azienda nel tempo, che indica la sostenibilità della crescita dell'azienda. Un rapporto più basso può indicare che l'azienda sta sviluppando un vantaggio competitivo e guadagnando quote di mercato",
      NetIncome:
        "Il profitto che un'azienda realizza dopo aver detratto tutte le spese, le tasse e i costi dal totale delle entrate nel tempo",
      Ebitda:
        "Gli utili di un'azienda prima degli interessi, delle tasse, degli ammortamenti e delle svalutazioni nel tempo. Misura la performance finanziaria complessiva e la redditività di un'azienda dalle operazioni principali",
      Eps: "La parte degli utili di un'azienda attribuita a ciascuna azione in circolazione. Indica quanti soldi riceverebbero gli azionisti per ciascuna azione che possiedono",
      TotalExpenses:
        "La somma di tutti i costi sostenuti da un'azienda nello svolgimento della propria attività, comprese le spese operative, gli interessi, le tasse, ecc. Il grafico può mostrare le spese totali nel tempo, indicando tendenze e efficienza dei costi",
      SharesIssued: "Il numero totale di azioni emesse da un'azienda",
      FreeCashFlow:
        "Il denaro generato da un'azienda dopo aver considerato le uscite di cassa per supportare le operazioni e mantenere i beni di capitale. Rappresenta il denaro disponibile per i dividendi, il rimborso del debito o il reinvestimento",
      OperatingCashFlow:
        "Il flusso di cassa generato dalle operazioni aziendali di un'azienda nel tempo. Mostra se un'azienda può generare un flusso di cassa positivo sufficiente per mantenere e far crescere le proprie operazioni",
      Dividend:
        "Una parte degli utili di un'azienda distribuita agli azionisti, solitamente sotto forma di pagamenti in contanti.",
      GrossMargin:
        "La percentuale delle entrate rimanenti dopo aver sottratto il costo delle merci vendute (COGS). Mostra quanto efficacemente un'azienda produce e vende i propri prodotti",
      CashVsDebt:
        "Un grafico comparativo che mostra le riserve di cassa dell'azienda rispetto al debito totale. Aiuta a valutare la salute finanziaria e la liquidità dell'azienda",
    },
    dataDescriptions: {
      peHistorical:
        "La media storica del rapporto P/E a 5 anni fornisce una copertura temporale adeguata per catturare i cambiamenti nelle tendenze del mercato e nelle performance aziendali. Riflette anche le condizioni economiche più recenti, i tassi di interesse e gli sviluppi del settore.",
      peHistoricalCompare:
        "Se il rapporto P/E è superiore alla media storica, spesso indica una sopravvalutazione e alte aspettative di crescita, con potenziali rischi se la crescita non raggiunge le aspettative. Al contrario, un P/E inferiore alla media suggerisce una sottovalutazione dovuta al pessimismo del mercato o a problemi temporanei di crescita. È cruciale considerare fattori economici, settoriali e specifici dell'azienda, poiché una società in crescita in un settore emergente può giustificare valutazioni più elevate.",
      MarketCap:
        "Il valore totale delle azioni di una società; calcolato moltiplicando il prezzo delle azioni per il numero totale di azioni",
      PE: "Il rapporto Prezzo / Utili (P/E) misura quanto gli investitori sono disposti a pagare per ogni dollaro di utili della società; calcolato dividendo il prezzo delle azioni per l'utile per azione. Un alto rapporto P/E può indicare che gli investitori si aspettano un alto tasso di crescita futura o che le azioni possono essere sopravvalutate",
      PS: "Il rapporto Prezzo / Vendite (P/S) misura quanto gli investitori pagano per ogni dollaro delle vendite della società; calcolato dividendo il prezzo delle azioni per le vendite per azione. Un alto rapporto P/S può indicare che gli investitori si aspettano una forte crescita futura delle vendite o che le azioni possono essere sopravvalutate",
      PB: "Il rapporto Prezzo / Valore Contabile (P/B) è un rapporto che confronta il valore di mercato della società con il suo valore contabile (attività meno passività); calcolato dividendo il prezzo delle azioni per il valore contabile per azione. Un alto rapporto P/B può indicare una forte fiducia degli investitori nella performance futura della società o che le azioni potrebbero essere sopravvalutate se la società non ha una forte crescita degli attivi",
      FCFYield:
        "Il rendimento del flusso di cassa libero misura quanto flusso di cassa libero una società genera rispetto alla sua capitalizzazione di mercato; calcolato dividendo il flusso di cassa libero per la capitalizzazione di mercato",
      PEGRatio:
        "Il rapporto Prezzo / Utili rispetto alla Crescita (PEG) considera il rapporto P/E di una società in relazione al tasso di crescita degli utili; aiuta a vedere se un'azione è sopravvalutata o sottovalutata. Un rapporto PEG di 1 suggerisce un valore equo, inferiore a 1 suggerisce sottovalutazione rispetto al tasso di crescita, e superiore a 1 suggerisce sopravvalutazione rispetto al tasso di crescita",
      OverallRisk:
        "Una valutazione di quanto è rischiosa un'investimento, considerando fattori come volatilità, stabilità finanziaria e condizioni di mercato; un rischio più basso è solitamente migliore, ma dipende dalla tolleranza al rischio dell'investitore",
      week52HighPrice:
        "Il prezzo più alto che un'azione ha raggiunto nell'ultimo anno; un'azione scambiata vicino al suo massimo di 52 settimane può indicare una forte performance recente, ma potrebbe anche suggerire che è sopravvalutata",
      ProfitabilityAnnual:
        "Se la società ha guadagnato più di quanto ha speso nell'ultimo anno; la redditività è generalmente un buon segno della salute finanziaria di una società",
      GrowingRevenueAnnual:
        "Se i ricavi (vendite) della società sono aumentati rispetto all'anno precedente; una crescita dei ricavi coerente è generalmente un segno positivo di espansione aziendale",
      GrowingNetProfitAnnual:
        "Se il profitto netto (entrate dopo le spese) della società è aumentato rispetto all'anno precedente; un aumento del profitto netto è un forte indicatore di miglioramento delle performance finanziarie",
      PriceGrowthoverthepast5Years:
        "L'aumento percentuale del prezzo di un'azione negli ultimi cinque anni; una percentuale più alta indica una migliore performance a lungo termine",
      PriceGrowthoverthepast1Years:
        "L'aumento percentuale del prezzo di un'azione nell'ultimo anno; una forte crescita a breve termine è positiva ma dovrebbe essere considerata nel contesto di altri fattori",
      Cash: "L'ammontare totale di denaro liquido che la società ha disponibile per l'uso immediato; maggiore liquidità è generalmente migliore per la flessibilità finanziaria",
      Debt: "L'ammontare totale di denaro che la società deve ai creditori; un debito più elevato può essere rischioso se la società non riesce a generare abbastanza reddito per estinguerlo",
      CashDebtRatio:
        "Una misura della capacità di una società di estinguere il debito con il denaro disponibile; un rapporto più alto indica una migliore salute finanziaria",
      Profitability:
        "Se la società ha guadagnato più di quanto ha speso nell'ultimo trimestre; essere redditizi su base trimestrale mostra che la società sta generando costantemente reddito",
      GrowingRevenue:
        "Se i ricavi della società sono aumentati rispetto al trimestre precedente; la crescita continua dei ricavi è un segno di successo aziendale in corso",
      GrowingNetProfit:
        "Se il profitto netto della società è aumentato rispetto al trimestre precedente; un profitto netto crescente trimestralmente indica un miglioramento delle performance finanziarie a breve termine",
      DividendYield:
        "Il pagamento annuale dei dividendi che una società offre ai suoi azionisti, espresso come percentuale del prezzo delle azioni; un rendimento più alto può indicare un maggior reddito dai dividendi rispetto al prezzo delle azioni",
      PayoutRatio:
        "La percentuale degli utili di una società distribuita come dividendi agli azionisti; un rapporto più basso suggerisce che la società sta trattenendo più utili per la crescita, mentre un rapporto molto alto potrebbe indicare che il dividendo potrebbe essere insostenibile",
      FiveYearAvgDividendYield:
        "Il rendimento medio dei dividendi che una società ha offerto negli ultimi cinque anni; aiuta gli investitori a vedere quanto siano consistenti o stabili stati i pagamenti dei dividendi nel tempo",
    },
    labels: {
      TotalCash: "Cassa Totale",
      TotalDebt: "Debito Totale",
      Revenue: "Entrate",
      RevenueVsNetIncome: "Entrate vs. Reddito Netto",
      NetIncome: "Reddito Netto",
    },
    Quarterly: "Trimestrale",
    Annually: "Annualmente",
    FullScreen: "Schermo intero",
    ExitFullScreen: "Esci dallo schermo intero",
    homeMetaTitle: "StockPenguins | Scanner di Azioni Globali Gratuito",
    homeMetaDescription:
      "Scopri l'applicazione definitiva per il monitoraggio gratuito delle azioni per le azioni statunitensi come Apple, Tesla, Amazon e Microsoft. Accedi a metriche dettagliate come Prezzo, Capitalizzazione di Mercato, Ricavi, Utile Netto, EBITDA, Flusso di Cassa Libero, EPS, Dividendo, Rapporto P/E e altro ancora. Analizza e confronta le azioni senza sforzo per prendere decisioni di investimento informate. Cerca ora!",
    pageMetaTitle:
      "Metriche Azionarie | P/E, Ricavi e Capitalizzazione di Mercato",
    pageMetaDescription:
      "Scopri metriche gratuite, tra cui Prezzo, Capitalizzazione di Mercato, Ricavi, Utile Netto, EBITDA, Flusso di Cassa Libero, EPS, Dividendo, Rapporto P/E e altro ancora. Rimani informato sulle prestazioni finanziarie di Apple. Esplora ora!",
  },
  [LANGUAGES[8].value]: {
    // Korean
    search: "주식 검색",
    peHistorical: "5년간의 평균 P/E 비율",
    peHistoricalCompare: "5년 평균 P/E 비율보다 낮거나 높은가?",
    scanner: {
      industry: "산업",
      ind_aginputs: "농업 자재",
      ind_indpollctrl: "산업 - 오염 및 처리 제어",
      ind_indmaterials: "산업 자재",
      ind_autorv: "자동차 - 레크리에이션 차량",
      ind_discstores: "할인 매장",
      ind_insdiv: "보험 - 다양화",
      ind_entertainment: "엔터테인먼트",
      ind_reitdiv: "REIT - 다양화",
      ind_medicaldist: "의료 - 유통",
      ind_softinfra: "소프트웨어 - 인프라",
      ind_softapp: "소프트웨어 - 애플리케이션",
      ind_electricaleqp: "전기 장비 및 부품",
      ind_astmgmtglobal: "자산 관리 - 글로벌",
      ind_indpower: "독립 전력 생산자",
      ind_oilgasdrill: "석유 및 가스 시추",
      ind_leisure: "레저",
      ind_medicaldev: "의료 - 기기",
      ind_coal: "석탄",
      ind_edu: "교육 및 훈련 서비스",
      ind_reitind: "REIT - 산업",
      ind_autoparts: "자동차 - 부품",
      ind_metalfab: "제조 - 금속 가공",
      ind_fincredit: "재무 - 신용 서비스",
      ind_broadcasting: "방송",
      ind_medcarefac: "의료 - 치료 시설",
      ind_chemspecialty: "화학 - 특수",
      ind_astmgmt: "자산 관리",
      ind_insreins: "보험 - 재보험",
      ind_travellodging: "여행 숙소",
      ind_findiv: "재무 - 다양화",
      ind_banks: "은행",
      ind_bevalcoholic: "음료 - 알콜",
      ind_hardwareeqp: "하드웨어, 장비 및 부품",
      ind_inddist: "산업 - 유통",
      ind_constrmat: "건설 자재",
      ind_fooddist: "식품 유통",
      ind_bevwines: "음료 - 와인 및 증류소",
      ind_furnishings: "가구, 비품 및 가전 제품",
      ind_silver: "은",
      ind_specialtybiz: "특수 비즈니스 서비스",
      ind_apparelmanu: "의류 - 제조업체",
      ind_publishing: "출판",
      ind_finmortgages: "재무 - 모기지",
      ind_railroads: "철도",
      ind_insprop: "보험 - 재산 및 상해",
      ind_telco: "통신 서비스",
      ind_reitretail: "REIT - 소매",
      ind_internetcontent: "인터넷 콘텐츠 및 정보",
      ind_indinfrastructure: "산업 - 인프라 운영",
      ind_regwater: "규제된 물",
      ind_packaging: "포장 및 용기",
      ind_realeservices: "부동산 - 서비스",
      ind_reddiversified: "부동산 - 다양화",
      ind_renewutilities: "재생 가능한 공공 서비스",
      ind_preciousmetals: "기타 귀금속",
      ind_autodealerships: "자동차 - 딜러십",
      ind_foodconf: "식품 제과업체",
      ind_toolsmfg: "제조 - 도구 및 액세서리",
      ind_findata: "재무 - 데이터 및 증권 거래소",
      ind_adagencies: "광고 대행사",
      ind_luxurygoods: "사치품",
      ind_householdprod: "가정용 및 개인 제품",
      ind_reitresidential: "REIT - 주거",
      ind_deptstores: "백화점",
      ind_personalprod: "개인 제품 및 서비스",
      ind_paperlumber: "종이, 목재 및 임업 제품",
      ind_staffing: "인력 및 고용 서비스",
      ind_resconstr: "주거 건설",
      ind_healthcare: "헬스케어",
      ind_construction: "건설",
      ind_specialtyretail: "특수 소매",
      ind_mediaentertain: "미디어 및 엔터테인먼트",
      ind_realdev: "부동산 - 개발",
      ind_astmgmtincome: "자산 관리 - 소득",
      ind_engconst: "엔지니어링 및 건설",
      ind_gambling: "도박, 리조트 및 카지노",
      ind_reitoffice: "REIT - 사무실",
      ind_finservices: "금융 서비스",
      ind_drugmanugen: "제약 제조업체 - 일반",
      ind_homeimprovement: "주택 개조",
      ind_steel: "강철",
      ind_regionalbanks: "은행 - 지역",
      ind_agfarmproducts: "농업 농산물",
      ind_conglomerates: "대기업",
      ind_medhealthcareplans: "의료 - 건강 관리 계획",
      ind_chemicals: "화학",
      ind_oilmidstream: "석유 및 가스 중간",
      ind_oilexp: "석유 및 가스 탐사 및 생산",
      ind_computerhardware: "컴퓨터 하드웨어",
      ind_banksdiversified: "은행 - 다양화",
      ind_restaurants: "레스토랑",
      ind_oilintegrated: "석유 및 가스 통합",
      ind_divutilities: "다양화된 공공 서비스",
      ind_reithotel: "REIT - 호텔 및 모텔",
      ind_gaming: "전자 게임 및 멀티미디어",
      ind_medpharma: "의료 - 제약",
      ind_grocerystores: "식료품점",
      ind_wastemgmt: "폐기물 관리",
      ind_reggas: "규제된 가스",
      ind_trucking: "트럭 운송",
      ind_investbanking: "투자 - 은행 및 투자 서비스",
      ind_automanufact: "자동차 - 제조업체",
      ind_reitmortgage: "REIT - 모기지",
      ind_biotech: "생명공학",
      ind_techdistrib: "기술 유통업체",
      ind_bizequip: "비즈니스 장비 및 용품",
      ind_apparelfoot: "의류 - 신발 및 액세서리",
      ind_medicalspec: "의료 - 전문 분야",
      ind_apparelretail: "의류 - 소매",
      ind_regelectric: "규제된 전기",
      ind_finconglomerates: "재무 - 대기업",
      ind_oilrefining: "석유 및 가스 정제 및 마케팅",
      ind_reitspecialty: "REIT - 특수",
      ind_agmachinery: "농업 - 기계",
      ind_packagedfoods: "포장 식품",
      ind_reithealthcare: "REIT - 건강 관리 시설",
      ind_solar: "태양광",
      ind_copper: "구리",
      ind_gold: "금",
      ind_medicalsupp: "의료 - 기기 및 용품",
      ind_insurancebrokers: "보험 - 중개인",
      ind_security: "보안 및 보호 서비스",
      ind_tobacco: "담배",
      ind_fincapmarkets: "재무 - 자본 시장",
      ind_mfgmisc: "제조 - 기타",
      ind_uranium: "우라늄",
      ind_insurancelife: "보험 - 생명",
      ind_indmachinery: "산업 - 기계",
      ind_shellcompanies: "페이퍼 컴퍼니",
      ind_consumelectronics: "소비자 전자 제품",
      ind_medicaldiag: "의료 - 진단 및 연구",
      ind_oilequip: "석유 및 가스 장비 및 서비스",
      ind_commequip: "통신 장비",
      ind_aerospace: "항공 우주 및 방위",
      ind_travelservices: "여행 서비스",
      ind_drugmanuspecialty: "제약 제조업체 - 특수 및 일반",
      ind_aluminum: "알루미늄",
      ind_itservices: "정보 기술 서비스",
      ind_medhealthinfo: "의료 - 건강 관리 정보 서비스",
      ind_bevnonalcoholic: "음료 - 비알콜",
      ind_consulting: "컨설팅 서비스",
      ind_consumerfin: "소비자 금융 서비스",
      ind_renewables: "재생 가능 에너지",
      ind_electricvehicles: "전기차",
      ind_water: "수자원 및 수도",
      ind_oilgastransport: "석유 및 가스 운송",
      ind_mortgages: "모기지 및 부동산 대출",
      ind_railtrans: "철도 운송",
      ind_tourism: "관광",
      ind_solarenergy: "태양광 에너지",
      ind_indgases: "산업용 가스",
      ind_soda: "소다",
      ind_lending: "대출",
      ind_healthtech: "헬스케어 기술",
      ind_cybersecurity: "사이버 보안",
      ind_pharmaceutical: "제약",
      ind_inddrugs: "산업 - 약품",
      ind_space: "우주",
      ind_ecoenergy: "친환경 에너지",
      ind_rentleasing: "렌탈 및 리스 서비스",
      ind_marineshipping: "해상 운송",
      ind_software: "소프트웨어 - 서비스",
      ind_astmgmtbonds: "자산 관리 - 채권",
      ind_freightlogistics: "통합 화물 및 물류",
      ind_insspec: "보험 - 특수",
      ind_semiconductors: "반도체",
      ind_airlines: "항공사, 공항 및 항공 서비스",

      revgrowavg: "지난 5년간 매출 성장",
      revgrowavg_neg: "부정적 (<0%)",
      revgrowavg_pos: "긍정적 (>0%)",
      revgrowavg_u10: "-10% 이하",
      revgrowavg_u20: "-20% 이하",
      revgrowavg_u50: "-50% 이하",
      revgrowavg_u100: "-100% 이하",
      revgrowavg_o10: "10% 이상",
      revgrowavg_o20: "20% 이상",
      revgrowavg_o50: "50% 이상",
      revgrowavg_o100: "100% 이상",

      revgrow: "분기별 매출 성장",
      revgrow_neg: "부정적 (<0%)",
      revgrow_pos: "긍정적 (>0%)",
      revgrow_u10: "-10% 이하",
      revgrow_u20: "-20% 이하",
      revgrow_u50: "-50% 이하",
      revgrow_u100: "-100% 이하",
      revgrow_o10: "10% 이상",
      revgrow_o20: "20% 이상",
      revgrow_o50: "50% 이상",
      revgrow_o100: "100% 이상",

      netincgrow: "분기별 순이익 성장",
      netincgrow_neg: "부정적 (<0%)",
      netincgrow_pos: "긍정적 (>0%)",
      netincgrow_u10: "-10% 이하",
      netincgrow_u20: "-20% 이하",
      netincgrow_u50: "-50% 이하",
      netincgrow_u100: "-100% 이하",
      netincgrow_o10: "10% 이상",
      netincgrow_o20: "20% 이상",
      netincgrow_o50: "50% 이상",
      netincgrow_o100: "100% 이상",

      ltdc: "장기 부채 / 자본화",
      ltdc_high: "높음 (>0.5)",
      ltdc_low: "낮음 (<0.1)",
      ltdc_u1: "1 미만",
      ltdc_u0_9: "0.9 미만",
      ltdc_u0_8: "0.8 미만",
      ltdc_u0_7: "0.7 미만",
      ltdc_u0_6: "0.6 미만",
      ltdc_u0_5: "0.5 미만",
      ltdc_u0_4: "0.4 미만",
      ltdc_u0_3: "0.3 미만",
      ltdc_u0_2: "0.2 미만",
      ltdc_u0_1: "0.1 미만",
      ltdc_o0_1: "0.1 초과",
      ltdc_o0_2: "0.2 초과",
      ltdc_o0_3: "0.3 초과",
      ltdc_o0_4: "0.4 초과",
      ltdc_o0_5: "0.5 초과",
      ltdc_o0_6: "0.6 초과",
      ltdc_o0_7: "0.7 초과",
      ltdc_o0_8: "0.8 초과",
      ltdc_o0_9: "0.9 초과",
      ltdc_o1: "1 초과",

      grossMargin: "총 마진",
      grossm_pos: "양수 (>0%)",
      grossm_neg: "음수 (<0%)",
      grossm_u90: "90% 미만",
      grossm_u70: "70% 미만",
      grossm_u50: "50% 미만",
      grossm_u30: "30% 미만",
      grossm_u10: "10% 미만",
      grossm_u5: "5% 미만",
      grossm_u_10: "-10% 미만",
      grossm_u_30: "-30% 미만",
      grossm_u_50: "-50% 미만",
      grossm_u_70: "-70% 미만",
      grossm_u_100: "-100% 미만",
      grossm_o0: "0% 초과",
      grossm_o10: "10% 초과",
      grossm_o30: "30% 초과",
      grossm_o50: "50% 초과",
      grossm_o70: "70% 초과",
      grossm_o90: "90% 초과",

      deRatio: "부채/자본",
      deratio_high: "높음 (>0.5)",
      deratio_low: "낮음 (<0.1)",
      deratio_u1: "1 미만",
      deratio_u0_9: "0.9 미만",
      deratio_u0_8: "0.8 미만",
      deratio_u0_7: "0.7 미만",
      deratio_u0_6: "0.6 미만",
      deratio_u0_5: "0.5 미만",
      deratio_u0_4: "0.4 미만",
      deratio_u0_3: "0.3 미만",
      deratio_u0_2: "0.2 미만",
      deratio_u0_1: "0.1 미만",
      deratio_o0_1: "0.1 이상",
      deratio_o0_2: "0.2 이상",
      deratio_o0_3: "0.3 이상",
      deratio_o0_4: "0.4 이상",
      deratio_o0_5: "0.5 이상",
      deratio_o0_6: "0.6 이상",
      deratio_o0_7: "0.7 이상",
      deratio_o0_8: "0.8 이상",
      deratio_o0_9: "0.9 이상",
      deratio_o1: "1 이상",

      netProfitMargin: "순이익률",
      netprf_pos: "양수 (>0%)",
      netprf_neg: "음수 (<0%)",
      netprf_veryneg: "매우 음수 (<-20%)",
      netprf_high: "높음 (>20%)",
      netprf_u90: "90% 미만",
      netprf_u80: "80% 미만",
      netprf_u50: "50% 미만",
      netprf_u30: "30% 미만",
      netprf_u10: "10% 미만",
      netprf_u5: "5% 미만",
      netprf_u_10: "-10% 미만",
      netprf_u_30: "-30% 미만",
      netprf_u_50: "-50% 미만",
      netprf_u_100: "-100% 미만",
      netprf_o10: "10% 이상",
      netprf_o30: "30% 이상",
      netprf_o40: "40% 이상",
      netprf_o50: "50% 이상",
      netprf_o80: "80% 이상",
      netprf_o90: "90% 이상",

      dividendYield: "배당 수익률",
      divy_none: "없음 (0%)",
      divy_pos: "긍정적 (>0%)",
      divy_high: "높음 (>5%)",
      divy_veryhigh: "매우 높음 (>10%)",
      divy_o1: "1% 이상",
      divy_o2: "2% 이상",
      divy_o3: "3% 이상",
      divy_o4: "4% 이상",
      divy_o5: "5% 이상",
      divy_o6: "6% 이상",
      divy_o7: "7% 이상",
      divy_o8: "8% 이상",
      divy_o9: "9% 이상",
      divy_o10: "10% 이상",

      payoutRatio: "배당성향",
      payoutr_none: "없음 (0%)",
      payoutr_pos: "긍정적 (>0%)",
      payoutr_low: "낮음 (<20%)",
      payoutr_high: "높음 (>50%)",
      payoutr_o10: "10% 이상",
      payoutr_o20: "20% 이상",
      payoutr_o50: "50% 이상",
      payoutr_o80: "80% 이상",
      payoutr_o100: "100% 이상",
      payoutr_u10: "10% 미만",
      payoutr_u20: "20% 미만",
      payoutr_u50: "50% 미만",
      payoutr_u80: "80% 미만",
      payoutr_u100: "100% 미만",

      peHistoric: "P/E 역사적",
      pehistoric_below: "역사적 평균 이하",
      pehistoric_above: "역사적 평균 이상",
      pbHistoric: "P/B 역사적",
      pbhistoric_below: "역사적 평균 이하",
      pbhistoric_above: "역사적 평균 이상",
      psHistoric: "P/S 역사적",
      pshistoric_below: "역사적 평균 이하",
      pshistoric_above: "역사적 평균 이상",
      pfcf: "가격/FCF",
      pfcf_low: "낮음 (<15)",
      pfcf_high: "높음 (>50)",
      pfcf_u10: "10 이하",
      pfcf_u20: "20 이하",
      pfcf_u50: "50 이하",
      pfcf_u80: "80 이하",
      pfcf_u100: "100 이하",
      pfcf_o10: "10 이상",
      pfcf_o20: "20 이상",
      pfcf_o50: "50 이상",
      pfcf_o80: "80 이상",
      pfcf_o100: "100 이상",

      operatingMargin: "영업 이익률",
      opmr_pos: "긍정적 (>0%)",
      opmr_neg: "부정적 (<0%)",
      opmr_veryneg: "매우 부정적 (<-20%)",
      opmr_high: "높음 (>25%)",
      opmr_u90: "90% 이하",
      opmr_u80: "80% 이하",
      opmr_u70: "70% 이하",
      opmr_u60: "60% 이하",
      opmr_u50: "50% 이하",
      opmr_u40: "40% 이하",
      opmr_u30: "30% 이하",
      opmr_u20: "20% 이하",
      opmr_u10: "10% 이하",
      opmr_u_m10: "-10% 이하",
      opmr_u_m30: "-30% 이하",
      opmr_u_m50: "-50% 이하",
      opmr_u_m100: "-100% 이하",
      opmr_o10: "10% 이상",
      opmr_o20: "20% 이상",
      opmr_o30: "30% 이상",
      opmr_o40: "40% 이상",
      opmr_o50: "50% 이상",
      opmr_o60: "60% 이상",
      opmr_o70: "70% 이상",
      opmr_o80: "80% 이상",
      opmr_o90: "90% 이상",

      pricecash: "가격/현금",
      pricecash_low: "낮음 (<3)",
      pricecash_high: "높음 (>50)",
      pricecash_u1: "1 이하",
      pricecash_u2: "2 이하",
      pricecash_u3: "3 이하",
      pricecash_u4: "4 이하",
      pricecash_u5: "5 이하",
      pricecash_u6: "6 이하",
      pricecash_u7: "7 이하",
      pricecash_u8: "8 이하",
      pricecash_u9: "9 이하",
      pricecash_u10: "10 이하",
      pricecash_o1: "1 이상",
      pricecash_o2: "2 이상",
      pricecash_o3: "3 이상",
      pricecash_o4: "4 이상",
      pricecash_o5: "5 이상",
      pricecash_o6: "6 이상",
      pricecash_o7: "7 이상",
      pricecash_o8: "8 이상",
      pricecash_o9: "9 이상",
      pricecash_o10: "10 이상",
      pricecash_o20: "20 이상",
      pricecash_o30: "30 이상",
      pricecash_o40: "40 이상",
      pricecash_o50: "50 이상",

      exchange: "거래소",
      any: "어느 것",
      nasdaq: "나스닥",
      nyse: "뉴욕 증권거래소",
      amex: "AMEX",
      marketCap: "시가총액",
      mcap_mega: "메가 (2000억 달러 이상)",
      mcap_large: "대형 (100억 달러에서 2000억 달러)",
      mcap_mid: "중형 (20억 달러에서 100억 달러)",
      mcap_small: "소형 (3억 달러에서 20억 달러)",
      mcap_micro: "초소형 (5000만 달러에서 3억 달러)",
      mcap_nano: "나노 (5000만 달러 미만)",
      mcap_largeover: "대형 이상 (100억 달러 초과)",
      mcap_midover: "중형 이상 (20억 달러 초과)",
      mcap_smallover: "소형 이상 (3억 달러 초과)",
      mcap_microover: "초소형 이상 (5000만 달러 초과)",
      mcap_largeunder: "대형 이하 (2000억 달러 미만)",
      mcap_midunder: "중형 이하 (100억 달러 미만)",
      mcap_smallunder: "소형 이하 (20억 달러 미만)",
      mcap_microunder: "초소형 이하 (3억 달러 미만)",
      performance: "성능",
      perf_dup: "1일 상승",
      perf_ddown: "1일 하락",
      perf_d15u: "1일 -15%",
      perf_d10u: "1일 -10%",
      perf_d10o: "1일 +10%",
      perf_d15o: "1일 +15%",
      perf_1w30u: "1주 -30%",
      perf_1w10u: "1주 -10%",
      perf_1wdown: "1주 하락",
      perf_1wup: "1주 상승",
      perf_1w10o: "1주 +10%",
      perf_1w30o: "1주 +30%",
      perf_4w50u: "1개월 -50%",
      perf_4w30u: "1개월 -30%",
      perf_4w10u: "1개월 -10%",
      perf_4wdown: "1개월 하락",
      perf_4wup: "1개월 상승",
      perf_4w20o: "1개월 +20%",
      perf_4w30o: "1개월 +30%",
      perf_4w50o: "1개월 +50%",
      perf_13w50u: "1분기 -50%",
      perf_13w30u: "1분기 -30%",
      perf_13w10u: "1분기 -10%",
      perf_13wdown: "1분기 하락",
      perf_13wup: "1분기 상승",
      perf_13w10o: "1분기 +10%",
      perf_13w30o: "1분기 +30%",
      perf_13w50o: "1분기 +50%",
      perf_26w75u: "반년 -75%",
      perf_26w50u: "반년 -50%",
      perf_26w20u: "반년 -20%",
      perf_26w10u: "반년 -10%",
      perf_26wdown: "반년 하락",
      perf_26wup: "반년 상승",
      perf_26w10o: "반년 +10%",
      perf_26w20o: "반년 +20%",
      perf_26w50o: "반년 +50%",
      perf_26w100o: "반년 +100%",
      perf_52w75u: "1년 -75%",
      perf_52w50u: "1년 -50%",
      perf_52w30u: "1년 -30%",
      perf_52w10u: "1년 -10%",
      perf_52wdown: "1년 하락",
      perf_52wup: "1년 상승",
      perf_52w10o: "1년 +10%",
      perf_52w50o: "1년 +50%",
      perf_52w100o: "1년 +100%",
      perf_52w300o: "1년 +300%",
      perf_52w500o: "1년 +500%",
      perf_ytd75u: "연초 대비 -75%",
      perf_ytd50u: "연초 대비 -50%",
      perf_ytd30u: "연초 대비 -30%",
      perf_ytd10u: "연초 대비 -10%",
      perf_ytddown: "연초 대비 하락",
      perf_ytdup: "연초 대비 상승",
      perf_ytd10o: "연초 대비 +10%",
      perf_ytd30o: "연초 대비 +30%",
      perf_ytd50o: "연초 대비 +50%",
      perf_ytd100o: "연초 대비 +100%",

      pe: "P / E",
      pe_low: "낮음 (<15)",
      pe_profitable: "수익성 있음 (>0)",
      pe_high: "높음 (>50)",
      pe_u5: "5 이하",
      pe_u10: "10 이하",
      pe_u15: "15 이하",
      pe_u20: "20 이하",
      pe_u30: "30 이하",
      pe_u40: "40 이하",
      pe_u50: "50 이하",
      pe_o5: "5 이상",
      pe_o10: "10 이상",
      pe_o15: "15 이상",
      pe_o20: "20 이상",
      pe_o30: "30 이상",
      pe_o40: "40 이상",
      pe_o50: "50 이상",
      peg: "PEG",
      peg_low: "낮음 (<1)",
      peg_high: "높음 (>2)",
      peg_u1: "1 이하",
      peg_u2: "2 이하",
      peg_u3: "3 이하",
      peg_o1: "1 이상",
      peg_o2: "2 이상",
      peg_o3: "3 이상",

      pb: "P / B",
      pb_low: "낮음 (<1)",
      pb_high: "높음 (>5)",
      pb_u1: "1 이하",
      pb_u2: "2 이하",
      pb_u3: "3 이하",
      pb_u4: "4 이하",
      pb_u5: "5 이하",
      pb_u6: "6 이하",
      pb_u7: "7 이하",
      pb_u8: "8 이하",
      pb_u9: "9 이하",
      pb_u10: "10 이하",
      pb_o1: "1 이상",
      pb_o2: "2 이상",
      pb_o3: "3 이상",
      pb_o4: "4 이상",
      pb_o5: "5 이상",
      pb_o6: "6 이상",
      pb_o7: "7 이상",
      pb_o8: "8 이상",
      pb_o9: "9 이상",
      pb_o10: "10 이상",

      ps: "P / S",
      ps_low: "낮음 (<1)",
      ps_high: "높음 (>5)",
      ps_u1: "1 이하",
      ps_u2: "2 이하",
      ps_u3: "3 이하",
      ps_u4: "4 이하",
      ps_u5: "5 이하",
      ps_u6: "6 이하",
      ps_u7: "7 이하",
      ps_u8: "8 이하",
      ps_u9: "9 이하",
      ps_u10: "10 이하",
      ps_o1: "1 이상",
      ps_o2: "2 이상",
      ps_o3: "3 이상",
      ps_o4: "4 이상",
      ps_o5: "5 이상",
      ps_o6: "6 이상",
      ps_o7: "7 이상",
      ps_o8: "8 이상",
      ps_o9: "9 이상",
      ps_o10: "10 이상",

      quick: "신속 비율",
      quick_high: "높음 (>3)",
      quick_low: "낮음 (<0.5)",
      quick_u1: "1 이하",
      quick_u0_5: "0.5 이하",
      quick_o0_5: "0.5 이상",
      quick_o1: "1 이상",
      quick_o1_5: "1.5 이상",
      quick_o2: "2 이상",
      quick_o3: "3 이상",
      quick_o4: "4 이상",
      quick_o5: "5 이상",
      quick_o10: "10 이상",

      current: "현재 비율",
      current_high: "높음 (>3)",
      current_low: "낮음 (<1)",
      current_u1: "1 이하",
      current_u0_5: "0.5 이하",
      current_o0_5: "0.5 이상",
      current_o1: "1 이상",
      current_o1_5: "1.5 이상",
      current_o2: "2 이상",
      current_o3: "3 이상",
      current_o4: "4 이상",
      current_o5: "5 이상",
      current_o10: "10 이상",

      sector: "부문",
      sector_realestate: "부동산",
      sector_health: "의료",
      sector_energy: "에너지",
      sector_utilities: "유틸리티",
      sector_cd: "소비자 방어",
      sector_fs: "금융 서비스",
      sector_coms: "통신 서비스",
      sector_cs: "소비자 서비스",
      sector_bm: "기초 재료",
      sector_i: "산업",
      sector_cc: "소비자 경기",
      sector_t: "기술",
    },

    TopCompaniesTitle: "미국에서 가장 큰 기업",
    TotalMarketCap: "총 시가 총액",
    Companies: "기업",
    sortBy: "정렬 기준",
    Terms: "이용 약관",
    Privacy: "개인정보 보호정책",
    logout: "로그아웃",
    portfolioLeader: "포트폴리오 리더",
    portfolio: {
      symbol: "심볼",
      portfolioName: "포트폴리오 이름",
      date: "날짜",
      price: "가격",
      noOfShares: "주식 수",
      buy: "구매",
      sellTransactions: "매도 거래",
      purchasePrice: "구매 가격",
      purchaseDate: "구매일",
      priceSold: "판매 가격",
      dateSold: "판매일",
      profitAndLoss: "손익",
      company: "회사",
      sector: "부문",
      currentPrice: "현재 가격",
      avgPurchasePrice: "평균 구매 가격",
      totalShares: "총 주식",
      portfolioPct: "포트폴리오 비율",
      capital: "자본",
      sell: "판매",
      history: "기록",
      pctReturns: "수익률 %",
      name: "이름",
      noOfCompanies: "회사 수",
      total: "총계",
    },
    login: {
      login: "로그인",
      signup: "회원가입",
      forgotpassword: "비밀번호를 잊으셨나요?",
      resetpassword: "비밀번호 재설정",
      email: "이메일",
      password: "비밀번호",
      forgotPasswordQ: "비밀번호를 잊으셨나요?",
      createAccount: "새로운 Stock Penguins 계정 만들기",
      firstName: "이름",
      lastName: "성",
      requestOTP: "OTP 요청",
      otp: "OTP",
    },
    stockScreener: "주식 스크리너",
    topPresets: "인기 프리셋",
    myPortfolio: "내 포트폴리오",
    portfolioLeaderboard: "포트폴리오 리더보드",
    peHistoric: "P/E 역사적 데이터",
    SimilarCompanies: "유사한 회사들",
    Stock: "주식",
    presetName: "프리셋 이름",
    createdBy: "작성자",
    upvotes: "좋아요",
    savePreset: "프리셋 저장",
    share: "공유",
    save: "저장",
    selectPreset: "프리셋 선택",
    loginToSP: "Stock Penguins에 로그인",
    clearFilters: "필터 지우기",

    today: "1일 변동",
    oneMonthChange: "1개월 변동",
    oneYearChange: "1년 변동",
    OneYear: "1 년",
    ThreeYear: "3 년",
    FiveYear: "5 년",
    TenYear: "10 년",
    CAGRDesc: "시간에 따른 역사적 변화",
    welcome: "환영합니다",
    SearchBarPlaceholder: "다음 10배 주식은 무엇입니까?",
    Search: "검색",
    Industry: "산업",
    Sector: "부문",
    MarketCap: "시가총액",
    PE: "P / E",
    PS: "P / S",
    PB: "P / B",
    FCFYield: "FCF 수익률",
    PEGRatio: "PEG 비율",
    OverallRisk: "피오트로스키 점수",
    week52HighPrice: "52주 최고가",
    PriceGrowthoverthepast5Years: "지난 5년간 주가 성장률",
    PriceGrowthoverthepast1Years: "지난 1년간 주가 성장률",
    Profitability: "수익성 (분기별)",
    GrowingRevenue: "매출 성장 (분기별)",
    GrowingNetProfit: "순이익 성장 (분기별)",
    ProfitabilityAnnual: "수익성 (연간)",
    GrowingRevenueAnnual: "매출 성장 (연간)",
    GrowingNetProfitAnnual: "순이익 성장 (연간)",
    FinancialMetrics: "평가 요약",
    BalanceSheet: "재무 지표",
    Cash: "현금",
    Debt: "부채",
    CashDebtRatio: "현금 부채 비율",
    DividendYield: "배당 수익률",
    PayoutRatio: "배당 성향",
    FiveYearAvgDividendYield: "5년 평균 배당 수익률",
    DividendGrowthRateoverthepast5Years: "지난 5년간 배당 성장률",
    QualityOfCompany: "기업 품질",
    Dividend: "배당",
    comparedToLastQuarter: "지난 분기와 비교",
    comparedToLastYear: "지난해와 비교",
    newTab: "새 탭",
    compare: "비교하다",
    topCompanies: "최고 기업",
    watchlist: "감시 목록",
    rank: "순위",
    companyName: "회사 이름",
    symbol: "기호",
    marketCap: "시가총액",
    price: "가격",
    volume: "거래량",
    country: "국가",
    joinDiscord: "디스코드에 참여하기",
    joinTelegram: "텔레그램에 참여하기",
    contactUs: "문의하기",
    InTheLast365Days: "지난 365일 동안",
    LastUpdated: "마지막 업데이트",
    pages: "페이지",

    titles: {
      PE: "P / E",
      PS: "P / S",
      PB: "P / B",
      RevenueGrowth: "매출 성장 %",
      NetIncomeGrowth: "순이익 성장 %",
      Price: "가격",
      MarketCap: "시가총액",
      Revenue: "수익",
      RevenueVsNetIncome: "수익 대 순이익",
      NetIncome: "순이익",
      Ebitda: "EBITDA",
      Eps: "EPS",
      TotalExpenses: "총비용",
      SharesIssued: "발행 주식",
      FreeCashFlow: "자유 현금 흐름",
      OperatingCashFlow: "영업 현금 흐름",
      Dividend: "배당",
      GrossMargin: "매출총이익률",
      CashVsDebt: "현금 대 부채",
    },
    description: {
      PE: "주가수익비율 (P/E)은 투자자들이 회사의 1달러당 수익에 대해 얼마나 기꺼이 지불하는지를 측정하는 지표로, 주가를 주당 순이익으로 나누어 계산합니다. 높은 P/E 비율은 투자자들이 미래에 높은 성장률을 기대하거나 주식이 과대평가되었음을 나타낼 수 있습니다",
      PS: "주가매출비율 (P/S)은 투자자들이 회사의 1달러당 매출에 대해 얼마나 지불하는지를 측정하며, 주가를 주당 매출로 나누어 계산합니다. 높은 P/S 비율은 투자자들이 미래의 매출 성장률이 높을 것이라고 기대하거나 주식이 과대평가되었음을 나타낼 수 있습니다",
      PB: "주가순자산비율 (P/B)은 회사의 시장가치를 장부가치(자산에서 부채를 뺀 값)와 비교하는 비율입니다. 주가를 주당 장부가치로 나누어 계산합니다. 높은 P/B 비율은 투자자들이 회사의 미래 실적에 대해 강한 신뢰를 가지고 있거나, 회사가 강력한 자산 성장을 보이지 않을 경우 주식이 과대평가되었음을 나타낼 수 있습니다",
      RevenueGrowth: "매출 성장률",
      NetIncomeGrowth: "순이익 성장률",
      Price: "시간에 따른 회사 주가 변동",
      MarketCap: "시간에 따른 회사의 발행 주식 총 가치",
      Revenue:
        "시간에 따른 비즈니스 활동, 상품 및 서비스 판매에서 발생한 총 수익",
      RevenueVsNetIncome:
        "시간에 따른 회사의 총 수익과 순이익 비교로, 회사 성장의 지속 가능성을 나타냅니다. 낮은 비율은 회사가 경쟁 우위를 개발하고 시장 점유율을 확보하고 있음을 나타낼 수 있습니다",
      NetIncome:
        "시간에 따른 총 수익에서 모든 비용, 세금 및 비용을 공제한 후 회사의 이익",
      Ebitda:
        "시간에 따른 회사의 이자, 세금, 감가 상각비 및 상각비 차감 전 이익. 핵심 운영에서 회사의 전반적인 재무 성과와 수익성을 측정합니다",
      Eps: "발행된 보통주 한 주당 할당된 회사의 이익 비율. 주주가 소유한 각 주식에 대해 얼마를 받을지 나타냅니다",
      TotalExpenses:
        "비즈니스 수행 시 발생하는 모든 비용의 합계, 운영 비용, 이자, 세금 등을 포함합니다. 그래프는 시간에 따른 총비용을 보여주어 비용 동향과 효율성을 나타낼 수 있습니다",
      SharesIssued: "회사의 총 발행 주식 수",
      FreeCashFlow:
        "운영 지원 및 자본 자산 유지를 위한 현금 유출을 고려한 후 회사가 생성하는 현금 흐름. 배당, 부채 상환 또는 재투자에 사용할 수 있는 자금을 나타냅니다",
      OperatingCashFlow:
        "시간에 따른 회사의 비즈니스 운영에서 생성된 현금 흐름. 회사가 운영을 유지하고 성장시키기에 충분한 긍정적인 현금 흐름을 생성할 수 있는지 여부를 보여줍니다",
      Dividend:
        "일반적으로 현금 지급 형태로 주주에게 배분되는 회사 수익의 일부입니다",
      GrossMargin:
        "판매한 상품 원가(COGS)를 공제한 후 남은 수익의 비율. 회사가 제품을 효율적으로 생산 및 판매하고 있는지를 나타냅니다",
      CashVsDebt:
        "회사의 현금 준비금과 총 부채를 비교하는 그래프. 회사의 재정 상태와 유동성을 평가하는 데 도움이 됩니다",
    },
    dataDescriptions: {
      peHistorical:
        "5년간의 평균 P/E 비율은 시장 트렌드 변화와 기업 성과를 포착할 수 있는 적절한 기간을 제공합니다. 또한 최신 경제 상황, 금리, 산업 발전을 반영합니다.",
      peHistoricalCompare:
        "P/E 비율이 역사적 평균을 초과하면, 이는 과대 평가 및 높은 성장 기대를 나타내며, 성장률이 기대에 미치지 못할 경우 잠재적인 위험을 초래할 수 있습니다. 반대로, 평균 이하의 P/E는 시장의 비관론이나 일시적인 성장 문제로 인해 저평가되었음을 나타냅니다. 경제, 산업 및 기업 특유의 요인을 고려하는 것이 중요하며, 성장 중인 산업에 있는 성장 기업은 더 높은 평가를 정당화할 수 있습니다.",

      MarketCap: "회사의 주식 총 가치; 주가를 총 주식 수로 곱하여 계산합니다.",
      PE: "주가수익비율(P/E)은 투자자들이 회사의 수익 1달러당 지불할 의향이 있는 금액을 측정하는 지표입니다. 주가를 주당 수익으로 나누어 계산합니다. 높은 P/E 비율은 투자자들이 높은 미래 성장률을 기대하거나 주식이 과대평가되었을 수 있음을 나타낼 수 있습니다.",
      PS: "주가매출비율(P/S)은 투자자들이 회사의 매출 1달러당 지불하는 금액을 측정하는 지표입니다. 주가를 주당 매출로 나누어 계산합니다. 높은 P/S 비율은 투자자들이 높은 미래 매출 성장을 기대하거나 주식이 과대평가되었을 수 있음을 나타낼 수 있습니다.",
      PB: "주가순자산비율(P/B)은 회사의 시장 가치를 장부가치(자산에서 부채를 뺀 값)와 비교하는 비율입니다. 주가를 주당 장부가치로 나누어 계산합니다. 높은 P/B 비율은 회사의 미래 성과에 대한 강한 투자자 신뢰를 나타낼 수 있으며, 회사가 강력한 자산 성장 없이는 주식이 과대평가될 수 있습니다.",
      FCFYield:
        "자유현금흐름 수익률(FCF Yield)은 회사가 시장 가치에 비해 생성하는 자유 현금 흐름의 양을 측정하는 지표입니다. 자유 현금 흐름을 시장 가치로 나누어 계산합니다.",
      PEGRatio:
        "주가수익성장비율(PEG)은 회사의 P/E 비율을 수익 성장률과 관련하여 고려하는 평가 지표입니다. 주식이 과대평가되었는지, 과소평가되었는지를 확인하는 데 도움이 됩니다. PEG 비율이 1인 경우 공정 가치, 1 미만인 경우 성장률에 비해 과소평가, 1 초과인 경우 성장률에 비해 과대평가를 나타냅니다.",
      OverallRisk:
        "투자가 얼마나 위험한지를 평가하는 지표로, 변동성, 재정 안정성 및 시장 상황과 같은 요소를 고려합니다. 위험이 낮을수록 일반적으로 더 좋지만, 투자자의 위험 감수성에 따라 달라질 수 있습니다.",
      week52HighPrice:
        "주식이 지난 1년 동안 도달한 최고 가격; 52주 최고가 근처에서 거래되는 주식은 최근 강력한 성과를 나타낼 수 있지만, 과대평가되었을 수도 있습니다.",
      ProfitabilityAnnual:
        "회사가 지난 1년 동안 지출한 금액보다 더 많은 돈을 벌었는지 여부; 수익성은 일반적으로 회사의 재정 건강을 나타내는 좋은 신호입니다.",
      GrowingRevenueAnnual:
        "회사의 매출이 지난 해에 비해 증가했는지 여부; 지속적인 매출 성장은 일반적으로 사업 확장의 긍정적인 신호입니다.",
      GrowingNetProfitAnnual:
        "회사의 순이익(경비 후 수익)이 지난 해에 비해 증가했는지 여부; 순이익 증가가 재무 성과 개선의 강력한 지표입니다.",
      PriceGrowthoverthepast5Years:
        "주식 가격이 지난 5년 동안 증가한 비율; 높은 비율은 장기적인 성과가 더 좋음을 나타냅니다.",
      PriceGrowthoverthepast1Years:
        "주식 가격이 지난 1년 동안 증가한 비율; 강력한 단기 성장은 긍정적이지만 다른 요소와 함께 고려해야 합니다.",
      Cash: "회사가 즉시 사용할 수 있는 총 유동 자금; 더 많은 현금은 일반적으로 재정적 유연성을 높입니다.",
      Debt: "회사가 대출자에게 빚진 총 금액; 높은 부채는 회사가 이를 상환할 만큼의 충분한 소득을 생성하지 못하면 위험할 수 있습니다.",
      CashDebtRatio:
        "회사가 현금으로 부채를 상환할 수 있는 능력의 측정 지표; 비율이 높을수록 재정 건강이 더 좋습니다.",
      Profitability:
        "회사가 가장 최근 분기 동안 지출한 금액보다 더 많은 돈을 벌었는지 여부; 분기별로 수익성이 있는 것은 회사가 일관되게 수익을 생성하고 있음을 보여줍니다.",
      GrowingRevenue:
        "회사의 매출이 이전 분기와 비교하여 증가했는지 여부; 지속적인 매출 성장은 지속적인 사업 성공의 신호입니다.",
      GrowingNetProfit:
        "회사의 순이익이 이전 분기와 비교하여 증가했는지 여부; 분기별 순이익 증가가 단기적인 재무 성과 개선을 나타냅니다.",
      DividendYield:
        "회사가 주주에게 지급하는 연간 배당금, 주가에 대한 비율로 표현됩니다; 높은 배당 수익률은 주가에 비해 더 많은 배당금 수익을 나타낼 수 있습니다.",
      PayoutRatio:
        "회사가 주주에게 배당금으로 지급한 수익의 비율; 비율이 낮으면 회사가 성장을 위해 더 많은 수익을 보유하고 있음을 나타내며, 매우 높은 비율은 배당금이 지속 불가능할 수 있음을 나타낼 수 있습니다.",
      FiveYearAvgDividendYield:
        "회사가 지난 5년 동안 제공한 평균 배당 수익률; 이는 투자자가 배당금 지급의 일관성이나 안정성을 볼 수 있도록 도와줍니다.",
    },
    labels: {
      TotalCash: "총 현금",
      TotalDebt: "총 부채",
      Revenue: "수익",
      NetIncome: "순이익",
    },
    Quarterly: "분기별",
    Annually: "연간",
    FullScreen: "전체 화면",
    ExitFullScreen: "전체 화면 종료",
    homeMetaTitle: "StockPenguins | 무료 글로벌 주식 스캐너",
    homeMetaDescription:
      "Apple, Tesla, Amazon 및 Microsoft와 같은 미국 주식을 위한 궁극의 무료 주식 스캐너 앱을 발견하세요. 가격, 시가총액, 수익, 순이익, EBITDA, 잉여현금흐름, EPS, 배당금, P/E 비율 등 자세한 지표에 액세스할 수 있습니다. 주식을 손쉽게 분석하고 비교하여 정보에 입각한 투자 결정을 내리세요. 지금 검색하세요!",
    pageMetaTitle: "주식 지표 | P/E, 수익 및 시가총액",
    pageMetaDescription:
      "가격, 시가총액, 수익, 순이익, EBITDA, 잉여현금흐름, EPS, 배당금, P/E 비율 등 무료 지표를 발견하세요. Apple의 재무 성과에 대한 정보를 유지하세요. 지금 탐색하세요!",
  },
  [LANGUAGES[9].value]: {
    // Vietnamese
    search: "Tìm kiếm Cổ phiếu",
    peHistorical: "Tỷ lệ P/E trung bình 5 năm",
    peHistoricalCompare: "Dưới hoặc trên tỷ lệ P/E trung bình 5 năm?",

    scanner: {
      industry: "Ngành công nghiệp",
      ind_aginputs: "Nông nghiệp - Đầu vào",
      ind_indpollctrl: "Công nghiệp - Kiểm soát ô nhiễm & Xử lý",
      ind_indmaterials: "Vật liệu công nghiệp",
      ind_autorv: "Ô tô - Xe giải trí",
      ind_discstores: "Cửa hàng giảm giá",
      ind_insdiv: "Bảo hiểm - Đa dạng hóa",
      ind_entertainment: "Giải trí",
      ind_reitdiv: "REIT - Đa dạng hóa",
      ind_medicaldist: "Y tế - Phân phối",
      ind_softinfra: "Phần mềm - Hạ tầng",
      ind_softapp: "Phần mềm - Ứng dụng",
      ind_electricaleqp: "Thiết bị điện & Phụ tùng",
      ind_astmgmtglobal: "Quản lý tài sản - Toàn cầu",
      ind_indpower: "Nhà sản xuất điện độc lập",
      ind_oilgasdrill: "Dầu & Khí - Khoan",
      ind_leisure: "Giải trí",
      ind_medicaldev: "Y tế - Thiết bị",
      ind_coal: "Than",
      ind_edu: "Giáo dục & Dịch vụ đào tạo",
      ind_reitind: "REIT - Công nghiệp",
      ind_autoparts: "Ô tô - Phụ tùng",
      ind_metalfab: "Sản xuất - Gia công kim loại",
      ind_fincredit: "Tài chính - Dịch vụ tín dụng",
      ind_broadcasting: "Phát sóng",
      ind_medcarefac: "Y tế - Cơ sở chăm sóc",
      ind_chemspecialty: "Hóa chất - Chuyên dụng",
      ind_astmgmt: "Quản lý tài sản",
      ind_insreins: "Bảo hiểm - Tái bảo hiểm",
      ind_travellodging: "Lưu trú du lịch",
      ind_findiv: "Tài chính - Đa dạng hóa",
      ind_banks: "Ngân hàng",
      ind_bevalcoholic: "Thức uống - Có cồn",
      ind_hardwareeqp: "Phần cứng, Thiết bị & Phụ tùng",
      ind_inddist: "Công nghiệp - Phân phối",
      ind_constrmat: "Vật liệu xây dựng",
      ind_fooddist: "Phân phối thực phẩm",
      ind_bevwines: "Thức uống - Nhà máy rượu & Chưng cất",
      ind_furnishings: "Nội thất, Thiết bị & Thiết bị điện",
      ind_silver: "Bạc",
      ind_specialtybiz: "Dịch vụ kinh doanh chuyên biệt",
      ind_apparelmanu: "May mặc - Nhà sản xuất",
      ind_publishing: "Xuất bản",
      ind_finmortgages: "Tài chính - Thế chấp",
      ind_railroads: "Đường sắt",
      ind_insprop: "Bảo hiểm - Tài sản & Thiệt hại",
      ind_telco: "Dịch vụ viễn thông",
      ind_reitretail: "REIT - Bán lẻ",
      ind_internetcontent: "Nội dung & Thông tin Internet",
      ind_indinfrastructure: "Công nghiệp - Hoạt động hạ tầng",
      ind_regwater: "Nước được quản lý",
      ind_packaging: "Đóng gói & Container",
      ind_realeservices: "Bất động sản - Dịch vụ",
      ind_reddiversified: "Bất động sản - Đa dạng hóa",
      ind_renewutilities: "Tiện ích tái tạo",
      ind_preciousmetals: "Kim loại quý khác",
      ind_autodealerships: "Ô tô - Đại lý",
      ind_foodconf: "Nhà sản xuất thực phẩm",
      ind_toolsmfg: "Sản xuất - Dụng cụ & Phụ kiện",
      ind_findata: "Tài chính - Dữ liệu & Sở giao dịch chứng khoán",
      ind_adagencies: "Công ty quảng cáo",
      ind_luxurygoods: "Hàng hóa xa xỉ",
      ind_householdprod: "Sản phẩm hộ gia đình & Cá nhân",
      ind_reitresidential: "REIT - Nhà ở",
      ind_deptstores: "Cửa hàng bách hóa",
      ind_personalprod: "Sản phẩm & Dịch vụ cá nhân",
      ind_paperlumber: "Giấy, Gỗ & Sản phẩm rừng",
      ind_staffing: "Dịch vụ nhân sự & Tuyển dụng",
      ind_resconstr: "Xây dựng nhà ở",
      ind_healthcare: "Chăm sóc sức khỏe",
      ind_construction: "Xây dựng",
      ind_specialtyretail: "Bán lẻ chuyên biệt",
      ind_mediaentertain: "Truyền thông & Giải trí",
      ind_realdev: "Bất động sản - Phát triển",
      ind_astmgmtincome: "Quản lý tài sản - Thu nhập",
      ind_engconst: "Kỹ thuật & Xây dựng",
      ind_gambling: "Đánh bạc, Khu nghỉ dưỡng & Sòng bạc",
      ind_reitoffice: "REIT - Văn phòng",
      ind_finservices: "Dịch vụ tài chính",
      ind_drugmanugen: "Nhà sản xuất thuốc - Tổng quát",
      ind_homeimprovement: "Cải thiện nhà ở",
      ind_steel: "Thép",
      ind_regionalbanks: "Ngân hàng - Khu vực",
      ind_agfarmproducts: "Nông nghiệp - Sản phẩm nông trại",
      ind_conglomerates: "Tập đoàn",
      ind_medhealthcareplans: "Y tế - Kế hoạch chăm sóc sức khỏe",
      ind_chemicals: "Hóa chất",
      ind_oilmidstream: "Dầu & Khí - Trung gian",
      ind_oilexp: "Dầu & Khí - Khám phá & Sản xuất",
      ind_computerhardware: "Phần cứng máy tính",
      ind_banksdiversified: "Ngân hàng - Đa dạng hóa",
      ind_restaurants: "Nhà hàng",
      ind_oilintegrated: "Dầu & Khí - Tích hợp",
      ind_divutilities: "Tiện ích đa dạng",
      ind_reithotel: "REIT - Khách sạn & Nhà nghỉ",
      ind_gaming: "Trò chơi điện tử & Đa phương tiện",
      ind_medpharma: "Y tế - Dược phẩm",
      ind_grocerystores: "Cửa hàng tạp hóa",
      ind_wastemgmt: "Quản lý chất thải",
      ind_reggas: "Khí đốt được quản lý",
      ind_trucking: "Vận tải",
      ind_investbanking: "Đầu tư - Dịch vụ ngân hàng & Đầu tư",
      ind_automanufact: "Ô tô - Nhà sản xuất",
      ind_reitmortgage: "REIT - Thế chấp",
      ind_biotech: "Công nghệ sinh học",
      ind_techdistrib: "Phân phối công nghệ",
      ind_bizequip: "Thiết bị & Vật tư kinh doanh",
      ind_apparelfoot: "May mặc - Giày dép & Phụ kiện",
      ind_medicalspec: "Y tế - Chuyên môn",
      ind_apparelretail: "May mặc - Bán lẻ",
      ind_regelectric: "Điện được quản lý",
      ind_finconglomerates: "Tài chính - Tập đoàn",
      ind_oilrefining: "Dầu & Khí - Chế biến & Tiếp thị",
      ind_reitspecialty: "REIT - Chuyên biệt",
      ind_agmachinery: "Nông nghiệp - Máy móc",
      ind_packagedfoods: "Thực phẩm đóng gói",
      ind_reithealthcare: "REIT - Cơ sở chăm sóc sức khỏe",
      ind_solar: "Năng lượng mặt trời",
      ind_copper: "Đồng",
      ind_gold: "Vàng",
      ind_medicalsupp: "Y tế - Thiết bị & Vật tư",
      ind_insurancebrokers: "Bảo hiểm - Môi giới",
      ind_security: "Dịch vụ an ninh & Bảo vệ",
      ind_tobacco: "Thuốc lá",
      ind_fincapmarkets: "Tài chính - Thị trường vốn",
      ind_mfgmisc: "Sản xuất - Khác",
      ind_uranium: "Uranium",
      ind_insurancelife: "Bảo hiểm - Cuộc sống",
      ind_emergingmarket: "Thị trường mới nổi",
      ind_travel: "Du lịch",
      ind_metals: "Kim loại",
      ind_hospitals: "Bệnh viện",
      ind_logging: "Chặt cây",
      ind_charitable: "Tổ chức từ thiện",
      ind_solidwaste: "Chất thải rắn",
      ind_scientific: "Khoa học",
      ind_telecommunications: "Viễn thông",
      ind_manufacturing: "Sản xuất",
      ind_deductible: "Khấu trừ",
      ind_offshore: "Ngoài khơi",
      ind_generalins: "Bảo hiểm - Tổng quát",
      ind_electricity: "Điện",
      ind_plastics: "Nhựa",
      ind_computers: "Máy tính",
      ind_building: "Xây dựng",
      ind_food: "Thực phẩm",
      ind_mining: "Khai thác",
      ind_tech: "Công nghệ",
      ind_indmachinery: "Công nghiệp - Máy móc",
      ind_shellcompanies: "Công ty ma",
      ind_consumelectronics: "Điện tử tiêu dùng",
      ind_medicaldiag: "Y tế - Chẩn đoán & Nghiên cứu",
      ind_oilequip: "Thiết bị & Dịch vụ dầu khí",
      ind_commequip: "Thiết bị truyền thông",
      ind_aerospace: "Hàng không & Quốc phòng",
      ind_travelservices: "Dịch vụ du lịch",
      ind_drugmanuspecialty: "Nhà sản xuất thuốc - Chuyên biệt & Tổng quát",
      ind_aluminum: "Nhôm",
      ind_itservices: "Dịch vụ công nghệ thông tin",
      ind_medhealthinfo: "Y tế - Dịch vụ thông tin chăm sóc sức khỏe",
      ind_bevnonalcoholic: "Thức uống - Không có cồn",
      ind_consulting: "Dịch vụ tư vấn",
      ind_rentleasing: "Dịch vụ cho thuê & Thuê",
      ind_marineshipping: "Vận chuyển hàng hải",
      ind_software: "Phần mềm - Dịch vụ",
      ind_astmgmtbonds: "Quản lý tài sản - Trái phiếu",
      ind_freightlogistics: "Vận chuyển và Logistics tích hợp",
      ind_insspec: "Bảo hiểm - Chuyên biệt",
      ind_semiconductors: "Bán dẫn",
      ind_airlines: "Hãng hàng không, Sân bay & Dịch vụ hàng không",

      revgrowavg: "Tăng trưởng Doanh thu 5 Năm Qua",
      revgrowavg_neg: "Âm (<0%)",
      revgrowavg_pos: "Dương (>0%)",
      revgrowavg_u10: "Dưới -10%",
      revgrowavg_u20: "Dưới -20%",
      revgrowavg_u50: "Dưới -50%",
      revgrowavg_u100: "Dưới -100%",
      revgrowavg_o10: "Trên 10%",
      revgrowavg_o20: "Trên 20%",
      revgrowavg_o50: "Trên 50%",
      revgrowavg_o100: "Trên 100%",

      revgrow: "Tăng trưởng Doanh thu q/q",
      revgrow_neg: "Âm (<0%)",
      revgrow_pos: "Dương (>0%)",
      revgrow_u10: "Dưới -10%",
      revgrow_u20: "Dưới -20%",
      revgrow_u50: "Dưới -50%",
      revgrow_u100: "Dưới -100%",
      revgrow_o10: "Trên 10%",
      revgrow_o20: "Trên 20%",
      revgrow_o50: "Trên 50%",
      revgrow_o100: "Trên 100%",

      netincgrow: "Tăng trưởng Thu nhập Ròng q/q",
      netincgrow_neg: "Âm (<0%)",
      netincgrow_pos: "Dương (>0%)",
      netincgrow_u10: "Dưới -10%",
      netincgrow_u20: "Dưới -20%",
      netincgrow_u50: "Dưới -50%",
      netincgrow_u100: "Dưới -100%",
      netincgrow_o10: "Trên 10%",
      netincgrow_o20: "Trên 20%",
      netincgrow_o50: "Trên 50%",
      netincgrow_o100: "Trên 100%",

      ltdc: "Nợ Dài Hạn / Vốn Hóa",
      ltdc_high: "Cao (>0.5)",
      ltdc_low: "Thấp (<0.1)",
      ltdc_u1: "Dưới 1",
      ltdc_u0_9: "Dưới 0.9",
      ltdc_u0_8: "Dưới 0.8",
      ltdc_u0_7: "Dưới 0.7",
      ltdc_u0_6: "Dưới 0.6",
      ltdc_u0_5: "Dưới 0.5",
      ltdc_u0_4: "Dưới 0.4",
      ltdc_u0_3: "Dưới 0.3",
      ltdc_u0_2: "Dưới 0.2",
      ltdc_u0_1: "Dưới 0.1",
      ltdc_o0_1: "Trên 0.1",
      ltdc_o0_2: "Trên 0.2",
      ltdc_o0_3: "Trên 0.3",
      ltdc_o0_4: "Trên 0.4",
      ltdc_o0_5: "Trên 0.5",
      ltdc_o0_6: "Trên 0.6",
      ltdc_o0_7: "Trên 0.7",
      ltdc_o0_8: "Trên 0.8",
      ltdc_o0_9: "Trên 0.9",
      ltdc_o1: "Trên 1",

      grossMargin: "Biên lợi nhuận gộp",
      grossm_pos: "Dương (>0%)",
      grossm_neg: "Âm (<0%)",
      grossm_u90: "Dưới 90%",
      grossm_u70: "Dưới 70%",
      grossm_u50: "Dưới 50%",
      grossm_u30: "Dưới 30%",
      grossm_u10: "Dưới 10%",
      grossm_u5: "Dưới 5%",
      grossm_u_10: "Dưới -10%",
      grossm_u_30: "Dưới -30%",
      grossm_u_50: "Dưới -50%",
      grossm_u_70: "Dưới -70%",
      grossm_u_100: "Dưới -100%",
      grossm_o0: "Trên 0%",
      grossm_o10: "Trên 10%",
      grossm_o30: "Trên 30%",
      grossm_o50: "Trên 50%",
      grossm_o70: "Trên 70%",
      grossm_o90: "Trên 90%",

      deRatio: "Nợ/Vốn chủ sở hữu",
      deratio_high: "Cao (>0.5)",
      deratio_low: "Thấp (<0.1)",
      deratio_u1: "Dưới 1",
      deratio_u0_9: "Dưới 0.9",
      deratio_u0_8: "Dưới 0.8",
      deratio_u0_7: "Dưới 0.7",
      deratio_u0_6: "Dưới 0.6",
      deratio_u0_5: "Dưới 0.5",
      deratio_u0_4: "Dưới 0.4",
      deratio_u0_3: "Dưới 0.3",
      deratio_u0_2: "Dưới 0.2",
      deratio_u0_1: "Dưới 0.1",
      deratio_o0_1: "Trên 0.1",
      deratio_o0_2: "Trên 0.2",
      deratio_o0_3: "Trên 0.3",
      deratio_o0_4: "Trên 0.4",
      deratio_o0_5: "Trên 0.5",
      deratio_o0_6: "Trên 0.6",
      deratio_o0_7: "Trên 0.7",
      deratio_o0_8: "Trên 0.8",
      deratio_o0_9: "Trên 0.9",
      deratio_o1: "Trên 1",

      netProfitMargin: "Biên lợi nhuận ròng",
      netprf_pos: "Dương (>0%)",
      netprf_neg: "Âm (<0%)",
      netprf_veryneg: "Rất âm (<-20%)",
      netprf_high: "Cao (>20%)",
      netprf_u90: "Dưới 90%",
      netprf_u80: "Dưới 80%",
      netprf_u50: "Dưới 50%",
      netprf_u30: "Dưới 30%",
      netprf_u10: "Dưới 10%",
      netprf_u5: "Dưới 5%",
      netprf_u_10: "Dưới -10%",
      netprf_u_30: "Dưới -30%",
      netprf_u_50: "Dưới -50%",
      netprf_u_100: "Dưới -100%",
      netprf_o10: "Trên 10%",
      netprf_o30: "Trên 30%",
      netprf_o40: "Trên 40%",
      netprf_o50: "Trên 50%",
      netprf_o80: "Trên 80%",
      netprf_o90: "Trên 90%",

      dividendYield: "Tỷ Suất Cổ Tức",
      divy_none: "Không (0%)",
      divy_pos: "Dương (>0%)",
      divy_high: "Cao (>5%)",
      divy_veryhigh: "Rất Cao (>10%)",
      divy_o1: "Trên 1%",
      divy_o2: "Trên 2%",
      divy_o3: "Trên 3%",
      divy_o4: "Trên 4%",
      divy_o5: "Trên 5%",
      divy_o6: "Trên 6%",
      divy_o7: "Trên 7%",
      divy_o8: "Trên 8%",
      divy_o9: "Trên 9%",
      divy_o10: "Trên 10%",

      payoutRatio: "Tỷ Lệ Chi Trả",
      payoutr_none: "Không (0%)",
      payoutr_pos: "Dương (>0%)",
      payoutr_low: "Thấp (<20%)",
      payoutr_high: "Cao (>50%)",
      payoutr_o10: "Trên 10%",
      payoutr_o20: "Trên 20%",
      payoutr_o50: "Trên 50%",
      payoutr_o80: "Trên 80%",
      payoutr_o100: "Trên 100%",
      payoutr_u10: "Dưới 10%",
      payoutr_u20: "Dưới 20%",
      payoutr_u50: "Dưới 50%",
      payoutr_u80: "Dưới 80%",
      payoutr_u100: "Dưới 100%",

      peHistoric: "P/E Lịch sử",
      pehistoric_below: "Dưới mức trung bình lịch sử",
      pehistoric_above: "Trên mức trung bình lịch sử",
      pbHistoric: "P/B Lịch sử",
      pbhistoric_below: "Dưới mức trung bình lịch sử",
      pbhistoric_above: "Trên mức trung bình lịch sử",
      psHistoric: "P/S Lịch sử",
      pshistoric_below: "Dưới mức trung bình lịch sử",
      pshistoric_above: "Trên mức trung bình lịch sử",
      pfcf: "Giá/FCF",
      pfcf_low: "Thấp (<15)",
      pfcf_high: "Cao (>50)",
      pfcf_u10: "Dưới 10",
      pfcf_u20: "Dưới 20",
      pfcf_u50: "Dưới 50",
      pfcf_u80: "Dưới 80",
      pfcf_u100: "Dưới 100",
      pfcf_o10: "Trên 10",
      pfcf_o20: "Trên 20",
      pfcf_o50: "Trên 50",
      pfcf_o80: "Trên 80",
      pfcf_o100: "Trên 100",

      operatingMargin: "Biên lợi nhuận hoạt động",
      opmr_pos: "Dương (>0%)",
      opmr_neg: "Âm (<0%)",
      opmr_veryneg: "Rất âm (<-20%)",
      opmr_high: "Cao (>25%)",
      opmr_u90: "Dưới 90%",
      opmr_u80: "Dưới 80%",
      opmr_u70: "Dưới 70%",
      opmr_u60: "Dưới 60%",
      opmr_u50: "Dưới 50%",
      opmr_u40: "Dưới 40%",
      opmr_u30: "Dưới 30%",
      opmr_u20: "Dưới 20%",
      opmr_u10: "Dưới 10%",
      opmr_u_m10: "Dưới -10%",
      opmr_u_m30: "Dưới -30%",
      opmr_u_m50: "Dưới -50%",
      opmr_u_m100: "Dưới -100%",
      opmr_o10: "Trên 10%",
      opmr_o20: "Trên 20%",
      opmr_o30: "Trên 30%",
      opmr_o40: "Trên 40%",
      opmr_o50: "Trên 50%",
      opmr_o60: "Trên 60%",
      opmr_o70: "Trên 70%",
      opmr_o80: "Trên 80%",
      opmr_o90: "Trên 90%",

      pricecash: "Giá/tiền mặt",
      pricecash_low: "Thấp (<3)",
      pricecash_high: "Cao (>50)",
      pricecash_u1: "Dưới 1",
      pricecash_u2: "Dưới 2",
      pricecash_u3: "Dưới 3",
      pricecash_u4: "Dưới 4",
      pricecash_u5: "Dưới 5",
      pricecash_u6: "Dưới 6",
      pricecash_u7: "Dưới 7",
      pricecash_u8: "Dưới 8",
      pricecash_u9: "Dưới 9",
      pricecash_u10: "Dưới 10",
      pricecash_o1: "Trên 1",
      pricecash_o2: "Trên 2",
      pricecash_o3: "Trên 3",
      pricecash_o4: "Trên 4",
      pricecash_o5: "Trên 5",
      pricecash_o6: "Trên 6",
      pricecash_o7: "Trên 7",
      pricecash_o8: "Trên 8",
      pricecash_o9: "Trên 9",
      pricecash_o10: "Trên 10",
      pricecash_o20: "Trên 20",
      pricecash_o30: "Trên 30",
      pricecash_o40: "Trên 40",
      pricecash_o50: "Trên 50",
      exchange: "Sàn giao dịch",
      any: "Bất kỳ",
      nasdaq: "NASDAQ",
      nyse: "NYSE",
      amex: "AMEX",
      marketCap: "Vốn hóa thị trường",
      mcap_mega: "Siêu lớn (200 tỷ đô trở lên)",
      mcap_large: "Lớn (10 tỷ đến 200 tỷ đô)",
      mcap_mid: "Trung bình (2 tỷ đến 10 tỷ đô)",
      mcap_small: "Nhỏ (300 triệu đến 2 tỷ đô)",
      mcap_micro: "Siêu nhỏ (50 triệu đến 300 triệu đô)",
      mcap_nano: "Nano (dưới 50 triệu đô)",
      mcap_largeover: "+Lớn (hơn 10 tỷ đô)",
      mcap_midover: "+Trung bình (hơn 2 tỷ đô)",
      mcap_smallover: "+Nhỏ (hơn 300 triệu đô)",
      mcap_microover: "+Siêu nhỏ (hơn 50 triệu đô)",
      mcap_largeunder: "-Lớn (dưới 200 tỷ đô)",
      mcap_midunder: "-Trung bình (dưới 10 tỷ đô)",
      mcap_smallunder: "-Nhỏ (dưới 2 tỷ đô)",
      mcap_microunder: "-Siêu nhỏ (dưới 300 triệu đô)",

      performance: "Hiệu suất",
      perf_dup: "Tăng trong ngày",
      perf_ddown: "Giảm trong ngày",
      perf_d15u: "Trong ngày -15%",
      perf_d10u: "Trong ngày -10%",
      perf_d10o: "Trong ngày +10%",
      perf_d15o: "Trong ngày +15%",
      perf_1w30u: "Tuần -30%",
      perf_1w10u: "Tuần -10%",
      perf_1wdown: "Tuần giảm",
      perf_1wup: "Tuần tăng",
      perf_1w10o: "Tuần +10%",
      perf_1w30o: "Tuần +30%",
      perf_4w50u: "Tháng -50%",
      perf_4w30u: "Tháng -30%",
      perf_4w10u: "Tháng -10%",
      perf_4wdown: "Tháng giảm",
      perf_4wup: "Tháng tăng",
      perf_4w20o: "Tháng +20%",
      perf_4w30o: "Tháng +30%",
      perf_4w50o: "Tháng +50%",
      perf_13w50u: "Quý -50%",
      perf_13w30u: "Quý -30%",
      perf_13w10u: "Quý -10%",
      perf_13wdown: "Quý giảm",
      perf_13wup: "Quý tăng",
      perf_13w10o: "Quý +10%",
      perf_13w30o: "Quý +30%",
      perf_13w50o: "Quý +50%",
      perf_26w75u: "Nửa năm -75%",
      perf_26w50u: "Nửa năm -50%",
      perf_26w20u: "Nửa năm -20%",
      perf_26w10u: "Nửa năm -10%",
      perf_26wdown: "Nửa năm giảm",
      perf_26wup: "Nửa năm tăng",
      perf_26w10o: "Nửa năm +10%",
      perf_26w20o: "Nửa năm +20%",
      perf_26w50o: "Nửa năm +50%",
      perf_26w100o: "Nửa năm +100%",
      perf_52w75u: "Năm -75%",
      perf_52w50u: "Năm -50%",
      perf_52w30u: "Năm -30%",
      perf_52w10u: "Năm -10%",
      perf_52wdown: "Năm giảm",
      perf_52wup: "Năm tăng",
      perf_52w10o: "Năm +10%",
      perf_52w50o: "Năm +50%",
      perf_52w100o: "Năm +100%",
      perf_52w300o: "Năm +300%",
      perf_52w500o: "Năm +500%",
      perf_ytd75u: "Từ đầu năm -75%",
      perf_ytd50u: "Từ đầu năm -50%",
      perf_ytd30u: "Từ đầu năm -30%",
      perf_ytd10u: "Từ đầu năm -10%",
      perf_ytddown: "Từ đầu năm giảm",
      perf_ytdup: "Từ đầu năm tăng",
      perf_ytd10o: "Từ đầu năm +10%",
      perf_ytd30o: "Từ đầu năm +30%",
      perf_ytd50o: "Từ đầu năm +50%",
      perf_ytd100o: "Từ đầu năm +100%",

      pe: "P / E",
      pe_low: "Thấp (<15)",
      pe_profitable: "Có lãi (>0)",
      pe_high: "Cao (>50)",
      pe_u5: "Dưới 5",
      pe_u10: "Dưới 10",
      pe_u15: "Dưới 15",
      pe_u20: "Dưới 20",
      pe_u30: "Dưới 30",
      pe_u40: "Dưới 40",
      pe_u50: "Dưới 50",
      pe_o5: "Trên 5",
      pe_o10: "Trên 10",
      pe_o15: "Trên 15",
      pe_o20: "Trên 20",
      pe_o30: "Trên 30",
      pe_o40: "Trên 40",
      pe_o50: "Trên 50",
      peg: "PEG",
      peg_low: "Thấp (<1)",
      peg_high: "Cao (>2)",
      peg_u1: "Dưới 1",
      peg_u2: "Dưới 2",
      peg_u3: "Dưới 3",
      peg_o1: "Trên 1",
      peg_o2: "Trên 2",
      peg_o3: "Trên 3",

      pb: "P / B",
      pb_low: "Thấp (<1)",
      pb_high: "Cao (>5)",
      pb_u1: "Dưới 1",
      pb_u2: "Dưới 2",
      pb_u3: "Dưới 3",
      pb_u4: "Dưới 4",
      pb_u5: "Dưới 5",
      pb_u6: "Dưới 6",
      pb_u7: "Dưới 7",
      pb_u8: "Dưới 8",
      pb_u9: "Dưới 9",
      pb_u10: "Dưới 10",
      pb_o1: "Trên 1",
      pb_o2: "Trên 2",
      pb_o3: "Trên 3",
      pb_o4: "Trên 4",
      pb_o5: "Trên 5",
      pb_o6: "Trên 6",
      pb_o7: "Trên 7",
      pb_o8: "Trên 8",
      pb_o9: "Trên 9",
      pb_o10: "Trên 10",

      ps: "P / S",
      ps_low: "Thấp (<1)",
      ps_high: "Cao (>5)",
      ps_u1: "Dưới 1",
      ps_u2: "Dưới 2",
      ps_u3: "Dưới 3",
      ps_u4: "Dưới 4",
      ps_u5: "Dưới 5",
      ps_u6: "Dưới 6",
      ps_u7: "Dưới 7",
      ps_u8: "Dưới 8",
      ps_u9: "Dưới 9",
      ps_u10: "Dưới 10",
      ps_o1: "Trên 1",
      ps_o2: "Trên 2",
      ps_o3: "Trên 3",
      ps_o4: "Trên 4",
      ps_o5: "Trên 5",
      ps_o6: "Trên 6",
      ps_o7: "Trên 7",
      ps_o8: "Trên 8",
      ps_o9: "Trên 9",
      ps_o10: "Trên 10",

      quick: "Tỷ lệ Nhanh",
      quick_high: "Cao (>3)",
      quick_low: "Thấp (<0.5)",
      quick_u1: "Dưới 1",
      quick_u0_5: "Dưới 0.5",
      quick_o0_5: "Trên 0.5",
      quick_o1: "Trên 1",
      quick_o1_5: "Trên 1.5",
      quick_o2: "Trên 2",
      quick_o3: "Trên 3",
      quick_o4: "Trên 4",
      quick_o5: "Trên 5",
      quick_o10: "Trên 10",

      current: "Tỷ lệ Hiện tại",
      current_high: "Cao (>3)",
      current_low: "Thấp (<1)",
      current_u1: "Dưới 1",
      current_u0_5: "Dưới 0.5",
      current_o0_5: "Trên 0.5",
      current_o1: "Trên 1",
      current_o1_5: "Trên 1.5",
      current_o2: "Trên 2",
      current_o3: "Trên 3",
      current_o4: "Trên 4",
      current_o5: "Trên 5",
      current_o10: "Trên 10",

      sector: "Ngành",
      sector_realestate: "Bất động sản",
      sector_health: "Chăm sóc sức khỏe",
      sector_energy: "Năng lượng",
      sector_utilities: "Tiện ích",
      sector_cd: "Phòng vệ tiêu dùng",
      sector_fs: "Dịch vụ tài chính",
      sector_coms: "Dịch vụ viễn thông",
      sector_cs: "Dịch vụ tiêu dùng",
      sector_bm: "Vật liệu cơ bản",
      sector_i: "Công nghiệp",
      sector_cc: "Tiêu dùng chu kỳ",
      sector_t: "Công nghệ",
    },

    TopCompaniesTitle: "Các Công Ty Lớn Nhất tại Mỹ theo",
    TotalMarketCap: "Tổng Vốn Hóa Thị Trường",
    Companies: "Công ty",
    sortBy: "Sắp xếp theo",
    Terms: "Điều Khoản & Điều Kiện",
    Privacy: "Chính Sách Bảo Mật",
    logout: "Đăng xuất",
    portfolioLeader: "Người Dẫn Đầu Danh Mục Đầu Tư",
    portfolio: {
      symbol: "Mã cổ phiếu",
      portfolioName: "Tên danh mục",
      date: "Ngày",
      price: "Giá",
      noOfShares: "Số lượng cổ phiếu",
      buy: "Mua",
      sellTransactions: "Giao dịch bán",
      purchasePrice: "Giá mua",
      purchaseDate: "Ngày mua",
      priceSold: "Giá bán",
      dateSold: "Ngày bán",
      profitAndLoss: "Lợi nhuận & Lỗ",
      company: "Công ty",
      sector: "Ngành",
      currentPrice: "Giá hiện tại",
      avgPurchasePrice: "Giá mua trung bình",
      totalShares: "Tổng cổ phiếu",
      portfolioPct: "% của danh mục",
      capital: "Vốn",
      sell: "Bán",
      history: "Lịch sử",
      pctReturns: "Lợi nhuận %",
      name: "Tên",
      noOfCompanies: "Số Công ty",
      total: "Tổng",
    },
    login: {
      login: "Đăng Nhập",
      signup: "Đăng Ký",
      forgotpassword: "Quên Mật Khẩu",
      resetpassword: "Đặt Lại Mật Khẩu",
      email: "Email",
      password: "Mật Khẩu",
      forgotPasswordQ: "Quên mật khẩu?",
      createAccount: "Tạo tài khoản Stock Penguins mới",
      firstName: "Tên",
      lastName: "Họ",
      requestOTP: "Yêu cầu OTP",
      otp: "OTP",
    },
    stockScreener: "Bộ Lọc Cổ Phiếu",
    topPresets: "Cài Đặt Sẵn Hàng Đầu",
    myPortfolio: "Danh Mục Của Tôi",
    portfolioLeaderboard: "Bảng Xếp Hạng Danh Mục",
    peHistoric: "P/E Lịch Sử",
    SimilarCompanies: "Công Ty Tương Tự",
    Stock: "Cổ Phiếu",
    presetName: "Tên Cài Sẵn",
    createdBy: "Tạo Bởi",
    upvotes: "Lượt Thích",
    savePreset: "Lưu Cài Sẵn",
    share: "Chia Sẻ",
    save: "Lưu",
    selectPreset: "Chọn Cài Sẵn",
    loginToSP: "Đăng Nhập Vào Stock Penguins",
    clearFilters: "Xóa Bộ Lọc",

    today: "Thay đổi trong 1 Ngày",
    oneMonthChange: "Thay đổi trong 1 Tháng",
    oneYearChange: "Thay đổi trong 1 Năm",
    OneYear: "1 Năm",
    ThreeYear: "3 Năm",
    FiveYear: "5 Năm",
    TenYear: "10 Năm",
    CAGRDesc: "Thay đổi lịch sử theo thời gian",
    welcome: "Chào mừng",
    SearchBarPlaceholder: "Cổ phiếu nào sẽ tăng gấp 10 lần tiếp theo của bạn?",
    Search: "Tìm kiếm",
    Industry: "Ngành",
    Sector: "Lĩnh vực",
    MarketCap: "Vốn hóa thị trường",
    PE: "P / E",
    PS: "P / S",
    PB: "P / B",
    FCFYield: "Tỷ suất FCF",
    PEGRatio: "Tỷ lệ PEG",
    OverallRisk: "Điểm số Piotroski",
    week52HighPrice: "Giá cao nhất 52 tuần",
    PriceGrowthoverthepast5Years: "Tăng trưởng giá trong 5 năm qua",
    PriceGrowthoverthepast1Years: "Tăng trưởng giá trong năm qua",
    Profitability: "Có lãi? (Quý)",
    GrowingRevenue: "Doanh thu tăng trưởng? (Quý)",
    GrowingNetProfit: "Lợi nhuận ròng tăng trưởng? (Quý)",
    ProfitabilityAnnual: "Có lãi? (Hàng năm)",
    GrowingRevenueAnnual: "Doanh thu tăng trưởng? (Hàng năm)",
    GrowingNetProfitAnnual: "Lợi nhuận ròng tăng trưởng? (Hàng năm)",
    FinancialMetrics: "Tóm tắt định giá",
    BalanceSheet: "Chỉ số tài chính",
    Cash: "Tiền mặt",
    Debt: "Nợ",
    CashDebtRatio: "Tỷ lệ Tiền mặt/Nợ",
    DividendYield: "Tỷ suất cổ tức",
    PayoutRatio: "Tỷ lệ chi trả",
    FiveYearAvgDividendYield: "Tỷ suất cổ tức trung bình 5 năm",
    DividendGrowthRateoverthepast5Years:
      "Tỷ lệ tăng trưởng cổ tức trong 5 năm qua",
    QualityOfCompany: "Chất lượng công ty",
    Dividend: "Cổ tức",
    comparedToLastQuarter: "So với quý trước",
    comparedToLastYear: "So với năm trước",
    newTab: "Thẻ mới",
    compare: "So sánh",
    topCompanies: "Công ty hàng đầu",
    watchlist: "Danh sách theo dõi",
    rank: "Hạng",
    companyName: "Tên công ty",
    symbol: "Ký hiệu",
    marketCap: "Vốn hóa thị trường",
    price: "Giá",
    volume: "Khối lượng",
    country: "Quốc gia",
    joinDiscord: "Tham gia Discord",
    joinTelegram: "Tham gia Telegram",
    contactUs: "Liên hệ với chúng tôi",
    InTheLast365Days: "Trong 365 ngày qua",
    LastUpdated: "Cập nhật lần cuối",
    pages: "Trang",

    titles: {
      PE: "P / E",
      PS: "P / S",
      PB: "P / B",
      RevenueGrowth: "Tăng trưởng doanh thu %",
      NetIncomeGrowth: "Tăng trưởng thu nhập ròng %",
      Price: "Giá",
      MarketCap: "Vốn hóa thị trường",
      Revenue: "Doanh thu",
      RevenueVsNetIncome: "Doanh thu vs. Thu nhập ròng",
      NetIncome: "Thu nhập ròng",
      Ebitda: "EBITDA",
      Eps: "EPS",
      TotalExpenses: "Tổng chi phí",
      SharesIssued: "Cổ phiếu đang lưu hành",
      FreeCashFlow: "Dòng tiền tự do",
      OperatingCashFlow: "Dòng tiền hoạt động",
      Dividend: "Cổ tức",
      GrossMargin: "Biên lợi nhuận gộp",
      CashVsDebt: "Tiền mặt vs. Nợ",
    },
    description: {
      PE: "Tỷ lệ Giá trên Thu nhập (P/E) đo lường mức độ sẵn sàng của nhà đầu tư khi trả bao nhiêu cho mỗi đô la lợi nhuận của công ty; được tính bằng cách chia giá cổ phiếu cho thu nhập trên mỗi cổ phiếu. Tỷ lệ P/E cao có thể chỉ ra rằng nhà đầu tư kỳ vọng tăng trưởng mạnh trong tương lai hoặc cổ phiếu có thể bị định giá quá cao",
      PS: "Tỷ lệ Giá trên Doanh thu (P/S) đo lường mức độ sẵn sàng của nhà đầu tư khi trả bao nhiêu cho mỗi đô la doanh thu của công ty; được tính bằng cách chia giá cổ phiếu cho doanh thu trên mỗi cổ phiếu. Tỷ lệ P/S cao có thể chỉ ra rằng nhà đầu tư kỳ vọng doanh thu sẽ tăng trưởng mạnh trong tương lai hoặc cổ phiếu có thể bị định giá quá cao",
      PB: "Tỷ lệ Giá trên Giá trị Sổ sách (P/B) là tỷ lệ so sánh giá trị thị trường của công ty với giá trị sổ sách (tài sản trừ nợ phải trả); được tính bằng cách chia giá cổ phiếu cho giá trị sổ sách trên mỗi cổ phiếu. Tỷ lệ P/B cao có thể cho thấy sự tự tin mạnh mẽ của nhà đầu tư về hiệu suất tương lai của công ty hoặc cổ phiếu có thể bị định giá quá cao nếu công ty không có tăng trưởng tài sản mạnh",
      RevenueGrowth: "Tỷ lệ tăng trưởng doanh thu",
      NetIncomeGrowth: "Tỷ lệ tăng trưởng thu nhập ròng",
      Price: "Giá cổ phiếu lịch sử của công ty theo thời gian",
      MarketCap:
        "Tổng giá trị cổ phiếu lưu hành của một công ty theo thời gian",
      Revenue:
        "Tổng số tiền tạo ra từ các hoạt động kinh doanh, bán hàng hóa và dịch vụ theo thời gian",
      RevenueVsNetIncome:
        "So sánh giữa tổng doanh thu và thu nhập ròng của công ty theo thời gian, cho thấy tính bền vững của sự tăng trưởng của công ty. Tỷ lệ thấp hơn có thể chỉ ra rằng công ty đang phát triển lợi thế cạnh tranh và chiếm lĩnh thị phần",
      NetIncome:
        "Lợi nhuận mà công ty tạo ra sau khi đã trừ hết chi phí, thuế và chi phí từ tổng doanh thu theo thời gian",
      Ebitda:
        "Lợi nhuận trước lãi vay, thuế, khấu hao và khấu trừ của một công ty theo thời gian. Đo lường hiệu quả tài chính tổng thể và khả năng sinh lời của công ty từ các hoạt động cốt lõi",
      Eps: "Phần lợi nhuận của công ty được phân bổ cho mỗi cổ phiếu phổ thông đang lưu hành. Nó cho biết các cổ đông sẽ nhận được bao nhiêu tiền cho mỗi cổ phiếu họ sở hữu",
      TotalExpenses:
        "Tổng số chi phí mà công ty phải chịu trong việc thực hiện kinh doanh, bao gồm chi phí hoạt động, lãi vay, thuế, v.v. Biểu đồ có thể hiển thị tổng chi phí theo thời gian, chỉ ra xu hướng và hiệu quả chi phí",
      SharesIssued: "Tổng số cổ phiếu mà công ty đã phát hành",
      FreeCashFlow:
        "Dòng tiền mà công ty tạo ra sau khi tính đến dòng tiền ra để hỗ trợ hoạt động và duy trì tài sản cố định. Nó đại diện cho số tiền có sẵn để trả cổ tức, trả nợ hoặc tái đầu tư",
      OperatingCashFlow:
        "Dòng tiền tạo ra từ hoạt động kinh doanh của công ty theo thời gian. Nó cho thấy liệu công ty có thể tạo ra đủ dòng tiền dương để duy trì và phát triển hoạt động của mình hay không",
      Dividend:
        "Một phần lợi nhuận của công ty được phân phối cho các cổ đông, thường dưới dạng thanh toán bằng tiền mặt",
      GrossMargin:
        "Tỷ lệ phần trăm doanh thu còn lại sau khi trừ chi phí hàng hóa đã bán (COGS). Nó cho thấy công ty sản xuất và bán sản phẩm hiệu quả như thế nào",
      CashVsDebt:
        "Biểu đồ so sánh dự trữ tiền mặt của công ty với tổng nợ của nó. Nó giúp đánh giá tình hình tài chính và thanh khoản của công ty",
    },
    dataDescriptions: {
      peHistorical:
        "Tỷ lệ P/E trung bình trong 5 năm qua cung cấp độ phủ hợp lý về thời gian để nắm bắt các thay đổi trong xu hướng thị trường và hiệu suất của công ty. Nó cũng phản ánh các điều kiện kinh tế mới nhất, lãi suất và các phát triển trong ngành.",
      peHistoricalCompare:
        "Nếu tỷ lệ P/E cao hơn mức trung bình lịch sử, thường cho thấy sự đánh giá quá cao và kỳ vọng tăng trưởng cao, với những rủi ro tiềm ẩn nếu tăng trưởng không đạt được. Ngược lại, một tỷ lệ P/E dưới mức trung bình cho thấy sự đánh giá thấp do tâm lý bi quan của thị trường hoặc các vấn đề tạm thời về tăng trưởng. Điều quan trọng là phải xem xét các yếu tố kinh tế, ngành và cụ thể của công ty, vì một công ty phát triển trong ngành đang phát triển có thể biện minh cho mức định giá cao hơn.",
      MarketCap:
        "Tổng giá trị của cổ phiếu công ty; được tính bằng cách nhân giá cổ phiếu với tổng số cổ phiếu.",
      PE: "Tỷ lệ giá trên lợi nhuận (P/E) đo lường số tiền mà các nhà đầu tư sẵn sàng trả cho mỗi đô la lợi nhuận của công ty; được tính bằng cách chia giá cổ phiếu cho lợi nhuận trên mỗi cổ phiếu. Tỷ lệ P/E cao có thể cho thấy các nhà đầu tư kỳ vọng tỷ lệ tăng trưởng cao trong tương lai hoặc cổ phiếu có thể bị định giá quá cao.",
      PS: "Tỷ lệ giá trên doanh thu (P/S) đo lường số tiền mà các nhà đầu tư trả cho mỗi đô la doanh thu của công ty; được tính bằng cách chia giá cổ phiếu cho doanh thu trên mỗi cổ phiếu. Tỷ lệ P/S cao có thể cho thấy các nhà đầu tư kỳ vọng tăng trưởng doanh thu cao trong tương lai hoặc cổ phiếu có thể bị định giá quá cao.",
      PB: "Tỷ lệ giá trên giá trị sổ sách (P/B) là tỷ lệ so sánh giá trị thị trường của công ty với giá trị sổ sách của nó (tài sản trừ nợ); được tính bằng cách chia giá cổ phiếu cho giá trị sổ sách trên mỗi cổ phiếu. Tỷ lệ P/B cao có thể cho thấy sự tự tin mạnh mẽ của nhà đầu tư vào hiệu suất tương lai của công ty hoặc cổ phiếu có thể bị định giá quá cao nếu công ty không có sự tăng trưởng tài sản mạnh mẽ.",
      FCFYield:
        "Tỷ suất lợi nhuận từ dòng tiền tự do (FCF Yield) đo lường mức độ dòng tiền tự do mà công ty tạo ra so với giá trị thị trường của nó; được tính bằng cách chia dòng tiền tự do cho giá trị thị trường.",
      PEGRatio:
        "Tỷ lệ giá/lợi nhuận trên tăng trưởng (PEG) là một chỉ số định giá xem xét tỷ lệ P/E của công ty liên quan đến tỷ lệ tăng trưởng lợi nhuận của nó; giúp xác định xem cổ phiếu có bị định giá quá cao hay quá thấp. Tỷ lệ PEG bằng 1 cho thấy giá trị hợp lý, dưới 1 cho thấy định giá thấp so với tỷ lệ tăng trưởng, và trên 1 cho thấy định giá cao so với tỷ lệ tăng trưởng.",
      OverallRisk:
        "Đánh giá mức độ rủi ro của một khoản đầu tư, xét đến các yếu tố như biến động, sự ổn định tài chính và điều kiện thị trường; rủi ro thấp thường tốt hơn, nhưng phụ thuộc vào mức độ chấp nhận rủi ro của nhà đầu tư.",
      week52HighPrice:
        "Giá cao nhất mà cổ phiếu đã đạt được trong năm qua; cổ phiếu giao dịch gần mức cao nhất trong 52 tuần có thể cho thấy hiệu suất gần đây mạnh mẽ, nhưng cũng có thể cho thấy nó bị định giá quá cao.",
      ProfitabilityAnnual:
        "Công ty có kiếm được nhiều tiền hơn số tiền đã chi trong năm qua không; tính hiệu quả là dấu hiệu tốt cho sức khỏe tài chính của công ty.",
      GrowingRevenueAnnual:
        "Doanh thu của công ty có tăng so với năm trước không; sự tăng trưởng doanh thu liên tục thường là dấu hiệu tích cực của sự mở rộng kinh doanh.",
      GrowingNetProfitAnnual:
        "Lợi nhuận ròng của công ty (thu nhập sau chi phí) có tăng so với năm trước không; lợi nhuận ròng tăng là chỉ số mạnh mẽ về sự cải thiện hiệu suất tài chính.",
      PriceGrowthoverthepast5Years:
        "Tỷ lệ tăng giá cổ phiếu trong 5 năm qua; tỷ lệ cao hơn cho thấy hiệu suất dài hạn tốt hơn.",
      PriceGrowthoverthepast1Years:
        "Tỷ lệ tăng giá cổ phiếu trong năm qua; tăng trưởng ngắn hạn mạnh mẽ là tích cực nhưng nên được xem xét trong bối cảnh các yếu tố khác.",
      Cash: "Tổng số tiền lỏng mà công ty có sẵn để sử dụng ngay lập tức; nhiều tiền mặt thường tốt hơn cho sự linh hoạt tài chính.",
      Debt: "Tổng số tiền mà công ty nợ các chủ nợ; nợ cao có thể rủi ro nếu công ty không thể tạo ra đủ thu nhập để trả nợ.",
      CashDebtRatio:
        "Đo lường khả năng của công ty để thanh toán nợ bằng tiền mặt sẵn có; tỷ lệ cao hơn cho thấy sức khỏe tài chính tốt hơn.",
      Profitability:
        "Công ty có kiếm được nhiều tiền hơn số tiền đã chi trong quý gần nhất không; việc có lợi nhuận hàng quý cho thấy công ty đang liên tục tạo ra thu nhập.",
      GrowingRevenue:
        "Doanh thu của công ty có tăng so với quý trước không; tăng trưởng doanh thu liên tục là dấu hiệu của sự thành công kinh doanh liên tục.",
      GrowingNetProfit:
        "Lợi nhuận ròng của công ty có tăng so với quý trước không; lợi nhuận ròng tăng hàng quý cho thấy sự cải thiện hiệu suất tài chính trong ngắn hạn.",
      DividendYield:
        "Khoản thanh toán cổ tức hàng năm mà công ty trả cho các cổ đông, được biểu thị dưới dạng tỷ lệ phần trăm của giá cổ phiếu; tỷ suất cổ tức cao hơn có thể cho thấy thu nhập từ cổ tức nhiều hơn so với giá cổ phiếu.",
      PayoutRatio:
        "Tỷ lệ phần trăm lợi nhuận của công ty được trả dưới dạng cổ tức cho các cổ đông; tỷ lệ thấp hơn cho thấy công ty giữ lại nhiều lợi nhuận hơn để phát triển, trong khi tỷ lệ rất cao có thể cho thấy cổ tức có thể không bền vững.",
      FiveYearAvgDividendYield:
        "Tỷ suất cổ tức trung bình mà công ty đã cung cấp trong 5 năm qua; giúp các nhà đầu tư thấy mức độ nhất quán hoặc ổn định của việc thanh toán cổ tức theo thời gian.",
    },
    labels: {
      TotalCash: "Tổng tiền mặt",
      TotalDebt: "Tổng nợ",
      Revenue: "Doanh thu",
      NetIncome: "Thu nhập ròng",
    },
    Quarterly: "Hàng quý",
    Annually: "Hàng năm",
    FullScreen: "Toàn màn hình",
    ExitFullScreen: "Thoát chế độ toàn màn hình",
    homeMetaTitle: "StockPenguins | Máy Quét Cổ Phiếu Toàn Cầu Miễn Phí",
    homeMetaDescription:
      "Khám phá ứng dụng quét cổ phiếu miễn phí tuyệt vời nhất cho cổ phiếu Mỹ như Apple, Tesla, Amazon và Microsoft. Truy cập các chỉ số chi tiết như Giá, Vốn Hóa Thị Trường, Doanh Thu, Lợi Nhuận Ròng, EBITDA, Dòng Tiền Tự Do, EPS, Cổ Tức, Tỷ Lệ P/E và nhiều hơn nữa. Phân tích và so sánh cổ phiếu một cách dễ dàng để đưa ra quyết định đầu tư thông minh. Tìm kiếm ngay!",
    pageMetaTitle: "Chỉ Số Cổ Phiếu | P/E, Doanh Thu & Vốn Hóa Thị Trường",
    pageMetaDescription:
      "Khám phá các chỉ số miễn phí, bao gồm Giá, Vốn Hóa Thị Trường, Doanh Thu, Lợi Nhuận Ròng, EBITDA, Dòng Tiền Tự Do, EPS, Cổ Tức, Tỷ Lệ P/E và nhiều hơn nữa. Cập nhật thông tin về hiệu suất tài chính của Apple. Khám phá ngay!",
  },
  [LANGUAGES[10].value]: {
    // Bahasa Indonesia
    search: "Cari Saham",

    peHistorical: "Rata-rata Historis Rasio P/E 5 Tahun",
    peHistoricalCompare:
      "Di bawah atau di atas rata-rata historis P/E 5 tahun?",

    scanner: {
      industry: "Industri",
      ind_aginputs: "Input Pertanian",
      ind_indpollctrl: "Industri - Kontrol Polusi & Pengolahan",
      ind_indmaterials: "Bahan Industri",
      ind_autorv: "Otomotif - Kendaraan Rekreasi",
      ind_discstores: "Toko Diskon",
      ind_insdiv: "Asuransi - Diversifikasi",
      ind_entertainment: "Hiburan",
      ind_reitdiv: "REIT - Diversifikasi",
      ind_medicaldist: "Medis - Distribusi",
      ind_softinfra: "Perangkat Lunak - Infrastruktur",
      ind_softapp: "Perangkat Lunak - Aplikasi",
      ind_electricaleqp: "Peralatan & Suku Cadang Listrik",
      ind_astmgmtglobal: "Manajemen Aset - Global",
      ind_indpower: "Produsen Energi Mandiri",
      ind_oilgasdrill: "Minyak & Gas - Pengeboran",
      ind_leisure: "Rekreasi",
      ind_medicaldev: "Medis - Alat",
      ind_coal: "Batubara",
      ind_edu: "Layanan Pendidikan & Pelatihan",
      ind_reitind: "REIT - Industri",
      ind_autoparts: "Otomotif - Suku Cadang",
      ind_metalfab: "Manufaktur - Fabrikasi Logam",
      ind_fincredit: "Keuangan - Layanan Kredit",
      ind_broadcasting: "Siaran",
      ind_medcarefac: "Medis - Fasilitas Perawatan",
      ind_chemspecialty: "Bahan Kimia - Khusus",
      ind_astmgmt: "Manajemen Aset",
      ind_insreins: "Asuransi - Reasuransi",
      ind_travellodging: "Akomodasi Perjalanan",
      ind_findiv: "Keuangan - Diversifikasi",
      ind_banks: "Bank",
      ind_bevalcoholic: "Minuman - Beralkohol",
      ind_hardwareeqp: "Peralatan, Perkakas & Suku Cadang",
      ind_inddist: "Industri - Distribusi",
      ind_constrmat: "Bahan Konstruksi",
      ind_fooddist: "Distribusi Makanan",
      ind_bevwines: "Minuman - Pabrik Anggur & Penyulingan",
      ind_furnishings: "Perabotan, Perlengkapan & Perangkat",
      ind_silver: "Perak",
      ind_specialtybiz: "Layanan Bisnis Khusus",
      ind_apparelmanu: "Pakaian - Produsen",
      ind_publishing: "Penerbitan",
      ind_finmortgages: "Keuangan - Hipotek",
      ind_railroads: "Jalur Kereta",
      ind_insprop: "Asuransi - Properti & Kecelakaan",
      ind_telco: "Layanan Telekomunikasi",
      ind_reitretail: "REIT - Ritel",
      ind_internetcontent: "Konten & Informasi Internet",
      ind_indinfrastructure: "Industri - Operasi Infrastruktur",
      ind_regwater: "Air Terkontrol",
      ind_packaging: "Pengemasan & Kontainer",
      ind_realeservices: "Real Estat - Layanan",
      ind_reddiversified: "Real Estat - Diversifikasi",
      ind_renewutilities: "Utilitas Terbarukan",
      ind_preciousmetals: "Logam Mulia Lainnya",
      ind_autodealerships: "Otomotif - Dealer",
      ind_foodconf: "Pabrik Makanan",
      ind_toolsmfg: "Manufaktur - Perkakas & Aksesori",
      ind_findata: "Keuangan - Data & Bursa Saham",
      ind_adagencies: "Agen Periklanan",
      ind_luxurygoods: "Barang Mewah",
      ind_householdprod: "Produk Rumah Tangga & Pribadi",
      ind_reitresidential: "REIT - Residensial",
      ind_deptstores: "Toko Serba Ada",
      ind_personalprod: "Produk & Layanan Pribadi",
      ind_paperlumber: "Kertas, Kayu & Produk Hutan",
      ind_staffing: "Layanan Penyediaan Tenaga Kerja",
      ind_resconstr: "Konstruksi Residensial",
      ind_healthcare: "Perawatan Kesehatan",
      ind_construction: "Konstruksi",
      ind_specialtyretail: "Ritel Khusus",
      ind_mediaentertain: "Media & Hiburan",
      ind_realdev: "Real Estat - Pengembangan",
      ind_astmgmtincome: "Manajemen Aset - Pendapatan",
      ind_engconst: "Rekayasa & Konstruksi",
      ind_gambling: "Perjudian, Resor & Kasino",
      ind_reitoffice: "REIT - Kantor",
      ind_finservices: "Layanan Keuangan",
      ind_drugmanugen: "Produsen Obat - Umum",
      ind_homeimprovement: "Perbaikan Rumah",
      ind_steel: "Baja",
      ind_regionalbanks: "Bank - Regional",
      ind_agfarmproducts: "Produk Pertanian",
      ind_conglomerates: "Konglomerat",
      ind_medhealthcareplans: "Medis - Rencana Kesehatan",
      ind_chemicals: "Bahan Kimia",
      ind_oilmidstream: "Minyak & Gas - Midstream",
      ind_oilexp: "Minyak & Gas - Eksplorasi & Produksi",
      ind_computerhardware: "Perangkat Keras Komputer",
      ind_banksdiversified: "Bank - Diversifikasi",
      ind_restaurants: "Restoran",
      ind_oilintegrated: "Minyak & Gas - Terintegrasi",
      ind_divutilities: "Utilitas Diversifikasi",
      ind_reithotel: "REIT - Hotel & Motel",
      ind_gaming: "Permainan Elektronik & Multimedia",
      ind_medpharma: "Medis - Farmasi",
      ind_grocerystores: "Toko Kelontong",
      ind_wastemgmt: "Pengelolaan Limbah",
      ind_reggas: "Gas Terkontrol",
      ind_trucking: "Pengangkutan",
      ind_investbanking: "Investasi - Layanan Perbankan & Investasi",
      ind_automanufact: "Otomotif - Produsen",
      ind_reitmortgage: "REIT - Hipotek",
      ind_biotech: "Bioteknologi",
      ind_techdistrib: "Distribusi Teknologi",
      ind_bizequip: "Peralatan & Suplai Bisnis",
      ind_apparelfoot: "Pakaian - Alas Kaki & Aksesori",
      ind_medicalspec: "Medis - Spesialis",
      ind_apparelretail: "Pakaian - Ritel",
      ind_regelectric: "Listrik Terkontrol",
      ind_finconglomerates: "Keuangan - Konglomerat",
      ind_oilrefining: "Minyak & Gas - Pengolahan & Pemasaran",
      ind_reitspecialty: "REIT - Khusus",
      ind_agmachinery: "Pertanian - Mesin",
      ind_packagedfoods: "Makanan Kemasan",
      ind_reithealthcare: "REIT - Fasilitas Kesehatan",
      ind_solar: "Solar",
      ind_copper: "Tembaga",
      ind_gold: "Emas",
      ind_medicalsupp: "Medis - Alat & Perlengkapan",
      ind_insurancebrokers: "Asuransi - Pialang",
      ind_security: "Layanan Keamanan & Perlindungan",
      ind_tobacco: "Tembakau",
      ind_fincapmarkets: "Keuangan - Pasar Modal",
      ind_mfgmisc: "Manufaktur - Beragam",
      ind_uranium: "Uranium",
      ind_insurancelife: "Asuransi - Jiwa",
      ind_indmachinery: "Industri - Mesin",
      ind_shellcompanies: "Perusahaan Cangkang",
      ind_consumelectronics: "Elektronik Konsumen",
      ind_medicaldiag: "Medis - Diagnostik & Riset",
      ind_oilequip: "Peralatan & Layanan Minyak & Gas",
      ind_commequip: "Peralatan Komunikasi",
      ind_aerospace: "Dirgantara & Pertahanan",
      ind_travelservices: "Layanan Perjalanan",
      ind_drugmanuspecialty: "Produsen Obat - Khusus & Umum",
      ind_indprotection: "Industri - Proteksi",
      ind_healthcaredistribution: "Distribusi Kesehatan",
      ind_entertainmentdiversified: "Hiburan - Diversifikasi",
      ind_electronicsdiversified: "Elektronik - Diversifikasi",
      ind_petrochemicals: "Petrokimia",
      ind_gas: "Gas",
      ind_apparelsoft: "Pakaian - Perangkat Lunak & Tekstil",
      ind_retaildiversified: "Ritel - Diversifikasi",
      ind_fastfood: "Makanan Cepat Saji",
      ind_constructionprods: "Produk Konstruksi",
      ind_indhospitals: "Industri - Rumah Sakit",
      ind_construcntasks: "Konstruksi - Tugas",
      ind_drugretail: "Ritel Obat",
      ind_transportation: "Transportasi",
      ind_gasindep: "Gas - Mandiri",
      ind_biotechlife: "Bioteknologi - Hidup",
      ind_retailproducts: "Produk Ritel",
      ind_autorepair: "Otomotif - Perbaikan",
      ind_foodservices: "Layanan Makanan",
      ind_homefurnishings: "Perabotan Rumah Tangga",
      ind_oilrigs: "Minyak & Gas - Rig",
      ind_cement: "Semen",
      ind_autoindustry: "Industri Otomotif",
      ind_broadband: "Brosur Lebar",
      ind_petrochemicals: "Petrokimia",
      ind_securityservices: "Layanan Keamanan",
      ind_biofuels: "Bahan Bakar Biodegradasi",
      ind_logistics: "Logistik",
      ind_gasprocessing: "Pengolahan Gas",
      ind_automotiveaftermarket: "Pasar Setelah Penjualan Otomotif",
      ind_aluminum: "Aluminium",
      ind_itservices: "Layanan Teknologi Informasi",
      ind_medhealthinfo: "Medis - Layanan Informasi Kesehatan",
      ind_bevnonalcoholic: "Minuman - Non-Alkohol",
      ind_consulting: "Layanan Konsultasi",
      ind_rentleasing: "Layanan Sewa & Leasing",
      ind_marineshipping: "Pengiriman Maritim",
      ind_software: "Perangkat Lunak - Layanan",
      ind_astmgmtbonds: "Manajemen Aset - Obligasi",
      ind_freightlogistics: "Freight Terintegrasi & Logistik",
      ind_insspec: "Asuransi - Khusus",
      ind_semiconductors: "Semikonduktor",
      ind_airlines: "Maskapai Penerbangan, Bandara & Layanan Udara",

      revgrowavg: "Pertumbuhan Pendapatan 5 Tahun Terakhir",
      revgrowavg_neg: "Negatif (<0%)",
      revgrowavg_pos: "Positif (>0%)",
      revgrowavg_u10: "Di Bawah -10%",
      revgrowavg_u20: "Di Bawah -20%",
      revgrowavg_u50: "Di Bawah -50%",
      revgrowavg_u100: "Di Bawah -100%",
      revgrowavg_o10: "Di Atas 10%",
      revgrowavg_o20: "Di Atas 20%",
      revgrowavg_o50: "Di Atas 50%",
      revgrowavg_o100: "Di Atas 100%",

      revgrow: "Pertumbuhan Pendapatan q/q",
      revgrow_neg: "Negatif (<0%)",
      revgrow_pos: "Positif (>0%)",
      revgrow_u10: "Di Bawah -10%",
      revgrow_u20: "Di Bawah -20%",
      revgrow_u50: "Di Bawah -50%",
      revgrow_u100: "Di Bawah -100%",
      revgrow_o10: "Di Atas 10%",
      revgrow_o20: "Di Atas 20%",
      revgrow_o50: "Di Atas 50%",
      revgrow_o100: "Di Atas 100%",

      netincgrow: "Pertumbuhan Pendapatan Bersih q/q",
      netincgrow_neg: "Negatif (<0%)",
      netincgrow_pos: "Positif (>0%)",
      netincgrow_u10: "Di Bawah -10%",
      netincgrow_u20: "Di Bawah -20%",
      netincgrow_u50: "Di Bawah -50%",
      netincgrow_u100: "Di Bawah -100%",
      netincgrow_o10: "Di Atas 10%",
      netincgrow_o20: "Di Atas 20%",
      netincgrow_o50: "Di Atas 50%",
      netincgrow_o100: "Di Atas 100%",

      ltdc: "Utang Jangka Panjang / Kapitalisasi",
      ltdc_high: "Tinggi (>0.5)",
      ltdc_low: "Rendah (<0.1)",
      ltdc_u1: "Di Bawah 1",
      ltdc_u0_9: "Di Bawah 0.9",
      ltdc_u0_8: "Di Bawah 0.8",
      ltdc_u0_7: "Di Bawah 0.7",
      ltdc_u0_6: "Di Bawah 0.6",
      ltdc_u0_5: "Di Bawah 0.5",
      ltdc_u0_4: "Di Bawah 0.4",
      ltdc_u0_3: "Di Bawah 0.3",
      ltdc_u0_2: "Di Bawah 0.2",
      ltdc_u0_1: "Di Bawah 0.1",
      ltdc_o0_1: "Di Atas 0.1",
      ltdc_o0_2: "Di Atas 0.2",
      ltdc_o0_3: "Di Atas 0.3",
      ltdc_o0_4: "Di Atas 0.4",
      ltdc_o0_5: "Di Atas 0.5",
      ltdc_o0_6: "Di Atas 0.6",
      ltdc_o0_7: "Di Atas 0.7",
      ltdc_o0_8: "Di Atas 0.8",
      ltdc_o0_9: "Di Atas 0.9",
      ltdc_o1: "Di Atas 1",

      grossMargin: "Margin Kotor",
      grossm_pos: "Positif (>0%)",
      grossm_neg: "Negatif (<0%)",
      grossm_u90: "Di Bawah 90%",
      grossm_u70: "Di Bawah 70%",
      grossm_u50: "Di Bawah 50%",
      grossm_u30: "Di Bawah 30%",
      grossm_u10: "Di Bawah 10%",
      grossm_u5: "Di Bawah 5%",
      grossm_u_10: "Di Bawah -10%",
      grossm_u_30: "Di Bawah -30%",
      grossm_u_50: "Di Bawah -50%",
      grossm_u_70: "Di Bawah -70%",
      grossm_u_100: "Di Bawah -100%",
      grossm_o0: "Di Atas 0%",
      grossm_o10: "Di Atas 10%",
      grossm_o30: "Di Atas 30%",
      grossm_o50: "Di Atas 50%",
      grossm_o70: "Di Atas 70%",
      grossm_o90: "Di Atas 90%",

      deRatio: "Utang/Modal",
      deratio_high: "Tinggi (>0.5)",
      deratio_low: "Rendah (<0.1)",
      deratio_u1: "Di Bawah 1",
      deratio_u0_9: "Di Bawah 0.9",
      deratio_u0_8: "Di Bawah 0.8",
      deratio_u0_7: "Di Bawah 0.7",
      deratio_u0_6: "Di Bawah 0.6",
      deratio_u0_5: "Di Bawah 0.5",
      deratio_u0_4: "Di Bawah 0.4",
      deratio_u0_3: "Di Bawah 0.3",
      deratio_u0_2: "Di Bawah 0.2",
      deratio_u0_1: "Di Bawah 0.1",
      deratio_o0_1: "Di Atas 0.1",
      deratio_o0_2: "Di Atas 0.2",
      deratio_o0_3: "Di Atas 0.3",
      deratio_o0_4: "Di Atas 0.4",
      deratio_o0_5: "Di Atas 0.5",
      deratio_o0_6: "Di Atas 0.6",
      deratio_o0_7: "Di Atas 0.7",
      deratio_o0_8: "Di Atas 0.8",
      deratio_o0_9: "Di Atas 0.9",
      deratio_o1: "Di Atas 1",

      netProfitMargin: "Margin Laba Bersih",
      netprf_pos: "Positif (>0%)",
      netprf_neg: "Negatif (<0%)",
      netprf_veryneg: "Sangat Negatif (<-20%)",
      netprf_high: "Tinggi (>20%)",
      netprf_u90: "Di Bawah 90%",
      netprf_u80: "Di Bawah 80%",
      netprf_u50: "Di Bawah 50%",
      netprf_u30: "Di Bawah 30%",
      netprf_u10: "Di Bawah 10%",
      netprf_u5: "Di Bawah 5%",
      netprf_u_10: "Di Bawah -10%",
      netprf_u_30: "Di Bawah -30%",
      netprf_u_50: "Di Bawah -50%",
      netprf_u_100: "Di Bawah -100%",
      netprf_o10: "Di Atas 10%",
      netprf_o30: "Di Atas 30%",
      netprf_o40: "Di Atas 40%",
      netprf_o50: "Di Atas 50%",
      netprf_o80: "Di Atas 80%",
      netprf_o90: "Di Atas 90%",

      dividendYield: "Hasil Dividen",
      divy_none: "Tidak Ada (0%)",
      divy_pos: "Positif (>0%)",
      divy_high: "Tinggi (>5%)",
      divy_veryhigh: "Sangat Tinggi (>10%)",
      divy_o1: "Lebih dari 1%",
      divy_o2: "Lebih dari 2%",
      divy_o3: "Lebih dari 3%",
      divy_o4: "Lebih dari 4%",
      divy_o5: "Lebih dari 5%",
      divy_o6: "Lebih dari 6%",
      divy_o7: "Lebih dari 7%",
      divy_o8: "Lebih dari 8%",
      divy_o9: "Lebih dari 9%",
      divy_o10: "Lebih dari 10%",

      payoutRatio: "Rasio Pembayaran",
      payoutr_none: "Tidak Ada (0%)",
      payoutr_pos: "Positif (>0%)",
      payoutr_low: "Rendah (<20%)",
      payoutr_high: "Tinggi (>50%)",
      payoutr_o10: "Lebih dari 10%",
      payoutr_o20: "Lebih dari 20%",
      payoutr_o50: "Lebih dari 50%",
      payoutr_o80: "Lebih dari 80%",
      payoutr_o100: "Lebih dari 100%",
      payoutr_u10: "Kurang dari 10%",
      payoutr_u20: "Kurang dari 20%",
      payoutr_u50: "Kurang dari 50%",
      payoutr_u80: "Kurang dari 80%",
      payoutr_u100: "Kurang dari 100%",

      peHistoric: "P/E Historis",
      pehistoric_below: "Di bawah rata-rata historis",
      pehistoric_above: "Di atas rata-rata historis",
      pbHistoric: "P/B Historis",
      pbhistoric_below: "Di bawah rata-rata historis",
      pbhistoric_above: "Di atas rata-rata historis",
      psHistoric: "P/S Historis",
      pshistoric_below: "Di bawah rata-rata historis",
      pshistoric_above: "Di atas rata-rata historis",
      pfcf: "Harga/FCF",
      pfcf_low: "Rendah (<15)",
      pfcf_high: "Tinggi (>50)",
      pfcf_u10: "Di bawah 10",
      pfcf_u20: "Di bawah 20",
      pfcf_u50: "Di bawah 50",
      pfcf_u80: "Di bawah 80",
      pfcf_u100: "Di bawah 100",
      pfcf_o10: "Di atas 10",
      pfcf_o20: "Di atas 20",
      pfcf_o50: "Di atas 50",
      pfcf_o80: "Di atas 80",
      pfcf_o100: "Di atas 100",

      operatingMargin: "Margin Operasi",
      opmr_pos: "Positif (>0%)",
      opmr_neg: "Negatif (<0%)",
      opmr_veryneg: "Sangat Negatif (<-20%)",
      opmr_high: "Tinggi (>25%)",
      opmr_u90: "Di Bawah 90%",
      opmr_u80: "Di Bawah 80%",
      opmr_u70: "Di Bawah 70%",
      opmr_u60: "Di Bawah 60%",
      opmr_u50: "Di Bawah 50%",
      opmr_u40: "Di Bawah 40%",
      opmr_u30: "Di Bawah 30%",
      opmr_u20: "Di Bawah 20%",
      opmr_u10: "Di Bawah 10%",
      opmr_u_m10: "Di Bawah -10%",
      opmr_u_m30: "Di Bawah -30%",
      opmr_u_m50: "Di Bawah -50%",
      opmr_u_m100: "Di Bawah -100%",
      opmr_o10: "Di Atas 10%",
      opmr_o20: "Di Atas 20%",
      opmr_o30: "Di Atas 30%",
      opmr_o40: "Di Atas 40%",
      opmr_o50: "Di Atas 50%",
      opmr_o60: "Di Atas 60%",
      opmr_o70: "Di Atas 70%",
      opmr_o80: "Di Atas 80%",
      opmr_o90: "Di Atas 90%",

      pricecash: "Harga/Tunai",
      pricecash_low: "Rendah (<3)",
      pricecash_high: "Tinggi (>50)",
      pricecash_u1: "Di Bawah 1",
      pricecash_u2: "Di Bawah 2",
      pricecash_u3: "Di Bawah 3",
      pricecash_u4: "Di Bawah 4",
      pricecash_u5: "Di Bawah 5",
      pricecash_u6: "Di Bawah 6",
      pricecash_u7: "Di Bawah 7",
      pricecash_u8: "Di Bawah 8",
      pricecash_u9: "Di Bawah 9",
      pricecash_u10: "Di Bawah 10",
      pricecash_o1: "Di Atas 1",
      pricecash_o2: "Di Atas 2",
      pricecash_o3: "Di Atas 3",
      pricecash_o4: "Di Atas 4",
      pricecash_o5: "Di Atas 5",
      pricecash_o6: "Di Atas 6",
      pricecash_o7: "Di Atas 7",
      pricecash_o8: "Di Atas 8",
      pricecash_o9: "Di Atas 9",
      pricecash_o10: "Di Atas 10",
      pricecash_o20: "Di Atas 20",
      pricecash_o30: "Di Atas 30",
      pricecash_o40: "Di Atas 40",
      pricecash_o50: "Di Atas 50",

      exchange: "Bursa",
      any: "Apapun",
      nasdaq: "NASDAQ",
      nyse: "NYSE",
      amex: "AMEX",
      marketCap: "Kapitalisasi Pasar",
      mcap_mega: "Mega ($200 miliar dan lebih)",
      mcap_large: "Besar ($10 miliar hingga $200 miliar)",
      mcap_mid: "Menengah ($2 miliar hingga $10 miliar)",
      mcap_small: "Kecil ($300 juta hingga $2 miliar)",
      mcap_micro: "Mikro ($50 juta hingga $300 juta)",
      mcap_nano: "Nano (di bawah $50 juta)",
      mcap_largeover: "+Besar (di atas $10 miliar)",
      mcap_midover: "+Menengah (di atas $2 miliar)",
      mcap_smallover: "+Kecil (di atas $300 juta)",
      mcap_microover: "+Mikro (di atas $50 juta)",
      mcap_largeunder: "-Besar (di bawah $200 miliar)",
      mcap_midunder: "-Menengah (di bawah $10 miliar)",
      mcap_smallunder: "-Kecil (di bawah $2 miliar)",
      mcap_microunder: "-Mikro (di bawah $300 juta)",

      performance: "Kinerja",
      perf_dup: "Naik Dalam Satu Hari",
      perf_ddown: "Turun Dalam Satu Hari",
      perf_d15u: "Dalam Satu Hari -15%",
      perf_d10u: "Dalam Satu Hari -10%",
      perf_d10o: "Dalam Satu Hari +10%",
      perf_d15o: "Dalam Satu Hari +15%",
      perf_1w30u: "Minggu -30%",
      perf_1w10u: "Minggu -10%",
      perf_1wdown: "Minggu Turun",
      perf_1wup: "Minggu Naik",
      perf_1w10o: "Minggu +10%",
      perf_1w30o: "Minggu +30%",
      perf_4w50u: "Bulan -50%",
      perf_4w30u: "Bulan -30%",
      perf_4w10u: "Bulan -10%",
      perf_4wdown: "Bulan Turun",
      perf_4wup: "Bulan Naik",
      perf_4w20o: "Bulan +20%",
      perf_4w30o: "Bulan +30%",
      perf_4w50o: "Bulan +50%",
      perf_13w50u: "Kuartal -50%",
      perf_13w30u: "Kuartal -30%",
      perf_13w10u: "Kuartal -10%",
      perf_13wdown: "Kuartal Turun",
      perf_13wup: "Kuartal Naik",
      perf_13w10o: "Kuartal +10%",
      perf_13w30o: "Kuartal +30%",
      perf_13w50o: "Kuartal +50%",
      perf_26w75u: "Setengah Tahun -75%",
      perf_26w50u: "Setengah Tahun -50%",
      perf_26w20u: "Setengah Tahun -20%",
      perf_26w10u: "Setengah Tahun -10%",
      perf_26wdown: "Setengah Tahun Turun",
      perf_26wup: "Setengah Tahun Naik",
      perf_26w10o: "Setengah Tahun +10%",
      perf_26w20o: "Setengah Tahun +20%",
      perf_26w50o: "Setengah Tahun +50%",
      perf_26w100o: "Setengah Tahun +100%",
      perf_52w75u: "Tahun -75%",
      perf_52w50u: "Tahun -50%",
      perf_52w30u: "Tahun -30%",
      perf_52w10u: "Tahun -10%",
      perf_52wdown: "Tahun Turun",
      perf_52wup: "Tahun Naik",
      perf_52w10o: "Tahun +10%",
      perf_52w50o: "Tahun +50%",
      perf_52w100o: "Tahun +100%",
      perf_52w300o: "Tahun +300%",
      perf_52w500o: "Tahun +500%",
      perf_ytd75u: "Tahun Ini -75%",
      perf_ytd50u: "Tahun Ini -50%",
      perf_ytd30u: "Tahun Ini -30%",
      perf_ytd10u: "Tahun Ini -10%",
      perf_ytddown: "Tahun Ini Turun",
      perf_ytdup: "Tahun Ini Naik",
      perf_ytd10o: "Tahun Ini +10%",
      perf_ytd30o: "Tahun Ini +30%",
      perf_ytd50o: "Tahun Ini +50%",
      perf_ytd100o: "Tahun Ini +100%",

      pe: "P / E",
      pe_low: "Rendah (<15)",
      pe_profitable: "Menguntungkan (>0)",
      pe_high: "Tinggi (>50)",
      pe_u5: "Di Bawah 5",
      pe_u10: "Di Bawah 10",
      pe_u15: "Di Bawah 15",
      pe_u20: "Di Bawah 20",
      pe_u30: "Di Bawah 30",
      pe_u40: "Di Bawah 40",
      pe_u50: "Di Bawah 50",
      pe_o5: "Di Atas 5",
      pe_o10: "Di Atas 10",
      pe_o15: "Di Atas 15",
      pe_o20: "Di Atas 20",
      pe_o30: "Di Atas 30",
      pe_o40: "Di Atas 40",
      pe_o50: "Di Atas 50",
      peg: "PEG",
      peg_low: "Rendah (<1)",
      peg_high: "Tinggi (>2)",
      peg_u1: "Di Bawah 1",
      peg_u2: "Di Bawah 2",
      peg_u3: "Di Bawah 3",
      peg_o1: "Di Atas 1",
      peg_o2: "Di Atas 2",
      peg_o3: "Di Atas 3",

      pb: "P / B",
      pb_low: "Rendah (<1)",
      pb_high: "Tinggi (>5)",
      pb_u1: "Di bawah 1",
      pb_u2: "Di bawah 2",
      pb_u3: "Di bawah 3",
      pb_u4: "Di bawah 4",
      pb_u5: "Di bawah 5",
      pb_u6: "Di bawah 6",
      pb_u7: "Di bawah 7",
      pb_u8: "Di bawah 8",
      pb_u9: "Di bawah 9",
      pb_u10: "Di bawah 10",
      pb_o1: "Di atas 1",
      pb_o2: "Di atas 2",
      pb_o3: "Di atas 3",
      pb_o4: "Di atas 4",
      pb_o5: "Di atas 5",
      pb_o6: "Di atas 6",
      pb_o7: "Di atas 7",
      pb_o8: "Di atas 8",
      pb_o9: "Di atas 9",
      pb_o10: "Di atas 10",

      ps: "P / S",
      ps_low: "Rendah (<1)",
      ps_high: "Tinggi (>5)",
      ps_u1: "Di bawah 1",
      ps_u2: "Di bawah 2",
      ps_u3: "Di bawah 3",
      ps_u4: "Di bawah 4",
      ps_u5: "Di bawah 5",
      ps_u6: "Di bawah 6",
      ps_u7: "Di bawah 7",
      ps_u8: "Di bawah 8",
      ps_u9: "Di bawah 9",
      ps_u10: "Di bawah 10",
      ps_o1: "Di atas 1",
      ps_o2: "Di atas 2",
      ps_o3: "Di atas 3",
      ps_o4: "Di atas 4",
      ps_o5: "Di atas 5",
      ps_o6: "Di atas 6",
      ps_o7: "Di atas 7",
      ps_o8: "Di atas 8",
      ps_o9: "Di atas 9",
      ps_o10: "Di atas 10",

      quick: "Rasio Cepat",
      quick_high: "Tinggi (>3)",
      quick_low: "Rendah (<0.5)",
      quick_u1: "Di bawah 1",
      quick_u0_5: "Di bawah 0.5",
      quick_o0_5: "Di atas 0.5",
      quick_o1: "Di atas 1",
      quick_o1_5: "Di atas 1.5",
      quick_o2: "Di atas 2",
      quick_o3: "Di atas 3",
      quick_o4: "Di atas 4",
      quick_o5: "Di atas 5",
      quick_o10: "Di atas 10",

      current: "Rasio Saat Ini",
      current_high: "Tinggi (>3)",
      current_low: "Rendah (<1)",
      current_u1: "Di bawah 1",
      current_u0_5: "Di bawah 0.5",
      current_o0_5: "Di atas 0.5",
      current_o1: "Di atas 1",
      current_o1_5: "Di atas 1.5",
      current_o2: "Di atas 2",
      current_o3: "Di atas 3",
      current_o4: "Di atas 4",
      current_o5: "Di atas 5",
      current_o10: "Di atas 10",

      sector: "Sektor",
      sector_realestate: "Real Estat",
      sector_health: "Perawatan Kesehatan",
      sector_energy: "Energi",
      sector_utilities: "Layanan Utilitas",
      sector_cd: "Konsumsi Defensif",
      sector_fs: "Layanan Keuangan",
      sector_coms: "Layanan Komunikasi",
      sector_cs: "Layanan Konsumen",
      sector_bm: "Bahan Dasar",
      sector_i: "Industri",
      sector_cc: "Konsumsi Siklis",
      sector_t: "Teknologi",
    },

    TopCompaniesTitle: "Perusahaan Terbesar di AS berdasarkan",
    TotalMarketCap: "Total Kapitalisasi Pasar",
    Companies: "Perusahaan",
    sortBy: "Urutkan berdasarkan",
    Terms: "Syarat & Ketentuan",
    Privacy: "Kebijakan Privasi",
    logout: "Keluar",
    portfolioLeader: "Pemimpin Portofolio",
    portfolio: {
      symbol: "Simbol",
      portfolioName: "Nama Portofolio",
      date: "Tanggal",
      price: "Harga",
      noOfShares: "Jumlah Saham",
      buy: "Beli",
      sellTransactions: "Transaksi Jual",
      purchasePrice: "Harga Beli",
      purchaseDate: "Tanggal Beli",
      priceSold: "Harga Jual",
      dateSold: "Tanggal Jual",
      profitAndLoss: "Laba & Rugi",
      company: "Perusahaan",
      sector: "Sektor",
      currentPrice: "Harga Saat Ini",
      avgPurchasePrice: "Harga Beli Rata-rata",
      totalShares: "Total Saham",
      portfolioPct: "% Portofolio",
      capital: "Modal",
      sell: "Jual",
      history: "Riwayat",
      pctReturns: "Pengembalian %",
      name: "Nama",
      noOfCompanies: "Jumlah Perusahaan",
      total: "Total",
    },
    login: {
      login: "Masuk",
      signup: "Daftar",
      forgotpassword: "Lupa Kata Sandi",
      resetpassword: "Atur Ulang Kata Sandi",
      email: "Email",
      password: "Kata Sandi",
      forgotPasswordQ: "Lupa kata sandi?",
      createAccount: "Buat Akun Stock Penguins Baru",
      firstName: "Nama Depan",
      lastName: "Nama Belakang",
      requestOTP: "Minta OTP",
      otp: "OTP",
    },
    stockScreener: "Penyaring Saham",
    topPresets: "Preset Teratas",
    myPortfolio: "Portofolio Saya",
    portfolioLeaderboard: "Papan Peringkat Portofolio",
    peHistoric: "P/E Historis",
    SimilarCompanies: "Perusahaan Serupa",
    Stock: "Saham",
    presetName: "Nama Preset",
    createdBy: "Dibuat Oleh",
    upvotes: "Suara Positif",
    savePreset: "Simpan Preset",
    share: "Bagikan",
    save: "Simpan",
    selectPreset: "Pilih Preset",
    loginToSP: "Masuk ke Stock Penguins",
    clearFilters: "Hapus Filter",

    today: "Perubahan 1 Hari",
    oneMonthChange: "Perubahan 1 Bulan",
    oneYearChange: "Perubahan 1 Tahun",
    OneYear: "1 Tahun",
    ThreeYear: "3 Tahun",
    FiveYear: "5 Tahun",
    TenYear: "10 Tahun",
    CAGRDesc: "Perubahan historis dari waktu ke waktu",
    welcome: "Selamat datang",
    SearchBarPlaceholder: "Apa investasi 10 kali lipat berikutnya Anda?",
    Search: "Cari",
    Industry: "Industri",
    Sector: "Sektor",
    MarketCap: "Kapitalisasi Pasar",
    PE: "P / E",
    PS: "P / S",
    PB: "P / B",
    FCFYield: "Hasil FCF",
    PEGRatio: "Rasio PEG",
    OverallRisk: "Skor Piotroski",
    week52HighPrice: "Harga Tertinggi 52 Minggu",
    PriceGrowthoverthepast5Years: "Pertumbuhan Harga dalam 5 Tahun Terakhir",
    PriceGrowthoverthepast1Years: "Pertumbuhan Harga dalam 1 Tahun Terakhir",
    Profitability: "Laba? (Triwulanan)",
    GrowingRevenue: "Pendapatan tumbuh? (Triwulanan)",
    GrowingNetProfit: "Keuntungan bersih tumbuh? (Triwulanan)",
    ProfitabilityAnnual: "Laba? (Tahunan)",
    GrowingRevenueAnnual: "Pendapatan tumbuh? (Tahunan)",
    GrowingNetProfitAnnual: "Keuntungan bersih tumbuh? (Tahunan)",
    FinancialMetrics: "Ringkasan Penilaian",
    BalanceSheet: "Metrik Keuangan",
    Cash: "Kas",
    Debt: "Utang",
    CashDebtRatio: "Rasio Kas/Utang",
    DividendYield: "Imbal Hasil Dividen",
    PayoutRatio: "Rasio Pembayaran",
    FiveYearAvgDividendYield: "Imbal Hasil Dividen Rata-Rata 5 Tahun",
    DividendGrowthRateoverthepast5Years:
      "Tingkat Pertumbuhan Dividen dalam 5 Tahun Terakhir",
    QualityOfCompany: "Kualitas Perusahaan",
    Dividend: "Dividen",
    comparedToLastQuarter: "Dibandingkan dengan Kuartal Terakhir",
    comparedToLastYear: "Dibandingkan dengan Tahun Lalu",
    newTab: "Tab baru",
    compare: "Bandingkan",
    topCompanies: "Perusahaan Teratas",
    watchlist: "Daftar Pantauan",
    rank: "Peringkat",
    companyName: "Nama Perusahaan",
    symbol: "Simbol",
    marketCap: "Kapitalisasi Pasar",
    price: "Harga",
    volume: "Volume",
    country: "Negara",
    joinDiscord: "Bergabung dengan Discord",
    joinTelegram: "Bergabung dengan Telegram",
    contactUs: "Hubungi Kami",
    InTheLast365Days: "Dalam 365 hari terakhir",
    LastUpdated: "Terakhir diperbarui",
    pages: "Halaman",

    titles: {
      PE: "P / E",
      PS: "P / S",
      PB: "P / B",
      RevenueGrowth: "Pertumbuhan Pendapatan %",
      NetIncomeGrowth: "Pertumbuhan Pendapatan Bersih %",
      Price: "Harga",
      MarketCap: "Kapitalisasi Pasar",
      Revenue: "Pendapatan",
      RevenueVsNetIncome: "Pendapatan vs. Laba Bersih",
      NetIncome: "Laba Bersih",
      Ebitda: "EBITDA",
      Eps: "EPS",
      TotalExpenses: "Total Biaya",
      SharesIssued: "Saham Beredar",
      FreeCashFlow: "Arus Kas Bebas",
      OperatingCashFlow: "Arus Kas Operasional",
      Dividend: "Dividen",
      GrossMargin: "Margin Kotor",
      CashVsDebt: "Kas vs. Utang",
    },
    description: {
      PE: "Rasio Harga terhadap Laba (P/E) mengukur seberapa banyak investor bersedia membayar untuk setiap dolar laba perusahaan; dihitung dengan membagi harga saham dengan laba per saham. Rasio P/E yang tinggi dapat menunjukkan bahwa investor mengharapkan tingkat pertumbuhan masa depan yang tinggi atau saham mungkin dinilai terlalu tinggi",
      PS: "Rasio Harga terhadap Penjualan (P/S) mengukur seberapa banyak investor membayar untuk setiap dolar penjualan perusahaan; dihitung dengan membagi harga saham dengan penjualan per saham. Rasio P/S yang tinggi dapat menunjukkan bahwa investor mengharapkan pertumbuhan penjualan masa depan yang tinggi atau saham mungkin dinilai terlalu tinggi",
      PB: "Rasio Harga terhadap Nilai Buku (P/B) adalah rasio yang membandingkan nilai pasar perusahaan dengan nilai bukunya (aset dikurangi kewajiban); dihitung dengan membagi harga saham dengan nilai buku per saham. Rasio P/B yang tinggi dapat menunjukkan keyakinan investor yang kuat pada kinerja masa depan perusahaan atau bahwa saham mungkin dinilai terlalu tinggi jika perusahaan tidak memiliki pertumbuhan aset yang kuat",
      RevenueGrowth: "Persentase pertumbuhan pendapatan",
      NetIncomeGrowth: "Persentase pertumbuhan pendapatan bersih",
      Price: "Harga saham historis perusahaan dari waktu ke waktu",
      MarketCap:
        "Nilai total saham yang beredar dari sebuah perusahaan dari waktu ke waktu",
      Revenue:
        "Jumlah uang yang dihasilkan dari kegiatan bisnis, penjualan barang dan jasa dari waktu ke waktu",
      RevenueVsNetIncome:
        "Perbandingan antara total pendapatan dan laba bersih perusahaan dari waktu ke waktu, menunjukkan seberapa berkelanjutan pertumbuhan perusahaan tersebut. Rasio yang lebih rendah dapat mengindikasikan bahwa perusahaan sedang mengembangkan keunggulan kompetitif dan mengambil pangsa pasar",
      NetIncome:
        "Keuntungan yang diperoleh sebuah perusahaan setelah semua biaya, pajak, dan biaya telah dikurangkan dari total pendapatan dari waktu ke waktu",
      Ebitda:
        "Pendapatan sebelum bunga, pajak, depresiasi, dan amortisasi sebuah perusahaan dari waktu ke waktu. Ini mengukur kinerja keuangan dan profitabilitas keseluruhan perusahaan dari operasi inti",
      Eps: "Bagian dari laba perusahaan yang dialokasikan untuk setiap saham biasa yang beredar. Ini menunjukkan berapa banyak uang yang akan diterima pemegang saham untuk setiap saham yang mereka miliki",
      TotalExpenses:
        "Jumlah semua biaya yang ditanggung oleh sebuah perusahaan dalam menjalankan bisnisnya, termasuk biaya operasional, bunga, pajak, dll. Grafik dapat menunjukkan total biaya dari waktu ke waktu, mengindikasikan tren biaya dan efisiensi",
      SharesIssued:
        "Jumlah total saham yang dikeluarkan oleh sebuah perusahaan",
      FreeCashFlow:
        "Uang tunai yang dihasilkan sebuah perusahaan setelah mempertimbangkan aliran keluar untuk mendukung operasi dan mempertahankan aset modal. Ini mewakili uang yang tersedia untuk dividen, pembayaran utang, atau reinvestasi",
      OperatingCashFlow:
        "Uang tunai yang dihasilkan dari operasi bisnis sebuah perusahaan dari waktu ke waktu. Ini menunjukkan apakah sebuah perusahaan dapat menghasilkan cukup arus kas positif untuk mempertahankan dan mengembangkan operasinya",
      Dividend:
        "Sebagian dari laba sebuah perusahaan yang didistribusikan kepada pemegang saham, biasanya dalam bentuk pembayaran tunai",
      GrossMargin:
        "Persentase pendapatan yang tersisa setelah dikurangi biaya barang yang dijual (COGS). Ini menunjukkan seberapa efisien sebuah perusahaan dalam memproduksi dan menjual produknya",
      CashVsDebt:
        "Perbandingan grafis antara cadangan kas perusahaan dengan total utangnya. Ini membantu menilai kesehatan keuangan dan likuiditas perusahaan",
    },
    dataDescriptions: {
      peHistorical:
        "Rata-rata historis rasio P/E 5 tahun memberikan cakupan durasi yang memadai untuk menangkap perubahan tren pasar dan kinerja perusahaan. Ini juga mencerminkan kondisi ekonomi terbaru, suku bunga, dan perkembangan industri.",
      peHistoricalCompare:
        "Jika rasio P/E di atas rata-rata historis, ini sering menunjukkan penilaian yang terlalu tinggi dan ekspektasi pertumbuhan yang tinggi, dengan risiko potensial jika pertumbuhan tidak tercapai. Sebaliknya, rasio P/E di bawah rata-rata menunjukkan penilaian rendah akibat pesimisme pasar atau masalah pertumbuhan sementara. Penting untuk mempertimbangkan faktor ekonomi, industri, dan faktor spesifik perusahaan, karena perusahaan yang sedang berkembang di industri yang sedang berkembang mungkin membenarkan penilaian yang lebih tinggi.",

      MarketCap:
        "Total nilai saham perusahaan; dihitung dengan mengalikan harga saham dengan total jumlah saham.",
      PE: "Rasio Harga terhadap Laba (P/E) mengukur seberapa banyak investor bersedia membayar untuk setiap dolar laba perusahaan; dihitung dengan membagi harga saham dengan laba per saham. Rasio P/E yang tinggi dapat menunjukkan bahwa investor mengharapkan tingkat pertumbuhan masa depan yang tinggi atau saham mungkin dinilai terlalu tinggi.",
      PS: "Rasio Harga terhadap Penjualan (P/S) mengukur seberapa banyak investor membayar untuk setiap dolar penjualan perusahaan; dihitung dengan membagi harga saham dengan penjualan per saham. Rasio P/S yang tinggi dapat menunjukkan bahwa investor mengharapkan pertumbuhan penjualan masa depan yang tinggi atau saham mungkin dinilai terlalu tinggi.",
      PB: "Rasio Harga terhadap Nilai Buku (P/B) adalah rasio yang membandingkan nilai pasar perusahaan dengan nilai bukunya (aset dikurangi kewajiban); dihitung dengan membagi harga saham dengan nilai buku per saham. Rasio P/B yang tinggi dapat menunjukkan keyakinan investor yang kuat pada kinerja masa depan perusahaan atau bahwa saham mungkin dinilai terlalu tinggi jika perusahaan tidak memiliki pertumbuhan aset yang kuat.",
      FCFYield:
        "Tingkat Hasil Arus Kas Bebas (FCF Yield) adalah ukuran seberapa banyak arus kas bebas yang dihasilkan perusahaan dibandingkan dengan nilai pasar perusahaan; dihitung dengan membagi arus kas bebas dengan nilai pasar.",
      PEGRatio:
        "Rasio Harga/Laba terhadap Pertumbuhan (PEG) adalah metrik penilaian yang mempertimbangkan rasio P/E perusahaan terkait dengan tingkat pertumbuhan laba; membantu melihat apakah saham dinilai terlalu tinggi atau terlalu rendah. Rasio PEG sebesar 1 menunjukkan nilai wajar, di bawah 1 menunjukkan penilaian rendah dibandingkan dengan tingkat pertumbuhan, dan di atas 1 menunjukkan penilaian tinggi dibandingkan dengan tingkat pertumbuhan.",
      OverallRisk:
        "Penilaian tentang seberapa berisiko suatu investasi, mempertimbangkan faktor-faktor seperti volatilitas, stabilitas keuangan, dan kondisi pasar; risiko yang lebih rendah biasanya lebih baik, tetapi tergantung pada toleransi risiko investor.",
      week52HighPrice:
        "Harga tertinggi yang dicapai saham dalam tahun lalu; saham yang diperdagangkan mendekati harga tertinggi 52 minggu mungkin menunjukkan kinerja yang kuat baru-baru ini, tetapi juga dapat menunjukkan bahwa saham tersebut dinilai terlalu tinggi.",
      ProfitabilityAnnual:
        "Apakah perusahaan telah menghasilkan lebih banyak uang daripada yang dibelanjakan selama tahun lalu; profitabilitas umumnya merupakan tanda baik dari kesehatan keuangan perusahaan.",
      GrowingRevenueAnnual:
        "Apakah pendapatan (penjualan) perusahaan telah meningkat dibandingkan dengan tahun sebelumnya; pertumbuhan pendapatan yang konsisten biasanya merupakan tanda positif dari ekspansi bisnis.",
      GrowingNetProfitAnnual:
        "Apakah laba bersih (pendapatan setelah biaya) perusahaan telah meningkat dibandingkan dengan tahun sebelumnya; laba bersih yang meningkat adalah indikator kuat dari perbaikan kinerja keuangan.",
      PriceGrowthoverthepast5Years:
        "Persentase kenaikan harga saham selama 5 tahun terakhir; persentase yang lebih tinggi menunjukkan kinerja jangka panjang yang lebih baik.",
      PriceGrowthoverthepast1Years:
        "Persentase kenaikan harga saham selama tahun lalu; pertumbuhan jangka pendek yang kuat adalah positif tetapi harus dipertimbangkan dalam konteks faktor lainnya.",
      Cash: "Jumlah total uang tunai yang tersedia bagi perusahaan untuk digunakan segera; lebih banyak uang tunai umumnya lebih baik untuk fleksibilitas keuangan.",
      Debt: "Jumlah total uang yang perusahaan berutang kepada pemberi pinjaman; utang yang tinggi dapat berisiko jika perusahaan tidak dapat menghasilkan pendapatan yang cukup untuk membayar utangnya.",
      CashDebtRatio:
        "Ukuran kemampuan perusahaan untuk membayar utangnya dengan uang tunai yang tersedia; rasio yang lebih tinggi menunjukkan kesehatan keuangan yang lebih baik.",
      Profitability:
        "Apakah perusahaan menghasilkan lebih banyak uang daripada yang dibelanjakan pada kuartal terbaru; menjadi menguntungkan setiap kuartal menunjukkan bahwa perusahaan secara konsisten menghasilkan pendapatan.",
      GrowingRevenue:
        "Apakah pendapatan perusahaan meningkat dibandingkan dengan kuartal sebelumnya; pertumbuhan pendapatan yang berkelanjutan adalah tanda keberhasilan bisnis yang berkelanjutan.",
      GrowingNetProfit:
        "Apakah laba bersih perusahaan meningkat dibandingkan dengan kuartal sebelumnya; laba bersih yang meningkat setiap kuartal menunjukkan perbaikan kinerja keuangan dalam jangka pendek.",
      DividendYield:
        "Pembayaran dividen tahunan yang diberikan perusahaan kepada pemegang saham, dinyatakan sebagai persentase dari harga saham; hasil yang lebih tinggi dapat menunjukkan lebih banyak pendapatan dari dividen relatif terhadap harga saham.",
      PayoutRatio:
        "Persentase dari laba perusahaan yang dibayarkan sebagai dividen kepada pemegang saham; rasio yang lebih rendah menunjukkan perusahaan menyimpan lebih banyak laba untuk pertumbuhan, sementara rasio yang sangat tinggi mungkin menunjukkan dividen tersebut dapat tidak berkelanjutan.",
      FiveYearAvgDividendYield:
        "Rata-rata hasil dividen yang ditawarkan perusahaan selama lima tahun terakhir; membantu investor melihat seberapa konsisten atau stabil pembayaran dividen sepanjang waktu.",
    },
    labels: {
      TotalCash: "Total Kas",
      TotalDebt: "Total Utang",
      Revenue: "Pendapatan",
      NetIncome: "Laba Bersih",
    },
    Quarterly: "Kuartalan",
    Annually: "Secara tahunan",
    FullScreen: "Layar penuh",
    ExitFullScreen: "Keluar dari layar penuh",
    homeMetaTitle: "StockPenguins | Pemindai Saham Global Gratis",
    homeMetaDescription:
      "Temukan aplikasi pemindai saham gratis terbaik untuk saham AS seperti Apple, Tesla, Amazon, dan Microsoft. Akses metrik terperinci seperti Harga, Kapitalisasi Pasar, Pendapatan, Laba Bersih, EBITDA, Arus Kas Bebas, EPS, Dividen, Rasio P/E, dan banyak lagi. Analisis dan bandingkan saham dengan mudah untuk membuat keputusan investasi yang tepat. Cari sekarang!",
    pageMetaTitle: "Metrik Saham | P/E, Pendapatan & Kapitalisasi Pasar",
    pageMetaDescription:
      "Temukan metrik gratis, termasuk Harga, Kapitalisasi Pasar, Pendapatan, Laba Bersih, EBITDA, Arus Kas Bebas, EPS, Dividen, Rasio P/E, dan banyak lagi. Tetap terinformasi tentang kinerja keuangan Apple. Jelajahi sekarang!",
  },
};
